import { gql } from 'apollo-boost';

export const GET_GROUP_NOTIFICATIONS_GQL = gql`
    query($groupId: ID!){
        group_announcements(groupId: $groupId){
            nodes{
                id
                title
                message
                created_at
                employee{ id name }
            }
        }
    }
`;
