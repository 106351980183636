import * as React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import S from './nav-selected-club-area.module.scss';
import { Image } from '../common/images/img-file';
import { RouteConstants } from '../routes/route-constants';
import { useBusiness } from '../../graphql/graph-hooks';

const NavSelectedClubArea = () => {
  const { t } = useTranslation();
  const { name, city, state, first_image } = useBusiness();

  return (
    <div className={S.navClubAreaWrapper}>
      <Link to={RouteConstants.clubSettings} className={S.navClubGrid}>
        <Image src={first_image?.thumb} alt={name || 'club'} fallback='CLUB' />
        <div className={S.navClubName}>{name}</div>
        <div className={S.navClubCity}>
          {city}, {state}
        </div>
      </Link>

      <Link to={RouteConstants.clubs} className={S.clubChange}>
        {t('club_nav.change_club')}
      </Link>
    </div>
  );
};

NavSelectedClubArea.displayName = 'NavSelectedClubArea';

export { NavSelectedClubArea };
