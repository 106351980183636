import ReactDOM from 'react-dom';
import React from 'react';
import Axios from 'axios';

import * as Sentry from '@sentry/react';
import LogRocket from 'logrocket';

import { toast } from 'react-toastify';
import './sass/libs/normalize.scss';
import 'react-toastify/dist/ReactToastify.css';
import 'react-image-gallery/styles/scss/image-gallery.scss';
import './sass/calendar-styles.scss';
import './sass/index.scss';

import { APIConstants } from './helpers/constants';
import { loadGoogleMaps } from './helpers/load-script';
import { RouteConstants } from './components/routes/route-constants';
import { ApolloComponent } from './components/apollo';
import './i18n';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import posthog from 'posthog-js';

import './index.css';

posthog.init(APIConstants.posthog_key, { api_host: 'https://app.posthog.com' });

Sentry.init({
  dsn: APIConstants.sentry_dsn,
  environment: APIConstants.environment,
  release: APIConstants.app_version,
});

LogRocket.init(APIConstants.logrocket_dsn);

LogRocket.getSessionURL((sessionURL) => {
  Sentry.configureScope((scope) => {
    scope.setExtra('sessionURL', sessionURL);
  });
});

const source = Axios.CancelToken.source();

Axios.defaults.baseURL = APIConstants.base_api_url;
Axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    const statusCode = (error.response || {}).status;

    if (statusCode === 403) {
      source.cancel('user is unauthorized');
      //  Now we can navigate wherever from here without worrying about other catch blocks

      window.location.replace(`${RouteConstants.timeout}${window.location.search}`);
      //  Tony + Frank: https://github.com/axios/axios/issues/715#issuecomment-290993181
      return new Promise(() => {});
    }

    return Promise.reject(error);
  }
);

toast.configure({
  position: toast.POSITION.BOTTOM_RIGHT,
  pauseOnHover: true,
  pauseOnFocusLoss: false,
});

loadGoogleMaps(() => {
  ReactDOM.render(<ApolloComponent />, document.getElementById('root'));
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
