import { ChangeEvent, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { RadioInput } from 'components/common/radio-buttons/radio-input';

import { BOOKING_STATUS } from 'helpers/enums';

const { ACCEPTED, CHECKED_IN, CHECKED_OUT, FAILED_TO_ATTEND } = BOOKING_STATUS;

const AcceptedFilter = ({ selected, onChange }: { selected?: string; onChange: (value: BOOKING_STATUS) => void }) => {
  const { t } = useTranslation();

  const options = [
    { label: `${t('common.all')} ${t('common.accepted')}`, value: 'ALL' },
    { label: `${t('common.not')} ${t('common.checked_in')}`, value: ACCEPTED },
    { label: t('common.checked_in'), value: CHECKED_IN },
    { label: t('common.checked_out'), value: CHECKED_OUT },
    { label: t('common.failed_to_attend'), value: FAILED_TO_ATTEND },
  ];

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => onChange(e.currentTarget.value as BOOKING_STATUS);

  return (
    <div className='flex gap-3'>
      {options.map(({ label, value }) => (
        <RadioInput
          value={value}
          label={label}
          key={`booking_filter_${value}`}
          id={`booking_filter_${value}`}
          name='ACCEPTED_BOOKING_FILTER'
          isChecked={selected === value}
          handleChange={handleOnChange}
        />
      ))}
    </div>
  );
};

export default memo(AcceptedFilter);
