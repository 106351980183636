import * as moment from 'moment-timezone';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { CalDayView } from './booking-views/cal-day-view';
import { CalWeekView } from './booking-views/cal-week-view';
import { CAL_VIEWS } from '../../../../../helpers/enums';
import * as TimeHelpers from '../../../../../helpers/times';
import i18next from 'i18next';
import posthog from 'posthog-js';

const TIMESTAMP_PATTERN = TimeHelpers.TimePatterns.timestamp;
const HOUR_PATTERN = TimeHelpers.TimePatterns.fullTime;
const { t } = i18next;

export const handleSelectAllow = (startStr, timezone, hasPermission) => {
  if (!hasPermission) {
    return false;
  }

  const createPastBookings = posthog.isFeatureEnabled('create-past-bookings');
  return createPastBookings || !moment.tz(startStr, timezone).startOf('day').isBefore(moment().startOf('day'));
};

export const convertBusinessHoursForCalendar = (hours_of_operation = []) => {
  const hoursArray = [];
  hours_of_operation
    .filter(({ closed }) => !closed)
    .forEach(({ day, open, close, all_day }) => {
      const OPEN = moment.utc(open);
      const CLOSE = moment.utc(close);
      const IS_BEFORE = OPEN.isBefore(CLOSE);

      if (all_day) {
        hoursArray.push({ daysOfWeek: [day], startTime: '00:00', endTime: '24:59' }); // We need to use this value so Fullcalendar wont display closed hours
      } else if (IS_BEFORE) {
        hoursArray.push({
          daysOfWeek: [day],
          startTime: OPEN.format('HH:mm'),
          endTime: CLOSE.format('HH:mm'),
        });
      } else {
        // Club closes the following day
        hoursArray.push({
          daysOfWeek: [day],
          startTime: OPEN.format('HH:mm'),
          endTime: '24:00',
        });

        const NEXT_INDEX = day + 1 === 7 ? 0 : day + 1;

        hoursArray.push({
          daysOfWeek: [NEXT_INDEX],
          startTime: '00:00',
          endTime: CLOSE.format('HH:mm'),
        });
      }
    });

  return hoursArray;
};

export const getCalendarViewInterval = (view) => {
  switch (view) {
    case CAL_VIEWS.MONTH:
      return 'month';
    case CAL_VIEWS.WEEK:
      return 'week';
    case CAL_VIEWS.DAY:
      return 'day';
    default:
      return null;
  }
};

export const normalizeCalEvents = (bookingList, view) =>
  bookingList.map((booking) => ({
    start: booking.start_time,
    end: booking.end_time,
    extendedProps: booking,
    classNames: getClassNames(view, bookingList, booking),
    title: createShiftTitle(
      view,
      booking.accepted_shift_applications_count +
        booking.checked_in_shift_applications_count +
        booking.checked_out_shift_applications_count,
      booking.slots,
      booking.shift_type
    ),
  }));

// used only for month views
const createShiftTitle = (view, acceptedCount, totalSlots, shiftType = '') => {
  const shift = shiftType
    ? shiftType.split(/_/).reduce((acu, cur) => acu.concat(cur.slice(0, 1).toUpperCase()), '')
    : '';
  if (view === CAL_VIEWS.MONTH) {
    return `- ${shift ? `${shift} -` : ''} ${acceptedCount}/${totalSlots} ${t('bookingCalendar.filled')}`;
  } else {
    return `Status: ${acceptedCount}/${totalSlots}  ${t('bookingCalendar.positions_filled')}`;
  }
};

const getClassNames = (
  view,
  list,
  {
    id,
    shift_type,
    slots,
    start_time,
    end_time,
    accepted_shift_applications_count,
    pending_shift_applications_count,
    checked_in_shift_applications_count,
    checked_out_shift_applications_count,
  }
) => {
  let classNames = '';

  if (shift_type === 'event') {
    classNames += ' fc-shift-event';
  }
  if (shift_type === 'promo') {
    classNames += ' fc-shift-promo';
  } else if (shift_type === 'audition') {
    classNames += ' fc-shift-audition';
  } else if (
    accepted_shift_applications_count + checked_in_shift_applications_count + checked_out_shift_applications_count >=
    slots
  ) {
    classNames += ' fc-shift-filled';
  } else if (pending_shift_applications_count > 0) {
    classNames += ' fc-shift-action-needed';
  } else {
    classNames += ' fc-shift-unfilled';
  }

  // Set Font Size
  if (view === CAL_VIEWS.DAY) {
    classNames += ' fc-medium-font';
  } else {
    classNames += ' fc-small-font';
  }

  if (view !== CAL_VIEWS.MONTH) {
    const WIDTH_NUM = list.reduce((acm, cur) => {
      if (cur.id === id) {
        return acm;
      }
      if (
        moment(cur.end_time).isSameOrBefore(moment(start_time)) ||
        moment(cur.start_time).isSameOrAfter(moment(end_time))
      ) {
        return acm;
      } else {
        return acm + 1;
      }
    }, 1);

    switch (WIDTH_NUM) {
      case 2:
        classNames += ' fc-width-half';
        break;
      case 3:
        classNames += ' fc-width-third';
        break;
      default:
        break;
    }
  }

  return classNames;
};

// THIS COMPONENT WILL NOT HAVE ACCESS TO REDUX STORE DUE TO ReactDOM.RENDER
// EVERYTHING NEEDED MUST BE PASSED VIA PROPS
export const renderBooking = (info, timezone, redirect) => {
  const VIEW = info.view.type;

  if (VIEW !== CAL_VIEWS.MONTH) {
    info.el.style.marginRight = '0';

    const eventProps = {
      onRedirect: redirect,
      viewLayout: VIEW,
      timezone: timezone,
      shift: info.event.extendedProps,
      start: info.event.start, //JS Date Obj
      end: info.event.end, //JS Date Obj
    };

    if (VIEW === CAL_VIEWS.WEEK) {
      ReactDOM.render(<CalWeekView {...eventProps} />, info.el);
    } else if (VIEW === CAL_VIEWS.DAY) {
      ReactDOM.render(<CalDayView {...eventProps} />, info.el);
    }
  }

  return info.el;
};

export const getEarliestOpeningTime = (view, hours_of_operation = [], date) => {
  const DEFAULT_OPENING_TIME = '01:00:00';
  if (hours_of_operation.some(({ all_day }) => all_day)) {
    return DEFAULT_OPENING_TIME;
  }

  if (view === CAL_VIEWS.DAY) {
    const DAY_NUM = moment(date).day();
    const MATCH = hours_of_operation.find((hourObj) => hourObj.day === DAY_NUM);

    if (MATCH && MATCH.closed) {
      return DEFAULT_OPENING_TIME;
    }

    return moment(MATCH.open, TIMESTAMP_PATTERN).subtract(2, 'hours').format(HOUR_PATTERN);
  } else if (view === CAL_VIEWS.WEEK) {
    const OPENING_TIME = hours_of_operation.reduce((acm, { closed, open }) => {
      if (closed) {
        return acm;
      }
      return moment.utc(open).isBefore(acm) ? moment.utc(open) : acm;
    }, moment.utc('2000-01-01 11:59PM', TIMESTAMP_PATTERN));

    return OPENING_TIME.subtract(2, 'hours').format(HOUR_PATTERN);
  } else {
    return DEFAULT_OPENING_TIME;
  }
};

export const getLatestClosingTime = (view, hours_of_operation = [], date) => {
  const DEFAULT_TIME = '2000-01-01 11:59AM';
  const DEFAULT_CLOSING_TIME = '24:59:00';
  const DEFAULT_DATE_TIME = moment(DEFAULT_TIME, TIMESTAMP_PATTERN);

  if (hours_of_operation.some(({ all_day }) => all_day)) {
    return DEFAULT_CLOSING_TIME;
  }

  if (view === CAL_VIEWS.DAY) {
    const DAY_NUM = moment(date).day();
    const match = hours_of_operation.find(({ day }) => day === DAY_NUM);

    if (match && match.closed) {
      return DEFAULT_CLOSING_TIME;
    }

    const time = moment.utc(match.close);
    if (!time.isBefore(DEFAULT_DATE_TIME)) {
      return time.format(HOUR_PATTERN);
    }

    // Add 24 to the hour so calendar extends to the next day, plus a few extra for padding.
    const hours = parseInt(time.format('H')) + 24 + 2;
    return `${hours}:${time.format('mm:ss')}`;
  } else if (view === CAL_VIEWS.WEEK) {
    let closingTime = moment(DEFAULT_TIME, TIMESTAMP_PATTERN);

    for (let i = 0; i < hours_of_operation.length; i++) {
      const { close, closed } = hours_of_operation[i];
      if (closed === false) {
        const CLOSE_TIME = moment.utc(close);

        if (CLOSE_TIME.isBefore(DEFAULT_DATE_TIME)) {
          CLOSE_TIME.add(1, 'days');
        }
        if (CLOSE_TIME.isAfter(closingTime)) {
          closingTime = CLOSE_TIME;
        }
      }
    }

    if (closingTime.isSame(DEFAULT_DATE_TIME, 'day')) {
      return closingTime.format(HOUR_PATTERN);
    } else {
      // Add 24 to the hour so calendar extends to the next day, plus a few extra for padding.
      const hour = parseInt(closingTime.format('H')) + 24 + 2;
      const minute = closingTime.format('mm');
      return `${hour}:${minute}:00`;
    }
  } else return DEFAULT_CLOSING_TIME;
};

const StartOfMonth = (dateTime, timezone) =>
  moment.tz(dateTime, timezone).startOf('month').subtract(7, 'days').toISOString();
const EndOfMonth = (dateTime, timezone) => moment.tz(dateTime, timezone).endOf('month').add(14, 'days').toISOString();
const StartOfWeek = (dateTime, timezone) =>
  moment.tz(dateTime, timezone).startOf('week').subtract(1, 'days').toISOString();
const EndOfWeek = (dateTime, timezone) => moment.tz(dateTime, timezone).endOf('week').add(1, 'days').toISOString();
const StartOf = (dateTime, timezone, interval) => moment.tz(dateTime, timezone).startOf(interval).toISOString();
const MidWeek = (dateTime, timezone) => moment.tz(dateTime, timezone).startOf('month').add(14, 'days').toISOString();
const MidDay = (dateTime, timezone) => moment.tz(dateTime, timezone).startOf('day').add(14, 'hours').toISOString();

export const getCalendarDateData = (view, date, timezone) => {
  if (view === CAL_VIEWS.MONTH) {
    return {
      targetDate: MidWeek(date, timezone),
      range: {
        endsAfter: StartOfMonth(date, timezone),
        startsBefore: EndOfMonth(date, timezone),
      },
    };
  } else if (view === CAL_VIEWS.WEEK) {
    return {
      targetDate: StartOf(date, timezone, 'week'),
      range: {
        endsAfter: StartOfWeek(date, timezone),
        startsBefore: EndOfWeek(date, timezone),
      },
    };
  } else if (view === CAL_VIEWS.DAY) {
    return {
      targetDate: MidDay(date, timezone),
      range: {
        endsAfter: StartOfMonth(date, timezone),
        startsBefore: EndOfMonth(date, timezone),
      },
    };
  } else return undefined;
};

export const getBusinessDayStart = (arrayOfDays, dateTime) => {
  const DAY_NUM = moment(dateTime).day();
  return arrayOfDays.find(({ day }) => day === DAY_NUM)?.open;
};

export const getBusinessWeekStart = (arrayOfDays) =>
  arrayOfDays.reduce((acm, { open, closed }) => {
    return !closed && !moment(open).isAfter(acm) ? open : acm;
  }, moment('2000-01-01 11:59PM', TIMESTAMP_PATTERN));
