import { gql } from 'apollo-boost';


export const COURSES_CATEGORY_GQL = gql`
    {
        course_categories{
            id
            name
        }
    }
`;
