import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  error: Error;
  resetErrorBoundary: () => void;
}
export const ErrorFallback: React.FC<Props> = ({ error, resetErrorBoundary }): React.ReactElement => {
  const { t } = useTranslation();
  return (
    <div role='alert'>
      <h3>{t('errors.generic')}</h3>
      <pre className='text-red-600'>{error.message}</pre>
      <button onClick={resetErrorBoundary}>{t('errors.try_again')}</button>
    </div>
  );
};
