import * as PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import S from './booking-image-field.module.scss';
import { useModalBookingCtx } from '../../../modal-booking-context';
import { setFormFields } from '../../../modal-booking-actions';
import { ImageField } from '../../../../../common/images/img-field';
import { FormLabel } from '../../../../../common/form/form-parts/form-label';

const BookingImageField = ({ isDisabled }) => {
  const { state, dispatch } = useModalBookingCtx();
  const { t } = useTranslation();

  const handleFileChange = useCallback(
    ({ preview, image_attachment }) => dispatch(setFormFields({ preview, image_attachment })),
    [dispatch]
  );

  const handleDeleteFile = useCallback(
    () => dispatch(setFormFields({ preview: null, image_attachment: null })),
    [dispatch]
  );

  return (
    <div className={S.bookingEventImg}>
      <FormLabel hasError={false} isDisabled={isDisabled} label={t('bookingCalendar.event_image')} />
      <ImageField
        hasPermission
        isLoading={false}
        alt={t('bookingCalendar.event_image')}
        size='250px'
        preview={state.form.preview}
        setFile={handleFileChange}
        isDisabled={!state.meta.isEditable}
        deleteFile={handleDeleteFile}
      />
    </div>
  );
};

BookingImageField.displayName = 'BookingImageField';
BookingImageField.propTypes = {
  isDisabled: PropTypes.bool.isRequired,
};

export { BookingImageField };
