import _throttle from 'lodash/throttle';
import { useState } from 'react';

export const useGroupNotificationsScroll = (gridRef, hasNextPage, loadNext) => {
  const [isLoading, setIsLoading] = useState(false);

  const loadMore = _throttle(async () => {
    const {scrollTop, scrollHeight, children} = gridRef.current;

    if (!isLoading && hasNextPage) {
      const LEFT_TO_SCROLL = scrollHeight - scrollTop;
      const ROW = children[0].clientHeight;

      if ((LEFT_TO_SCROLL) < (ROW * 10)) {
        setIsLoading(true);
        await loadNext();
        setIsLoading(false);
      }
    }
  }, 500);

  return { isLoading, loadMore };
};
