import { useCallback } from 'react';
import * as PropTypes from 'prop-types';
import classNames from 'classnames';

import S from './checkbox-list.module.scss';
import { CheckboxSpan } from './checkboxes/checkbox-span';

const CheckBoxList = ({ list, name, handleChange, selected }) => {
  const handleSelection = useCallback(
    (val) => {
      handleChange(selected.includes(val) ? selected.filter((v) => v !== val) : [...selected, val]);
    },
    [selected, handleChange]
  );

  return (
    <ul className={S.dropdownList}>
      {list.map(({ value, label }) => {
        const IS_SELECTED = selected.includes(value);
        return (
          <li
            className={classNames({ [S.active]: IS_SELECTED, [S.disabled]: !value })}
            key={`checkbox_list_${name}_${value}`}
            onClick={() => (value ? handleSelection(value) : null)}
          >
            <CheckboxSpan isForm isChecked={IS_SELECTED} disabled={!value} />
            {label}
          </li>
        );
      })}
    </ul>
  );
};

CheckBoxList.displayName = 'CheckBoxList';
CheckBoxList.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  selected: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export { CheckBoxList };
