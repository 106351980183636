import React, { useCallback, useState } from 'react';
import * as PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { validateEmail } from 'helpers/validations/login-validations';
import { CallPostPasswordRecover } from 'rest-calls';
import { LoginInputField } from '../login-input-field';

import S from '../pre-login-parts.module.scss';

type RecoveryFormProps = {
  onSuccess: (hasSubmitted: boolean) => void;
};

const RecoveryForm: React.FC<RecoveryFormProps> = ({ onSuccess }): React.ReactElement => {
  const { t } = useTranslation();
  const [isDisabled, setIsDisabled] = useState(false);
  const [emailObj, setEmailObj] = useState({ value: '', error: '' });

  const handleChange = useCallback((e) => setEmailObj({ ...emailObj, value: e.target.value.trim() }), [emailObj]);

  const handleBlur = useCallback((e) => {
    setEmailObj({
      value: e.target.value.trim(),
      error: validateEmail(e.target.value.trim()),
    });
  }, []);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const EMAIL = emailObj.value.trim();
    if (validateEmail(EMAIL)) return;

    try {
      setIsDisabled(true);
      const { data } = await CallPostPasswordRecover({ email: EMAIL });

      if (data.success) onSuccess(true);
      else {
        toast.error(t('errors.generic'));
        setIsDisabled(false);
      }
    } catch (err: any) {
      setIsDisabled(false);

      err.response.status === 404 ? toast.info(t('recoveryPage.not_in_database')) : toast.error(t('errors.generic'));
    } // don't use finally due to unMount onSuccess
  };

  return (
    <form onSubmit={handleSubmit} className={S.userForm}>
      <h1 className={S.pageHeading}>{t('recoveryPage.password_recovery')}</h1>

      <div className={S.mainText}>{t('recoveryPage.recovery_instructions')}</div>

      <LoginInputField
        label={t('common.email_address')}
        id='email'
        type='email'
        name='email'
        value={emailObj.value}
        isDisabled={isDisabled}
        handleChange={handleChange}
        handleBlur={handleBlur}
        error={emailObj.error}
      />

      <button type='submit' className={S.submitBtn} disabled={isDisabled}>
        {t('common.submit')}
      </button>
      <Link to={'/'} className={S.activeLink}>
        {t('recoveryPage.back_to_main')}
      </Link>
    </form>
  );
};

RecoveryForm.displayName = 'RecoveryForm';

RecoveryForm.propTypes = {
  onSuccess: PropTypes.func.isRequired,
};

export { RecoveryForm };
