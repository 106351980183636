import _debounce from 'lodash/debounce';
import { useState, useRef } from 'react';

export const useDebounce = (
  initValue,
  func,
  delay= 500
) => {
  const [displayValue, setDisplayValue] = useState(initValue);
  const delayedFunc = useRef(_debounce(func, delay)).current;

  const onChange = e => {
    setDisplayValue(e.target.value);
    delayedFunc(e.target.value);
  };

  return {
    value: displayValue,
    onChange
  };
};
