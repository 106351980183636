import * as React from 'react';

const SET_NEW_CLUB_KEYS = 'SET_NEW_CLUB_KEYS';
const SET_NEW_CLUB_KEY = 'SET_NEW_CLUB_KEY';
const SET_CLUB_FORM_KEY = 'SET_CLUB_FORM_KEY';
const SET_CLUB_FORM_KEYS = 'SET_CLUB_FORM_KEYS';
const SET_CLUB_HOUR_FIELD = 'SET_CLUB_HOUR_FIELD';
const SET_CLUB_IMG_FIELD = 'SET_CLUB_IMG_FIELD';
const SET_CLUB_ERROR_FIELD = 'SET_CLUB_ERROR_FIELD';

const EMPTY_DAY_OBJ = { open: '12:00AM', close: '12:00PM' };
const EMPTY_IMG_OBJ = { image_attachment: null, preview: null, isLoading: false };

const PAGE_NEW_CLUB_INIT_STATE = {
  view: 'basic',
  isDisabled: true,
  errors: {},
  loadingImgIndexes: [],
  form: {
    name: '',
    street1: '',
    street2: '',
    city: '',
    state: '',
    postal: '',
    url: '',
    phone: '',
    schedule: {
      0: { ...EMPTY_DAY_OBJ },
      1: { ...EMPTY_DAY_OBJ },
      2: { ...EMPTY_DAY_OBJ },
      3: { ...EMPTY_DAY_OBJ },
      4: { ...EMPTY_DAY_OBJ },
      5: { ...EMPTY_DAY_OBJ },
      6: { ...EMPTY_DAY_OBJ },
    },

    orgId: '',
    isActive: false,
    allowReviews: false,
    hasFreeAgents: false,
    under21Allowed: null,
    stageCount: '',
    sqFootage: '',
    maxBookings: '',
    profileOptions: [],
    requiredDocs: [],
    notes: '',
    auditionInfo: '',
    regLink: '',
    minDancersForFreeAgentSearch: 30,
    dancerCount: 0,
    images: {
      0: EMPTY_IMG_OBJ,
      1: EMPTY_IMG_OBJ,
      2: EMPTY_IMG_OBJ,
      3: EMPTY_IMG_OBJ,
      4: EMPTY_IMG_OBJ,
      5: EMPTY_IMG_OBJ,
      6: EMPTY_IMG_OBJ,
    },
  },
};

export const setClubField = (field = '', payload) => ({
  type: SET_NEW_CLUB_KEY,
  field,
  payload,
});

export const setClubFormField = (field = '', payload) => ({
  type: SET_CLUB_FORM_KEY,
  field,
  payload,
});

export const setClubFormFields = (payload) => ({
  type: SET_CLUB_FORM_KEYS,
  payload,
});

export const setClubFormHour = (field, payload) => ({
  type: SET_CLUB_HOUR_FIELD,
  field,
  payload,
});

export const setClubFormImg = (field, payload) => ({
  type: SET_CLUB_IMG_FIELD,
  field,
  payload,
});

export const setClubFormError = (field, payload) => ({
  type: SET_CLUB_ERROR_FIELD,
  field,
  payload,
});

function pageNewClubReducer(state, { type, field, payload }) {
  switch (type) {
    case SET_NEW_CLUB_KEY:
      return { ...state, [field]: payload };

    case SET_CLUB_HOUR_FIELD:
      return {
        ...state,
        form: {
          ...state.form,
          schedule: {
            ...state.form.schedule,
            [field]: payload,
          },
        },
      };

    case SET_CLUB_IMG_FIELD:
      return {
        ...state,
        form: {
          ...state.form,
          images: {
            ...state.form.images,
            [field]: payload,
          },
        },
      };

    case SET_CLUB_FORM_KEY:
      return { ...state, form: { ...state.form, [field]: payload } };

    case SET_CLUB_FORM_KEYS:
      return { ...state, form: { ...state.form, ...payload } };

    case SET_NEW_CLUB_KEYS:
      return { ...state, ...payload };

    case SET_CLUB_ERROR_FIELD:
      return { ...state, errors: { ...state.errors, [field]: payload } };

    default:
      throw new Error(`Unsupported action type: ${type}`);
  }
}

const PAGE_CTX = React.createContext();

export function PageClubFormProvider(props) {
  const [state, dispatch] = React.useReducer(pageNewClubReducer, PAGE_NEW_CLUB_INIT_STATE);
  const value = React.useMemo(() => [state, dispatch], [state]);

  return <PAGE_CTX.Provider value={value} {...props} />;
}

export function useClubFormCtx() {
  const context = React.useContext(PAGE_CTX);

  if (!context) {
    throw new Error(`${useClubFormCtx.name} must be used within a ${pageNewClubReducer.name}`);
  }

  const [state, dispatch] = context;
  return { state, dispatch };
}
