import { forwardRef } from 'react';
import * as PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import * as classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import S from './club-search-grid-item.module.scss';
import { Image } from '../../../common/images/img-file';
import { RouteConstants } from '../../../routes/route-constants';
import { useUser } from '../../../../graphql/graph-hooks';

const ClubSearchGridItem = forwardRef(
  (
    {
      isActive,
      closed,
      first_image,
      businessId,
      name,
      city,
      state,
      zip,
      street_1,
      street_2,
      phone_number,
      handleSelect,
      handleClose,
      handleOpen,
    },
    ref
  ) => {
    const { t } = useTranslation();
    const { admin, roles } = useUser();
    const TITLE = admin
      ? t('employeePage.roles.admin')
      : roles?.[businessId]?.slug
      ? t(`employeePage.roles.${roles?.[businessId]?.slug}`)
      : t('common.unknown');

    return (
      <li ref={ref} className={classNames(S.listItem, { [S.selected]: isActive })} onClick={handleSelect}>
        <div className={S.area}>
          <div className={S.thumbnail}>
            <Image
              alt={name}
              src={first_image?.medium}
              classname={classNames(S.img, { [S.closed]: closed })}
              fallback='CLUB'
            />
          </div>
        </div>
        <div className={S.area}>
          <div className={S.name}>{name}</div>
          <div className={S.title}>{TITLE}</div>
        </div>
        <div className={S.area}>
          <div>
            <div>{street_1}</div>
            {street_2 && <div>{street_2}</div>}
            <div>
              {city}, {state} {zip}
            </div>
          </div>
        </div>
        <div className={S.area}>{phone_number}</div>
        <div className={S.actionArea}>
          <Link className={S.viewLink} to={RouteConstants.clubSettings}>
            {t('common.view')}
          </Link>
          {admin && (
            <button
              type='button'
              className={closed ? S.invertedBtnGreen : S.invertedBtnRed}
              onClick={closed ? handleOpen : handleClose}
            >
              {closed ? t('common.open') : t('common.close')}
            </button>
          )}
        </div>
      </li>
    );
  }
);

ClubSearchGridItem.displayName = 'ClubSearchGridItem';

ClubSearchGridItem.propTypes = {
  street_1: PropTypes.string,
  street_2: PropTypes.string,
  businessId: PropTypes.string,
  isActive: PropTypes.bool.isRequired,
  closed: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  zip: PropTypes.string.isRequired,
  phone_number: PropTypes.string.isRequired,
  first_image: PropTypes.shape({
    id: PropTypes.string.isRequired,
    medium: PropTypes.string.isRequired,
  }),
  handleSelect: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleOpen: PropTypes.func.isRequired,
};

ClubSearchGridItem.defaultProps = {
  first_image: {},
  businessId: null,
  street_1: null,
  street_2: null,
};

export { ClubSearchGridItem };
