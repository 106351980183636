import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { useFetchMore as useFetchMoreComments, useModal } from 'components/global-hooks';
import { useUser } from 'graphql/graph-hooks';

import PageContainer from 'components/common/page/page-container';
import PageHeader from 'components/common/page/page-header';
import PageBody from 'components/common/page/page-body';
import LoadMoreButton from 'components/common/buttons/load-more-btn/load-more-btn';

import CommentList from './CommentList';
import StatusFilter from '../StatusFilter';

import { PageInfo, GetstreamCommentReport, GetstreamComment } from 'types/getstream';
import { GET_GETSTREAM_COMMENT_REPORT } from 'graphql/queries/getstream';
import { GETSTREAM_COMMENT_APPROVE, GETSTREAM_COMMENT_REJECT } from 'graphql/mutations/getstream';
import { APIConstants } from 'helpers/constants';
import { UI_MODALS } from 'helpers/enums';
import { logError } from 'helpers/errors/bug-report';

const SocialComments: React.FC = () => {
  const { t } = useTranslation();
  const { initModal, closeModal } = useModal();
  const [filter, setFilter] = useState('PENDING');
  const [comments, setComments] = useState<GetstreamComment[] | null>(null);
  const [pagesCount, setPagesCount] = useState(1);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const { getstream_user } = useUser();

  const { fetchMore: fetchMoreComments, error: fetchMoreError } = useFetchMoreComments('getstream_comment_reports');
  const { loading, error, data, refetch, fetchMore } = useQuery<{
    getstream_comment_reports: {
      nodes: GetstreamCommentReport[];
      pageInfo: PageInfo;
      totalCount: number;
    };
  }>(GET_GETSTREAM_COMMENT_REPORT, {
    fetchPolicy: 'cache-and-network',
    variables: { first: 20 * pagesCount, status: filter },
    pollInterval: filter === 'PENDING' ? 10000 : 0,
  });

  const [approve, { loading: approving }] = useMutation(GETSTREAM_COMMENT_APPROVE, {
    onError: (_error) => {
      toast.error(t('social.comments.approve.failure'));
      logError(_error, 'GETSTREAM_COMMENT_APPROVE', 'SocialComments');
    },
    onCompleted: () => {
      toast.success(t('social.comments.approve.success'));
      refetch();
    },
  });

  const [reject, { loading: rejecting }] = useMutation(GETSTREAM_COMMENT_REJECT, {
    onError: (_error) => {
      toast.error(t('social.comments.reject.failure'));
      logError(_error, 'GETSTREAM_COMMENT_REJECT', 'SocialComments');
    },
    onCompleted: () => {
      toast.success(t('social.comments.reject.success'));
      refetch();
    },
  });

  const fetchGetstreamComments = (ids: string[]) => {
    const requests = [];
    for (let i = 0; i < ids.length; i++) {
      const url = `${APIConstants.getstream.api.fullUrl}/reaction/${ids[i]}?api_key=${APIConstants.getstream.api.key}`;
      requests.push(
        fetch(url, {
          method: 'GET',
          cache: 'no-store',
          headers: {
            'Content-Type': 'application/json',
            'Stream-Auth-Type': 'jwt',
            Authorization: getstream_user.auth_token,
          },
        })
      );
    }
    return Promise.all(requests);
  };

  const mergeWithCachedComments = (data: GetstreamCommentReport[]): GetstreamComment[] => {
    return data.map(
      ({
        cache: {
          user_id,
          activity_id,
          data: { text },
          created_at,
        },
        ...comment
      }) => {
        return {
          ...comment,
          user_id,
          activity_id,
          text,
          created_at,
        };
      }
    );
  };

  const mergeWithGetstreamComments = async (data: GetstreamCommentReport[]): Promise<GetstreamComment[]> => {
    const results = await fetchGetstreamComments(data.map(({ getstream_id }) => getstream_id));
    const mergedComments = [];
    for (let i = 0; i < results.length; i++) {
      try {
        const result = await results[i].json();
        if (!result.id) throw result;
        const {
          id,
          user_id,
          activity_id,
          data: { text },
          created_at,
        } = result;
        const idx = data.findIndex(({ getstream_id }) => getstream_id === id);
        const comment = data.splice(idx, 1)[0];
        mergedComments.push({ ...comment, user_id, activity_id, text, created_at });
      } catch (error) {
        logError(error, 'fetchGetstreamComments', 'SocialComments');
      }
    }
    return mergedComments;
  };

  const handleOnApprove = (id: string) => {
    approve({ variables: { input: { id } } });
  };

  const handleOnReject = (id: string) => {
    initModal(UI_MODALS.CONFIRMATION, {
      title: t('social.comments.reject.confirm.title'),
      message: t('social.comments.reject.confirm.message'),
      onConfirmation: () => {
        closeModal();
        reject({ variables: { input: { id } } });
      },
    });
  };

  const handleLoadMore = async () => {
    setIsLoadingMore(true);
    await fetchMoreComments(fetchMore, {
      cursor: data?.getstream_comment_reports.pageInfo.endCursor,
    });
    setPagesCount(pagesCount + 1);
  };

  const handleOnView = async (id: string) => {
    try {
      // Fetch getstream post
      const response = await fetch(
        `${APIConstants.getstream.api.fullUrl}/activities/?api_key=${APIConstants.getstream.api.key}&ids=${id}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Stream-Auth-Type': 'jwt',
            Authorization: getstream_user.auth_token,
          },
        }
      );
      const { results } = await response.json();
      const [_, postId] = results[0].foreign_id.split(':');

      initModal(UI_MODALS.SOCIAL_POST, {
        id: postId,
      });
    } catch (error) {
      logError(error, 'Fetch getstream activities', 'Comments');
    }
  };

  useEffect(() => {
    if (loading || !data) return;

    const {
      getstream_comment_reports: { nodes },
    } = data;

    (async () => {
      const reportedComments = nodes.filter(({ status }) => status === filter);

      const formattedComments =
        filter === 'REJECTED'
          ? mergeWithCachedComments(reportedComments.filter(({ cache }) => cache !== null))
          : await mergeWithGetstreamComments(reportedComments);

      setComments(formattedComments);
      setIsLoadingMore(false);
    })();
  }, [loading, data, filter]);

  useEffect(() => {
    setComments(null);
    setPagesCount(1);
  }, [filter]);

  return (
    <PageContainer>
      <PageHeader title={t('admin_nav.comments')}>
        <StatusFilter value={filter} onChange={setFilter} />
      </PageHeader>
      <PageBody
        isLoading={loading || approving || rejecting || comments === null}
        error={(error || fetchMoreError) && t('errors.generic')}
      >
        {!error && comments && (
          <CommentList data={comments} onReject={handleOnReject} onApprove={handleOnApprove} onView={handleOnView} />
        )}

        {comments !== null && data?.getstream_comment_reports.pageInfo.hasNextPage && (
          <div className='mt-10 text-center'>
            <LoadMoreButton isLoading={isLoadingMore} onClick={handleLoadMore} />
          </div>
        )}
      </PageBody>
    </PageContainer>
  );
};

export default SocialComments;
