import { FC } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { toast } from 'react-toastify';
import StarRatings from 'react-star-ratings';
import { useTranslation } from 'react-i18next';

import { SUBMIT_RATING_GQL } from '../../../graphql/mutations/shift-application-rating-update';

import { RatingsProps } from './types';

const RATING_LIST = ['appearance', 'professionalism', 'presence'];

const Ratings: FC<RatingsProps> = ({ id, canChange, ratings }) => {
  const { t } = useTranslation();

  const [rateApplication] = useMutation<any, { [key: string]: string | number }>(SUBMIT_RATING_GQL, {
    variables: {
      id,
      rating1: ratings[0] || 0,
      rating2: ratings[1] || 0,
      rating3: ratings[2] || 0,
    },
    onError: () => {
      toast.error(t('manageBookings.rate.failure'));
    },
    update: (cache, { data: { shift_application_rate } }) => {
      toast.success(t('manageBookings.rate.success'));
      cache.writeData({
        id: `ShiftApplication:${id}`,
        data: shift_application_rate.shift_application,
      });
    },
  });

  const handleRating = (key: string, value: number) => rateApplication({ variables: { [key]: value } });

  return (
    <div className='flex flex-col justify-between'>
      {RATING_LIST.map((item, idx) => (
        <div
          key={`rating_area_${item}`}
          className='flex justify-between items-center text-sm capitalize text-business-purple'
        >
          <span className='text-xs mr-4'>{t('dancerPage.' + item)}</span>
          <StarRatings
            starDimension='13px'
            starSpacing='1px'
            numberOfStars={5}
            starRatedColor='#D1D439'
            starHoverColor={'#EC008C'}
            rating={ratings[idx] || 0}
            changeRating={canChange ? handleRating.bind(this, `rating${idx + 1}`) : () => null}
          />
        </div>
      ))}
    </div>
  );
};

export default Ratings;
