import React, { useCallback, useRef } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import S from './group-notification-list.module.scss';
import { GroupAnnouncementItem } from './group-notification-item';
import { useGroupNotificationsScroll } from '../use-infinte-scroll';
import * as GroupNoteCTX from '../group-notification-context';
import { LoadingDisco } from '../../../../common/loading/loading-disco';

const GroupNotificationList = ({ list, fetchMore, handleDelete }) => {
  const { t } = useTranslation();
  const LIST_REF = useRef();
  const {
    state: { selected, pagination },
    dispatch,
  } = GroupNoteCTX.useGroupNoteCtx();

  const getMore = () => {
    return fetchMore({
      variables: { cursor: pagination.endCursor },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) return previousResult;

        dispatch(
          GroupNoteCTX.setGroupNoteFields({
            pagination: fetchMoreResult.admin_group_announcements.pageInfo,
            totalCount: fetchMoreResult.admin_group_announcements.totalCount,
          })
        );

        return {
          admin_group_announcements: {
            ...fetchMoreResult.admin_group_announcements,
            nodes: [
              ...previousResult.admin_group_announcements.nodes,
              ...fetchMoreResult.admin_group_announcements.nodes,
            ],
          },
        };
      },
    });
  };

  const handleSelection = useCallback(
    (id) => {
      dispatch(
        GroupNoteCTX.setGroupNoteField(
          'selected',
          selected.includes(id) ? selected.filter((nId) => nId !== id) : [...selected, id]
        )
      );
    },
    [dispatch, selected]
  );

  const { isLoading, loadMore } = useGroupNotificationsScroll(LIST_REF, pagination.hasNextPage, getMore);

  return (
    <>
      <div className={S.groupNotificationTableHeader}>
        <div>{t('common.details')}</div>
        <div>{t('notificationsPage.announcement')}</div>
        <div>{t('common.actions')}</div>
      </div>
      <div ref={LIST_REF} onScroll={loadMore} className={S.scrollBar}>
        {list.map((notification) => (
          <GroupAnnouncementItem
            key={`ANNOUNCEMENT_${notification.id}`}
            announcement={notification}
            isSelected={selected.includes(notification.id)}
            onSelect={handleSelection}
            onDelete={handleDelete}
          />
        ))}

        {isLoading && (
          <div className={S.loadingRow}>
            <LoadingDisco />
          </div>
        )}
      </div>
    </>
  );
};

GroupNotificationList.displayName = 'GroupNotificationList';

GroupNotificationList.propTypes = {
  list: PropTypes.arrayOf(PropTypes.object),
  fetchMore: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
};

export { GroupNotificationList };
