import { forwardRef } from 'react';
import * as PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import * as classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import S from './club-search-list-item.module.scss';
import { Image } from '../../../common/images/img-file';
import { RouteConstants } from '../../../routes/route-constants';
import { useUser } from '../../../../graphql/graph-hooks';

const ClubSearchListItem = forwardRef(
  (
    {
      isActive,
      closed,
      id,
      zip,
      city,
      name,
      state,
      first_image,
      street_1,
      street_2,
      businessId,
      phone_number,
      handleSelect,
      handleClose,
      handleOpen,
    },
    ref
  ) => {
    const { t } = useTranslation();
    const { admin, roles } = useUser();
    const TITLE = admin
      ? t('employeePage.roles.admin')
      : roles?.[businessId]?.slug
      ? t(`employeePage.roles.${roles?.[businessId]?.slug}`)
      : t('common.unknown');

    return (
      <li ref={ref} className={classNames(S.listItem, { [S.active]: isActive })} onClick={handleSelect}>
        <div className={classNames(S.thumbnail, { [S.closed]: closed })}>
          <Image src={first_image?.thumb} alt={name} fallback='CLUB' />
        </div>
        <div className={S.columnsName}>
          <div>{name}</div>
          <div>{TITLE}</div>
        </div>
        <div className={S.columnsAddress}>
          <div>{street_1}</div>
          {street_2 && <div>{street_2}</div>}
          <div>
            {city}, {state} {zip}
          </div>
        </div>
        <div className={S.columnsPhone}>{phone_number}</div>

        <div className={S.actionArea}>
          <button type='button' disabled={isActive} className={S.invertedBtnBlue}>
            {t('common.select')}
          </button>
          <Link className={S.invertedBtnBlue} to={RouteConstants.clubSettings}>
            {t('common.view')}
          </Link>
          {admin && (
            <button
              type='button'
              className={closed ? S.invertedBtnGreen : S.invertedBtnRed}
              onClick={closed ? handleOpen : handleClose}
            >
              {closed ? t('common.open') : t('common.close')}
            </button>
          )}
        </div>
      </li>
    );
  }
);

ClubSearchListItem.displayName = 'ClubSearchListItem';

ClubSearchListItem.propTypes = {
  id: PropTypes.string.isRequired,
  businessId: PropTypes.string,
  first_image: PropTypes.object,
  street_1: PropTypes.string,
  street_2: PropTypes.string,
  isActive: PropTypes.bool.isRequired,
  closed: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  zip: PropTypes.string.isRequired,
  phone_number: PropTypes.string.isRequired,

  handleSelect: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleOpen: PropTypes.func.isRequired,
};

ClubSearchListItem.defaultProps = {
  first_image: {},
  businessId: null,
  street_1: null,
  street_2: null,
};

export { ClubSearchListItem };
