import * as React from 'react';
import * as PropTypes from 'prop-types';

import S from './message-area.module.scss';

const TextMessage = ({ lang, title, message, hasLabel }) => (
  <div className={S.textWrapper}>
    {hasLabel && <span>{lang}:</span>}
    <div className={S.msgTitle}>{title}</div>
    <div>{message}</div>
  </div>
);

TextMessage.displayName = 'NotificationMsg';
TextMessage.propTypes = {
  hasLabel: PropTypes.bool.isRequired,
  lang: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
};

const MessageArea = ({ message, title }) => {
  const HAS_SPANISH = !!(title.es || message.es);

  return (
    <div className={S.msgWrapper}>
      <TextMessage lang='English' message={message.en} title={title.en} hasLabel={HAS_SPANISH} />
      {HAS_SPANISH && <TextMessage lang='Spanish' message={message.es} title={title.es} hasLabel />}
    </div>
  );
};

const MsgShape = PropTypes.shape({
  en: PropTypes.string.isRequired,
  es: PropTypes.string,
});

MessageArea.displayName = 'MessageArea';
MessageArea.propTypes = {
  message: MsgShape.isRequired,
  title: MsgShape.isRequired,
};

export { MessageArea };
