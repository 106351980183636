export const filterViaText = (
  collection = [],
  key: string,
  text: string
): Record<string, unknown>[] => {
  const TEST_STRING = text.toLowerCase();
  return collection.filter((obj) => {
    if (!obj[key]) return false;

    const STRING = String(obj[key]);
    return STRING.toLowerCase().includes(TEST_STRING);
  });
};

