import i18n from 'i18next';

export const validateGroupName = (name = '', list = []) => {
  const { t } = i18n;
  const LIST = list?.map((name) => name.toLowerCase());

  if (name === '') return t('errors.validation.required', { field_name: t('modals.group_add_dancer.group.name') });
  else if (name.length < 3)
    return t('errors.validation.min_length', { field_name: t('modals.group_add_dancer.group.name'), min_length: 3 });
  else if (name.length > 120)
    return t('errors.validation.max_length', { field_name: t('modals.group_add_dancer.group.name'), max_length: 120 });
  else if (LIST.includes(name.toLowerCase().trim()))
    return t('errors.validation.unique', { field_name: t('modals.group_add_dancer.group.name') });
  else return '';
};

export const validateGroupId = (id, list = []) => {
  if (id === '')
    return t('errors.validation.required', { field_name: t('modals.group_add_dancer.group.group') + 'ID' });
  else if (!['-1', ...list].includes(id)) return t('errors.validation.invalid_option');
  return '';
};
