import { gql } from 'apollo-boost';

export const BUSINESS_EMPLOYEES_GQL = gql`
    query($businessId:ID!) {
        business(id: $businessId) {
            employments{
                id
                role
                added_by { id name }
                employee{
                    id
                    name
                    email
                    phone_number
                    notes
                    roles
                    first_image {
                        id
                        thumb
                    }
                }
            }
        }
    }
`;
