import { URL_PATTERN } from '../regex-patterns';
import i18n from 'i18next';

const COURSE_CONFIG = { title: { min: 3, max: 120 } };
const { t } = i18n;
export const validateCourseField = (field, value) => {
  switch (field) {
    case 'title':
      return validateCourseTitle(value);
    case 'videoUrl':
      return validateCourseUrl(value, t('modals.course.video_url'), true);
    case 'imageUrl':
      return validateCourseUrl(value, t('modals.course.image_url'), false);
    case 'description':
      return '';
    default:
      return 'Field is unknown';
  }
};

const validateCourseTitle = (text) => {
  if (text === '') return t('errors.validation.required', { field_name: t('modals.course.title') });
  else if (text.length < COURSE_CONFIG.title.min)
    return t('errors.validation.min_length', {
      field_name: t('modals.course.title'),
      min_length: COURSE_CONFIG.title.min,
    });
  else if (text.length > COURSE_CONFIG.title.max)
    return t('errors.validation.max_length', {
      field_name: t('modals.course.title'),
      max_length: COURSE_CONFIG.title.max,
    });
  else return '';
};

const validateCourseUrl = (url, field, isRequired) => {
  if (isRequired && url === '') return t('errors.validation.required', { field_name: t('modals.course.video_url') });
  if (!URL_PATTERN.test(url)) return t('errors.validation.invalid_url');
  else return '';
};

export const validateCourseCategory = (selections, validList) =>
  selections.reduce((acm, cur) => {
    if (acm) return acm;
    return !validList.includes(cur) ? t('errors.validation.invalid_option') : acm;
  }, '');
