import * as React from 'react';
import * as PropTypes from 'prop-types';
import { useApolloClient } from '@apollo/react-hooks';

import { NotificationList } from './notification-list';
import S from './notification-wrapper.module.scss';
import { GET_GROUP_NOTIFICATIONS_GQL } from '../../../../../../../graphql/queries/group-announcements';
import { useTranslation } from 'react-i18next';

const NotificationWrapper = ({ groupId, timezone }) => {
  const { t } = useTranslation();

  const CLIENT = useApolloClient();
  const DATA = CLIENT.readQuery({ query: GET_GROUP_NOTIFICATIONS_GQL, variables: { groupId } });

  return DATA?.group_announcements?.nodes.length === 0 ? (
    <h3 className={S.noBookingWrapper}>{t('groupsPage.no_announcements')}</h3>
  ) : (
    <NotificationList list={DATA.group_announcements.nodes} timezone={timezone} />
  );
};

NotificationWrapper.displayName = 'AnnouncementsPresenter';
NotificationWrapper.propTypes = {
  groupId: PropTypes.string.isRequired,
  timezone: PropTypes.string.isRequired,
};

export { NotificationWrapper };
