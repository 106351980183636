import React, { useMemo } from 'react';
import * as PropTypes from 'prop-types';

import S from './dancer-grid-location.module.scss';
import { useBusiness } from '../../../graphql/graph-hooks';
import { closestLocation } from '../../../helpers/distance';

const DancerGridLocation = ({ locations }) => {
  const { longitude, latitude } = useBusiness();

  const CLOSEST_LOCATION = useMemo(
    () => closestLocation({ lon: longitude, lat: latitude }, locations).location,
    [locations, longitude, latitude]
  );

  return <div className={S.dancerGridLocation}>{CLOSEST_LOCATION}</div>;
};

DancerGridLocation.displayName = 'DancerGridLocation';
DancerGridLocation.propTypes = {
  locations: PropTypes.array,
};

DancerGridLocation.defaultProps = {
  locations: [],
};

export { DancerGridLocation };
