import React from 'react';
import { MobileData } from './bi_mobile_data';
import { SignupData } from './bi_signup_data';
import { CumulativeBusinessesData } from './bi_cumulative_businesses_data';
import { CumulativeDancersData } from './bi_cumulative_dancers_data';
import { MonthlyBusinessCount } from './bi_daily_businesse_count';
import { VisitsPerPeriod } from './bi_visits_per_period';
import { VisitorsMapped } from './bi_visitors_mapped';

const StatisticsPage = () => {
  return (
    <div className='container mx-auto mt-4'>
      <div className='flex flex-row'>
        <div className='w-full'>
          <VisitsPerPeriod />
        </div>
      </div>
      <div className='flex flex-row'>
        <div className='w-full h-136'>
          <VisitorsMapped />
        </div>
      </div>
      <div className='flex flex-row'>
        <div className='basis-1/2'>
          <MobileData />
        </div>
        <div className='basis-1/2'>
          <SignupData />
        </div>
      </div>
      <div className='flex flex-row'>
        <div className='basis-1/2'>
          <CumulativeDancersData />
        </div>
        <div className='basis-1/2'>
          <CumulativeBusinessesData />
        </div>
      </div>
      <div className='flex flex-row'>
        <div className='w-full'>
          <MonthlyBusinessCount />
        </div>
      </div>
    </div>
  );
};

StatisticsPage.displayName = 'StatisticsPage';

export { StatisticsPage };
