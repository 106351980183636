import moment from 'moment';
import posthog from 'posthog-js';

import { isInEditableRange, isInRejectRange } from './helpers';
import { BOOKING_STATUS } from 'helpers/enums';

import { ACTION_TYPES } from './constants';
import { GetActionsInput } from './types';

const {
  INVITE_CANCEL,
  ACCEPT,
  ACCEPT_CANCEL,
  STANDBY,
  STANDBY_CANCEL,
  REJECT,
  REJECT_CANCEL,
  CHECK_IN,
  CHECK_IN_CANCEL,
  CHECK_OUT,
  CHECK_OUT_CANCEL,
  RATE_UNDO,
  FAILED_TO_ATTEND,
  FAILED_TO_ATTEND_CANCEL,
} = ACTION_TYPES;

export const getActions = ({
  status,
  permissions,
  slotsAvailable,
  appNum,
  start_time,
  end_time,
  ratedNum,
  isInvite,
}: GetActionsInput): ACTION_TYPES[] => {
  const createPastBookings = posthog.isFeatureEnabled('create-past-bookings');
  const IN_EDITABLE_RANGE = createPastBookings || isInEditableRange(start_time, end_time);
  const IN_REJECTION_RANGE = createPastBookings || isInRejectRange(end_time);
  const AFTER_CHECKIN = moment().isAfter(start_time);
  const HAS_ROOM = slotsAvailable - appNum >= 0;
  const actionMap = {
    [BOOKING_STATUS.INVITED]: [INVITE_CANCEL],
    [BOOKING_STATUS.PENDING]: [ACCEPT, STANDBY, REJECT],
    [BOOKING_STATUS.ACCEPTED]: [ACCEPT_CANCEL, CHECK_IN, FAILED_TO_ATTEND, REJECT],
    [BOOKING_STATUS.STANDBY]: [STANDBY_CANCEL, ACCEPT, REJECT],
    [BOOKING_STATUS.REJECTED]: [REJECT_CANCEL, ACCEPT, STANDBY],
    [BOOKING_STATUS.CHECKED_IN]: [CHECK_IN_CANCEL, CHECK_OUT],
    [BOOKING_STATUS.CHECKED_OUT]: [CHECK_OUT_CANCEL, RATE_UNDO],
    [BOOKING_STATUS.FAILED_TO_ATTEND]: [FAILED_TO_ATTEND_CANCEL],
  };
  const availableActions = actionMap[status] ? actionMap[status] : [];
  const actions: ACTION_TYPES[] = [];

  availableActions.forEach((action) => {
    if (
      (ratedNum !== appNum && action === RATE_UNDO) ||
      (ratedNum && action === CHECK_OUT_CANCEL) ||
      (!permissions.CAN_MANAGE_REQUESTS &&
        [ACCEPT, ACCEPT_CANCEL, STANDBY, STANDBY_CANCEL, REJECT, REJECT_CANCEL].includes(action)) ||
      ((!permissions.CAN_SEND_INVITES || !isInvite) && action === INVITE_CANCEL) ||
      (!HAS_ROOM && status !== BOOKING_STATUS.FAILED_TO_ATTEND && [ACCEPT].includes(action)) ||
      (!permissions.CAN_CHECK_IN &&
        [CHECK_IN, CHECK_OUT, CHECK_IN_CANCEL, CHECK_OUT_CANCEL, RATE_UNDO, FAILED_TO_ATTEND].includes(action)) ||
      (!IN_REJECTION_RANGE && [REJECT].includes(action)) ||
      (!IN_EDITABLE_RANGE && !createPastBookings && [CHECK_IN].includes(action)) ||
      (!AFTER_CHECKIN && [FAILED_TO_ATTEND].includes(action)) ||
      (status === BOOKING_STATUS.ACCEPTED && isInvite && [REJECT].includes(action)) ||
      (status === BOOKING_STATUS.REJECTED && isInvite)
    ) {
      // not available for one of the reasons
    } else {
      actions.push(action);
    }
  });
  return actions;
};
