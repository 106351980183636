import { gql } from 'apollo-boost';

export const IMG_DELETE = gql`
    mutation($imgId: ID!) {
        image_delete(input: { 
            id: $imgId
        }) {
            message 
        }
    }
`;
