import * as React from 'react';
import * as PropTypes from 'prop-types';

import { LoginLayout } from './pages-login/login-layout';

const SET_GLOBAL_FIELD = 'SET_GLOBAL_FIELD';
const RESET_GLOBAL_STATE = 'RESET_GLOBAL_STATE';
const SET_APP_FIELDS = 'SET_APP_FIELDS';
const SET_MODAL_FIELDS = 'SET_MODAL_FIELDS';

//DATA WILL NOT PERSIST
export const GLOBAL_INIT_STATE = {
  pageSearchParams: null,

  app: {
    unreadBusiness: 0,
    unreadAdmin: 0,
    appCount: 0,
    auditionCount: 0,
    verificationCount: 0,
    photoVerificationCount: 0,
    inviteCodeRequestsCount: 0,
    dobChangeRequestsCount: 0,
    version: null,
  },

  modal: {
    history: [],
    type: null,
    context: null,
  },
};

export const setModalData = (payload) => ({ type: SET_MODAL_FIELDS, payload });
export const setAppData = (payload) => ({ type: SET_APP_FIELDS, payload });
export const setGlobalField = (field = '', payload) => ({ type: SET_GLOBAL_FIELD, field, payload });

function globalReducer(state, { type, field, payload }) {  
  switch (type) {
    case SET_GLOBAL_FIELD:
      return { ...state, [field]: payload };

    case RESET_GLOBAL_STATE:
      return GLOBAL_INIT_STATE;

    case SET_MODAL_FIELDS:
      const history = [...state.modal.history];
      const { isClosing, ...rest } = payload;      
      if(isClosing){
        history.pop();        
      } else if(state.modal.type){
        history.push({ type: state.modal.type, context: state.modal.context });
      } 
      
      return { ...state, modal: { ...state.modal, history, ...rest } };

    case SET_APP_FIELDS:
      return { ...state, app: { ...state.app, ...payload } };

    default:
      throw new Error(`Unsupported action type: ${type}`);
  }
}

const GLOBAL_CTX = React.createContext();

export function GlobalProvider(props) {
  const [state, dispatch] = React.useReducer(globalReducer, GLOBAL_INIT_STATE);
  const value = React.useMemo(() => [state, dispatch], [state]);

  return (
    <GLOBAL_CTX.Provider value={value} {...props}>
      <LoginLayout businessId={props.businessId} />
    </GLOBAL_CTX.Provider>
  );
}

GlobalProvider.propTypes = {
  businessId: PropTypes.string,
};

export function useGlobalCtx() {
  const context = React.useContext(GLOBAL_CTX);

  if (!context) {
    throw new Error(`${useGlobalCtx.name} must be used within a ${globalReducer.name}`);
  }

  const [state, dispatch] = context;
  return { state, dispatch };
}
