import { gql } from 'apollo-boost';

export const GET_SHIFT_STATS = gql`
  query BusinessShiftStats($businessId: ID!, $startDate: DateTime!, $endDate: DateTime!) {
    business_shift_stats(businessId: $businessId, startDate: $startDate, endDate: $endDate) {
      nodes {
        date
        shift_type
        accepted_count
        checked_in_count
        checked_out_count
        pending_count
        rejected_count
        standby_count
        failed_to_attend_count
      }
    }
  }
`;
