import React, { useMemo } from 'react';
import { AxisOptions, Chart } from 'react-charts';
import { ErrorBoundary } from 'react-error-boundary';
import { SerieData, ChartData } from '../../../types/charts.d';
import { ErrorFallback } from '../error_fallback';

type Formatter = (value: number) => string;

interface Formatters {
  scale?: Formatter;
  tooltip?: Formatter;
  cursor?: Formatter;
}

interface Props {
  data: SerieData[];
  chartType?: 'area' | 'bar' | 'line' | 'bubble' | undefined;
  stacked?: boolean;
  colorArray?: string[];
  formatters?: Formatters;
}

export const BarChart: React.FC<Props> = ({ data, chartType, stacked, colorArray, formatters }): React.ReactElement => {
  const primaryAxis = useMemo<AxisOptions<ChartData>>(
    () => ({
      getValue: (datum) => datum.date,
    }),
    []
  );

  const secondaryAxes = useMemo<AxisOptions<ChartData>[]>(
    () => [
      {
        getValue: (datum) => datum.value,
        stacked: stacked || false,
        elementType: chartType || 'bar',
        shouldNice: true,
        minDomainLength: 1,
        min: 0,
        hardMin: 0,
        scaleType: 'linear',
        formatters: formatters,
      },
    ],
    [chartType, stacked, formatters?.scale, formatters?.tooltip, formatters?.cursor]
  );

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Chart
        options={{
          data,
          primaryAxis,
          secondaryAxes,
          defaultColors: colorArray,
          dark: true,
          padding: { bottom: 50 },
        }}
      />
    </ErrorBoundary>
  );
};
