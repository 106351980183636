import React, { FC, useCallback } from 'react';

import S from './password-reset-button.module.scss';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useMutation } from '@apollo/react-hooks';
import { PASSWORD_RESET_CREATE } from '../../../graphql/mutations/password_reset_create';
import { logError } from '../../../helpers/errors/bug-report';
import { toast } from 'react-toastify';
import { ButtonSpinner } from '../../common/button_spinner';

type Props = {
  userId: string;
  className?: string;
};

const PasswordResetButton: FC<Props> = ({ userId, className }) => {
  const { t } = useTranslation();

  const [resetPassword, { loading: isLoading }] = useMutation(PASSWORD_RESET_CREATE, {
    onError: (err) => {
      logError(err, 'PASSWORD_RESET_CREATE', PasswordResetButton.displayName);
      toast.error(`${t('errors.server.500')}: ${t('employeePage.password_reset_failure')}`);
    },
    onCompleted: () => toast.success(t('employeePage.password_reset_success')),
  });

  const onPasswordReset = useCallback(
    async () => {
      await resetPassword({
        variables: {
          input: {
            userId: userId
          }
        }
      });
    },
    [resetPassword, userId]
  );

  return (
    <button
      className={classNames(S.invertedBtnGreen, S.button, className)}
      onClick={onPasswordReset}
      disabled={isLoading}
    >
      {isLoading ? <ButtonSpinner classes={'mr-0'} /> : t('common.reset_password')}
    </button>
  );
};

export default PasswordResetButton;
