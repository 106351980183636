import * as React from 'react';

import { ModalSwitcher } from './modal-switcher';
import { useGlobalCtx } from '../global-context';
import S from '../../sass/overlay.module.scss';

const ModalOverlay = () => {
  const {
    state: {
      modal: { type, context },
    },
  } = useGlobalCtx();

  return !type ? null : (
    <div className={S.darkOverlay}>
      <ModalSwitcher type={type} ctx={context} />
    </div>
  );
};

ModalOverlay.displayName = 'ModalOverlay';

export { ModalOverlay };
