import _range from 'lodash/range';
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import S from './hours-of-operation.module.scss';
import { HoursColumn } from './hours-column';
import { FormLabel } from '../../../../../common/form/form-parts/form-label';

const HoursOfOperation = ({ isDisabled }) => {
  const { t } = useTranslation();
  return (
    <div style={{ gridArea: 'hours' }}>
      <FormLabel gridArea='label' label={t('clubPage.opening_hours')} isDisabled={isDisabled} hasError={false} />

      <div className={S.columns}>
        {_range(0, 7).map((num) => (
          <HoursColumn key={`Hours_col_${num}`} isDisabled={isDisabled} index={num} />
        ))}
      </div>
    </div>
  );
};

HoursOfOperation.displayName = 'HoursOfOperation';

HoursOfOperation.propTypes = {
  isDisabled: PropTypes.bool.isRequired,
};

export { HoursOfOperation };
