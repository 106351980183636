import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';

import { useModal, useFetchMore as useFetchMorePosts } from 'components/global-hooks';

import PageContainer from 'components/common/page/page-container';
import PageHeader from 'components/common/page/page-header';
import PageBody from 'components/common/page/page-body';
import LoadMoreButton from 'components/common/buttons/load-more-btn/load-more-btn';

import StatusFilter from '../StatusFilter';
import PostList from './PostList';

import {
  GETSTREAM_POST_APPROVE,
  GETSTREAM_POST_COMMENTS_DISABLE,
  GETSTREAM_POST_COMMENTS_ENABLE,
  GETSTREAM_POST_REJECT,
  GETSTREAM_POST_DELETE,
} from 'graphql/mutations/getstream';
import { GET_GETSTREAM_POSTS } from 'graphql/queries/getstream';

import { PageInfo, GetstreamPost } from 'types/getstream';
import { UI_MODALS } from 'helpers/enums';
import { logError } from 'helpers/errors/bug-report';

const SocialPosts: React.FC = () => {
  const { t } = useTranslation();
  const { initModal, closeModal } = useModal();
  const [filter, setFilter] = useState('PENDING');
  const {
    isLoading: isFetchMoreLoading,
    fetchMore: fetchMorePosts,
    error: fetchMoreError,
  } = useFetchMorePosts('getstream_posts');

  const { loading, error, data, refetch, fetchMore } = useQuery<{
    getstream_posts: { nodes: GetstreamPost[]; pageInfo: PageInfo; totalCount: number };
  }>(GET_GETSTREAM_POSTS, {
    fetchPolicy: 'network-only',
    variables: { first: 20, status: filter, commentReportsStatus: 'REJECTED' },
  });

  const [approve, { loading: approving }] = useMutation(GETSTREAM_POST_APPROVE, {
    onError: (_error) => {
      toast.error(t('social.posts.approve.failure'));
      logError(_error, 'GETSTREAM_POST_APPROVE', 'SocialPosts');
    },
    onCompleted: async () => {
      toast.success(t('social.posts.approve.success'));
      await refetch();
    },
  });

  const [reject, { loading: rejecting }] = useMutation(GETSTREAM_POST_REJECT, {
    onError: (_error) => {
      toast.error(t('social.posts.reject.failure'));
      logError(_error, 'GETSTREAM_POST_REJECT', 'SocialPosts');
    },
    onCompleted: () => {
      toast.success(t('social.posts.reject.success'));
      refetch();
    },
  });

  const [deletePost, { loading: deleting }] = useMutation(GETSTREAM_POST_DELETE, {
    onError: (_error) => {
      toast.error(t('social.posts.delete.failure'));
      logError(_error, 'GETSTREAM_POST_DELETE', 'SocialPosts');
    },
    onCompleted: () => {
      toast.success(t('social.posts.delete.success'));
      refetch();
    },
  });

  const [disableComments, { loading: disablingComments }] = useMutation(GETSTREAM_POST_COMMENTS_DISABLE, {
    onError: (_error) => {
      toast.error(t('social.posts.disable_comments.failure'));
      logError(_error, 'GETSTREAM_POST_COMMENTS_DISABLE', 'SocialPosts');
    },
    onCompleted: () => {
      toast.success(t('social.posts.disable_comments.success'));
    },
  });

  const [enableComments, { loading: enablingComments }] = useMutation(GETSTREAM_POST_COMMENTS_ENABLE, {
    onError: (_error) => {
      toast.error(t('social.posts.enable_comments.failure'));
      logError(_error, 'GETSTREAM_POST_COMMENTS_ENABLE', 'SocialPosts');
    },
    onCompleted: () => {
      toast.success(t('social.posts.enable_comments.success'));
    },
  });

  const handleGetMore = () => {
    fetchMorePosts(fetchMore, {
      cursor: data?.getstream_posts.pageInfo.endCursor,
    });
  };

  const handleOnApprove = (id: string) => {
    approve({ variables: { input: { id } } });
  };

  const handleOnReject = (id: string) => {
    initModal(UI_MODALS.CONFIRMATION, {
      title: t('social.posts.reject.confirm.title'),
      message: t('social.posts.reject.confirm.message'),
      onConfirmation: () => {
        closeModal();
        reject({ variables: { input: { id } } });
      },
    });
  };

  const handleOnDelete = (id: string) => {
    initModal(UI_MODALS.CONFIRMATION, {
      title: t('social.posts.delete.confirm.title'),
      message: t('social.posts.delete.confirm.message'),
      onConfirmation: () => {
        closeModal();
        deletePost({ variables: { input: { id } } });
      },
    });
  };

  const handleOnView = async (id: string) => {
    initModal(UI_MODALS.SOCIAL_POST, {
      id,
      postData: data?.getstream_posts.nodes.find((node) => node.id === id),
    });
  };

  const handleOnToggleComments = async (event: React.MouseEvent<HTMLInputElement>) => {
    const { id } = event.currentTarget;
    const commentsDisabled = event.currentTarget.getAttribute('value') === 'true';
    const mutation = commentsDisabled ? enableComments : disableComments;
    await mutation({ variables: { input: { id } } });
  };

  return (
    <PageContainer>
      <PageHeader title={t('admin_nav.posts')}>
        <StatusFilter value={filter} onChange={setFilter} />
      </PageHeader>
      <PageBody
        isLoading={loading || approving || rejecting || deleting || disablingComments || enablingComments}
        error={(error || fetchMoreError) && t('errors.generic')}
      >
        {(!loading || !error) && data?.getstream_posts.nodes && (
          <PostList
            data={data.getstream_posts.nodes}
            onView={handleOnView}
            onReject={handleOnReject}
            onDelete={handleOnDelete}
            onApprove={handleOnApprove}
            onToggleComments={handleOnToggleComments}
          />
        )}

        {data?.getstream_posts.pageInfo.hasNextPage && (
          <div className='mt-10 mb-10 text-center'>
            <LoadMoreButton isLoading={isFetchMoreLoading} onClick={handleGetMore} />
          </div>
        )}
      </PageBody>
    </PageContainer>
  );
};

export default SocialPosts;
