import { gql } from 'apollo-boost';

export const CREATE_GROUP_MSG_GQL = gql`
    mutation group_msg($groupId: ID!, $title: LocalizedStringHash!, $message: LocalizedStringHash!){
        group_notify(input:{
            groupId: $groupId
            title: $title
            message: $message
        }){
            group_announcement{
                id
                title
                message
                created_at
                employee{ id name }
            }
        }
    }
`;
