import * as React from 'react';

const SET_MODAL_EXISTING_KEYS = 'SET_MODAL_EXISTING_KEYS';
const SET_MODAL_EXISTING_ROLE = 'SET_MODAL_EXISTING_ROLE';

const MODAL_EXISTING_INIT_STATE = {
  selectedClubId: [],
  form: {},
};


export const setExistingRoleField = (id, role) => ({
  type: SET_MODAL_EXISTING_ROLE,
  field: id,
  payload: role,
});

export const setExistingFields = payload => ({
  type: SET_MODAL_EXISTING_KEYS, payload,
});

function ModalExistingReducer(state, { type, field, payload }) {
  switch (type) {
    case SET_MODAL_EXISTING_KEYS:
      return {...state, ...payload};

    case SET_MODAL_EXISTING_ROLE:
      return {...state, form: {...state.form, [field]: payload}};

    default:
      throw new Error(`Unsupported action type: ${type}`);
  }
}

const PAGE_CTX = React.createContext();


export function ModalExistingProvider(props) {
  const [state, dispatch] = React.useReducer(ModalExistingReducer, MODAL_EXISTING_INIT_STATE);
  const value = React.useMemo(() => [state, dispatch], [state]);

  return <PAGE_CTX.Provider value={value} {...props} />;
}


export function useExistingCtx() {
  const context = React.useContext(PAGE_CTX);

  if (!context) {
    throw new Error(`${useExistingCtx.name} must be used within a ${ModalExistingReducer.name}`);
  }

  const [state, dispatch] = context;
  return { state, dispatch };
}
