import * as PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router';
import { toast } from 'react-toastify';
import React, { useCallback, useMemo, useState } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { logError } from '../../../../helpers/errors/bug-report';
import { useTranslation } from 'react-i18next';

import { useEmployeeForm } from './use-employee-form';
import S from '../user-page.module.scss';
import { RouteConstants, RouteCreator } from '../../../routes/route-constants';
import { FilterCheckBox } from '../../../common/checkboxes/filter-checkbox';
import { FormTextField } from '../../../common/form/form-text-field';
import { FormLabel } from '../../../common/form/form-parts/form-label';
import { FormError } from '../../../common/form/form-parts/form-error';
import { InputFormSelect } from '../../../common/form/form-parts/input-form-select';
import { InputFormTextarea } from '../../../common/form/form-parts/input-form-textarea';
import { ImageField } from '../../../common/images/img-field';
import { ErrorPage } from '../../../common/pages/error-page';
import { LoadingPage } from '../../../common/pages/loading-page';
import { useUser } from '../../../../graphql/graph-hooks';
import { CREATE_EMPLOYEE_GQL } from '../../../../graphql/mutations/employee-create';
import { IMG_DELETE } from '../../../../graphql/mutations/image-delete';
import { UPDATE_EMPLOYEE_GQL } from '../../../../graphql/mutations/employee-update';
import { GET_EMPLOYEE_GQL } from '../../../../graphql/queries/employee-page-query';
import { EMPLOYEE_LVLS } from '../../../../helpers/constants';
import { EMPLOYEE_ROLES } from '../../../../helpers/enums';
import { NON_NUMBERS } from '../../../../helpers/regex-patterns';
import { CallUpdateUserPhoto } from '../../../../rest-calls';
import PasswordResetButton from '../password-reset-button';

const EmployeePage = ({ businessId }) => {
  const { t, i18n } = useTranslation();
  const { employmentId } = useParams();
  const HISTORY = useHistory();
  const [isLoadingPic, setIsLoadingPic] = useState(false);
  const { id: userId, admin, roles: userRoles } = useUser();
  // execs are level 10, admin needs to be higher to not cause issues
  const USER_LVL = admin ? 100 : EMPLOYEE_LVLS[userRoles[businessId].slug] ?? 0;
  const PAGE_HEADER = useMemo(() => (employmentId ? t('common.edit') : t('common.new')), [employmentId]);
  const ACTION = useMemo(() => (employmentId ? 'common.update' : 'common.create'), [employmentId]);

  const { src, values, errors, roleOptions, handleChange, updateImage, handleOnBlur, isValid, setForm, permissions } =
    useEmployeeForm(businessId);

  const checkGqlErrors = (error) => {
    if (error.graphQLErrors?.length) {
      const errorKeys = Object.keys(error.graphQLErrors[0].extensions);
      switch (errorKeys[0]) {
        case 'email':
          switch (error.graphQLErrors[0].extensions[errorKeys[0]]) {
            case 'has already been taken':
              return t('errors.email_already_registered');
          }
          break;
      }
    }
    return t('errors.validation_error');
  };

  const ON_ERROR = useCallback(
    (err) => {
      const messageParts = err.message.split(':');
      const message = messageParts.length ? messageParts[messageParts.length - 1].trim() : t('errors.generic');
      let toastMessage;
      switch (message) {
        case 'DANCERS_CANNOT_BE_EMPLOYEES':
        case 'EMAIL_ALREADY_REGISTERED':
          toastMessage = t('errors.email_already_registered');
          break;
        case 'validation errors':
          toastMessage = checkGqlErrors(err);
          break;
        default:
          toastMessage = `${t('errors.server.500')}: ${t('errors.could_not_action_to_object', {
            action: t(`${ACTION.toLowerCase()}`),
            object: t('common.employee'),
          })}`;
      }
      toast.error(toastMessage);
    },
    [ACTION]
  );

  const ON_COMPLETE = useCallback(
    async ({ employment_create }) => {
      try {
        if (values.image_attachment) {
          setIsLoadingPic(true);
          await CallUpdateUserPhoto(employment_create.employee.id, values.image_attachment);
        }
        toast.success(
          t('errors.object_has_been_action_successfully', {
            object: values.name,
            action: t(`${ACTION.toLowerCase()}d`).toLowerCase(),
          })
        );
      } catch (err) {
        logError(err, 'employment_create', EmployeePage.displayName);
        toast.error(t('errors.unable_to_add_image'));
      }

      HISTORY.push(RouteConstants.employeeSearch);
    },
    [ACTION, HISTORY, values.name, values.image_attachment]
  );

  const EMPLOYEE_FORM = useMemo(
    () => ({
      businessId,
      role: values.role,
      name: values.name,
      email: values.email,
      phoneNumber: values?.phone_number?.replace(NON_NUMBERS, ''),
      permissions: values.permissions,
      notes: values.notes,
    }),
    [businessId, values]
  );

  const { loading, error, data } = useQuery(GET_EMPLOYEE_GQL, {
    skip: !employmentId,
    fetchPolicy: 'network-only',
    variables: { employmentId },
    onCompleted: (data) => {
      if (data?.employee && userId === data.employee.id) {
        HISTORY.push(RouteCreator.userForm(userId));
      } else {
        setForm(data?.employee ?? {});
      }
    },
  });

  const [createEmployee, { loading: createLoading }] = useMutation(CREATE_EMPLOYEE_GQL, {
    variables: EMPLOYEE_FORM,
    onError: ON_ERROR,
    onCompleted: ON_COMPLETE,
  });

  const [updateEmployee, { loading: updateLoading }] = useMutation(UPDATE_EMPLOYEE_GQL, {
    variables: { ...EMPLOYEE_FORM, userId: values.userId },
    onError: ON_ERROR,
    onCompleted: ON_COMPLETE,
  });

  const [deleteImg, { loading: processDeleteImg }] = useMutation(IMG_DELETE, {
    update: (cache) => {
      updateImage({ preview: null, image_attachment: null });
      cache.writeData({
        id: `Employee:${data.employee.id}`,
        data: { first_image: null },
      });
    },
    onError: (err) => {
      logError(err, IMG_DELETE, EmployeePage.displayName);
      toast.error(t('errors.unable_to_remove_image'));
    },
  });

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      if (isValid()) {
        employmentId ? updateEmployee() : createEmployee();
      }
    },
    [isValid, employmentId, createEmployee, updateEmployee]
  );

  const handleDeleteImage = () => {
    const imgId = data?.employee?.first_image?.id;
    if (imgId) {
      deleteImg({ variables: { imgId } });
    } else {
      updateImage({ preview: null, image_attachment: null });
    }
  };

  const EMPLOYEE_LVL = EMPLOYEE_LVLS[data?.employee?.roles?.[businessId]?.slug] ?? 0;
  const IS_DISABLED = useMemo(
    () => createLoading || processDeleteImg || updateLoading || EMPLOYEE_LVL >= USER_LVL || isLoadingPic,
    [createLoading, processDeleteImg, updateLoading, EMPLOYEE_LVL, USER_LVL, isLoadingPic]
  );

  const disableEdits = () => {
    if (admin) {
      return false;
    } else {
      return IS_DISABLED || ![EMPLOYEE_ROLES.BOOKING_AGENT, EMPLOYEE_ROLES.MANAGER].includes(values.role);
    }
  };

  if (loading) {
    return <LoadingPage />;
  }
  if (error) {
    return <ErrorPage msg={t('errors.unable_to_load_employee')} />;
  }

  return (
    <form className={S.userPageWrapper} onSubmit={onSubmit}>
      <header>
        <h1 className={S.h1Heading}>
          {PAGE_HEADER} {i18n.language === 'en' ? t('common.employee') : t('common.employee').toLowerCase()}
        </h1>
        <button className={S.invertedBtnGreen} disabled={IS_DISABLED}>
          {t(ACTION)} {t('common.employee').toLowerCase()}
        </button>
        <PasswordResetButton userId={userId} className={S.passwordResetButton}></PasswordResetButton>
      </header>

      <div className={S.formArea}>
        <section className={S.imgArea}>
          <h2>{t('employeePage.employee_image')}</h2>
          <ImageField
            isRound
            isLoading={loading}
            hasPermission={!IS_DISABLED}
            size='220px'
            alt='Employee Image'
            isDisabled={IS_DISABLED}
            setFile={updateImage}
            preview={src}
            deleteFile={handleDeleteImage}
          />
        </section>

        <section className={S.textFields}>
          <h2>{t('employeePage.employee_info')}</h2>
          <div className={S.row}>
            <FormTextField
              id='employeeFormNameField'
              name='name'
              label={t('common.full_name')}
              isDisabled={IS_DISABLED}
              error={errors.name}
              value={values.name}
              handleChange={handleChange}
              handleBlur={handleOnBlur}
            />

            <FormTextField
              id='employeeFormEmailField'
              name='email'
              label={t('common.email_address')}
              isDisabled={IS_DISABLED}
              value={values.email}
              error={errors.email}
              handleChange={handleChange}
              handleBlur={handleOnBlur}
            />
          </div>

          <div className={S.row}>
            <div>
              <FormLabel
                id='employeeFormRoleField'
                label={t('common.role')}
                hasError={!!errors.role}
                isDisabled={IS_DISABLED}
              />
              <InputFormSelect
                id='employeeFormRoleField'
                name='role'
                value={values.role}
                hasError={!!errors.role}
                isDisabled={IS_DISABLED}
                onChange={handleChange}
                options={roleOptions}
              />
              <FormError error={errors.role} />
            </div>

            {admin && (
              <FormTextField
                id='employeeFormPhoneField'
                name='phone_number'
                label={t('common.phone')}
                value={values.phone_number}
                error={errors.phone_number}
                isDisabled={IS_DISABLED}
                handleChange={handleChange}
                handleBlur={handleOnBlur}
              />
            )}
          </div>

          {admin && (
            <div>
              <FormLabel
                id='employeeFormNotesField'
                label={t('common.notes')}
                hasError={!!errors.notes}
                isDisabled={IS_DISABLED}
              />
              <InputFormTextarea
                id='employeeFormNotesField'
                name='notes'
                value={values.notes}
                placeholder={t('common.notes')}
                hasError={!!errors.notes}
                isDisabled={IS_DISABLED}
                handleChange={handleChange}
                handleBlur={handleOnBlur}
                rows={5}
              />
              <FormError error={errors.notes} />
            </div>
          )}
        </section>

        <section className={S.permissions}>
          <h2>{t('common.permissions')}</h2>

          {permissions.map(({ label, value }) => (
            <FilterCheckBox
              key={`PERMISSION_${value}`}
              id={`PERMISSION_${value}`}
              label={label}
              value={value}
              name='permissions'
              isDisabled={disableEdits()}
              isChecked={values.permissions.includes(value)}
              handleChange={handleChange}
            />
          ))}
        </section>
      </div>
    </form>
  );
};

EmployeePage.displayName = 'EmployeePage';

EmployeePage.propTypes = {
  businessId: PropTypes.string.isRequired,
};

export { EmployeePage };
