import { gql } from 'apollo-boost';

export const IMG_ORDER = gql`
    mutation(
        $businessId: ID!
        $type: Imageable!
        $order: [ID!]!
    ) {
        image_order(input: {
            imageableId: $businessId
            imageableType: $type
            imageIds: $order
        }) {
            images {
                id
                thumb
                position
            }
        }
    }
`;
