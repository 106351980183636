import React, { useEffect } from 'react';
import { BarChart } from '../../../common/chart_bar';
import { LoadingDiscoWithContainer } from '../../../common/loading/loading-disco';
import { useQuery } from 'react-query';
import { APIConstants } from '../../../../helpers/constants';
import { SerieData } from '../../../../types/charts';
import { useTranslation } from 'react-i18next';

export const VisitsPerPeriod = () => {
  const { t } = useTranslation();
  const [period, setPeriod] = React.useState('month');
  const token = localStorage.getItem('token');
  const { data, isLoading, error, refetch, isFetching } = useQuery<SerieData[]>(['visitsPerPeriod'], () =>
    fetch(`${APIConstants.BI_API_URL}/visits-per-period?per_period=${period}`, {
      headers: [['Authorization', `Bearer ${token}`]],
      credentials: 'omit',
    }).then((res) => res.json())
  );

  useEffect(() => {
    refetch();
  }, [period]);

  return (
    <div className='h-136 shadow ring-1 ring-gray-200 ring-opacity-5 md:rounded-lg'>
      <h2 className='mx-auto py-2 pl-6 text-lg font-medium leading-6 text-gray-400'>
        Visits per{' '}
        <select
          disabled={isFetching || isLoading}
          onChange={(e) => {
            setPeriod(e.target.value);
          }}
          defaultValue={period}
          className='bg-slate-900 border-2 border-gray-900 rounded-full px-1 py-1 font-medium leading-6 text-gray-400 hover:border-gray-700 focus:outline-none focus:border-gray-700 focus:shadow-outline-gray active:bg-gray-700 transition ease-in-out duration-150'
        >
          <option value='day'>{t('common.day')}</option>
          <option value='month'>{t('common.month')}</option>
        </select>
      </h2>
      {error ? (
        <div>{t('errors.generic')}</div>
      ) : !data || isLoading || isFetching ? (
        <LoadingDiscoWithContainer />
      ) : (
        <BarChart data={data} chartType='line' />
      )}
    </div>
  );
};
