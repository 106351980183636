export const sortByCollectionName = (isAsc, list) =>
  list.sort((a, b) => {
    const NAME_A = a.name.toUpperCase();
    const NAME_B = b.name.toUpperCase();
    let comparison = 0;

    if (isAsc) {
      if (NAME_A > NAME_B) comparison = 1;
      else if (NAME_A < NAME_B) comparison = -1;
    } else {
      if (NAME_A < NAME_B) comparison = 1;
      else if (NAME_A > NAME_B) comparison = -1;
    }
    return comparison;
  });
