import * as React from 'react';
import * as PropTypes from 'prop-types';

import { CheckBoxDropDown } from './form-parts/checkbox-dropdown';
import { FormError }        from './form-parts/form-error';
import { FormLabel }        from './form-parts/form-label';

const DropdownCheckboxField = ({
  id,
  name,
  label,
  value,
  error,
  gridArea,
  classname,
  collection,
  isDisabled,
  handleChange,
}) => {
  return (
    <div className={classname} style={{gridArea}}>
      <FormLabel
        id={id}
        label={label}
        isDisabled={isDisabled}
        hasError={!!error}
      />
      <CheckBoxDropDown
        name={name}
        list={collection}
        selected={value}
        disabled={isDisabled}
        handleChange={value => handleChange({name, value})}
      />
      <FormError error={error} />
    </div>
  );
};

DropdownCheckboxField.propTypes = {
  id:           PropTypes.string.isRequired,
  label:        PropTypes.string.isRequired,
  value:        PropTypes.array.isRequired,
  name:         PropTypes.string.isRequired,
  error:        PropTypes.string,
  gridArea:     PropTypes.string,
  classname:    PropTypes.string,
  isDisabled:   PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  collection: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired
  }))
};

export { DropdownCheckboxField };
