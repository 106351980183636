import { DANCER_PAGES } from '../../../../../helpers/enums';

export function getDancersSearchHeading(page) {
  switch (page) {
    case DANCER_PAGES.SUSPENDED:
      return 'dancerPage.paused_entertainers';
    case DANCER_PAGES.BLOCKED:
      return 'dancerPage.blocked_entertainers';
    case DANCER_PAGES.SEARCH:
      return 'dancerPage.entertainers';
    case DANCER_PAGES.ADMIN_SEARCH:
      return 'dancerPage.dancers';
    default:
      return undefined;
  }
}
