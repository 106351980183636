import i18next from 'i18next';
import { EMAIL_REGEX } from '../regex-patterns';

export const validateLoginPassword = (value) =>
  value === '' ? i18next.t('errors.validation.required', { field_name: i18next.t('common.password') }) : '';

export const validatePassword = (value) => {
  if (value === '') return i18next.t('errors.validation.required', { field_name: i18next.t('common.password') });
  else if (value.length < 8) return i18next.t('errors.password.min');
  else if (value.length > 64) return i18next.t('errors.password.max');
  else return '';
};

export function validateEmail(email) {
  if (email === '') return i18next.t('errors.validation.required', { field_name: i18next.t('common.email') });
  else if (!EMAIL_REGEX.test(email))
    return i18next.t('errors.validation.invalid', { field_name: i18next.t('common.email') });
  else return '';
}
