import * as React from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import S from './group-aside.module.scss';
import { NotificationWrapper } from './notifications/notification-wrapper';
import { PreApprovedBookings } from './pre-approved-bookings/pre-approved-wrapper';
import { AsideTabWrapper } from '../../../../../common/aside-tab-wrapper';

const GroupPageAside = ({ timezone, groupId }) => {
  const { t } = useTranslation();
  return (
    <aside className={S.groupDetailAside}>
      <AsideTabWrapper label={t('groupsPage.preapproved_bookings')}>
        <PreApprovedBookings groupId={groupId} timezone={timezone} />
      </AsideTabWrapper>

      <AsideTabWrapper label={t('groupsPage.blast_notifications')}>
        <NotificationWrapper groupId={groupId} timezone={timezone} />
      </AsideTabWrapper>
    </aside>
  );
};

GroupPageAside.displayName = 'GroupPageAside';

GroupPageAside.propTypes = {
  timezone: PropTypes.string.isRequired,
  groupId: PropTypes.string.isRequired,
};

export { GroupPageAside };
