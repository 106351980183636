import { gql } from 'apollo-boost';

import { GET_USER_FRAGMENT } from '../fragments/user-fragment';


export const USER_LOGIN = gql`
    ${GET_USER_FRAGMENT}
    mutation userLogin(
        $email: String!
        $password: String!
    ) {
        user_login(input: {
            emailAddress: $email,
            password: $password
        }) {
            api_token
            user { ...userFragment }
        }
    }
`;
