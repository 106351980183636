import { gql } from 'apollo-boost';

export const COURSE_FRAGMENT = gql`
    fragment courseKeys on Course {
        id
        title
        description
        youtube_url
        image_url
        created_at
        updated_at
        course_categories { id name }
    }
`;
