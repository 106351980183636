import { useCallback, useEffect, useRef, useState } from 'react';
import * as React from 'react';
import * as PropTypes from 'prop-types';
import classNames from 'classnames';

import S from './grid-image.module.scss';
import { Image } from './img-file';
import { NoImageView } from './no-image-view';
import { ImgPlaceholder } from './img-placeholder';
import { ImgLoading } from './img-loading';
import { DeleteImgIcon } from '../icons/delete-img-icon';
import { logError } from 'helpers/errors/bug-report';

const GridImage = ({
  alt,
  isRound,
  hasIcon,
  isLoading,
  previewSrc,
  hasPermission,
  handleDeleteFile,
  handleFileChange,
  itemIndex,
}) => {
  const [dragCounter, setDragCounter] = useState(0);
  const [isDragging, setIsDragging] = useState(false);

  const DROP_REF = useRef(null);
  const FILE_REF = useRef(null);

  const handleDragIn = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();

      setDragCounter(1);
      if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
        setIsDragging(true);
      }
    },
    [setDragCounter, setIsDragging]
  );

  const handleDragOut = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();

      setDragCounter(0);
      if (dragCounter > 0) return;
      setIsDragging(false);
    },
    [setDragCounter, dragCounter, setIsDragging]
  );

  const handleDrag = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const onFileInputClick = () => {
    FILE_REF.current.value = '';
    FILE_REF.current.click();
  };

  const handleFileInputChange = async (e) => {
    if (e.currentTarget.files && e.currentTarget.files.length > 0) {
      try {
        await handleFileChange(e.currentTarget.files[0]);
      } catch (error) {
        logError(error, 'grid-image.js', 'handleFileInputChange');
      }
    }
  };

  useEffect(() => {
    async function handleDrop(e) {
      e.preventDefault();
      e.stopPropagation();
      setIsDragging(false);

      if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
        try {
          await handleFileChange(e.dataTransfer.files[0]);
          e.dataTransfer.clearData();
          setDragCounter(0);
        } catch (error) {
          logError(error, 'grid-image.js', 'handleDrop');
        }
      }
    }

    setDragCounter(0);
    let div = DROP_REF.current;

    div.addEventListener('dragenter', handleDragIn);
    div.addEventListener('dragleave', handleDragOut);
    div.addEventListener('dragover', handleDrag);
    div.addEventListener('drop', handleDrop);

    return () => {
      div.removeEventListener('dragenter', handleDragIn);
      div.removeEventListener('dragleave', handleDragOut);
      div.removeEventListener('dragover', handleDrag);
      div.removeEventListener('drop', handleDrop);
    };
  }, [handleDragIn, handleDragOut, handleDrag, handleFileChange]);

  // return <div className={S.gridItemContent}>{itemIndex}</div>

  // return (
  //   <div className={S.gridItemContent}>
  //     {previewSrc && (
  //       <>
  //         {isLoading && <ImgLoading />}
  //         <Image
  //           alt={alt}
  //           src={previewSrc}
  //           className={classNames(S.gridImg, { [S.isRound]: isRound })}
  //         />
  //         {!isLoading && hasPermission && (
  //           <DeleteImgIcon
  //             className={S.trashIcon}
  //             handleDelete={handleDeleteFile}
  //           />
  //         )}
  //       </>
  //     )}
  //     {!previewSrc && (
  //       <>
  //         {!hasPermission ? (
  //           <NoImageView draggable={true} isRound={isRound} />
  //         ) : (
  //           <ImgPlaceholder
  //             alt={alt}
  //             size="130px"
  //             isRound={isRound}
  //             hasIcon={hasIcon}
  //             // handleBtnClick={onFileInputClick}
  //           />
  //         )}
  //       </>
  //     )}
  //   </div>
  // );

  return (
    <div
      ref={DROP_REF}
      className={classNames(S.imageGrid, {
        [S.dragging]: isDragging,
        [S.isRound]: isRound,
      })}
    >
      <input ref={FILE_REF} type='file' name='file' accept='image/*' onChange={handleFileInputChange} />

      {previewSrc && (
        <>
          {isLoading && <ImgLoading />}
          <Image
            alt={alt}
            src={previewSrc}
            className={classNames(S.gridImg, { [S.isRound]: isRound, active: itemIndex === 1 })}
          />
          {!isLoading && hasPermission && <DeleteImgIcon className={S.trashIcon} handleDelete={handleDeleteFile} />}
        </>
      )}
      {!previewSrc && (
        <>
          {!hasPermission ? (
            <NoImageView draggable={true} isRound={isRound} />
          ) : (
            <ImgPlaceholder
              alt={alt}
              size='130px'
              isRound={isRound}
              hasIcon={hasIcon}
              handleBtnClick={onFileInputClick}
            />
          )}
        </>
      )}
    </div>
  );
};

GridImage.displayName = 'GridImage';

GridImage.propTypes = {
  isRound: PropTypes.bool,
  hasIcon: PropTypes.bool,
  isLoading: PropTypes.bool,
  previewSrc: PropTypes.string,
  alt: PropTypes.string.isRequired,
  hasPermission: PropTypes.bool.isRequired,
  handleFileChange: PropTypes.func.isRequired,
  handleDeleteFile: PropTypes.func.isRequired,
  itemIndex: PropTypes.number.isRequired,
};

GridImage.defaultProps = {
  isRound: false,
  hasIcon: false,
  isLoading: false,
  previewSrc: null,
};

export { GridImage };
