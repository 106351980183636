import { toast } from 'react-toastify';
import * as PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';

import S from './existing-modal.module.scss';
import { ExistingClubItem } from './existing-club-list/existing-club-item';
import { useExistingCtx } from '../modal-existing-context';
import { LoadingDisco } from '../../../common/loading/loading-disco';
import { useBusiness, useUser } from '../../../../graphql/graph-hooks';
import { ADD_EXISTING_EMPLOYEE_GQL } from '../../../../graphql/mutations/employee-add-existing';
import { BUSINESS_EMPLOYEES_GQL } from '../../../../graphql/queries/employee-list-query';
import * as ExistingModalGQL from '../../../../graphql/queries/get-employee-by-org-query';
import { EMPLOYEE_LVLS } from '../../../../helpers/constants';
import { logError } from '../../../../helpers/errors/bug-report';
import { getRolePermissionOptions } from '../../../../helpers/employee-services';
import { sortByCollectionName } from '../../../../helpers/sorts';

const ExistingModalForm = ({ onClose }) => {
  const { t } = useTranslation();
  const {
    state: { selectedClubId, form },
  } = useExistingCtx();
  const [clubState, setClubState] = useState([]);
  const [isEmpty, setIsEmpty] = useState(true);
  const { id: businessId, organization } = useBusiness();
  const { admin, roles } = useUser();
  const USER_LVL = admin ? 10 : EMPLOYEE_LVLS[roles?.[businessId]?.slug];

  const [addEmployee] = useMutation(ADD_EXISTING_EMPLOYEE_GQL, {
    update: (
      cache,
      {
        data: {
          employment_create: { employee },
        },
      }
    ) => {
      const { business } = cache.readQuery({ query: BUSINESS_EMPLOYEES_GQL, variables: { businessId } });
      const EMPLOYMENT = employee.employments.find(({ business }) => business.id === businessId);

      cache.writeQuery({
        query: BUSINESS_EMPLOYEES_GQL,
        variables: { businessId },
        data: {
          business: {
            ...business,
            employments: [
              ...business.employments,
              {
                ...EMPLOYMENT,
                employee,
              },
            ],
          },
        },
      });
    },
    onError: (err) => {
      logError(err, 'ADD_EXISTING_EMPLOYEE_GQL', ExistingModalForm.displayName);
      toast.error(`${t('errors.server.500')}. ${t('modals.existing_employee.add.failure')}`);
    },
    onCompleted: () => {
      toast.success(t('modals.existing_employee.add.success'));
      onClose();
    },
  });

  const { loading, error } = useQuery(ExistingModalGQL.GET_EMPLOYEE_BY_ORG_GQL, {
    fetchPolicy: 'network-only',
    variables: { orgId: organization.id },
    onError: (err) => logError(err, 'GET_EMPLOYEE_BY_ORG_GQL', ExistingModalForm.displayName),
    onCompleted: ({ organization: { businesses } }) => {
      const EXISTING_EMPLOYEE_ARRAY = businesses
        .find(({ id }) => id === businessId)
        .employments.filter(({ employee }) => employee)
        .map(({ employee }) => employee.id);

      const OTHER_ORG_CLUBS = sortByCollectionName(
        true,
        businesses.reduce((acm, cur) => {
          if (cur.id === businessId) return acm;
          else {
            const EMPLOYEE_LVL = EMPLOYEE_LVLS[cur?.employments?.employee?.roles?.[cur.id]?.slug] || 0;
            return [
              ...acm,
              {
                ...cur,
                employments: cur.employments.filter(
                  ({ employee }) =>
                    employee && !EXISTING_EMPLOYEE_ARRAY.includes(employee?.id) && USER_LVL > EMPLOYEE_LVL
                ),
              },
            ];
          }
        }, [])
      );
      setIsEmpty(OTHER_ORG_CLUBS.reduce((acm, { employments }) => acm + employments.length, 0) === 0);
      setClubState(OTHER_ORG_CLUBS);
    },
  });

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      const EMPLOYEE_KEYS = Object.keys(form);
      if (selectedClubId === null) {
        return toast.warn(t('modals.existing_employee.select_club_and_employee'));
      }

      if (Object.values(form).reduce((a, c) => a + c, '') === '') {
        const message =
          EMPLOYEE_KEYS.length > 1
            ? t('modals.existing_employee.select_role_for_employee')
            : t('modals.existing_employee.select_role');
        return toast.warn(message);
      }

      await Promise.all(
        EMPLOYEE_KEYS.map(
          (userId) =>
            form[userId] &&
            addEmployee({
              variables: {
                userId,
                businessId,
                role: form[userId],
                permissions: getRolePermissionOptions(form[userId]),
              },
            })
        )
      );
    },
    [selectedClubId, form, addEmployee, businessId, onClose]
  );

  if (loading) return <LoadingDisco />;
  if (error) return <h3>Error</h3>;

  if (isEmpty || clubState.length === 0) return <h4 className={S.emptyList}>No available options</h4>;

  return (
    <form onSubmit={handleSubmit}>
      <ul className={S.clubList}>
        {clubState.map((club) => (
          <ExistingClubItem key={club.id} {...club} />
        ))}
      </ul>
      <div className={S.actionArea}>
        <button className={S.invertedBtnRed} onClick={onClose}>
          {t('common.cancel')}
        </button>
        <button className={S.invertedBtnGreen}>{t('modals.existing_employee.add.employee')}</button>
      </div>
    </form>
  );
};

ExistingModalForm.displayName = 'ExistingModalForm';
ExistingModalForm.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export { ExistingModalForm };
