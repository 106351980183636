import React from 'react';
import PropTypes from 'prop-types';

import S from '../../../../sass/layout-styles.module.scss';
import { ClubForm } from './club-form';
import { PageClubFormProvider } from './club-form-context';

const ClubFormPage = ({ isNewForm }) => {
  return (
    <PageClubFormProvider>
      <div className={S.pageWrapper}>
        <ClubForm isNewForm={isNewForm} />
      </div>
    </PageClubFormProvider>
  );
};

ClubFormPage.displayName = 'ClubFormPage';

ClubFormPage.propTypes = {
  isNewForm: PropTypes.bool,
};

ClubFormPage.defaultProps = {
  isNewForm: false,
};

export { ClubFormPage };
