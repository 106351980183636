import { gql } from 'apollo-boost';

export const REMOVE_EMPLOYEE_FROM_CLUB = gql`
    mutation employment_delete(
        $employmentId: ID!
    ) {
        employment_delete(input: {id: $employmentId}){
            employee { id }
        }
    }
`;
