import * as React from 'react';
import * as PropTypes from 'prop-types';

import S from './radio-span.module.scss';

//for use when you don't need an input like in a button
const RadioSpan = ({ checked }) => <span className={S.radioSpan}>{checked && <span />}</span>;

RadioSpan.displayName = 'SpanRadio';
RadioSpan.propTypes = {
  checked: PropTypes.bool.isRequired,
};

export { RadioSpan };
