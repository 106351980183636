import i18next from 'i18next';
const { t } = i18next;

export function validateOrgName(name, nameList = []) {
  const LENGTH = name.length;
  if (LENGTH === 0) return t('errors.validation.required', { field_name: t('common.name') });
  else if (LENGTH < 3) return t('errors.validation.min_length', { field_name: t('common.name'), min_length: 3 });
  else if (LENGTH > 255) return t('errors.validation.max_length', { field_name: t('common.name'), max_length: 255 });
  else if (nameList.map((name) => name.toUpperCase()).includes(name.toUpperCase())) {
    return t('errors.validation.unique', { field_name: t('common.name') });
  } else return '';
}
