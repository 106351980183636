import { gql } from 'apollo-boost';

export const GET_GROUP_NOTIFICATIONS_GQL = gql`
    query($cursor:String! $search: String!){
        admin_group_announcements(
            after: $cursor
            contentFilter: $search
            first: 12
        ){
            pageInfo{
                endCursor
                hasNextPage
            }
            totalCount
            nodes{
                id
                created_at
                title
                message
                group{
                    id
                    name
                    business {
                        id
                        name
                        first_image { id thumb }
                    }
                }
                employee{ id name }
            }
        }
    }
`;
