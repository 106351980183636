import * as PropTypes from 'prop-types';
import * as React from 'react';

import S from './group-grid.module.scss';
import { Image } from './images/img-file';

const GroupGrid = ({ dancers, groupId, count }) => {
  return (
    <div className={count <= 4 ? S.smGroupGrid : S.lgGroupGrid}>
      {dancers.slice(0, 9).map((dancer, i) => (
        <div key={`GROUP_${groupId}_DANCER_${dancer.id}`} className={S.groupImgWrapper}>
          <Image src={dancer.first_image?.thumb} alt='dancer.first_image?.thumb' fallback='USER' />
          {i === 8 && count > 9 && <div>{count}</div>}
        </div>
      ))}
    </div>
  );
};

GroupGrid.propTypes = {
  groupId: PropTypes.string.isRequired,
  dancers: PropTypes.array.isRequired,
  count: PropTypes.number.isRequired,
};

export { GroupGrid };
