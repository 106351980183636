import { useQuery } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';

import { LoadingDiscoWithContainer } from '../../../common/loading/loading-disco';
import { GET_DOB_REQUEST_LIST } from '../../../../graphql/queries/dob-request-list';
import DobRequestItem from '../dob-request-item';
import { useFetchMore } from 'components/global-hooks';

import { logError } from '../../../../helpers/errors/bug-report';

import { DobChangeRequest, PageInfo } from '../types';

import S from './dob-change-list.module.scss';
import LoadMoreButton from 'components/common/buttons/load-more-btn/load-more-btn';

const DobChangeList = ({ status }: { status: string }) => {
  const { t } = useTranslation();
  const gqlField = 'dob_requests';

  const { isLoading: isFetchMoreLoading, fetchMore: fetchMorePosts, error: fetchMoreError } = useFetchMore(gqlField);

  const { loading, error, data, refetch, fetchMore } = useQuery<{
    [gqlField]: { nodes: DobChangeRequest[]; pageInfo: PageInfo; totalCount: number };
  }>(GET_DOB_REQUEST_LIST, {
    fetchPolicy: 'network-only',
    variables: { first: 5, status },
    onError: (err) => logError(err, 'GET_DOB_REQUEST_LIST', DobChangeList.displayName),
  });

  const handleGetMore = () => {
    fetchMorePosts(fetchMore, {
      after: data?.[gqlField].pageInfo.endCursor,
    });
  };

  if (loading || !data) return <LoadingDiscoWithContainer />;
  if (error) return <h1 className={S.errorPage}>{t('errors.generic')}</h1>;
  if (data?.[gqlField].nodes?.length === 0) {
    return (
      <div className='w-full mx-auto pt-12 text-center text-3xl text-gray-300'>
        {t('dobChangePage.no_change_requests')}
      </div>
    );
  }

  return (
    <div className='flex flex-col mt-4'>
      {data?.[gqlField]?.nodes.map((dobRequest, idx) => (
        <DobRequestItem
          key={`dobrequest_${dobRequest.id}`}
          dobRequest={dobRequest}
          refetch={refetch}
          className={idx % 2 === 0 ? 'bg-gray-800' : ''}
          status={status}
        />
      ))}

      {fetchMoreError && <h1 className={S.error}>{t('errors.generic')}</h1>}

      {data?.[gqlField].pageInfo.hasNextPage && (
        <div className='mt-10 mb-10 text-center'>
          <LoadMoreButton isLoading={isFetchMoreLoading} onClick={handleGetMore} />
        </div>
      )}
    </div>
  );
};

DobChangeList.displayName = 'DobChangeList';
DobChangeList.propTypes = {};

export { DobChangeList };
