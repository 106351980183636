import { useState } from 'react';
import { setAppData, setModalData, useGlobalCtx } from './global-context';
import { useTranslation } from 'react-i18next';

export const useModal = () => {
  const { dispatch } = useGlobalCtx();

  return {
    setModal: (type) => dispatch(setModalData({ type })),
    initModal: (type, context, isClosing) => dispatch(setModalData({ type, context, isClosing })),
    closeModal: () => dispatch(setModalData({ type: null, context: null, isClosing: true })),
  };
};

export const useNavReRender = () => {
  const {
    dispatch,
    state: { app },
  } = useGlobalCtx();
  return {
    reRenderNav: () => dispatch(setAppData(app)),
  };
};

export const useFetchMore = (field, prependNodes = false) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const { t } = useTranslation();

  const fetchMore = async (fetchMore, variables) => {
    setIsLoading(true);
    try {
      await fetchMore({
        variables,
        updateQuery: ({ [field]: { nodes } }, { fetchMoreResult }) => {
          const updatedNodes = prependNodes
            ? [...fetchMoreResult[field].nodes, ...nodes]
            : [...nodes, ...fetchMoreResult[field].nodes];
          return {
            [field]: {
              ...fetchMoreResult[field],
              nodes: updatedNodes,
            },
          };
        },
      });
    } catch (error) {
      setError(t('errors.generic'));
    }
    setIsLoading(false);
  };

  return {
    isLoading,
    fetchMore,
    error,
  };
};
