import React, { useMemo } from 'react';
import * as PropTypes from 'prop-types';

import S from './booking-list-time.module.scss';
import { getFormattedTimeZone } from '../../../helpers/times';

const FORMAT = 'h:mm a';

const BookingTimeArea = ({ timezone, start, end }) => {
  const START = useMemo(() => getFormattedTimeZone(start, timezone, FORMAT), [start, timezone]);
  const END = useMemo(() => getFormattedTimeZone(end, timezone, FORMAT), [end, timezone]);

  return (
    <div className={S.bookingTimeArea}>
      {START}
      <span>-</span>
      {END}
    </div>
  );
};

BookingTimeArea.propTypes = {
  timezone: PropTypes.string.isRequired,
  end: PropTypes.string.isRequired,
  start: PropTypes.string.isRequired,
};

export { BookingTimeArea };
