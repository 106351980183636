import * as React from 'react';
import * as PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';

import { RouteConstants }                from '../routes/route-constants';
import { useBusiness, useHasPermission } from '../../graphql/graph-hooks';


const AuthRoute = ({permission, hasBusiness, ...props}) => {
  const { id } = useBusiness();
  const PASSES_BIZ  = ((hasBusiness && id) || !hasBusiness);
  const HAS_PERMISSION = useHasPermission(permission);

  return (PASSES_BIZ && (HAS_PERMISSION || !permission))
    ? <Route {...props} />
    : <Route path="/" component={() => <Redirect to={RouteConstants.clubs}/>} />;
};

AuthRoute.propTypes = {
  hasBusiness: PropTypes.bool,
  permission:  PropTypes.string,
};

AuthRoute.defaultProps = {
  hasBusiness: false,
  permission: null,
};


AuthRoute.displayName = 'AuthRoute';

export { AuthRoute };
