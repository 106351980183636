import { FC, memo } from 'react';
import classNames from 'classnames';
import S from './style.module.scss';
import { Suggestion } from './types';

interface OptionProps {
  id: number;
  value: Suggestion;
  active: boolean;
  onClick: (id: number) => void;
  onMouseEnter: (id: number) => void;
  formatLabel: (suggestion:Suggestion) => string;
}

const Option: FC<OptionProps> = ({ id, value, active, onClick, onMouseEnter, formatLabel }) => {
  const handleOnClick = () => onClick(id);
  const handleOnMouseEnter = () => onMouseEnter(id);

  return (
    <div
      className={classNames(S.option, { [S.active]: active })}
      title={formatLabel(value)}
      onClick={handleOnClick}
      onMouseEnter={handleOnMouseEnter}
    >
      {formatLabel(value)}
    </div>
  );
};

export default memo(Option);
