import ApolloClient from 'apollo-boost';
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import { persistCache } from 'apollo-cache-persist';
import React, { useEffect, useState } from 'react';
import { ApolloProvider } from '@apollo/react-hooks';
import { BrowserRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import FragmentData from '../configs/fragmentTypes.json';
import { APOLLO_LOCAL_STATE, graphQLDataFromObj } from '../graphql/local-state';
import * as Constants from '../helpers/constants';
import { logError } from '../helpers/errors/bug-report';
import { LoadingDiscoWithContainer } from './common/loading/loading-disco';
import { RouteConstants } from './routes/route-constants';
import { RouterSwitch } from './routes/router-switch';
import { QueryClientProvider, QueryClient } from 'react-query';
import { CustomProvider } from 'rsuite';

//CHANGES TO PROPS IN THIS COMPONENT WILL CAUSE PAGES TO REDRAW
const ApolloComponent = () => {
  const { i18n } = useTranslation();
  const [client, setClient] = useState(undefined);
  const queryClient = new QueryClient();

  const storedLocale = localStorage.getItem('locale');
  if (storedLocale && storedLocale !== i18n.language) {
    i18n.changeLanguage(storedLocale);
  }

  useEffect(() => {
    const cache = new InMemoryCache({
      dataIdFromObject: graphQLDataFromObj,
      fragmentMatcher: new IntrospectionFragmentMatcher({
        introspectionQueryResultData: FragmentData,
      }),
    });

    const CLIENT = new ApolloClient({
      name: 'WEB',
      uri: Constants.APIConstants.graphq_ql,
      resolvers: {}, // https://github.com/apollographql/apollo-client/pull/4499
      cache,
      request: (reqObj) => {
        const TOKEN = localStorage.getItem('token');
        reqObj.setContext({
          headers: { Authorization: TOKEN ? `Token: ${TOKEN}` : '' },
        });
      },
      onError: (errorObj) => {
        if (errorObj.networkError?.statusCode === 401) {
          window.location = RouteConstants.timeout;
        }
      },
    });

    cache.writeData({ data: APOLLO_LOCAL_STATE });

    persistCache({
      cache,
      storage: window.localStorage,
    })
      .then(() => {
        CLIENT.onResetStore(async () => await cache.writeData({ data: APOLLO_LOCAL_STATE }));
        setClient(CLIENT);
      })
      .catch((err) => {
        logError(err, 'persistCache', ApolloComponent.displayName);
        localStorage.clear();
        window.location = '/';
      });
  }, []);

  if (client === undefined) {
    return <LoadingDiscoWithContainer />;
  } else
    return (
      <CustomProvider theme='dark'>
        <ApolloProvider client={client}>
          <QueryClientProvider client={queryClient}>
            <BrowserRouter>
              <RouterSwitch />
            </BrowserRouter>
          </QueryClientProvider>
        </ApolloProvider>
      </CustomProvider>
    );
};

ApolloComponent.displayName = 'ApolloComponent';

export { ApolloComponent };
