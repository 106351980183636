import React, { useRef } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import S from './existing-modal.module.scss';
import { ExistingModalForm } from './existing-modal-form';
import { ModalExistingProvider } from '../modal-existing-context';
import { CloseButton } from '../../../common/buttons/close-button';
import { useOverlayClick } from '../../../../helpers/hooks/use-overlay-click';

const ModalExistingWrapper = ({ onClose }) => {
  const { t } = useTranslation();
  const MODAL_REF = useRef();
  useOverlayClick(MODAL_REF, onClose);

  return (
    <ModalExistingProvider>
      <div className={S.existingModalWrapper} ref={MODAL_REF}>
        <CloseButton onClick={onClose} />
        <h3 className={S.modalHeading}>{t('modals.existing_employee.find')}</h3>
        <ExistingModalForm onClose={onClose} />
      </div>
    </ModalExistingProvider>
  );
};

ModalExistingWrapper.displayName = 'ModalExistingWrapper';
ModalExistingWrapper.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export { ModalExistingWrapper };
