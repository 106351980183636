import React, { FC, useState } from 'react';
import useImageKit from 'helpers/hooks/use-imagekit';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { useHistory, useParams } from 'react-router';
import useSubmitForm from '../useSubmitForm';
import { useTranslation } from 'react-i18next';

import PageContainer from 'components/common/page/page-container';
import PageHeader from 'components/common/page/page-header';
import ScheduledPostForm from '../Form';
import { GETSTREAM_ADMIN_POST } from 'graphql/queries/getstream';
import { RouteConstants } from 'components/routes/route-constants';
import PageBody from 'components/common/page/page-body';
import { GetstreamScheduledPost } from 'types/getstream';
import { GETSTREAM_ADMIN_POST_UPDATE } from 'graphql/mutations/getstream';
import { toast } from 'react-toastify';
import { logError } from 'helpers/errors/bug-report';

const ScheduledPostEdit: FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { postId } = useParams<{ postId: string }>();

  const { loading, error, data } = useQuery<{
    getstream_admin_post: GetstreamScheduledPost;
  }>(GETSTREAM_ADMIN_POST, {
    fetchPolicy: 'network-only',
    variables: { id: postId },
  });

  const [updatePost] = useMutation(GETSTREAM_ADMIN_POST_UPDATE, {
    onCompleted: () => {
      toast.success(t('social.scheduler.update.success'));
      history.push(RouteConstants.socialScheduledPosts);
    },
    onError: (error) => {
      toast.error(t('social.scheduler.update.failure'));
      logError(error, 'GETSTREAM_ADMIN_POST_UPDATE', 'ScheduledPostEdit');
    },
  });

  const { submit: submitForm, isLoading } = useSubmitForm(updatePost);

  const initialValues = data?.getstream_admin_post
    ? {
        id: data.getstream_admin_post.id,
        title: data.getstream_admin_post.title,
        text: data.getstream_admin_post.text,
        link_title: data.getstream_admin_post.link.title,
        link_url: data.getstream_admin_post.link.url,
        media: data.getstream_admin_post.media.sort((a, b) => (a.order > b.order ? 1 : b.order > a.order ? -1 : 0)),
        publishAt: new Date(data.getstream_admin_post.publish_at),
      }
    : null;

  return (
    <PageContainer>
      <PageHeader title={t('social.scheduler.edit')} />
      <PageBody isLoading={loading} error={error && t('errors.generic')}>
        <div className='mt-6 max-w-5xl'>
          {initialValues && (
            <ScheduledPostForm initialValues={initialValues} onSubmit={submitForm} isLoading={isLoading} />
          )}
        </div>
      </PageBody>
    </PageContainer>
  );
};

export default ScheduledPostEdit;
