import { useCallback, useMemo } from 'react';
import * as React from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import S from '../existing-modal.module.scss';
import { ExistingEmployeeItem } from './existing-employee-item';
import { setExistingFields, useExistingCtx } from '../../modal-existing-context';
import { EMPLOYEE_ROLE_OPTIONS } from '../../../../../helpers/constants';
import { useBusiness, useUser } from '../../../../../graphql/graph-hooks';
import classNames from 'classnames';

const ExistingClubItem = ({ id: clubId, name, employments }) => {
  const { t } = useTranslation();
  const {
    state: { selectedClubId },
    dispatch,
  } = useExistingCtx();
  const { roles, admin } = useUser();
  const { id: businessId } = useBusiness();
  const handleClubSelection = useCallback(() => {
    if (clubId !== selectedClubId)
      dispatch(
        setExistingFields({
          selectedClubId: clubId,
          form: {},
        })
      );
  }, [clubId, dispatch, selectedClubId]);

  const IS_SELECTED = useMemo(() => selectedClubId === clubId, [clubId, selectedClubId]);
  const USER_SLUG = admin ? 'ADMIN' : roles?.[businessId]?.slug ?? t('common.unknown');

  const OPTIONS = useMemo(() => {
    if (USER_SLUG === 'ADMIN') return EMPLOYEE_ROLE_OPTIONS;
    const INDEX = EMPLOYEE_ROLE_OPTIONS.findIndex(({ value }) => value === USER_SLUG);
    return EMPLOYEE_ROLE_OPTIONS.slice(0, INDEX);
  }, [USER_SLUG]);

  return (
    <li className={S.clubArea}>
      <div className={classNames(S.titleArea, { [S.selected]: IS_SELECTED })} onClick={handleClubSelection}>
        {`${name} (${employments.length})`}
      </div>

      {IS_SELECTED && (
        <ul className={S.employeeArea}>
          {IS_SELECTED && employments.length === 0 ? (
            <p className={S.emptyList}>{t('modals.existing_employee.no_employees')}</p>
          ) : (
            employments.map(({ employee }) => (
              <ExistingEmployeeItem key={`employee_${clubId}_${employee.id}`} options={OPTIONS} {...employee} />
            ))
          )}
        </ul>
      )}
    </li>
  );
};

ExistingClubItem.displayName = 'ExistingClubItem';
ExistingClubItem.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  employments: PropTypes.arrayOf(PropTypes.object),
};

export { ExistingClubItem };
