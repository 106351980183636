export const HANDLE_TAG = '@';
export const HANDLE_PATTERN = '[a-zA-Z0-9]+';

export const parseText = (text, maxLength, classes = { link: '' }) => {
  const parsed = [];
  let isTruncated = false;
  let tokens = text.split(/(\s)/g);
  // Truncate if needed
  if (maxLength) {
    let length = 0;
    tokens.every((token, idx) => {
      length += token.length;
      if (length > maxLength) {
        tokens = tokens.splice(0, idx);
        if (tokens[tokens.length - 1] === ' ') {
          // Remove trailing space
          tokens = tokens.splice(0, idx - 1);
        }
        // Add elipsis
        tokens[tokens.length - 1] += '...';
        isTruncated = true;
        return false;
      } else return true;
    });
  }

  // Add links
  tokens.forEach((token, i) => {
    const pattern = new RegExp('<' + HANDLE_TAG + HANDLE_PATTERN + '/>', 'g');
    if (pattern.test(token)) {
      const handle = token.slice(2, token.length - 2);
      parsed.push(
        <span className={classes.link} key={i}>
          {`@${handle}`}
        </span>
      );
    } else {
      parsed.push(token);
    }
  });

  return {
    text: parsed,
    isTruncated,
  };
};
