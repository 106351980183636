import { Fragment, useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

interface Props {
  isOpen: boolean;
  closeModal: () => void;
  imageData:
    | {
        data: string;
        mime_type: 'JPG' | 'PNG' | 'GIF';
        file_name: string;
      }
    | undefined;
  circularCrop?: boolean;
  canDeletePhoto?: boolean;
  setImageData: (imageData: { data: string; mime_type: 'JPG' | 'PNG' | 'GIF'; file_name: string } | undefined) => void;
}
export default function ModalFeedPhoto(props: Props) {
  const { t } = useTranslation();
  const { isOpen, closeModal, imageData, setImageData, circularCrop, canDeletePhoto } = props;
  const [imgData, setImgData] = useState(imageData);

  const [cropper, setCropper] = useState<any>();

  useEffect(() => {
    setImgData(imageData);
  }, [imageData]);

  const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      const fileName = file.name;
      const reader = new FileReader();
      reader.addEventListener('load', () =>
        setImgData({
          data: reader?.result?.toString() || '',
          file_name: fileName,
          mime_type: fileName.split('.').pop()?.toUpperCase() as 'JPG' | 'PNG' | 'GIF',
        })
      );
      reader.readAsDataURL(file);
    }
  };

  const getCropData = () => {
    if (imgData === undefined) {
      return setImageData(undefined);
    }
    if (typeof cropper !== 'undefined') {
      setImageData({ ...imgData, data: cropper.getCroppedCanvas().toDataURL() });
    }
  };

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as='div' className='relative z-10' onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
        </Transition.Child>

        <div className='fixed z-10 inset-0 overflow-y-scroll'>
          <div className='flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <Dialog.Panel className='relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-scroll shadow-xl transform transition-all sm:my-8  min-h-96 min-w-128 max-w-full mx-8 sm:p-6'>
                <div>
                  <div className='mt-3 text-center sm:mt-5'>
                    <Dialog.Title as='h3' className='text-lg leading-6 font-medium text-gray-900'>
                      {t('modals.image_crop.crop_and_upload_image')}
                    </Dialog.Title>
                    <div className='mt-2'>
                      <div className='flex justify-between'>
                        <input type='file' accept='image/*' onChange={onSelectFile} className='border-none' />
                        {canDeletePhoto && (
                          <button
                            onClick={() => setImgData(undefined)}
                            className='w-36 h-8 mt-1 mx-2 rounded-md border border-transparent shadow-sm px-2 bg-gray-600 text-base font-medium text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:text-sm'
                          >
                            {t('common.delete')}
                          </button>
                        )}
                      </div>
                      {imgData && Boolean(imgData.data) && (
                        <div className='flex flex-row justify-between mt-6'>
                          <Cropper
                            className='mx-auto content-center w-full h-128'
                            initialAspectRatio={1}
                            aspectRatio={1}
                            preview='.img-preview'
                            src={imgData.data}
                            viewMode={1}
                            minCropBoxHeight={10}
                            minCropBoxWidth={10}
                            responsive={true}
                            restore={true}
                            autoCropArea={1}
                            checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                            onInitialized={(instance) => {
                              setCropper(instance);
                            }}
                            guides={true}
                          />
                          <div className='w-full p-4 inline-block box-border'>
                            <div
                              className={classNames(['img-preview overflow-hidden', circularCrop && 'rounded-full'])}
                              style={{ width: '100%', height: '300px' }}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='mt-5 sm:mt-6'>
                    <div className='flex justify-between'>
                      <button
                        type='button'
                        className='w-2/5 rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:text-sm'
                        onClick={(e) => {
                          e.preventDefault();
                          closeModal();
                        }}
                      >
                        {t('common.cancel')}
                      </button>
                      <button
                        type='button'
                        className='w-2/5 rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:text-sm'
                        onClick={(e) => {
                          e.preventDefault();
                          getCropData();
                          closeModal();
                        }}
                      >
                        {t('common.submit')}
                      </button>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
