import { gql } from 'apollo-boost';

export const CREATE_INVITE_CODE = gql`
  mutation registration_code_create(
    $userId: ID!
    $businessId: ID
    $code: String
    $description: String
    $locationId: ID
  ) {
    registration_code_create(
      input: {
        active: true
        userId: $userId
        businessId: $businessId
        code: $code
        description: $description
        locationId: $locationId
      }
    ) {
      registration_code {
        id
        active
        code
        users_count
        description
        location {
          id
          city
          state
          country
          google_places_id
        }
        business {
          id
        }
        user {
          id
          name
          first_image {
            id
            thumb
            medium
          }
        }
      }
    }
  }
`;
