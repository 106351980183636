import React, { ChangeEvent, DragEvent, FC, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'components/common/buttons';

import S from './styles.module.scss';

interface Props {
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onDropFile: (e: DragEvent) => void;
  isLoading?: boolean;
  disabled?: boolean;
  error?: string | null;
}

const FileInput: FC<Props> = ({ onChange, onDropFile, isLoading, disabled, error }) => {
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>(null);
  const [captured, setCaptured] = useState(false);

  const handleOnClick = () => {
    if (inputRef.current) {
      inputRef.current.click();
      inputRef.current.value = '';
    }
  };

  const handleOnDrop = (ev: DragEvent<HTMLDivElement>) => {
    ev.preventDefault();
    ev.stopPropagation();
    setCaptured(false);
    onDropFile(ev);
  };

  const handleOnDragEnter = (ev: DragEvent<HTMLDivElement>) => {
    ev.preventDefault();
    setCaptured(true);
  };

  const handleOnDragExit = (ev: DragEvent<HTMLDivElement>) => {
    setCaptured(false);
  };

  const handleOnDragOver = (ev: DragEvent<HTMLDivElement>) => {
    ev.preventDefault();
  };

  const borderColor = captured ? 'border-green-500' : 'border-blue-500';
  return (
    <>
      <div
        className={`${S.dropArea} mb-4 h-48 w-48 p-3 text-center border-dashed border-2 ${borderColor} flex items-center justify-center`}
        onDrop={handleOnDrop}
        onDragEnter={handleOnDragEnter}
        onDragExit={handleOnDragExit}
        onDragOver={handleOnDragOver}
      >
        <p>{isLoading ? t('common.please_wait') : t('common.drag_file_to_dropzone')}</p>
      </div>

      <div className='flex flex-row'>
        <Button
          type='button'
          title={t('social.scheduler.add_media')}
          color='blue'
          onClick={handleOnClick}
          isLoading={isLoading}
          disabled={disabled || isLoading}
        />
        <div className='flex flex-column items-center'>
          {error ? (
            <small className='text-red-500'>{error}</small>
          ) : (
            <small className='text-gray-500'>{t('errors.validation.max_filesize', { max_size: '10MB' })}</small>
          )}
        </div>
      </div>

      <input type='file' className='invisible' ref={inputRef} onChange={onChange} />
    </>
  );
};

export default FileInput;
