import i18next from 'i18next';
const { t } = i18next;

export const checkForFilterError = (err, genericErrorMessage) => {
  const flaggedWordTitle = t('errors.validation.flagged_word_detected');
  const genericMessage = genericErrorMessage || t('errors.server.500');
  const WORDS = (err.flagged_words || err?.graphQLErrors?.[0]?.extensions?.flagged_words) ?? [];

  let errors = { title: undefined, message: genericMessage, type: 'generic' };
  if (WORDS.length > 0) {
    errors = {
      title: flaggedWordTitle,
      message: [],
      type: 'flaggedWord',
    };
    WORDS.forEach(({ alternatives, word }) => {
      const alternativesJoined = (Array.isArray(alternatives[0]) ? alternatives[0] : alternatives).join(', ');
      const lastIndex = alternativesJoined.lastIndexOf(',');
      const alternativesStr = alternativesJoined.slice(0, lastIndex) + ' or ' + alternativesJoined.slice(lastIndex + 1);
      const message =
        alternatives.length > 0
          ? t('errors.validation.prohibited_word_alt', { word, alt: alternativesStr })
          : t('errors.validation.prohibited_word', { word });
      errors.message.push(message);
    });
  }
  return errors;
};
