import i18next from 'i18next';
const { t } = i18next;

export const validateSuspensionReason = (reason) => {
  const LENGTH = reason.trim().length;

  if (LENGTH >= 5 && LENGTH <= 255) return '';
  else if (LENGTH === 0) return t('errors.validation.required', { field_name: t('modals.suspend.reason') });
  else if (LENGTH < 5)
    return t('errors.validation.min_length', { field_name: t('modals.suspend.reason'), min_length: 5 });
  else if (LENGTH > 255)
    return t('errors.validation.max_length', { field_name: t('modals.suspend.reason'), max_length: 255 });
};
