import { gql } from 'apollo-boost';
import { defaultDataIdFromObject } from 'apollo-cache-inmemory';

export const APOLLO_IS_LOGGED_IN      = gql`{ isLoggedIn @client }`;
export const APOLLO_USER_ID           = gql`{ userId @client }`;
export const USER_CLUB_ID             = gql`{ userClubId @client }`;
export const APOLLO_HAS_TIMED_OUT     = gql`{ hasTimedOut @client }`;

export const SELECTED_BOOKING_GQL     = gql`{ pageBookingSelected @client }`;
export const SELECTED_BOOKING_REQ_GQL = gql`{ pageBookingReqSelected @client }`;

//GLOBAL STATE USE WITH CAUTION
export const APOLLO_LOCAL_STATE = {
  //BOOKING REQUEST PAGE
  //needed in global due to use in dancer modal
  pageBookingReqSelected: [], // /bookings/:id
  pageBookingSelected: [],    // /bookings

  //VARIABLES FOR ALL PAGES
  userClubId: null,
  userId: null,
  hasTimedOut: false,
  isLoggedIn: false,
};


export const graphQLDataFromObj = (object:any):string | null => {
  switch (object.__typename) {
    case 'Business':               return `Business:${object.id}`;
    case 'BlastAnnouncement':      return `BlastAnnouncement:${object.id}`;
    case 'BusinessConversation':   return `BusinessConversation:${object.id}`;
    case 'BusinessMessage':        return `BusinessMessage:${object.id}`;
    case 'Course':                 return `Course:${object.id}`;
    case 'CourseCategory':         return `CourseCategory:${object.id}`;
    case 'Conversation':           return `Conversation:${object.id}`;
    case 'Dancer':                 return `Dancer:${object.id}`;
    case 'Employee':               return `Employee:${object.id}`;
    case 'Employment':             return `Employment:${object.id}`;
    case 'Group':                  return `Group:${object.id}`;
    case 'GroupDetail':            return `GroupDetail:${object.id}`;
    case 'GroupAnnouncement':      return `GroupAnnouncement:${object.id}`;
    case 'HoursOfOperation':       return `HoursOfOperation:${object.id}`;
    case 'ImageAttachment':        return `ImageAttachment:${object.id}`;
    case 'Location':               return `Location:${object.id}`;
    case 'Message':                return `Message:${object.id}`;
    case 'Organization':           return `Organization:${object.id}`;
    case 'ProfileOption':          return `ProfileOption:${object.id}`;
    case 'PreapprovedShiftDetail': return `PreapprovedShiftDetail:${object.id}`;
    case 'RegistrationCode':       return `RegistrationCode:${object.id}`;
    case 'RecurringShift':         return `RecurringShift:${object.id}`;
    case 'Shift':                  return `Shift:${object.id}`;
    case 'ShiftApplication':       return `ShiftApplication:${object.id}`;
    case 'VerificationCheck':      return `VerificationCheck:${object.id}`;
    case 'User':                   return `User:${object.id}`;
    case 'ClientVersion':          return `ClientVersion:${object.client_version}`;
    default:
      // console.log(object.__typename, object);
      return defaultDataIdFromObject(object);
  }
};
