import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import S from './checkbox-list.module.scss';
import * as ClubFormCtx from '../club-form-context';
import { FormLabel } from '../../../../common/form/form-parts/form-label';
import { InputFormCheckbox } from '../../../../common/form/form-parts/input-form-checkbox';

const DOCUMENTS = [
  'license_agreement',
  'independent_contractor',
  'arbitration',
  'class_waiver',
  'drivers_license',
  'model_release',
  'w9',
  'biz_status_agreement',
  'identification',
];

const RequiredDocs = ({ isDisabled }) => {
  const { t } = useTranslation();

  // documents are stored in the database with their english names; has to remain that way due to mobile app showing
  // document names as-is, and we can't break backwards compatibility
  // TODO: implement support for both raw values and labels on backend, then start using the labels in web and mob, and
  //  then deprecate the raw values on mobile
  const { t: engT } = useTranslation('translation', {lng: 'en'});
  const ENG_DOCUMENTS = useMemo(() => {
    let docs = {};
    DOCUMENTS.forEach((doc) => {
      // docs[engT(`clubPage.required_docs_list.${doc}`)] = doc;
      docs[doc] = engT(`clubPage.required_docs_list.${doc}`);
    });
    return docs;
  }, [DOCUMENTS]);


  const {
    state: {
      form: { requiredDocs },
    },
    dispatch,
  } = ClubFormCtx.useClubFormCtx();

  const handleClick = useCallback(
    (doc) => {
      dispatch(
        ClubFormCtx.setClubFormField(
          'requiredDocs',
          requiredDocs.includes(ENG_DOCUMENTS[doc]) ? requiredDocs.filter((fid) => fid !== ENG_DOCUMENTS[doc]) : [...requiredDocs, ENG_DOCUMENTS[doc]]
        )
      );
    },
    [requiredDocs, dispatch]
  );

  return (
    <div className={S.checkboxList} style={{ gridArea: 'docs' }}>
      <FormLabel label={t('clubPage.required_docs')} isDisabled={isDisabled} hasError={false} />
      {DOCUMENTS.map((doc, i) => (
        <InputFormCheckbox
          key={`club_form_documents_${i}`}
          id={`club_form_documents_${i}`}
          label={t(`clubPage.required_docs_list.${doc}`)}
          value={i}
          name='requiredDocs'
          isChecked={requiredDocs.includes(ENG_DOCUMENTS[doc])}
          isDisabled={isDisabled}
          handleChange={() => handleClick(doc)}
        />
      ))}
    </div>
  );
};

RequiredDocs.displayName = 'RequiredDocs';

RequiredDocs.propTypes = {
  isDisabled: PropTypes.bool.isRequired,
};

export { RequiredDocs };
