import React, { useCallback, useEffect, useState } from 'react';
import { useApolloClient, useMutation } from '@apollo/react-hooks';
import { toast } from 'react-toastify';
import LogRocket from 'logrocket';
import * as Sentry from '@sentry/react';
import { useTranslation, Trans } from 'react-i18next';

import S from '../pre-login-parts.module.scss';
import SA from '../login-input-field.module.scss';
import { LoginInputField } from '../login-input-field';
import { APOLLO_HAS_TIMED_OUT } from '../../../graphql/local-state';
import { USER_LOGIN } from '../../../graphql/mutations/user-auth';
import { APIConstants } from '../../../helpers/constants';
import * as Validations from '../../../helpers/validations/login-validations';
import { logError } from '../../../helpers/errors/bug-report';
import posthog from 'posthog-js';
import PasswordInput from 'components/common/form/form-password-input';



const LoginForm = () => {
  const { t } = useTranslation();

  const [values, setValues] = useState({ email: '', password: '' });
  const [errors, setErrors] = useState({ email: '', password: '' });

  const CLIENT = useApolloClient();

  const validations = {
    email: Validations.validateEmail,
    password: Validations.validateLoginPassword,
  };

  const [loginAuth, { loading }] = useMutation(USER_LOGIN, {
    variables: values,
    context: { uri: `${APIConstants.graphq_ql}?no_auth=true` },
    update: (
      cache,
      {
        data: {
          user_login: { user, api_token },
        },
      }
    ) => {
      if (user.user_type === 'dancer') { toast.error(t('errors.download_app')); }
      else {
        localStorage.setItem('token', api_token);
        cache.writeData({
          data: {
            userId: user.id,
            isLoggedIn: true,
          },
        });
        posthog.identify(user.id, { name: user.name, email: user.email, admin: user.admin, user_type: user.user_type, locale: user.locale, businesses: user.employments.map((b) => b.business?.name) });
        Sentry.setUser({ id: user.id, name: user.name, email: user.email });
        LogRocket.identify(user.id, {
          name: user.name,
          email: user.email,
          admin: user.admin,
          user_type: user.user_type,
          roles: user.roles,
          locale: user.locale,
        });
      }
    },
    onError: (err) => {
      if (err?.response?.data?.session?.error) {
        toast.error(err.response.data.session.error);
      } else {
        logError(err, 'USER_LOGIN', LoginForm.displayName);
        toast.error(
          <p>
            <Trans t={t} i18nKey='loginForm.invalid_username_password'>
              Invalid Username or Password. Forgot your Password? Click <a href='/recovery' style={{ color: 'white' }}>HERE</a> to reset it
            </Trans>
          </p>,
          { autoClose: 10000 }
        );
      }
    },
  });
  const { hasTimedOut } = CLIENT.readQuery({ query: APOLLO_HAS_TIMED_OUT });

  useEffect(() => () => CLIENT.writeData({ data: { hasTimedOut: false } }), [CLIENT]);

  const handleChange = useCallback((e) => setValues({ ...values, [e.target.name]: e.target.value.trim() }), [values]);

  const handleBlur = useCallback(
    (e) => {
      const { name, value } = e.target;
      setValues({ ...values, [e.target.name]: e.target.value.trim() });
      setErrors({ ...errors, [e.target.name]: validations[name](value.trim()) });
    },
    [values, errors]
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    await loginAuth();
  };

  return (
    <form className={S.userForm} onSubmit={handleSubmit}>
      {hasTimedOut && (
        <div className={S.timeOut}>
          <Trans t={t} i18nKey='loginForm.session_timeout'>
            <div>You were logged out due to inactivity</div>
            <div>Please log back in to continue!</div>
          </Trans>
        </div>
      )}

      <LoginInputField
        label={t('common.email_address')}
        id='email'
        name='email'
        type='email'
        value={values.email}
        isDisabled={loading}
        handleChange={handleChange}
        handleBlur={handleBlur}
        error={errors.email}
      />

      <div className={SA.preLoginInputField}>
        <label htmlFor={'password'}>{t('common.password')}</label>
        <PasswordInput
          id='password'
          name='password'
          value={values.password}
          disabled={loading}
          onChange={handleChange}
          onBlur={handleBlur}
          error={errors.password}
        />
      </div>

      <button className={S.submitBtn} disabled={loading}>
        {t('common.submit')}
      </button>
    </form>
  );
};

LoginForm.displayName = 'LoginFormPresenter';

export { LoginForm };
