import { gql } from 'apollo-boost';

export const BIZ_IMG_ADD = gql`
    mutation business_add_image(
        $businessId:ID!
        $fileName: String!
        $type: Mime!
        $data: String!
    ){
        business_add_image(input:{
            id: $businessId
            imageInputObject:{
                fileName: $fileName
                mimeType: $type
                data: $data
            }
        }) {
            business{
                id
                images{ id position thumb medium }
                first_image { id thumb medium }
            }
        }
    }
`;
