import * as React from 'react';
import * as PropTypes from 'prop-types';
import { FaChevronLeft } from 'react-icons/fa';

import S from './nav-pp-logo.module.scss';
import logo from '../../assets/logo_side_by_side_white_P.svg';

const NavPPLogo = ({ onCloseButtonClick }) => {
  return (
    <header className={S.logoWrapper}>
      <FaChevronLeft onClick={onCloseButtonClick} size='20' color='#666666' />
      <img src={logo} alt='Pole Position Logo' className='mt-12' />
    </header>
  );
};

NavPPLogo.propTypes = {
  onCloseButtonClick: PropTypes.func.isRequired,
};

export { NavPPLogo };
