import { useMutation, useQuery } from '@apollo/react-hooks';
import * as PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import S from './modal-group-add-dancers-form.module.scss';
import { DancerGridItem } from '../../common/dancer-grid-item/dancer-grid-item';
import { FormSelectField } from '../../common/form/form-select-field';
import { FormTextField } from '../../common/form/form-text-field';
import { LoadingDiscoWithContainer } from '../../common/loading/loading-disco';
import { useBusiness, useUser } from '../../../graphql/graph-hooks';
import { GROUP_UPDATE_GQL } from '../../../graphql/mutations/dancer-add-to-group';
import { CREATE_GROUP_GQL } from '../../../graphql/mutations/group-create';
import { MODAL_ADD_TO_GROUP_DATA } from '../../../graphql/queries/dancers-add-to-group-query';
import { GET_GROUP_NAME_LIST } from '../../../graphql/queries/group-name-list';
import { logError } from '../../../helpers/errors/bug-report';
import * as GroupValidations from '../../../helpers/validations/group-validations';

const ModalGroupAddDancersForm = ({ onClose, dancerIds }) => {
  const { t } = useTranslation();
  const { admin } = useUser();
  const { id } = useBusiness();
  const [name, setName] = useState('');
  const [groupId, setGroupId] = useState('');
  const [nameList, setNameList] = useState([]);
  const [idsList, setIdsList] = useState([]);
  const [groupList, setGroupList] = useState([]);
  const [errors, setErrors] = useState({});
  const [dancers, setDancers] = useState(dancerIds);

  const handleNameChange = useCallback(
    (e) => {
      const NAME = e.target.value;
      setErrors({ club: '', name: GroupValidations.validateGroupName(NAME, nameList) });
      setName(NAME);
    },
    [nameList]
  );

  const handleClubChange = useCallback(
    (e) => {
      setName('');
      setGroupId(e.target.value);
      setErrors({ name: '', club: GroupValidations.validateGroupId(e.target.value, idsList) });
    },
    [idsList]
  );

  const { loading, error } = useQuery(MODAL_ADD_TO_GROUP_DATA, {
    variables: {
      dancerIds,
      isAdmin: admin,
      hasLocation: false,
      businessId: id,
      blockedByBusinessId: id,
      inGroupBusinessId: id,
    },
    onError: (err) => {
      logError(err, 'MODAL_ADD_TO_GROUP_DATA', ModalGroupAddDancersForm.displayName);
      toast.error(`${t('errors.server.500')}. ${t('modals.group_add_dancer.group.create.failure')}`);
    },
    onCompleted: ({ groups }) => {
      const { list, names, ids } = groups.reduce(
        (acm, { id, name }) => ({
          list: [...acm.list, { value: id, label: name }],
          names: [...acm.names, name],
          ids: [...acm.ids, id],
        }),
        {
          ids: [],
          names: [],
          list: [
            { value: '', label: t('modals.group_add_dancer.group.select_or_create') },
            { value: '-1', label: t('modals.group_add_dancer.group.create.new') },
          ],
        }
      );
      setIdsList(ids);
      setNameList(names);
      setGroupList(list);
    },
  });

  const [createGroup, { loading: createGroupLoading }] = useMutation(CREATE_GROUP_GQL, {
    variables: { businessId: id, name, dancerIds: dancers },
    onError: (err) => {
      logError(err, 'CREATE_GROUP_GQL', ModalGroupAddDancersForm.displayName);
      toast.error(`${t('errors.server.500')}. ${t('modals.group_add_dancer.group.create.failure')}`);
    },
    update: (cache, { data }) => {
      const { groups } = cache.readQuery({ query: GET_GROUP_NAME_LIST, variables: { businessId: id } });
      cache.writeQuery({
        query: GET_GROUP_NAME_LIST,
        variables: { businessId: id },
        data: { groups: [...groups, data.group_create.group] },
      });
    },
    onCompleted: () => onClose(),
  });

  const [addDancers, { loading: addDancerLoading }] = useMutation(GROUP_UPDATE_GQL, {
    variables: {
      groupId,
      toAdd: dancers,
      isAdmin: admin,
      hasLocation: false,
      inGroupBusinessId: id,
    },
    onError: (err) => {
      logError(err, 'GROUP_UPDATE_GQL', ModalGroupAddDancersForm.displayName);
      toast.error(`${t('errors.server.500')}. ${t('modals.group_add_dancer.group.create.failure')}`);
    },
    onCompleted: () => {
      toast.success(t('modals.group_add_dancer.dancer.add.success'));
      onClose();
    },
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    const ERROR_OBJ = {
      club: GroupValidations.validateGroupId(groupId, idsList),
      name: groupId === '-1' ? GroupValidations.validateGroupName(name.trim(), nameList) : '',
    };

    if (Object.values(ERROR_OBJ).some((err) => err !== '')) {
      setErrors(ERROR_OBJ);
      return;
    }
    if (idsList.includes(groupId)) await addDancers();
    else if (groupId === '-1') await createGroup();
  };

  const handleRemoveClick = (dancerId) => {
    setDancers([...dancers.filter((id) => id !== dancerId)]);
  };

  if (loading) return <LoadingDiscoWithContainer />;
  if (error) return <h3 className={S.errorMessage}>{t('modals.group_add_dancer.group.info.error')}</h3>;

  return (
    <form className={S.addToGroupForm} onSubmit={handleSubmit}>
      <h3 className={S.modalHeading}>{t('modals.group_add_dancer.dancer.add_to_group')}</h3>

      <FormSelectField
        id='modal_add_to_groupId'
        name='group'
        label={t('modals.group_add_dancer.group.group')}
        value={groupId}
        error={errors.club}
        options={groupList}
        className={S.groupName}
        isDisabled={createGroupLoading || addDancerLoading}
        handleChange={handleClubChange}
      />

      {groupId === '-1' && (
        <FormTextField
          id='modal_add_to_groupName'
          name='name'
          label={t('modals.group_add_dancer.group.name')}
          value={name}
          error={errors.name}
          isDisabled={createGroupLoading || addDancerLoading}
          handleChange={handleNameChange}
        />
      )}

      <div className={S.dancerList}>
        {dancers.map((id) => (
          <DancerGridItem
            dancerId={id}
            key={`DANCER_ADD_TO_GROUP_${id}`}
            onRemoveClick={dancers.length > 1 ? () => handleRemoveClick(id) : null}
          />
        ))}
      </div>

      <div className={S.actionArea}>
        <button
          className={S.invertedBtnRed}
          type='button'
          disabled={createGroupLoading || addDancerLoading}
          onClick={onClose}
        >
          {t('common.cancel')}
        </button>
        <button className={S.invertedBtnGreen} type='submit' disabled={createGroupLoading || addDancerLoading}>
          {t('common.save')}
        </button>
      </div>
    </form>
  );
};

ModalGroupAddDancersForm.displayName = 'ModalGroupAddToForm';

ModalGroupAddDancersForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  dancerIds: PropTypes.array.isRequired,
};

export { ModalGroupAddDancersForm };
