import * as React from 'react';

import { LoadingDisco } from '../loading/loading-disco';

import S from './loading-page.module.scss';

const LoadingPage = () => {
  return (
    <div className={S.loadingPageWrapper}>
      <LoadingDisco />
    </div>
  );
};

LoadingPage.displayName = 'LoadingPage';

export { LoadingPage };
