import { gql } from 'apollo-boost';

import { CONVERSATION_FRAGMENT } from '../fragments/conversation-fragment';

export const GET_CHAT_PAGE_LISTS = gql`
  ${CONVERSATION_FRAGMENT}
  query ($chatBizId: ID, $userId: ID!, $isAdmin: Boolean!, $blockedByBusinessId: ID) {
    conversations(businessId: $chatBizId, userId: $userId) {
      ...conversationFragment
    }
  }
`;

export const GET_CHAT_PAGE_LISTS_PAGINATED = gql`
  ${CONVERSATION_FRAGMENT}
  query (
    $chatBizId: ID
    $isAdmin: Boolean!
    $blockedByBusinessId: ID
    $inGroupBusinessId: ID
    $first: Int!
    $after: String
    $senderSearch: String
    $sortByUnreadMessages: Boolean
    $sortByLastMessageSent: Boolean
  ) {
    conversations_paginated(
      businessId: $chatBizId
      first: $first
      after: $after
      senderSearch: $senderSearch
      sortByUnreadMessages: $sortByUnreadMessages
      sortByLastMessageSent: $sortByLastMessageSent
    ) {
      nodes {
        ...conversationFragment
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;
