import moment from 'moment';
import React, { useCallback, useMemo } from 'react';
import * as PropTypes from 'prop-types';
import { Calendar } from 'react-calendar';
import { useTranslation } from 'react-i18next';

import S from './mini-calendar.module.scss';
import { MiniCalendarDateTile } from './mini-calendar-date-tile';
import { MiniCalLegend } from '../mini-cal-legend';
import { LoadingDisco } from '../../loading/loading-disco';

const MiniCalendar = ({ isLoading, data, jsDate, timezone, dateChange, monthChange }) => {
  const { t, i18n } = useTranslation();
  const TODAY = useMemo(moment);

  const TileClassName = useCallback(
    ({ date }) => (moment(TODAY).isSame(moment(date), 'day') ? t('common.today') : undefined),
    [TODAY]
  );

  const renderTile = useCallback(
    ({ date }) => <MiniCalendarDateTile today={TODAY} date={date} data={data} />,
    [TODAY, data]
  );

  return (
    <>
      <MiniCalLegend />

      <div className={S.miniCalendar}>
        {isLoading && (
          <div className={S.loadingOverlay}>
            <LoadingDisco />
          </div>
        )}
        <Calendar
          calendarType='US'
          minDetail='month'
          view='month'
          next2Label={null}
          prev2Label={null}
          value={jsDate}
          showNeighboringMonth={false}
          onActiveStartDateChange={monthChange}
          onClickDay={dateChange}
          tileClassName={TileClassName}
          tileContent={renderTile}
          locale={i18n.language}
        />
      </div>
    </>
  );
};

MiniCalendar.displayName = 'MiniCalendar';

MiniCalendar.propTypes = {
  data: PropTypes.object.isRequired,
  jsDate: PropTypes.instanceOf(Date).isRequired,
  timezone: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,

  dateChange: PropTypes.func.isRequired,
  monthChange: PropTypes.func.isRequired,
};

export { MiniCalendar };
