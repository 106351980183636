import _throttle from 'lodash/throttle';
import { useState } from 'react';

export const useVerificationInfiniteScroll = ({ gridRef, stage, fetchMore, data }) => {
  const PAGE_INFO = data?.verification_checks?.pageInfo;

  const [isLoading, setIsLoading] = useState(false);

  const loadMore = _throttle(async () => {
    const { scrollTop, scrollHeight, children } = gridRef.current;

    if (!isLoading && PAGE_INFO?.hasNextPage) {
      const LEFT_TO_SCROLL = scrollHeight - scrollTop;
      const ROW = children[0].clientHeight;

      if (LEFT_TO_SCROLL < ROW * 10) {
        setIsLoading(true);
        await fetchMore({
          variables: { verified: stage, after: PAGE_INFO.endCursor },
          updateQuery: (previousResult, { fetchMoreResult }) => {
            if (!fetchMoreResult) return previousResult;

            return {
              verification_checks: {
                ...fetchMoreResult.verification_checks,
                nodes: [...previousResult.verification_checks.nodes, ...fetchMoreResult.verification_checks.nodes],
              },
            };
          },
        });

        setIsLoading(false);
      }
    }
  }, 500);

  return {
    isLoading,
    loadMore,
  };
};
