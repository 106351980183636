import { useEffect, useCallback } from 'react';

export const useOverlayClick = (ref, func) => {
  const handleClick = useCallback(e => {
    if (ref && (!ref.current.contains(e.target))) func();
  }, [ref, func]);

  useEffect(
    () => {
      // add when mounted
      document.addEventListener('mousedown', handleClick);
      // return function to be called when unmounted
      return () => {
        document.removeEventListener('mousedown', handleClick);
      };
    },

    [handleClick]
  );
};
