import { FC } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { ACTION_TYPES } from './constants';
import { ActionButtonProps } from './types';

import S from '../../../sass/buttons/button.module.scss';

const {
  ACCEPT,
  ACCEPT_CANCEL,
  REJECT,
  REJECT_CANCEL,
  STANDBY,
  STANDBY_CANCEL,
  INVITE_CANCEL,
  CHECK_IN,
  CHECK_OUT,
  CHECK_IN_CANCEL,
  CHECK_OUT_CANCEL,
  FAILED_TO_ATTEND,
  FAILED_TO_ATTEND_CANCEL,
  RATE_UNDO,
} = ACTION_TYPES;

const actionNameMap = {
  STANDBY: STANDBY,
  STANDBY_CANCEL: 'undo',
  ACCEPT: ACCEPT,
  ACCEPT_CANCEL: 'undo',
  REJECT: REJECT,
  REJECT_CANCEL: 'undo',
  INVITE_CANCEL: 'cancel',
  FAILED_TO_ATTEND: 'missed',
  FAILED_TO_ATTEND_CANCEL: 'missed_undo',
  CHECK_IN: CHECK_IN,
  CHECK_IN_CANCEL: 'undo',
  CHECK_OUT: CHECK_OUT,
  CHECK_OUT_CANCEL: 'undo',
  RATE_UNDO: RATE_UNDO,
};

const ActionButton: FC<ActionButtonProps> = ({ action, isMany, onClick, disabled }) => {
  const { t } = useTranslation();
  const className = classNames({
    [S.invertedBtnYellow]: [
      STANDBY,
      ACCEPT_CANCEL,
      REJECT_CANCEL,
      STANDBY_CANCEL,
      CHECK_IN_CANCEL,
      CHECK_OUT_CANCEL,
      FAILED_TO_ATTEND_CANCEL,
      RATE_UNDO,
    ].includes(action),
    [S.invertedBtnBlue]: [STANDBY, CHECK_IN, CHECK_OUT, FAILED_TO_ATTEND].includes(action),
    [S.invertedBtnGreen]: action === ACCEPT,
    [S.invertedBtnRed]: [REJECT, INVITE_CANCEL].includes(action),
    ['opacity-50']: disabled,
  });

  return (
    <button className={className} disabled={disabled} onClick={onClick}>
      {`${t(`common.${actionNameMap[action as keyof typeof actionNameMap].toLowerCase()}`)}${
        isMany ? ` ${t('common.selected')}` : action === INVITE_CANCEL ? ` ${t('common.invite')}` : ''
      }`.toUpperCase()}
    </button>
  );
};

export default ActionButton;
