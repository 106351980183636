import { gql } from 'apollo-boost';

export const UPDATE_VERIFICATION_GQL = gql`
    mutation update_verification_check(
        $id: ID!,
        $verified: Boolean!,
        $rejectionText: String,
        $rejectionReason: VerificationRejectionReasonEnum,
        $rejectedPictureIds: [ID!]!
    ) {
        update_verification_check(input: {
            id: $id,
            verified: $verified,
            rejectionText: $rejectionText,
            rejectionReason: $rejectionReason,
            rejectedPictureIds: $rejectedPictureIds
        }) {
            verification_check{
                id
                rejection_text
                verified
            }
        }
    }
`;

export const UPDATE_PHOTO_VERIFICATION_GQL = gql`
    mutation update_photo_verification(
        $id: ID!,
        $isVerified: Boolean!
        $rejectionText: String
    ) {
    image_verification(input: {
            id: $id,
            isVerified: $isVerified,
            rejectionText: $rejectionText
        }) {
            image {
                id
            }
        }
    }
`;
