import React, { useCallback, useMemo, useRef, useState } from 'react';
import Calendar from 'react-calendar';
import { FaRegCalendarAlt } from 'react-icons/fa';
import * as PropTypes from 'prop-types';
import '../../../../../../sass/libs/shift-calendar.scss';
import capitalize from 'lodash/capitalize';

import S from './booking-date-field.module.scss';
import { useModalBookingCtx } from '../../../modal-booking-context';
import * as BookingActions from '../../../modal-booking-actions';
import { validateBookingField } from '../../../modal-booking-services';
import { useBusiness } from '../../../../../../graphql/graph-hooks';
import { useOutsideClick } from '../../../../../../helpers/hooks/use-outside-click';
import * as TimeHelpers from '../../../../../../helpers/times';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

const BookingDateField = ({ isDisabled, value }) => {
  const CAL_REF = useRef();
  const { t, i18n } = useTranslation();
  const [showCalendar, setShowCalendar] = useState(false);

  useOutsideClick(CAL_REF, () => setShowCalendar(false));

  const { timezone } = useBusiness();
  const { dispatch } = useModalBookingCtx();

  const DISPLAY_DATE = useMemo(() => {
    return moment(value).format(TimeHelpers.TimePatterns.shortDateFormatted);
  }, [value, timezone]);

  const handleDateClick = useCallback((e) => {
    e.preventDefault();
    setShowCalendar(true);
  }, []);

  const handleDateChange = useCallback(
    (jsDateObj) => {
      const DATE_STRING = jsDateObj.toISOString();
      dispatch(
        BookingActions.setFormFields({
          duplicates: [],
          date: DATE_STRING,
        })
      );
      dispatch(BookingActions.setBookingError('date', validateBookingField('date', DATE_STRING, [], t)));
      setShowCalendar(false);
    },
    [dispatch]
  );

  return (
    <div className={S.bookingDateWrapper}>
      <label className={classNames(S.bookingFormLabel, { [S.disabled]: isDisabled })}>{t('common.date')}</label>

      <button disabled={isDisabled} onClick={handleDateClick}>
        {i18n.language === 'en' ? capitalize(DISPLAY_DATE) : DISPLAY_DATE}
        <FaRegCalendarAlt />
      </button>

      {showCalendar && (
        <div ref={CAL_REF}>
          <Calendar
            disabled={false}
            calendarType='US'
            value={TimeHelpers.createMoment(value, timezone).toDate()}
            minDate={TimeHelpers.createMoment(new Date(), timezone).toDate()}
            minDetail='year'
            onChange={handleDateChange}
            next2Label={null}
            prev2Label={null}
            showNeighboringMonth={false}
            locale={i18n.language}
          />
        </div>
      )}
    </div>
  );
};

BookingDateField.propTypes = {
  value: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool.isRequired,
};

export { BookingDateField };
