import React from 'react';
import * as PropTypes from 'prop-types';
import { FaArrowDown, FaArrowUp } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

import S from 'sass/buttons/modern-btn.module.scss';

type OrderBtnProps = {
  handleClick: () => void;
  isAsc: boolean;
};

const OrderBtn: React.FC<OrderBtnProps> = ({ handleClick, isAsc }): React.ReactElement => {
  const { t } = useTranslation();
  return (
    <button type='button' className={S.modernBtn} onClick={handleClick}>
      {isAsc ? <FaArrowDown /> : <FaArrowUp />} {t('common.order')}
    </button>
  );
};

OrderBtn.displayName = 'OrderBtn';
OrderBtn.propTypes = {
  isAsc: PropTypes.bool.isRequired,
  handleClick: PropTypes.func.isRequired,
};

export { OrderBtn };
