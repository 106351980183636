import React, { useCallback, useState } from 'react';
import * as PropTypes from 'prop-types';
import { useMutation } from '@apollo/react-hooks';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { DancerGridItem } from '../../common/dancer-grid-item/dancer-grid-item';
import { FormTextareaField } from '../../common/form/form-textarea-field';
import { useBusiness } from '../../../graphql/graph-hooks';
import { INVITE_GENERALLY } from '../../../graphql/mutations/business-invite';
import { FaStar } from 'react-icons/fa';
import { logError } from '../../../helpers/errors/bug-report';

import S from './modal-invite-general-form.module.scss';

const ModalInviteGeneralForm = ({ onClose, dancers }) => {
  const { t } = useTranslation();
  const { id: businessId, name } = useBusiness();
  const dancerIds = dancers
    .filter(({ favorite_clubs }) => !!!favorite_clubs.find(({ id }) => id === businessId))
    .map(({ id }) => id);

  const [message, setMessage] = useState(`${name} would like you to come dance for them.`);

  const handleMessageChange = useCallback((e) => setMessage(e.target.value), [setMessage]);

  const [submitForm, { loading: processingSubmit }] = useMutation(INVITE_GENERALLY, {
    variables: { businessId, dancerIds },
    onCompleted: () => {
      toast.success(t('modals.invite.sent.success'));
      onClose();
    },
    onError: (err) => {
      logError(err, 'INVITE_GENERALLY', ModalInviteGeneralForm.displayName);
      toast.error(`${t('errors.server.500')}. ${t('modals.invite.sent.failure')}`);
    },
  });

  const someAlreadyFavorited = dancerIds.length !== dancers.length;

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      await submitForm();
    },
    [submitForm]
  );

  return (
    <form onSubmit={handleSubmit}>
      <FormTextareaField
        rows={3}
        name='message'
        id='modal_invite_general_message'
        value={message}
        //isDisabled={processingSubmit}
        isDisabled={true} // For now we'll disable custom message since we are missing BE support
        Successfully
        Dancers
        Invited
        label={t('modals.invite.message')}
        handleChange={handleMessageChange}
      />

      {someAlreadyFavorited && (
        <div className='flex items-center mt-4'>
          <FaStar size='18px' className='text-yellow-500 mr-2' />
          <div className='text-xs'>{t('modals.invite.already_favorited')}</div>
        </div>
      )}

      <div className={S.dancerList}>
        {dancers.map(({ id, favorite_clubs }) => {
          return (
            <div key={id} className='relative'>
              {!!favorite_clubs.find(({ id }) => id === businessId) && (
                <div>
                  <FaStar size='18px' className='text-yellow-500 absolute top-5 right-5 z-10' />
                </div>
              )}
              <DancerGridItem hasLocation key={`invite_dancer_${id}`} dancerId={id} />
            </div>
          );
        })}
      </div>

      <div className={S.actionArea}>
        <button className={S.invertedBtnRed} type='button' onClick={onClose} disabled={processingSubmit}>
          {t('common.cancel')}
        </button>
        <button className={S.invertedBtnGreen} type='submit' disabled={processingSubmit || !dancerIds.length}>
          {t('modals.invite.invite')}
        </button>
      </div>
    </form>
  );
};

ModalInviteGeneralForm.displayName = 'ModalInviteGeneralForm';
ModalInviteGeneralForm.propTypes = {
  dancers: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
};

export { ModalInviteGeneralForm };
