import * as PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useApolloClient, useMutation } from '@apollo/react-hooks';
import React, { useCallback, useState } from 'react';
import { logError } from '../../../../helpers/errors/bug-report';
import { useTranslation } from 'react-i18next';

import S from './profile-form.module.scss';
import { ImageField } from '../../../common/images/img-field';
import { useNavReRender } from '../../../global-hooks';
import { useUser } from '../../../../graphql/graph-hooks';
import { IMG_DELETE } from '../../../../graphql/mutations/image-delete';
import { CallUpdateUserPhoto } from '../../../../rest-calls';

const ProfileImage = ({ isDisabled, isProcessing }) => {
  const { t } = useTranslation();
  const CLIENT = useApolloClient();
  const [processingImg, setProcessingImg] = useState(false);
  const { id, first_image } = useUser();

  const { reRenderNav } = useNavReRender();

  const [deleteImage, { loading: processingDeleteImg }] = useMutation(IMG_DELETE, {
    variables: { imgId: first_image?.id },
    update: (cache) => {
      cache.writeData({
        id: `User:${id}`,
        data: { first_image: null },
      });
    },
    onCompleted: () => {
      reRenderNav();
      toast.success(t('employeePage.profile.delete_image.success'));
    },
  });

  const updateImage = useCallback(
    async ({ image_attachment }) => {
      try {
        setProcessingImg(true);
        const {
          data: { user },
        } = await CallUpdateUserPhoto(id, image_attachment);
        CLIENT.writeData({
          id: `User:${id}`,
          data: {
            first_image: {
              ...user.images[0],
              __typename: 'ImageAttachment',
            },
          },
        });
        reRenderNav();
        toast.success(t('employeePage.profile.update_image.success'));
      } catch (err) {
        logError(err, 'updateImage', ProfileImage.displayName);
        toast.error(t('employeePage.profile.update_image.failure'));
      } finally {
        setProcessingImg(false);
      }
    },
    [CLIENT, id, reRenderNav]
  );

  const IS_DISABLED = isDisabled || processingDeleteImg || processingImg;

  return (
    <div className={S.imgArea}>
      <h2 style={{ gridArea: 'imgLabel' }}>{t('employeePage.profile.image')}</h2>

      <ImageField
        isRound
        size='220px'
        alt='Profile Image'
        preview={first_image?.medium ?? null}
        isDisabled={IS_DISABLED}
        isLoading={processingDeleteImg || processingImg || isProcessing}
        hasPermission={!IS_DISABLED}
        setFile={updateImage}
        deleteFile={deleteImage}
      />
    </div>
  );
};

ProfileImage.displayName = 'ProfileImage';
ProfileImage.propTypes = {
  isProcessing: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool.isRequired,
};

export { ProfileImage };
