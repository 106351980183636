import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { logError } from '../../../helpers/errors/bug-report';
import { CallPostDancerEmails } from '../../../rest-calls';

import S from './email-form.module.scss';
import { EmailModalFieldPresenter } from './email-field-email';
import { validateEmail } from '../../../helpers/validations/user-validations';
import { useBusiness } from '../../../graphql/graph-hooks';
import { useTranslation } from 'react-i18next';

const EmailForm = ({ onClose }) => {
  const { t } = useTranslation();
  const [emailList, setEmailList] = useState([{ email: '', error: '' }]);
  const { id: businessId } = useBusiness();

  const addEmail = () => {
    const NEW_LIST = [...emailList, { email: '', error: '' }];
    setEmailList(NEW_LIST);
  };

  const removeEmail = (index) => {
    const NEW_LIST = [...emailList];
    NEW_LIST.splice(index, 1);
    setEmailList(NEW_LIST);
  };

  const updateEmail = (e, i) => {
    const NEW_LIST = [...emailList];
    NEW_LIST[i].email = e.target.value.trim();
    setEmailList(NEW_LIST);
  };

  const checkEmail = (e, i) => {
    const EMAIL = e.target.value.trim();
    const NEW_LIST = [...emailList];
    const ERROR = validateEmail(
      EMAIL,
      NEW_LIST.filter((_, ind) => ind !== i).map(({ email }) => email)
    );

    NEW_LIST[i] = { email: EMAIL, error: ERROR };
    setEmailList(NEW_LIST);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const HAS_EMAILS = emailList.some(({ email }) => email);
    const IS_VALID = emailList.every(({ error }) => !error);

    if (!HAS_EMAILS) {
      toast.warn(t('modals.email.enter'));
      return;
    } else if (!IS_VALID) return;

    try {
      await CallPostDancerEmails(emailList, businessId);
      onClose();
      toast.success(t('modals.email.submit.success'));
    } catch (err) {
      logError(err, handleSubmit, EmailForm.displayName);
      toast.error(`${t('errors.server.500')}. ${t('modals.email.submit.failure')}`);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <ol className={S.emailList}>
        {emailList.map((emailObj, i) => (
          <EmailModalFieldPresenter
            key={`modal_email_${i}`}
            hasButton={emailList.length > 1}
            index={i}
            removeEmail={() => removeEmail(i)}
            updateEmail={(e) => updateEmail(e, i)}
            checkEmail={(e) => checkEmail(e, i)}
            {...emailObj}
          />
        ))}
      </ol>

      <div className={S.btnArea}>
        <button className={S.invertedBtnBlue} type='button' onClick={addEmail}>
          {t('modals.email.add')}
        </button>
        <button className={S.blueBtn}>{t('common.submit')}</button>
      </div>
    </form>
  );
};

EmailForm.displayName = 'EmailForm';
EmailForm.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export { EmailForm };
