import { useCallback, useState } from 'react';

import { LOCALES }           from '../../../helpers/enums';
import * as Validations      from '../../../helpers/validations/notification-validations';
import { NOTIFICATION_TYPES} from '../../../helpers/constants';

const { EN, ES } = LOCALES;
const langs = [EN, ES];

export const useMessageNotificationForm = groupId => {
  
  const [errors, setErrors] = useState({
    type: '',
    title: {[EN]: '', [ES]: ''},
    message: {[EN]: '', [ES]: ''}
  });

  const [values, setValues] = useState({
    type: groupId ? NOTIFICATION_TYPES.DANCERS.value : '',
    title: {[EN]: '', [ES]: ''},
    message: {[EN]: '', [ES]: ''}
  });

  const handleTypeChange = useCallback(
    e => {
      const { name, value } = e.target;
      setValues({...values, [name]: value});
      setErrors({...errors, [name]: Validations.validateAudience(value)});
    },
    [values, errors, setValues, setErrors]
  );

  const updateValues = useCallback(
    (field, name, value) => setValues({...values, [field]: {...values[field], [name]: value}}),
    [setValues, values]
  );

  const updateErrors = useCallback(
    (field, name, value) => setErrors({...errors, [field]: {...errors[field], [name]: value}}),
    [setErrors, errors]
  );

  const handleTitleChange = useCallback(
    e => updateValues('title', e.target.name, e.target.value),
    [updateValues]
  );

  const handleMsgChange = useCallback(
    e => updateValues('message', e.target.name, e.target.value),
    [updateValues]
  );

  const handleTitleBlur = useCallback(
    e => {
      const { name: lang, value } = e.target;
      const otherLang = [EN, ES].filter(i => i !== lang)[0];
      const newValue = value.trim();
      const errorObj = {
        [lang]: Validations.validateTitle(newValue, values.title),
        [otherLang]: Validations.validateTitle(values.title[otherLang], {...values.title, [lang]:newValue})
      };
      setErrors({...errors, title: errorObj});
      updateValues('title', lang, newValue);

    },
    [setErrors, updateValues]
  );

  const handleMsgBlur = useCallback(
    e => {
      const { name: lang, value } = e.target;
      const newValue = value.trim();
      updateErrors('message', lang, Validations.validateMessage(
        {...values, message: {...values.message, [lang]:newValue}}, lang, groupId)
      );
      updateValues('message', lang, newValue);
    },
    [updateErrors, updateValues, groupId]
  );

  const validateForm = useCallback(
    () => {
      let isValid = true;
      const ERROR_OBJ = {
        type: Validations.validateAudience(values.type),
        title: {
          en: Validations.validateTitle(values.title.en, values.title),
          es: Validations.validateTitle(values.title.es, values.title)
        },
        message: {
          en: Validations.validateMessage(values, EN, groupId),
          es: Validations.validateMessage(values, ES, groupId) 
        },
      };

      if (ERROR_OBJ.type) isValid = false;
      
      ['title', 'message'].forEach(field => {
        if (Object.values(ERROR_OBJ[field]).reduce((a,c) => a + c, '')) isValid = false;
      });
      
      setErrors(ERROR_OBJ);
      
      return isValid;
    }, [values, groupId]
  );

  return {
    values,
    errors,
    langs,
    handleMsgBlur,
    handleTitleBlur,
    handleMsgChange,
    handleTypeChange,
    handleTitleChange,
    validateForm
  };
};
