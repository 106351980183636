import React, { useCallback, useState } from 'react';
import * as PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { AcceptedListItem } from './accepted-list-item';
import S from './invite-list-item.module.scss';
import { Image } from '../../../../common/images/img-file';
import { useModal } from '../../../../global-hooks';
import { UI_MODALS } from '../../../../../helpers/enums';

const InviteListItem = ({ id, user, code, active, filter, type, hasPermission, description, users_count, canEdit }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  const handleViewAccepted = useCallback(() => setIsOpen(!isOpen), [isOpen]);
  const { initModal } = useModal();

  return (
    <div className={classNames(S.inviteListItem, { [S.active]: active })}>
      <div className={S.invite}>
        <div className={S.dataColumn}>
          <Image
            alt={user?.name}
            fallback='USER'
            src={user?.first_image?.thumb}
            classname={classNames(S.creatorImg, { [S.inactive]: !active })}
          />
          <div>
            <div className={S.row}>
              <span className={S.inviteLabel}>{t('common.creator')}:</span>
              {user ? user.name : 'Club Code'}
            </div>
            {hasPermission && (
              <div className={S.row}>
                <span className={S.inviteLabel}>{t('employeePage.code')}:</span>
                <p className={S.code}>{code}</p>
              </div>
            )}
            <div className={S.row}>
              <span className={S.inviteLabel}>{t('common.status')}:</span>
              {active ? (
                <span className={S.statusActive}>{t('common.active')}</span>
              ) : (
                <span className={S.statusInactive}>{t('common.inactive')}</span>
              )}
              |{' '}
              <span className={S.bold}>
                <span>{users_count}</span> - {t('common.registered_dancers')}
              </span>
            </div>
            <p className={S.description}>
              <span className={S.inviteLabel}>{t('common.description')}:</span>
              {description || '--'}
            </p>
          </div>
        </div>
        <div className={S.actionCol}>
          {hasPermission && canEdit && (
            <button
              className={S.actionBtn}
              onClick={() => initModal(UI_MODALS.INVITE_CODE, { codeId: id, type, filter })}
            >
              {t('common.edit')} {t('employeePage.code')}
            </button>
          )}
          {users_count > 0 && (
            <button className={S.actionBtn} onClick={handleViewAccepted}>
              {isOpen
                ? `${t('common.hide')} ${t('common.registered')}`
                : `${t('common.view')} ${t('common.registered')} (${users_count})`}
            </button>
          )}
        </div>
      </div>
      {isOpen && <AcceptedListItem codeId={id} />}
    </div>
  );
};

InviteListItem.propTypes = {
  user: PropTypes.object,
  description: PropTypes.string,
  id: PropTypes.string.isRequired,
  users_count: PropTypes.number.isRequired,
  code: PropTypes.string,
  hasPermission: PropTypes.bool.isRequired,
  active: PropTypes.bool.isRequired,
  type: PropTypes.oneOf(['CLUB', 'ADMIN', 'EMPLOYEE']).isRequired,
  canEdit: PropTypes.bool.isRequired,
};

InviteListItem.defaultProps = {
  user: null,
  code: null,
  description: null,
};

export { InviteListItem };
