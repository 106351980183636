import { useCallback } from 'react';
import { FaSearch } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

import * as DancerSearchCTX from '../../dancer-search-context';
import { useDebounce } from '../../../../../../helpers/hooks/use-debounce';

import S from './filter-dancer-name.module.scss';

const FilterDancerName = () => {
  const { t } = useTranslation();
  const {
    dispatch,
    state: { nameFilter },
  } = DancerSearchCTX.useDancerSearchCtx();

  const handleChange = useCallback(
    (value) => dispatch(DancerSearchCTX.setDancerSearchField('nameFilter', value)),
    [dispatch]
  );

  const { value, onChange } = useDebounce(nameFilter, handleChange);

  return (
    <label className={S.dancerNameFilter} htmlFor='dancer_search_input'>
      <input
        id='dancer_search_input'
        type='text'
        placeholder={t('common.search_dancers')}
        value={value}
        onChange={onChange}
      />
      <FaSearch />
    </label>
  );
};

FilterDancerName.displayName = 'FilterDancerName';

export { FilterDancerName };
