import * as React from 'react';
import * as PropTypes from 'prop-types';

import { PreApproveBookingListItem } from './pre-approve-booking-list-item';
import { NoItems } from '../../../common/no-items';
import { PreApprovedBookingListWrapper } from '../../../common/booking-list/pre-approved-booking-list-wrapper';

const PreApproveBookingList = ({ list, groupId, timezone, selectedId, selectedDate, setBookingId }) => {
  return (
    <PreApprovedBookingListWrapper date={selectedDate}>
      {list.length === 0 ? (
        <NoItems />
      ) : (
        list.map((booking) => (
          <PreApproveBookingListItem
            key={booking.id}
            groupId={groupId}
            timezone={timezone}
            isSelected={booking.id === selectedId}
            setSelected={setBookingId}
            {...booking}
          />
        ))
      )}
    </PreApprovedBookingListWrapper>
  );
};

PreApproveBookingList.displayName = 'BookingListPresenter';
PreApproveBookingList.propTypes = {
  selectedId: PropTypes.string,
  groupId: PropTypes.string.isRequired,
  list: PropTypes.array.isRequired,
  timezone: PropTypes.string.isRequired,
  selectedDate: PropTypes.object.isRequired,

  setBookingId: PropTypes.func.isRequired,
};

PreApproveBookingList.defaultProps = {
  selectedId: null,
};

export { PreApproveBookingList };
