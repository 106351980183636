import { gql } from 'apollo-boost';

export const REJECT_DANCER = gql`
  mutation dancer_reject(
    $dancerId: ID!
    $rejectionReason: VerificationRejectionReasonEnum!
    $rejectionText: String
    $rejectedPictureIds: [ID]
  ) {
    dancer_reject(input:{
      id: $dancerId
      rejectionReason: $rejectionReason
      rejectionText: $rejectionText
      rejectedPictureIds: $rejectedPictureIds
    }
    ) {
      clientMutationId
      dancer {
        id
       
      }
    }
  }
`;

export const PROFILE_PICTURE_REJECT = gql`
  mutation profilePictureReject(
    $id: ID!,
    $rejectionText: I18nTextInputObject!,
    $rejectionReason: ImageRejectionReasonEnum!
  ){
    profile_picture_reject(input: {
      id: $id	
      rejectionText: $rejectionText
      rejectionReason: $rejectionReason
    }){
      clientMutationId
      image {
        id
        is_verified
        rejection_text      
      }
    }
  }
`;