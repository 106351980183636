import { useApolloClient } from '@apollo/react-hooks';
import { useCallback, useState } from 'react';
import * as PropTypes from 'prop-types';
import { useLocation } from 'react-router';
import { FaEllipsisH } from 'react-icons/fa';
import { useBusiness } from 'graphql/graph-hooks';

import S from './dancer-action-menu.module.scss';
import * as DancerMenuServices from './dancer-menu-services';
import { DancerMenuDropDown } from './dancer-menu';
import { useUser } from '../../../graphql/graph-hooks';
import { DANCER_GRID_FRAGMENT } from 'graphql/fragments/dancer-grid-fragment';

const DancerMenu = ({ appId, gridArea, dancerId, isDropDown = false, removeDancer, handleSelect, businessId, verificationStatus = null }) => {
  const CLIENT = useApolloClient();
  const { pathname } = useLocation();
  const { admin, roles } = useUser();
  const { id: currentBusinessId } = useBusiness();
  const targetBusinessId = businessId || currentBusinessId;
  const { suspended, blocked_by } = CLIENT.readFragment({
    fragment: DANCER_GRID_FRAGMENT,
    id: `Dancer:${dancerId}`,
    variables: {
      isAdmin: admin,
      hasLocation: false,
      blockedByBusinessId: targetBusinessId,
      inGroupBusinessId: targetBusinessId,
    },
    fragmentName: 'DancerGridItem',
  });

  const [isOpen, setIsOpen] = useState(false);

  const LINKS = DancerMenuServices.useMenuActions(dancerId, appId, removeDancer, handleSelect, targetBusinessId);

  const LIST = DancerMenuServices.getDancerMenuList(
    pathname,
    isDropDown,
    LINKS,
    businessId,
    blocked_by,
    suspended,
    admin,
    roles?.[businessId]?.permissions ?? [],
    verificationStatus

  );

  const handleClick = useCallback((func) => {
    if (func) {
      func();
    }
    setIsOpen(false);
  }, []);

  const handleChange = useCallback(
    (e) => {
      const FUNC = LIST[e.target.value].onSelect;
      if (FUNC) {
        FUNC();
      }
    },
    [LIST]
  );

  return isDropDown ? (
    <select style={{ gridArea }} className={S.dancerActionSelect} onChange={handleChange}>
      {LIST.map(({ label }, i) => (
        <option key={`dancer_option_${i}`} value={i}>
          {label}
        </option>
      ))}
    </select>
  ) : (
    <div style={{ gridArea }} className={S.dancerActionMenuWrapper}>
      <button className={S.dancerActionMenuBtn} onClick={() => setIsOpen(true)}>
        <FaEllipsisH />
      </button>
      {isOpen && <DancerMenuDropDown list={LIST} handleClick={handleClick} onClose={() => setIsOpen(false)} />}
    </div>
  );
};

DancerMenu.displayName = 'DancerMenu';
DancerMenu.propTypes = {
  appId: PropTypes.string,
  isDropDown: PropTypes.bool,
  dancerId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  gridArea: PropTypes.string,
  removeDancer: PropTypes.func,
  businessId: PropTypes.string,
  verificationStatus: PropTypes.string,
  handleSelect: PropTypes.func
};

DancerMenu.defaultProps = {
  isDropDown: false,
  gridArea: null,
  removeDancer: null,
  verificationStatus: null,
  handleSelect: null
};

export { DancerMenu };
