import { useMutation } from '@apollo/react-hooks';
import { useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';
import { logError } from '../../../../helpers/errors/bug-report';
import { useTranslation } from 'react-i18next';

import * as UserProfileCtx from './user-profile-ctx';
import { useNavReRender } from '../../../global-hooks';
import { useUser } from '../../../../graphql/graph-hooks';
import { USER_PROFILE_CHANGE } from '../../../../graphql/mutations/user-profile-update';
import * as UserValidations from '../../../../helpers/validations/user-validations';

export const useProfileForm = (onClose) => {
  const { t } = useTranslation();
  const { id, name, email, locale } = useUser();
  const { reRenderNav } = useNavReRender();

  const {
    dispatch,
    state: { form },
  } = UserProfileCtx.useProfilePageCtx();

  const initForm = useCallback(() => {
    dispatch(
      UserProfileCtx.setProfileFields({
        form: {
          id,
          name,
          email,
          locale,
          password: '',
          confirm: '',
        },
        errors: {},
      })
    );
  }, [dispatch, id, name, email]);

  const validateConfirm = useCallback(
    (confirm) => {
      if (confirm === '' && form.password !== '') {
        return t('errors.password.confirmation_required');
      } else if (confirm !== form.password) {
        return t('errors.password.confirmation_failed');
      } else return '';
    },
    [form.password]
  );

  const validations = {
    name: UserValidations.validateName,
    email: UserValidations.validateEmail,
    locale: UserValidations.validateLocale,
    password: UserValidations.validatePassword,
    confirm: validateConfirm,
  };

  const handleChange = useCallback(
    (e) => {
      const { name, value } = e.target;
      dispatch(UserProfileCtx.setProfileFormField(name, value));
      dispatch(UserProfileCtx.setProfileErrorField(name, validations[name](value)));
    },
    [dispatch, validations]
  );

  const handleOnBlur = useCallback(
    (e) => {
      const { name, value } = e.target;
      dispatch(UserProfileCtx.setProfileFormField(name, value.trim()));
      dispatch(UserProfileCtx.setProfileErrorField(name, validations[name](value.trim())));
    },
    [dispatch, validations]
  );

  const validateForm = useCallback(() => {
    const ERROR_OBJ = {
      name: UserValidations.validateName(form.name),
      email: UserValidations.validateEmail(form.email),
      locale: UserValidations.validateLocale(form.locale),
      password: UserValidations.validatePassword(form.password),
      confirm: validateConfirm(form.confirm),
    };
    dispatch(UserProfileCtx.setProfileField('errors', ERROR_OBJ));

    return !Object.values(ERROR_OBJ).some((msg) => msg !== '');
  }, [dispatch, form, validateConfirm]);

  const [updateProfile, { loading: ProcessingSubmit }] = useMutation(USER_PROFILE_CHANGE, {
    onError: (err) => {
      logError(err, USER_PROFILE_CHANGE, 'useProfileForm');
      toast.error(t('employeePage.profile.update.failure'));
    },
    update: (
      cache,
      {
        data: {
          user_profile_update: {
            user: { id, name, email, locale },
          },
        },
      }
    ) => {
      cache.writeData({
        id: `User:${id}`,
        data: { name, email, locale },
      });
    },
    onCompleted: () => {
      reRenderNav();
      toast.success(t('employeePage.profile.update.success'));
      onClose();
    },
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      await updateProfile({
        variables: {
          userId: form.id,
          name: form.name.trim(),
          email: form.email.trim(),
          locale: form.locale,
          password: form.password.trim() || undefined,
        },
      });
    }
  };

  const handleCancel = useCallback(() => {
    initForm();
    onClose();
  }, [initForm, onClose]);

  useEffect(() => {
    initForm();
    // eslint-disable-next-line
  }, []);

  return {
    isDisabled: ProcessingSubmit,
    handleCancel,
    handleSubmit,
    handleChange,
    handleOnBlur,
  };
};
