import { FC } from 'react';
import { LoadMoreProps } from './types';
import LoadMoreButton from 'components/common/buttons/load-more-btn/load-more-btn';

const LoadMore: FC<LoadMoreProps> = ({ hasNextPage, isLoading, isFetchingMore, fetchMore }) => {
  return (!isLoading && hasNextPage) || isFetchingMore ? (
    <div className='flex mt-6 items-center justify-center'>
      <LoadMoreButton onClick={fetchMore} isLoading={isFetchingMore} />
    </div>
  ) : null;
};

export default LoadMore;
