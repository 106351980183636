import { FilterDancerName } from './filter-dancer-name';
import { DancerStylesFilter } from './filter-dancer-styles';
import { DancerSkillsFilter } from './filter-dancer-skills';
import { FilterDancerFreeAgent } from './filter-dancer-free-agent';
import { FilterDancerLocation } from './filter-dancer-location';
import { useBusiness } from 'graphql/graph-hooks';

import S from './filter.module.scss';

const Filters = () => {
  const { free_agent_search_enabled } = useBusiness();
  return (
    <div className={S.filterWrapper}>
      <div className={S.leftColumnWrapper}>
        <FilterDancerLocation />
        <FilterDancerFreeAgent disabled={!free_agent_search_enabled} />
        <DancerStylesFilter />
        <DancerSkillsFilter />
      </div>
      <FilterDancerName />
    </div>
  );
};

Filters.displayName = 'Filters';

export { Filters };
