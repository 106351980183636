import React from 'react';
import { Col, Panel, Row, Table } from 'rsuite';
import { SerieData } from '../../../../../types/charts';
import { useTranslation } from 'react-i18next';

const { Column, Cell, HeaderCell } = Table;

interface Props {
  data: SerieData[] | undefined;
}

const Summary: React.FC<Props> = ({ data }) => {
  const { t } = useTranslation();
  const sums = data?.map((dataPoint: SerieData) => ({
    label: dataPoint.label,
    value: dataPoint.data.reduce((acc: number, item: { value: number }) => acc + item.value, 0),
  }));

  return (
    (data && (
      <Panel bordered header={t('clubPage.analytics.total_for_period')}>
        <div className='flex gap-5'>
          {sums?.map((item) => (
            <Panel
              className={'flex-1'}
              key={`summary-${item.label}`}
              header={t(`clubPage.analytics.${item.label}`)}
              bordered
            >
              {item.value}
            </Panel>
          ))}
        </div>
      </Panel>
    )) ||
    null
  );
};

export default Summary;
