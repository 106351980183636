import ColorButton from 'components/common/buttons/color-button';
import { getFormattedJsTime } from 'helpers/times';
import { parseText } from 'helpers/feed';
import { FC } from 'react';
import { GetstreamComment } from 'types/getstream';
import { GETSTREAM_COMMENT_REPORT_REASON_TYPE } from 'helpers/enums';
import { useTranslation } from 'react-i18next';

interface Props {
  comment: GetstreamComment;
  onApprove: (id: string) => void;
  onReject: (id: string) => void;
  onView: (id: string) => void;
}
const CommentItem: FC<Props> = ({
  comment: { id, user_id, text, created_at, moderated_at, reasons, status, activity_id },
  onApprove,
  onReject,
  onView,
}) => {
  const { t } = useTranslation();
  const reasonsMap = reasons
    ? Object.values(reasons).reduce((acc, cur) => {
        return {
          ...acc,
          [cur]: acc[cur] ? acc[cur] + 1 : 1,
        };
      }, {} as Record<GETSTREAM_COMMENT_REPORT_REASON_TYPE, number>)
    : [];

  return (
    <div className='flex flex-row justify-between p-4 w-full hover:bg-gray-800 border-b-2 border-gray-800 last:border-b-0'>
      <div className='self-center w-1/3'>
        <div className='font-bold'>@{user_id}</div>
        <div className='text-gray-200'>
          {' '}
          {t('social.posted')}: {getFormattedJsTime(created_at)}{' '}
        </div>
        {moderated_at && (
          <div className='text-gray-200'>
            {' '}
            {t('social.moderated')}: {getFormattedJsTime(moderated_at)}{' '}
          </div>
        )}
      </div>

      <div className='self-center w-1/2 line-clamp-1'>
        <div> {parseText(text, null, { link: 'text-blue-500' }).text} </div>
      </div>

      <div className='self-center w-1/3'>
        {Object.entries(reasonsMap).map(([key, val], idx) => {
          return (
            <div key={idx}>
              {t(`social.comments.flags.${key}`)} ({val})
            </div>
          );
        })}
      </div>

      <div className='flex flex-col justify-center space-y-2'>
        <ColorButton
          color={'blue'}
          type={'inverted'}
          className='uppercase w-24'
          onClick={onView.bind(this, activity_id)}
        >
          {t('common.view')}
        </ColorButton>
        {status !== 'APPROVED' && (
          <ColorButton color={'green'} type={'inverted'} className='uppercase w-24' onClick={onApprove.bind(this, id)}>
            {t('common.approve')}
          </ColorButton>
        )}
        {status !== 'REJECTED' && (
          <ColorButton color={'red'} type={'inverted'} className='uppercase w-24' onClick={onReject.bind(this, id)}>
            {t('common.reject')}
          </ColorButton>
        )}
      </div>
    </div>
  );
};

export default CommentItem;
