import { useEffect } from 'react';
import * as React from 'react';
import * as PropTypes from 'prop-types';
import { useApolloClient } from '@apollo/react-hooks';
import * as Sentry from '@sentry/react';

import { LoadingDiscoWithContainer } from '../common/loading/loading-disco';
import * as ApolloLocalState from '../../graphql/local-state';

const LogOut = ({ isTimeOut = false }) => {
  const CLIENT = useApolloClient();

  useEffect(() => {
    CLIENT.writeData({ data: { isLoggedIn: false } });
    return () => {
      localStorage.clear();
      CLIENT.writeData({
        data: {
          ...ApolloLocalState.APOLLO_LOCAL_STATE,
          hasTimedOut: isTimeOut,
        },
      });
      Sentry.setUser(null);
    };
  }, [CLIENT, isTimeOut]);

  return <LoadingDiscoWithContainer />;
};

LogOut.propTypes = {
  isTimeOut: PropTypes.bool.isRequired,
};

LogOut.defaultProps = {
  isTimeOut: false,
};

export { LogOut };
