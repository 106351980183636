import React from 'react';
import PropTypes from 'prop-types';

const InviteIcon = ({height, width}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height={height}
    width={width}
    viewBox="0 0 15 10"
  >
    <path
      fill="currentColor"
      d="M12.7 3.7c-.2.2-.3.5-.3.8s.1.6.3.8.5.3.8.3v2.2c0 .3-.1.6-.3.8s-.5.4-.8.4H1.1c-.3 0-.6-.1-.8-.3S0 8.2 0 7.9V5.6c.3 0 .6-.1.8-.3s.3-.5.3-.8-.1-.6-.3-.8-.5-.3-.8-.3V1.1C0 .8.1.5.3.3s.5-.3.8-.3h11.2c.3 0 .6.1.8.3s.3.5.3.8v2.2c-.2.1-.5.2-.7.4zm-1.5-1.6c0-.2-.1-.3-.2-.4s-.2-.2-.4-.2H2.8c-.2 0-.3.1-.4.2s-.2.2-.2.4V7c0 .2.1.3.2.4s.2.2.4.2h7.9c.2 0 .3-.1.4-.2s.2-.2.2-.4V2.1zM3 2.2h7.5v4.5H3V2.2z"
    />
  </svg>
);

InviteIcon.displayName = 'InviteIcon';
InviteIcon.propTypes = {
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired
};

export { InviteIcon };
