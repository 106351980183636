import { useMutation } from '@apollo/react-hooks';
import { useCallback, useRef, useState } from 'react';
import * as PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router';
import { logError } from '../../../helpers/errors/bug-report';
import { useTranslation } from 'react-i18next';

import S from './modal-suspend-dancer.module.scss';
import { CloseButton } from '../../common/buttons/close-button';
import { useModal } from '../../global-hooks';
import * as DancerGQL from '../../../graphql/mutations/dancer-suspend';
import { useOverlayClick } from '../../../helpers/hooks/use-overlay-click';
import * as SuspendModalSelectors from '../../../helpers/validations/suspension-validations';
import { DANCER_GRID_FRAGMENT } from 'graphql/fragments/dancer-grid-fragment';
import { useUser } from 'graphql/graph-hooks';
import updateFragment from 'graphql/cache/updateFragment';
import { ON_SUSPEND_KEEP_DANCER_PAGES } from 'helpers/constants';

const ModalSuspendDancer = ({ onClose, dancerId, removeDancer, blockedByBusinessId }) => {
  const MODAL_REF = useRef(null);
  const { closeModal } = useModal();
  const [reason, setReason] = useState('');
  const [error, setError] = useState('');
  const { pathname } = useLocation();
  const { admin: isAdmin } = useUser();
  const { t } = useTranslation();

  useOverlayClick(MODAL_REF, onClose);

  const onInputChange = useCallback((e) => setReason(e.target.value), [setReason]);

  const [suspendMutation, { loading }] = useMutation(DancerGQL.SUSPEND_DANCER, {
    variables: { id: dancerId },
    update: (
      cache,
      {
        data: {
          dancer_suspend: { dancer },
        },
      }
    ) => {
      updateFragment(cache, dancer, {
        fragment: DANCER_GRID_FRAGMENT,
        id: `Dancer:${dancerId}`,
        variables: { isAdmin, hasLocation: false, blockedByBusinessId, inGroupBusinessId: blockedByBusinessId },
        fragmentName: 'DancerGridItem',
      });
    },
    onCompleted: () => {
      toast.success(t('modals.suspend.success'));
      if (!ON_SUSPEND_KEEP_DANCER_PAGES.includes(pathname)) {
        removeDancer();
      }
      closeModal();
    },
    onError: (err) => {
      logError(err, 'SUSPEND_DANCER', ModalSuspendDancer.displayName);
      toast.error(`${t('errors.server.500')}: ${t('modals.suspend.failure')}`);
    },
  });

  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      const ERROR = SuspendModalSelectors.validateSuspensionReason(reason);
      if (ERROR === '') await suspendMutation({ variables: { reason } });
      else setError(ERROR);
    },
    [reason, suspendMutation]
  );

  return (
    <form className={S.suspensionModal} onSubmit={onSubmit} ref={MODAL_REF}>
      <CloseButton onClick={onClose} />
      <h3 className={S.modalHeading}>{t('modals.suspend.suspend_dancer')}</h3>

      <div>
        <label className={S.suspensionLabel}>{t('modals.suspend.reason')}</label>
        <input
          className={S.inputField}
          type='text'
          value={reason}
          disabled={loading}
          onChange={onInputChange}
          placeholder={t('modals.suspend.reason_for_pause')}
        />
        <div className={S.suspensionError}>{error}</div>
      </div>

      <div className={S.buttonRow}>
        <button className={S.invertedBtnGreen} type='submit'>
          {t('common.submit')}
        </button>
        <button className={S.invertedBtnRed} type='button' onClick={onClose}>
          {t('common.cancel')}
        </button>
      </div>
    </form>
  );
};

ModalSuspendDancer.displayName = 'ModalSuspendDancer';
ModalSuspendDancer.propTypes = {
  removeDancer: PropTypes.func,
  dancerId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  blockedByBusinessId: PropTypes.string.isRequired,
};

export { ModalSuspendDancer };
