import { useEffect, useState } from 'react';

import { useApolloClient } from '@apollo/react-hooks';
import { GET_CODE_FRAGMENT } from 'graphql/fragments/registration-code-fragment';
import { useTranslation } from 'react-i18next';

export const useInviteForm = (codeId) => {
  const { t } = useTranslation();
  const CLIENT = useApolloClient();

  const [values, setValues] = useState({ code: '', location: null, isActive: false, description: '' });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    const INVITE =
      CLIENT.readFragment({
        id: `RegistrationCode:${codeId}`,
        fragment: GET_CODE_FRAGMENT,
      }) || {};

    setValues({
      code: INVITE?.code ?? '',
      isActive: INVITE?.active ?? false,
      location: INVITE?.location ?? null,
      description: INVITE?.description ?? '',
    });
  }, [CLIENT, codeId]);

  const handleChange = (field, data) => {
    const value = data?.target ? data.target.value : data;
    const newValue = field !== 'isActive' ? value : !values.isActive;
    setValues({ ...values, [field]: newValue });
    if (errors[field]) {
      setErrors({ ...errors, [field]: validate(field, newValue) });
    }
  };

  const validate = (field, value) => {
    switch (field) {
      case 'code':
        if (value === '') return t('errors.validation.required', { field_name: t('employeePage.code') });
        else if (value.length < 4)
          return t('errors.validation.min_length', { field_name: t('employeePage.code'), min_length: 4 });
        else if (value.length > 15)
          return t('errors.validation.max_length', { field_name: t('employeePage.code'), max_length: 15 });
        else if (!/^[a-zA-Z0-9]{4,16}$/.test(value))
          return t('errors.validation.invalid', { field_name: t('employeePage.code') });
        else return '';
      case 'location':
        if (value === '') return t('errors.validation.required', { field_name: t('common.location') });
        break;
      case 'description':
        if (value.length > 100)
          return t('errors.validation.max_length', { field_name: t('common.description'), max_length: 100 });
        else return '';
    }
  };

  const handleValidation = () => {
    const result = {};
    ['code', 'description'].forEach((field) => {
      result[field] = validate(field, values[field]);
    });
    setErrors(result);
    return checkForErrors(result);
  };

  const checkForErrors = (obj) => !!Object.values(obj).reduce((prev, curr) => prev + curr, '');

  return {
    values,
    errors,
    setErrors,
    handleChange,
    handleValidation,
    hasErrors: checkForErrors(errors),
  };
};
