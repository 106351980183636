import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import _range from 'lodash/range';
import { useTranslation } from 'react-i18next';

import { HoursOfOperation } from './hours-of-operation/hours-of-operation';
import { ProfileOptions } from './profile-options';
import { RequiredDocs } from './required-docs';
import * as ClubFormCtx from '../club-form-context';
import { FormTextareaField } from '../../../../common/form/form-textarea-field';
import { FormNumberField } from '../../../../common/form/form-number-field';
import { FormSelectField } from '../../../../common/form/form-select-field';
import { FormTextField } from '../../../../common/form/form-text-field';
import * as ClubValidations from '../../../../../helpers/validations/club-validation';
import { useUser, useBusiness } from '../../../../../graphql/graph-hooks';
import classNames from 'classnames';

const AdditionalInfoForm = ({ isDisabled }) => {
  const { t } = useTranslation();
  const { admin } = useUser();
  const { free_agent_search_enabled: freeAgentSearchEnabled } = useBusiness();
  const {
    dispatch,
    state: { form, errors },
  } = ClubFormCtx.useClubFormCtx();
  const handleChange = useCallback(
    (e) => dispatch(ClubFormCtx.setClubFormField(e.target.name, e.target.value)),
    [dispatch]
  );
  return (
    <div className='grid grid-cols-6 gap-4'>
      <div className='col-span-6'>
        <HoursOfOperation isDisabled={isDisabled} />
      </div>
      <div>
        <FormNumberField
          id='club_form_sqFootage'
          gridArea='size'
          name='sqFootage'
          label={t('clubPage.square_footage')}
          min={1}
          error={errors.sqFootage}
          isDisabled={isDisabled}
          value={form.sqFootage}
          handleBlur={({ target: { name, value } }) => {
            dispatch(ClubFormCtx.setClubFormField(name, value.trim()));
            dispatch(ClubFormCtx.setClubFormError(name, ClubValidations.validateInteger(value.trim())));
          }}
          handleChange={({ target: { name, value } }) => {
            dispatch(ClubFormCtx.setClubFormField(name, value));
            dispatch(ClubFormCtx.setClubFormError(name, ClubValidations.validateInteger(value)));
          }}
        />
      </div>
      <div>
        <FormNumberField
          id='club_form_stageCount'
          gridArea='stages'
          min={1}
          name='stageCount'
          label={t('clubPage.num_stages')}
          isDisabled={isDisabled}
          value={form.stageCount}
          error={errors.stageCount}
          handleBlur={({ target: { name, value } }) => {
            dispatch(ClubFormCtx.setClubFormField(name, value.trim()));
            dispatch(ClubFormCtx.setClubFormError(name, ClubValidations.validateInteger(value.trim())));
          }}
          handleChange={({ target: { name, value } }) => {
            dispatch(ClubFormCtx.setClubFormField(name, value));
            dispatch(ClubFormCtx.setClubFormError(name, ClubValidations.validateInteger(value)));
          }}
        />
      </div>
      <div className='col-span-2'>
        <FormSelectField
          id='club_form_maxBookings'
          gridArea='maxBookings'
          name='maxBookings'
          label={t('clubPage.max_consecutive_bookings')}
          error={errors.maxBookings}
          isDisabled={isDisabled}
          value={form.maxBookings}
          handleChange={handleChange}
          options={[
            { value: '0', label: t('common.unlimited') },
            ..._range(1, 32).map((num) => ({ value: String(num), label: String(num) })),
          ]}
        />
      </div>
      <div>
        <FormTextField
          id='club_form_regLink'
          gridArea='regLink'
          name='regLink'
          label={t('clubPage.registration_link')}
          error={errors.regLink}
          isDisabled={isDisabled}
          value={form.regLink}
          handleBlur={({ target: { name, value } }) => {
            dispatch(ClubFormCtx.setClubFormField(name, value.trim()));
            dispatch(ClubFormCtx.setClubFormError(name, ClubValidations.validateClubUrl(value.trim())));
          }}
          handleChange={({ target: { name, value } }) => {
            dispatch(ClubFormCtx.setClubFormField(name, value));
            dispatch(ClubFormCtx.setClubFormError(name, ClubValidations.validateClubUrl(value)));
          }}
        />
      </div>
      {admin && (
        <>
          <div className='col-span-2'>
            <FormNumberField
              id='club_form_minDancersForFreeAgentSearch'
              name='minDancersForFreeAgentSearch'
              label={t('clubPage.min_dancers_free_agent_search')}
              min={0}
              error={errors.minDancersForFreeAgentSearch}
              isDisabled={isDisabled}
              value={form.minDancersForFreeAgentSearch}
              handleBlur={({ target: { name, value } }) => {
                dispatch(ClubFormCtx.setClubFormField(name, value.trim()));
                dispatch(ClubFormCtx.setClubFormError(name, ClubValidations.validateInteger(value.trim())));
              }}
              handleChange={({ target: { name, value } }) => {
                dispatch(ClubFormCtx.setClubFormField(name, value));
                dispatch(ClubFormCtx.setClubFormError(name, ClubValidations.validateInteger(value)));
              }}
            />
          </div>
          <div className='col-span-2'>
            <FormNumberField
              id='club_form_dancerCount'
              gridArea='dancerCount'
              name='dancerCount'
              label={t('clubPage.current_dancer_count')}
              min={0}
              error={errors.dancerCount}
              isDisabled={true}
              value={form.dancerCount}
              handleBlur={() => {}}
              handleChange={() => {}}
            />
          </div>
          <div
            className={classNames([
              'col-span-2 text-lg text-bold pt-7',
              freeAgentSearchEnabled ? 'text-green-400' : 'text-red-400',
            ])}
          >
            {freeAgentSearchEnabled ? t('clubPage.free_agent_enabled') : t('clubPage.free_agent_disabled')}
          </div>
        </>
      )}
      <div className='col-span-3'>
        <ProfileOptions isDisabled={isDisabled} />
      </div>
      <div className='col-span-3'>
        <RequiredDocs isDisabled={isDisabled} />
      </div>
      <div className='col-span-3'>
        <FormTextareaField
          id='club_form_audition'
          gridArea='audition'
          name='auditionInfo'
          label={t('clubPage.audition_notes')}
          isDisabled={isDisabled}
          error={errors.auditionInfo}
          value={form.auditionInfo}
          handleChange={handleChange}
        />
      </div>
      <div className='col-span-3'>
        <FormTextareaField
          id='club_form_notes'
          gridArea='notes'
          name='notes'
          label={t('clubPage.additional_comments')}
          isDisabled={isDisabled}
          error={errors.notes}
          value={form.notes}
          handleChange={handleChange}
        />
      </div>
    </div>
  );
};

AdditionalInfoForm.displayName = 'AdditionalInfoForm';

AdditionalInfoForm.propTypes = {
  isDisabled: PropTypes.bool.isRequired,
};

export { AdditionalInfoForm };
