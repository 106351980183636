import { gql } from 'apollo-boost';

export const GET_GROUP_NAME_LIST = gql`
    query($businessId: ID!){
        groups(businessId: $businessId){ 
            id 
            name 
        }
    }
`;
