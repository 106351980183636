import { useMutation } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';
import {
  GETSTREAM_ADMIN_POST_CANCEL,
  GETSTREAM_ADMIN_POST_DELETE,
  GETSTREAM_ADMIN_POST_PAUSE,
  GETSTREAM_ADMIN_POST_UNPAUSE,
} from 'graphql/mutations/getstream';
import { logError } from 'helpers/errors/bug-report';
import { toast } from 'react-toastify';

const useActions = (refetch: any) => {
  const { t } = useTranslation();
  const handleError = (action: string, error: any) => {
    logError(error, 'GETSTREAM_ADMIN_POST_' + action, 'PostSchedulerList');
    toast.error(t('errors.generic'));
  };

  const handleSuccess = (message: string) => {
    toast.success(t(message));
  };

  const handleUpdate = () => {
    refetch();
  };

  const [pausePost, { loading: pausing }] = useMutation(GETSTREAM_ADMIN_POST_PAUSE, {
    update: handleUpdate,
    onCompleted: handleSuccess.bind(this, 'social.scheduler.action.paused'),
    onError: handleError.bind(this, 'PAUSE'),
  });

  const [unPausePost, { loading: unpausing }] = useMutation(GETSTREAM_ADMIN_POST_UNPAUSE, {
    update: handleUpdate,
    onCompleted: handleSuccess.bind(this, 'social.scheduler.action.unpaused'),
    onError: handleError.bind(this, 'UNPAUSE'),
  });

  const [cancelPost, { loading: cancelling }] = useMutation(GETSTREAM_ADMIN_POST_CANCEL, {
    update: handleUpdate,
    onCompleted: handleSuccess.bind(this, 'social.scheduler.action.canceled'),
    onError: handleError.bind(this, 'CANCEL'),
  });

  const [deletePost, { loading: deleting }] = useMutation(GETSTREAM_ADMIN_POST_DELETE, {
    update: handleUpdate,
    onCompleted: handleSuccess.bind(this, 'social.scheduler.action.deleted'),
    onError: handleError.bind(this, 'DELETE'),
  });

  return {
    pausePost,
    unPausePost,
    cancelPost,
    deletePost,
    isMutating: pausing || unpausing || cancelling || deleting,
  };
};

export default useActions;
