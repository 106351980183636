import React, { FC, useRef, useState } from 'react';
import { useApolloClient, useMutation } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';

import { CloseButton } from '../../common/buttons/close-button';
import { useOverlayClick } from '../../../helpers/hooks/use-overlay-click';

import S from './style.module.scss';
import { FormSelectField } from 'components/common/form/form-select-field';
import { UNVERIFY_DANCER } from 'graphql/mutations/dancer-unverify';
import { toast } from 'react-toastify';
import { SpinnerIcon } from 'components/common/icons/spinner-icon';

interface Props {
  onClose: () => void;
  dancer: { id: string; name: string; images: [{ id: string; thumb: string; is_verified: boolean }] };
}

const options = [
  { value: '', label: 'common.select_option' },
  { value: 'INVALID_PROFILE_PICTURE', label: 'modals.verification.rejection_reason.profile_picture' },
  { value: 'UNABLE_TO_VERIFY', label: 'modals.verification.rejection_reason.unable_to_verify' },
];

const ModalUnverify: FC<Props> = ({ onClose, dancer: { id, name, images } }) => {
  const [selected, setSelected] = useState<any>([]);
  const [rejectionReason, setRejectionReason] = useState<string>();
  const MODAL_REF = useRef(null);
  const { t } = useTranslation();
  useOverlayClick(MODAL_REF, onClose);

  const [unverifyDancer, { loading }] = useMutation(UNVERIFY_DANCER, {
    variables: {
      dancerId: parseInt(id),
      rejectionReason,
      rejectedPictureIds: selected,
    },
    onCompleted: () => {
      toast.success(t('modals.unverify.unverify.success'));
      onClose();
    },
    onError: (err) => {
      toast.error(t('modals.unverify.unverify.failure'));
    },
  });

  const handleOnClick = (id: string, isSelected: boolean) => {
    if (isSelected) {
      setSelected(selected.filter((item: string) => item !== id));
    } else {
      setSelected([...selected, id]);
    }
  };

  const handleOnSelectReason = (e: any) => {
    setRejectionReason(e.target.value);
  };

  const handleUnverify = () => {
    unverifyDancer();
  };

  const submitBtnDisabled =
    loading || !rejectionReason || (rejectionReason === 'INVALID_PROFILE_PICTURE' && selected.length === 0);

  return (
    <div ref={MODAL_REF} className={S.modalUnverify}>
      <CloseButton onClick={onClose} />
      <h3 className='mb-4'>
        {t('modals.unverify.unverify.title')} {name}
      </h3>

      <p className='text-sm font-bold'>{t('modals.unverify.selectImages')}:</p>
      <div className='flex gap-3 mt-2 mb-4 hover:cursor-pointer'>
        {images.map(({ id, thumb, is_verified }) => {
          const isSelected = rejectionReason === 'UNABLE_TO_VERIFY' || !!selected.find((item: string) => id === item);
          const classes = isSelected ? 'relative border-2 border-red-900' : 'relative border-2 border-transparent';

          let statusLabel = t('common.pending');
          let labelColor = 'bg-yellow-500';

          if (is_verified === true) {
            statusLabel = t('common.accepted');
            labelColor = 'bg-green-500';
          } else if (is_verified === false) {
            statusLabel = t('common.rejected');
            labelColor = 'bg-red-500';
          }

          return (
            <div key={id} className={classes} onClick={handleOnClick.bind(this, id, isSelected)}>
              {isSelected && <div className='absolute top-0 left-0 w-full h-full bg-red-600 bg-opacity-25' />}
              <div className={`absolute top-0 left-0 p-1 text-white ${labelColor}`}>
                {statusLabel}
              </div>
              <img src={thumb} alt={`Dancer image ${id}`} />
            </div>
          );
        })}
      </div>

      <div>
        <div className='max-w-sm'>
          <FormSelectField
            label={t('modals.unverify.selectReason')}
            id='modal_unverification_reason'
            name='unverification_reason'
            handleChange={handleOnSelectReason}
            value={rejectionReason}
            isDisabled={loading}
            options={options.map(({ value, label }) => ({ value, label: t(label) }))}
          />

          <button
            disabled={submitBtnDisabled}
            onClick={handleUnverify}
            className={`mt-2 px-16 py-3 rounded border border-transparent bg-red-600 px-2.5 py-1.5 mr-4 text-xs font-medium shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 ${submitBtnDisabled && 'disabled:opacity-50'
              }`}
          >
            {!loading ? t('common.submit') : <SpinnerIcon />}
          </button>
        </div>
      </div>
    </div>
  );
};

export { ModalUnverify };
