import * as React from 'react';

const SET_COURSE_FIELD = 'SET_COURSE_FIELD';
const SET_COURSE_FORM_FIELD = 'SET_COURSE_FORM_FIELD';
const SET_COURSE_ERROR_FIELD = 'SET_COURSE_ERROR_FIELD';

const PAGE_COURSE_INIT_STATE = {
  form: {
    title: '',
    videoUrl: '',
    imageUrl: '',
    description: '',
    categories: [],
  },
  errors: {},
};

export const setCourseField = (field = '', payload) => ({
  type: SET_COURSE_FIELD,
  field,
  payload,
});

export const setCourseFormField = (field = '', payload) => ({
  type: SET_COURSE_FORM_FIELD,
  field,
  payload,
});

export const setCourseErrorField = (field = '', payload) => ({
  type: SET_COURSE_ERROR_FIELD,
  field,
  payload,
});

function courseFormReducer(state, { type, field, payload }) {
  switch (type) {
    case SET_COURSE_FIELD:
      return { ...state, [field]: payload };

    case SET_COURSE_FORM_FIELD:
      return { ...state, form: { ...state.form, [field]: payload } };

    case SET_COURSE_ERROR_FIELD:
      return { ...state, errors: { ...state.errors, [field]: payload } };

    default:
      throw new Error(`Unsupported action type: ${type}`);
  }
}

const PAGE_CTX = React.createContext();

export function CourseFormProvider(props) {
  const [state, dispatch] = React.useReducer(courseFormReducer, PAGE_COURSE_INIT_STATE);
  const value = React.useMemo(() => [state, dispatch], [state]);

  return <PAGE_CTX.Provider value={value} {...props} />;
}

export function useCourseFormCtx() {
  const context = React.useContext(PAGE_CTX);

  if (!context) {
    throw new Error(`${useCourseFormCtx.name} must be used within a ${courseFormReducer.name}`);
  }

  const [state, dispatch] = context;
  return { state, dispatch };
}
