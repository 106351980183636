import React, { useMemo } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import S from './pre-approved-list.module.scss';
import { PreApprovedBooking } from './pre-approved-booking';
import * as TimeHelpers from '../../../../../../../helpers/times';

const SHORT_TIME = TimeHelpers.TimePatterns.shortTime;
const DATE_FORMAT = TimeHelpers.TimePatterns.date;

const sortPreApprovedBookings = (list = [], timezone, isRecurring) =>
  list.reduce((acm, { id, start_time, end_time }) => {
    const START = TimeHelpers.createMoment(start_time, timezone);
    const END = TimeHelpers.createMoment(end_time, timezone);
    const DATE = isRecurring ? START.day() : START.format(DATE_FORMAT);

    if (!acm[DATE]) acm[DATE] = [];

    acm[DATE] = [
      ...acm[DATE],
      {
        id: id,
        time: `${START.format(SHORT_TIME)} - ${END.format(SHORT_TIME)}`,
        inProgress: isRecurring ? false : TimeHelpers.getCurrentTimeAtTimezone(timezone).isBetween(START, END),
      },
    ];

    return acm;
  }, {});

const PreApprovedList = ({ groupId, shifts, timezone, canUpdate, isRecurring }) => {
  const { i18n, t } = useTranslation();
  const BOOKING_MAP = useMemo(
    () => sortPreApprovedBookings(shifts, timezone, isRecurring),
    [shifts, timezone, isRecurring]
  );

  const BOOKING_LIST = useMemo(() => Object.keys(BOOKING_MAP).sort(), [BOOKING_MAP]);

  return (
    <>
      {BOOKING_LIST.map((time) => (
        <div className={S.groupListWrapper} key={`booking_dates_${time}`}>
          <div className={S.groupListDate}>
            {isRecurring
              ? `${t('groupsPage.every')} ${TimeHelpers.getDayFromIndex(time, 'dddd', i18n.language)}`
              : TimeHelpers.getFormattedJsTime(time, TimeHelpers.TimePatterns.bookingFormat)}
          </div>

          {BOOKING_MAP[time].map((booking) => (
            <PreApprovedBooking
              key={`non_recurring_booking_${booking.id}`}
              groupId={groupId}
              canUpdate={canUpdate}
              isRecurring={isRecurring}
              {...booking}
            />
          ))}
        </div>
      ))}
    </>
  );
};

PreApprovedList.displayName = 'PreApprovedList';

PreApprovedList.propTypes = {
  isRecurring: PropTypes.bool,
  canUpdate: PropTypes.bool.isRequired,
  groupId: PropTypes.string.isRequired,
  timezone: PropTypes.string.isRequired,
  shifts: PropTypes.arrayOf(PropTypes.object).isRequired,
};

PreApprovedList.defaultProps = {
  canUpdate: false,
  isRecurring: false,
};

export { PreApprovedList };
