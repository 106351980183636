import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import S from './form-label.module.scss';

const FormLabel = ({ id, label, hasError, isDisabled, gridArea }) => {
  return (
    <label
      style={{ gridArea }}
      htmlFor={id}
      className={classNames({
        [S.formLabel]: true,
        [S.error]: hasError,
        [S.disabled]: isDisabled,
      })}
    >
      {label}
    </label>
  );
};

FormLabel.displayName = 'FormLabel';

FormLabel.propTypes = {
  id: PropTypes.string,
  gridArea: PropTypes.string,
  label: PropTypes.string.isRequired,
  hasError: PropTypes.bool,
  isDisabled: PropTypes.bool.isRequired,
};

FormLabel.defaultProps = {
  id: null,
  gridArea: null,
  hasError: false,
};

export { FormLabel };
