import React, { useState } from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import classNames from 'classnames';

const PasswordInput = ({ className, ...props }) => {
  const [isMasked, setIsMasked] = useState(true);
  const toggleIsMasked = () => setIsMasked(!isMasked);

  const type = isMasked ? 'password' : 'text';
  const Icon = isMasked ? FaEye : FaEyeSlash;

  return (
    <div className='flex flex-row gap-2 relative items-center'>
      <input {...props} type={type} className={classNames([className, 'pr-6'])} />
      <Icon size='20' onClick={toggleIsMasked} className='absolute right-0 bottom-4' />
    </div>
  );
};

export default PasswordInput;
