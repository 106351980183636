import * as classNames from 'classnames';
import * as React from 'react';
import * as PropTypes from 'prop-types';

import S from './input-form-number.module.scss';

const InputFormNumber = ({
  id,
  step,
  min,
  max,
  name,
  value,
  hasError,
  isDisabled,
  placeholder,
  handleBlur,
  handleChange,
}) => {
  return (
    <input
      id={id}
      name={name}
      placeholder={placeholder}
      className={classNames(S.InputFormNumber, { [S.error]: hasError })}
      type='number'
      step={step}
      min={min}
      max={max}
      disabled={isDisabled}
      onChange={handleChange}
      onBlur={handleBlur}
      value={value}
    />
  );
};

InputFormNumber.displayName = 'InputFormNumber';

InputFormNumber.propTypes = {
  id: PropTypes.string,
  placeholder: PropTypes.string,
  step: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  value: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  hasError: PropTypes.bool.isRequired,
  handleBlur: PropTypes.func,
  handleChange: PropTypes.func.isRequired,
  integerOnly: PropTypes.bool,
};

InputFormNumber.defaultProps = {
  id: null,
  placeholder: null,
  handleBlur: null,
};

export { InputFormNumber };
