import React, { useState, useCallback, useEffect, useRef } from 'react';
import * as PropTypes from 'prop-types';
import { INVITE_TABS } from '../../../helpers/constants';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/react-hooks';
import { useOverlayClick } from 'helpers/hooks/use-overlay-click';

import S from './modal-invite.module.scss';
import { ModalInviteBookingsForm } from './modal-invites-booking-form';
import { ModalInviteGeneralForm } from './modal-invite-general-form';
import { Tabs } from '../../common/tabs';
import { CloseButton } from '../../common/buttons/close-button';
import { GET_INVITE_DANCER } from 'graphql/queries/dancer-for-invite';
import { useBusiness, useUser } from 'graphql/graph-hooks';
import { logError } from 'helpers/errors/bug-report';

const ModalInviteDancers = ({ dancerId, dancers = [], onClose }) => {
  const MODAL_REF = useRef(null);
  const { t } = useTranslation();
  const { id: businessId } = useBusiness();
  const { admin: isAdmin } = useUser();
  const [activeTab, setActiveTab] = useState(INVITE_TABS.GENERAL);
  const [selectedDancers, setSelectedDancers] = useState(dancers);
  const handleTabClick = useCallback(setActiveTab, [setActiveTab]);
  const dancerIds = selectedDancers.map(({ id }) => id);
  useOverlayClick(MODAL_REF, onClose);

  const { data } = useQuery(GET_INVITE_DANCER, {
    fetchPolicy: 'cache-and-network',
    variables: {
      id: dancerId,
      hasLocation: true,
      isAdmin,
      blockedByBusinessId: businessId,
      inGroupBusinessId: businessId,
    },
    skip: !dancerId,
    onError: (err) => logError(err, 'GET_DANCER_GQL', ModalInviteDancers.displayName),
  });

  useEffect(() => {
    if (data) {
      setSelectedDancers([data.dancer]);
    }
  }, [data]);

  return (
    <div className={S.inviteModal} ref={MODAL_REF}>
      <CloseButton onClick={onClose} />
      <h3 className={S.modalHeading}>{t('modals.invite.invite_entertainers')}</h3>
      <Tabs
        tabs={[
          { key: INVITE_TABS.GENERAL, label: t('modals.invite.tabs.general') },
          { key: INVITE_TABS.BOOKINGS, label: t('modals.invite.tabs.bookings') },
        ]}
        activeTab={activeTab}
        handleTabClick={handleTabClick}
      />

      {activeTab === INVITE_TABS.GENERAL ? (
        <ModalInviteGeneralForm onClose={onClose} dancers={selectedDancers} />
      ) : (
        <ModalInviteBookingsForm onClose={onClose} dancerIds={dancerIds} />
      )}
    </div>
  );
};

ModalInviteDancers.displayName = 'ModalInviteDancers';
ModalInviteDancers.propTypes = {
  onClose: PropTypes.func.isRequired,
  dancers: PropTypes.array,
  dancerId: PropTypes.string,
};

export { ModalInviteDancers };
