import { gql } from 'apollo-boost';

export const GETSTREAM_POST_APPROVE = gql`
  mutation GetstreamPostApprove($input: GetstreamPostApproveInput!) {
    getstream_post_approve(input: $input) {
      getstream_post {
        id
        status
      }
    }
  }
`;

export const GETSTREAM_POST_REJECT = gql`
  mutation GetstreamPostReject($input: GetstreamPostRejectInput!) {
    getstream_post_reject(input: $input) {
      getstream_post {
        id
        status
      }
    }
  }
`;

export const GETSTREAM_POST_DELETE = gql`
  mutation GetstreamPostDelete($input: GetstreamPostDeleteInput!) {
    getstream_post_delete(input: $input) {
      message
    }
  }
`;

export const GETSTREAM_COMMENT_APPROVE = gql`
  mutation GetstreamPostApprove($input: GetstreamCommentApproveInput!) {
    getstream_comment_approve(input: $input) {
      getstream_comment_report {
        id
        getstream_id
        status
      }
    }
  }
`;

export const GETSTREAM_COMMENT_REJECT = gql`
  mutation GetstreamPostReject($input: GetstreamCommentRejectInput!) {
    getstream_comment_reject(input: $input) {
      getstream_comment_report {
        id
        getstream_id
        status
      }
    }
  }
`;

export const GETSTREAM_POST_COMMENTS_DISABLE = gql`
  mutation GetstreamPostCommentsDisable($input: GetstreamPostCommentsDisableInput!) {
    getstream_post_comments_disable(input: $input) {
      getstream_post {
        id
        comments_disabled
      }
    }
  }
`;

export const GETSTREAM_POST_COMMENTS_ENABLE = gql`
  mutation GetstreamPostCommentsEnable($input: GetstreamPostCommentsEnableInput!) {
    getstream_post_comments_enable(input: $input) {
      getstream_post {
        id
        comments_disabled
      }
    }
  }
`;

export const GETSTREAM_ADMIN_POST_CREATE = gql`
  mutation getstreamAdminPostCreate($input: GetstreamAdminPostCreateInput!) {
    getstream_admin_post_create(input: $input) {
      getstream_admin_post {
        id
        title
        text
        status
        link {
          title
          url
        }
        media {
          external_file_id
          has_sound
          height
          media_type
          order
          url
          width
        }
      }
    }
  }
`;

export const GETSTREAM_ADMIN_POST_UPDATE = gql`
  mutation getstreamAdminPostUpdate($input: GetstreamAdminPostUpdateInput!) {
    getstream_admin_post_update(input: $input) {
      getstream_admin_post {
        id
        title
        text
        status
        link {
          title
          url
        }
        media {
          media_type
          order
          url
        }
      }
    }
  }
`;

export const GETSTREAM_ADMIN_POST_PAUSE = gql`
  mutation getstreamAdminPostPause($input: GetstreamAdminPostPauseInput!) {
    getstream_admin_post_pause(input: $input) {
      getstream_admin_post {
        id
        status
      }
    }
  }
`;

export const GETSTREAM_ADMIN_POST_UNPAUSE = gql`
  mutation getstreamAdminPostUnpause($input: GetstreamAdminPostUnpauseInput!) {
    getstream_admin_post_unpause(input: $input) {
      getstream_admin_post {
        id
        status
      }
    }
  }
`;

export const GETSTREAM_ADMIN_POST_CANCEL = gql`
  mutation getstreamAdminPostCancel($input: GetstreamAdminPostCancelInput!) {
    getstream_admin_post_cancel(input: $input) {
      getstream_admin_post {
        id
        status
      }
    }
  }
`;

export const GETSTREAM_ADMIN_POST_DELETE = gql`
  mutation getstreamAdminPostDelete($input: GetstreamAdminPostDeleteInput!) {
    getstream_admin_post_delete(input: $input) {
      message
    }
  }
`;
