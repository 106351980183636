import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Form, Formik, Field, ErrorMessage, FieldProps } from 'formik';
import classNames from 'classnames';
import { logError } from 'helpers/errors/bug-report';
import { toast } from 'react-toastify';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { camelizeKeys } from 'humps';
import * as Yup from 'yup';
import { ButtonSpinner } from 'components/common/button_spinner';
import { GET_INVITE_CODE_REQUEST_LIST, REGISTRATION_CODE_LIST } from 'graphql/queries/invite-list';
import { LoadingDiscoWithContainer } from 'components/common/loading/loading-disco';
import { InviteCodeRequest, RegistrationCode } from 'types';
import { INVITE_CODE_REQUEST_ACCEPT } from 'graphql/mutations/invite-code';
import { useTranslation } from 'react-i18next';
import { INVITE_CODE_STATUSES } from 'helpers/enums';

interface Props {
  isOpen: boolean;
  closeModal: () => void;
  codeRequest: InviteCodeRequest;
}

const ModalInviteAccept: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const { isOpen, closeModal, codeRequest } = props;

  const { data, loading } = useQuery<{ registration_codes: RegistrationCode[] }>(REGISTRATION_CODE_LIST, {
    fetchPolicy: 'cache-and-network',
    skip: !isOpen,
    onError: (err: Error) => {
      logError(err, 'REGISTRATION_CODE_LIST', ModalInviteAccept.displayName);
      toast.error(t('errors.server.generic'));
    },
  });

  const [acceptInviteCodeRequest, { loading: isSubmitting }] = useMutation(INVITE_CODE_REQUEST_ACCEPT, {
    onCompleted: () => {
      toast.success(t('inviteRequestsPage.update.success'));
      closeModal();
    },
    refetchQueries: [
      {
        query: GET_INVITE_CODE_REQUEST_LIST,
        variables: {
          first: 25,
          status: INVITE_CODE_STATUSES.PENDING,
        },
      },
    ],
    onError: (err: Error) => {
      logError(err, 'INVITE_CODE_REQUEST_ACCEPT', ModalInviteAccept.displayName);
      toast.error(t('inviteRequestsPage.update.failure'));
    },
  });

  const linkValidationSchema = Yup.object().shape({
    invite_code: Yup.object()
      .shape({ id: Yup.number() })
      .required(t('errors.validation.required', { field_name: t('inviteRequestsPage.registration_code.title') })),
  });

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as='div' className='relative z-10' onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
        </Transition.Child>

        <div className='fixed z-10 inset-0 overflow-y-auto'>
          <div className='flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <Dialog.Panel className='relative bg-gray-900 rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-3xl sm sm:w-full'>
                {loading ? (
                  <div className='h-96'>
                    <LoadingDiscoWithContainer />
                  </div>
                ) : !data ? (
                  <div className='text-center'>{t('common.empty_list')}</div>
                ) : (
                  <div className='sm:p-6'>
                    <div className='mt-3 sm:mt-5'>
                      <Dialog.Title as='h3' className='text-lg leading-6 text-center font-medium text-gray-300'>
                        {t('inviteRequestsPage.registration_code.assign')}
                      </Dialog.Title>
                      <div className='mt-2 min-h-96'>
                        <div className='mt-5 sm:mt-6'>
                          <Formik
                            validationSchema={linkValidationSchema}
                            initialValues={{ id: codeRequest.id, invite_code_id: codeRequest.invite_code?.id }}
                            onSubmit={(values) => {
                              const camelizedValues = camelizeKeys(values);
                              acceptInviteCodeRequest({ variables: { input: camelizedValues } });
                            }}
                          >
                            {({ values, touched, errors, isValid, setFieldValue }) => {
                              return (
                                <Form>
                                  <div className='bg-gray-800 shadow px-4 py-5 sm:rounded-lg sm:p-6 sm:col-span-3 mb-2'>
                                    <div className='md:grid md:grid-cols-3 md:gap-6'>
                                      <div className='sm:col-span-3 mb-2'>
                                        <label
                                          htmlFor='business_id'
                                          className='block text-sm font-medium text-gray-500'
                                        >
                                          {t('inviteRequestsPage.registration_code.plural')}
                                        </label>
                                        <div className='mt-1 flex rounded-md shadow-sm'>
                                          <Field
                                            name='invite_code'
                                            className={classNames(
                                              'flex-1 py-2 px-2 text-gray-800 focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300',
                                              {
                                                ['border-2  border-red-500']:
                                                  touched.invite_code_id && errors.invite_code_id,
                                              }
                                            )}
                                          >
                                            {({ field, form, meta }: FieldProps) => (
                                              <fieldset className='w-full'>
                                                <legend className='sr-only'>
                                                  {t('inviteRequestsPage.registration_code.title')}
                                                </legend>
                                                <div className='h-136 overflow-y-auto'>
                                                  {data?.registration_codes.map((registration_code) => {
                                                    return (
                                                      <label
                                                        key={registration_code.id}
                                                        className={classNames([
                                                          'relative w-full block cursor-pointer rounded-lg border bg-white px-6 py-4 shadow-sm focus:outline-none sm:flex sm:justify-between',
                                                          values.invite_code_id &&
                                                          registration_code.id === values.invite_code_id
                                                            ? 'border-indigo-500 ring-2 ring-indigo-500 bg-indigo-50'
                                                            : 'border-transparent',
                                                        ])}
                                                        onClick={() => {
                                                          setFieldValue('invite_code_id', registration_code.id);
                                                        }}
                                                      >
                                                        <input
                                                          type='radio'
                                                          name='server-size'
                                                          value={registration_code.id}
                                                          className='sr-only'
                                                        />
                                                        <span className='flex items-center'>
                                                          <span className='flex flex-col text-sm'>
                                                            <span className='font-medium text-xl text-gray-900'>
                                                              {registration_code.code}
                                                            </span>
                                                            <span className='text-gray-500'>
                                                              <span className='ml-1 sm:ml-0'>
                                                                {registration_code.description}
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                        <span
                                                          id='server-size-0-description-1'
                                                          className='mt-2 flex text-sm sm:mt-0 sm:ml-4 sm:flex-col sm:text-right'
                                                        >
                                                          <span className='font-medium text-gray-900'>
                                                            {registration_code.promotional ? 'PROMO' : ''}
                                                          </span>
                                                          <span className='block  text-gray-500 sm:inline'>
                                                            {registration_code.business?.name}
                                                          </span>
                                                          <span className='block  text-gray-500 sm:inline'>
                                                            {registration_code.business?.city}
                                                          </span>
                                                          <span className='block  text-gray-500 sm:inline'>
                                                            {registration_code.user?.name}
                                                          </span>
                                                        </span>
                                                        <span
                                                          className={classNames([
                                                            'pointer-events-none absolute -inset-px rounded-lg border-2',
                                                            values.invite_code_id &&
                                                            registration_code.id === values.invite_code_id
                                                              ? 'border-indigo-500'
                                                              : 'border-transparent',
                                                          ])}
                                                          aria-hidden='true'
                                                        ></span>
                                                      </label>
                                                    );
                                                  })}
                                                </div>
                                              </fieldset>
                                            )}
                                          </Field>
                                        </div>
                                        <ErrorMessage
                                          name='registration_code'
                                          className='text-red-500 text-sm'
                                          component='div'
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div className='flex justify-between mt-4'>
                                    <button
                                      type='button'
                                      className='w-40 rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:text-sm'
                                      onClick={() => {
                                        closeModal();
                                      }}
                                    >
                                      {t('common.cancel')}
                                    </button>
                                    <button
                                      type='submit'
                                      disabled={!isValid || isSubmitting}
                                      className={classNames([
                                        isValid ? 'bg-green-600 hover:bg-green-700' : 'bg-gray-600',
                                        'w-40 ml-3 inline-flex justify-center py-2 px-4 rounded-md border border-transparent shadow-sm text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:text-sm',
                                      ])}
                                    >
                                      {isSubmitting && <ButtonSpinner />} {t('common.submit')}
                                    </button>
                                  </div>
                                </Form>
                              );
                            }}
                          </Formik>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

ModalInviteAccept.displayName = 'ModalInviteAccept';

ModalInviteAccept.propTypes = {};

export { ModalInviteAccept };
