import * as React from 'react';
import * as PropTypes from 'prop-types';
import { Redirect, Route, Switch } from 'react-router-dom';
import { AuthRoute } from './auth-route';
import { PageCalendar } from './bookings/booking-calendar/page-calendar';
import ApplicationRequestsPage from './ApplicationRequestsPage';
import { PageChatProvider } from './chat/chat-ctx';
import { ClubFormPage } from './clubs/club-form/club-form-page';
import { ClubSearchPage } from './clubs/clubs-search/club-search-page';
import { CoursesPage } from './courses/courses-page';
import { StatisticsPage } from './statistics';
import { DancerSearch } from './dancers/dancers-search/component/dancer-search';
import { EmployeePage } from './employees/employee-page/employee-page';
import { EmployeeSearchPage } from './employees/employees-search/employee-search-page';
import { ProfilePage } from './employees/profile-page/profile-page';
import { PageGlobalProvider } from './groups/group-details/group-details-context';
import { PageGroupSearch } from './groups/groups-search/page-group-search';
import { InvitePagePresenter } from './employees/employee-invites/employee-invites-page';
import { LogOut } from './log-out';
import { BlastNotificationsPage } from './notifications/blast-notifications/blast-notifications-page';
import { GroupNotificationPage } from './notifications/group-notifications/component/group-notification-page';
import { RouteConstants } from '../routes/route-constants';
import S from './login-routes.module.scss';
import { VerificationPage } from './verification-page/verification-page';
import { PhotoVerificationPage } from './photo-verification-page/photo-verification-page';
import { ModalOverlay } from '../modals/modal-overlay';
import { Nav } from '../nav/nav';
import { DANCER_PAGES, PERMISSIONS } from '../../helpers/enums';
import { InviteRequestsPage } from './invite-requests';
import { VipPromosPage } from './vip-promos';
import { VipOfferAdd } from './vip-promos/add_offer';
import { VipOfferEdit } from './vip-promos/edit_offer';
import { DobChangePage } from './dob-change-page';
import ScheduledPostList from './social/PostScheduler/List';
import ScheduledPostAdd from './social/PostScheduler/Add';
import ScheduledPostEdit from './social/PostScheduler/Edit';
import SocialPosts from './social/Posts';
import SocialComments from './social/Comments';
import { LanguageSelect } from 'components/common/language-select';

const LoginRoutes = ({ chatBusinessId, businessId }) => {
  return (
    <div className={S.loggedInRoutesWrapper}>
      <Nav />
      <div className={S.mainPage}>
        <LanguageSelect />
        <Switch>
          <Route exact component={LogOut} path={RouteConstants.logOut} />

          <Route exact render={(props) => <LogOut isTimeout {...props} />} path={RouteConstants.timeout} />

          <Route exact component={ProfilePage} path={RouteConstants.userForm} />

          <Route exact path={RouteConstants.clubs} render={() => <ClubSearchPage businessId={businessId} />} />

          <Route exact component={ApplicationRequestsPage} path={RouteConstants.applicationRequests} />

          <Route exact component={PageCalendar} path={RouteConstants.calendar} />

          <Route exact component={ClubFormPage} path={RouteConstants.clubSettings} />

          <Route exact path={RouteConstants.employeeInvites} render={() => <InvitePagePresenter type='EMPLOYEE' />} />

          <AuthRoute
            exact
            permission='ADMIN_ONLY'
            render={() => <DancerSearch page={DANCER_PAGES.SUSPENDED} />}
            path={RouteConstants.dancersSearchSuspended}
          />

          <AuthRoute
            exact
            permission='ADMIN_ONLY'
            render={() => <DancerSearch page={DANCER_PAGES.ADMIN_SEARCH} />}
            path={RouteConstants.adminDancersSearch}
          />

          <AuthRoute
            hasBusiness
            exact
            permission={PERMISSIONS.SEARCH_DANCERS}
            render={() => <DancerSearch page={DANCER_PAGES.SEARCH} />}
            path={RouteConstants.dancersSearch}
          />

          <AuthRoute exact permission='ADMIN_ONLY' component={VerificationPage} path={RouteConstants.verifications} />

          <AuthRoute
            exact
            permission='ADMIN_ONLY'
            component={PhotoVerificationPage}
            path={RouteConstants.photoVerifications}
          />

          <AuthRoute exact permission='ADMIN_ONLY' component={DobChangePage} path={RouteConstants.dobChangeRequest} />

          <AuthRoute
            exact
            permission='ADMIN_ONLY'
            render={() => <InvitePagePresenter type='ADMIN' />}
            path={RouteConstants.adminCodes}
          />

          <AuthRoute
            exact
            permission='ADMIN_ONLY'
            render={() => <ClubFormPage isNewForm />}
            path={RouteConstants.newClub}
          />

          <AuthRoute
            exact
            permission='ADMIN_ONLY'
            render={() => <PageChatProvider isAdminChat={true} chatBizId={chatBusinessId} />}
            path={RouteConstants.adminConversations}
          />

          <AuthRoute
            exact
            permission='ADMIN_ONLY'
            component={BlastNotificationsPage}
            path={RouteConstants.blastNotifications}
          />

          <AuthRoute
            exact
            permission='ADMIN_ONLY'
            component={GroupNotificationPage}
            path={RouteConstants.groupNotifications}
          />

          <AuthRoute exact permission='ADMIN_ONLY' component={CoursesPage} path={RouteConstants.courses} />

          <AuthRoute
            exact
            permission='ADMIN_ONLY'
            component={InviteRequestsPage}
            path={RouteConstants.inviteRequests}
          />
          <AuthRoute exact permission='ADMIN_ONLY' component={StatisticsPage} path={RouteConstants.bugs} />
          <AuthRoute exact permission='ADMIN_ONLY' component={StatisticsPage} path={RouteConstants.statistics} />
          <AuthRoute exact permission='ADMIN_ONLY' component={VipPromosPage} path={RouteConstants.vipPromos} />
          <AuthRoute exact permission='ADMIN_ONLY' component={VipOfferAdd} path={RouteConstants.vipOfferAdd} />
          <AuthRoute exact permission='ADMIN_ONLY' component={VipOfferEdit} path={RouteConstants.vipOfferEdit} />

          <AuthRoute
            exact
            permission='ADMIN_ONLY'
            component={ScheduledPostList}
            path={RouteConstants.socialScheduledPosts}
          />
          <AuthRoute
            exact
            permission='ADMIN_ONLY'
            component={ScheduledPostAdd}
            path={RouteConstants.socialScheduledPostAdd}
          />
          <AuthRoute
            exact
            permission='ADMIN_ONLY'
            component={ScheduledPostEdit}
            path={RouteConstants.socialScheduledPostEdit}
          />
          <AuthRoute exact permission='ADMIN_ONLY' component={SocialPosts} path={RouteConstants.socialPosts} />
          <AuthRoute exact permission='ADMIN_ONLY' component={SocialComments} path={RouteConstants.socialComments} />

          <AuthRoute
            exact
            hasBusiness
            permission={PERMISSIONS.MANAGE_CONVERSATIONS}
            path={RouteConstants.conversations}
            render={() => <PageChatProvider isAdminChat={false} chatBizId={businessId} />}
          />

          <AuthRoute
            hasBusiness
            exact
            permission={PERMISSIONS.MANAGE_GROUPS}
            render={() => <PageGroupSearch businessId={businessId} />}
            path={RouteConstants.groupSearch}
          />

          <AuthRoute
            hasBusiness
            exact
            permission={PERMISSIONS.MANAGE_GROUPS}
            component={PageGlobalProvider}
            path={RouteConstants.groupDetails}
          />

          <AuthRoute
            hasBusiness
            exact
            permission={PERMISSIONS.MANAGE_REG_CODES}
            render={() => <InvitePagePresenter type='CLUB' />}
            path={RouteConstants.clubInvites}
          />

          <AuthRoute
            hasBusiness
            exact
            permission={PERMISSIONS.MANAGE_EMPLOYEES}
            component={EmployeeSearchPage}
            path={RouteConstants.employeeSearch}
          />

          <AuthRoute
            hasBusiness
            exact
            permission={PERMISSIONS.MANAGE_EMPLOYEES}
            render={() => <EmployeePage businessId={businessId} />}
            path={RouteConstants.employeeNew}
          />

          <AuthRoute
            hasBusiness
            exact
            permission={PERMISSIONS.MANAGE_EMPLOYEES}
            render={() => <EmployeePage businessId={businessId} />}
            path={RouteConstants.employeeEdit}
          />

          <Route path='/' render={() => <Redirect to={`${RouteConstants.clubs}${window.location.search}`} />} />
        </Switch>
        <ModalOverlay />
      </div>
    </div>
  );
};

LoginRoutes.displayName = 'LoginRoutes';
LoginRoutes.propTypes = {
  chatBusinessId: PropTypes.string.isRequired,
  businessId: PropTypes.string,
};

LoginRoutes.defaultProps = {
  chatBusinessId: null,
  businessId: null,
};

export { LoginRoutes };
