import { gql } from 'apollo-boost';
export const GET_PROFILE_PICTURES_LIST = gql`
  query ProfilePicturesList($isVerified: Boolean, $dancer: Boolean!, $sortOrder: String, $search: String, $first: Int, $after: String) {
    profile_pictures(isVerified: $isVerified, dancer: $dancer, order: $sortOrder, search: $search, first: $first, after: $after) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      nodes {
        id
        thumb
        medium
        is_verified
        rejection_text
        data_updated_at
        position
        user {
          id
          name
          email
          dancer
          verification_check {
            id
            first_image {
              id
              thumb
              medium
            }
          }
        }
      }
    }
  }
`;
