import React from 'react';
import * as PropTypes from 'prop-types';
import { FormLabel } from '../../common/form/form-parts/form-label';
import { useTranslation } from 'react-i18next';

import S from './courses-sort.module.scss';
import { InputFormSelect } from '../../common/form/form-parts/input-form-select';
import { OrderBtn } from '../../common/buttons/order-btn';

const CoursesSort = ({ isAsc, setIsAsc, sortField, setSortField }) => {
  const { t } = useTranslation();
  return (
    <div className={S.coursesSortWrapper}>
      <FormLabel isDisabled={false} label={t('common.sort')} />
      <OrderBtn handleClick={() => setIsAsc(!isAsc)} isAsc={isAsc} />
      <InputFormSelect
        id='courses_page_sort_field'
        name='sortField'
        value={sortField}
        onChange={(e) => setSortField(e.target.value)}
        options={[
          { label: t('common.title'), value: 'title' },
          { label: t('common.created_at'), value: 'created_at' },
          { label: t('common.updated_at'), value: 'updated_at' },
        ]}
      />
    </div>
  );
};

CoursesSort.displayName = 'CoursesSort';
CoursesSort.propTypes = {
  isAsc: PropTypes.bool.isRequired,
  sortField: PropTypes.string.isRequired,
  setIsAsc: PropTypes.func.isRequired,
  setSortField: PropTypes.func.isRequired,
};

export { CoursesSort };
