import { EMPLOYEE_ROLES, PERMISSIONS } from './enums';

const BOOKING_PERMISSIONS = [
  PERMISSIONS.CHECK_IN_DANCERS,
  PERMISSIONS.SEND_INVITES,
  PERMISSIONS.MANAGE_CONVERSATIONS,
  PERMISSIONS.MANAGE_REQUESTS,
  PERMISSIONS.MANAGE_GROUPS,
];

const MANAGER_PERMISSIONS = [
  PERMISSIONS.CHECK_IN_DANCERS,
  PERMISSIONS.SEND_INVITES,
  PERMISSIONS.MANAGE_CONVERSATIONS,
  PERMISSIONS.EDIT_CLUB,
  PERMISSIONS.MANAGE_GROUPS,
  PERMISSIONS.MANAGE_REQUESTS,
  PERMISSIONS.MANAGE_BOOKINGS,
  PERMISSIONS.BLOCK_DANCERS,
  PERMISSIONS.MANAGE_EMPLOYEES,
  PERMISSIONS.SEARCH_DANCERS,
];

const ALL_PERMISSIONS = [
  PERMISSIONS.CHECK_IN_DANCERS,
  PERMISSIONS.SEND_INVITES,
  PERMISSIONS.MANAGE_CONVERSATIONS,
  PERMISSIONS.EDIT_CLUB,
  PERMISSIONS.MANAGE_GROUPS,
  PERMISSIONS.MANAGE_REQUESTS,
  PERMISSIONS.MANAGE_BOOKINGS,
  PERMISSIONS.BLOCK_DANCERS,
  PERMISSIONS.MANAGE_EMPLOYEES,
  PERMISSIONS.SEARCH_DANCERS,
  PERMISSIONS.MANAGE_REG_CODES,
];

export const getRolePermissionOptions = (role) => {
  if (!role) return [];
  else if (role === EMPLOYEE_ROLES.BOOKING_AGENT) return BOOKING_PERMISSIONS;
  else if (role === EMPLOYEE_ROLES.MANAGER) return MANAGER_PERMISSIONS;
  else return ALL_PERMISSIONS;
};
