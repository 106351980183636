import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';

import { BOOKING_STATUS } from 'helpers/enums';
import { getBookingDateLink, getBookingDateString } from './helpers';
import { ShiftInfoProps } from './types';
import { Link } from 'react-router-dom';

const { ACCEPTED, CHECKED_IN, CHECKED_OUT, FAILED_TO_ATTEND } = BOOKING_STATUS;

const ShiftInfo: FC<ShiftInfoProps> = ({ type, hardcapped, applications, slots, start, end, timezone }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const date = getBookingDateString({ start, end, timezone, language });
  const shiftType = t(`bookingCalendar.${type}`);
  const linkToCalendar = getBookingDateLink({ start, timezone });

  const filledSlots = applications.filter(({ status }) =>
    [ACCEPTED, CHECKED_IN, CHECKED_OUT, FAILED_TO_ATTEND].includes(status)
  ).length;

  return (
    <div className={'flex flex-row gap-10 mb-10'}>
      <div className='flex gap-2'>
        <div className='text-gray-500'>{`${t('common.date')}:`}</div>
        <div className='text-business-purple'>{`${date}`}</div>
      </div>

      <div className='flex gap-2'>
        <div className='text-gray-500'>{`${t('common.type')}:`}</div>
        <div className='text-business-purple'>{`${shiftType}`}</div>
      </div>

      <div className='flex gap-2'>
        <div className='text-gray-500'>{`${t('common.slots')}:`}</div>
        <div className='text-business-purple'>{` (${filledSlots}/${slots})`}</div>
        {hardcapped && <div className='font-semibold '>{t('common.strict_limit')}</div>}
      </div>

      <div className='text-business-purple underline'>
        <Link to={linkToCalendar}>{t('common.view_calendar')}</Link>
      </div>
    </div>
  );
};

export default memo(ShiftInfo);
