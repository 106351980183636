import { gql } from 'apollo-boost';

export const TRANSLATE = gql`
  mutation Translate($input: TranslateInput!) {
    translate(input: $input) {
      translate {
        string_translated
      }
    }
  }
`;
