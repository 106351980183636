import { FilterDancerName } from './filter-dancer-name';
import { FilterDancerBusiness } from './filter-dancer-business';
import { FilterAdminDancerFreeAgent } from './filter-admin-dancer-free-agent';
import { FilterDancerDeleted } from './filter-dancer-deleted';

import S from './filter.module.scss';

const AdminFilters = () => {
  return (
    <div className={S.filterWrapper}>
      <div className={S.leftColumnWrapper}>
        <FilterDancerBusiness />
        <FilterDancerDeleted />
        <FilterAdminDancerFreeAgent />
      </div>
      <FilterDancerName />
    </div>
  );
};

AdminFilters.displayName = 'AdminFilters';

export { AdminFilters };
