import { useTranslation } from 'react-i18next';

import S from './closed-label.module.scss';

const ClosedLabel = () => {
  const { t } = useTranslation();

  return <div className={S.container}>{t('common.club_closed')}</div>;
};

ClosedLabel.displayName = 'ClosedLabel';

export { ClosedLabel };
