import React, { useRef } from 'react';
import * as PropTypes from 'prop-types';

import { CourseForm } from './course-form';
import S from './modal-course.module.scss';
import { CourseFormProvider } from './course-context';
import { CloseButton } from '../../common/buttons/close-button';
import { useOverlayClick } from '../../../helpers/hooks/use-overlay-click';
import { useTranslation } from 'react-i18next';

const ModalCourse = ({ onClose, courseId }) => {
  const { t } = useTranslation();
  const MODAL_REF = useRef();
  useOverlayClick(MODAL_REF, onClose);

  return (
    <div ref={MODAL_REF} className={S.courseModalWrapper}>
      <CourseFormProvider>
        <CloseButton onClick={onClose} />
        <h3 className={S.modalHeading}>{t(courseId ? 'modals.course.update.title' : 'modals.course.create.title')}</h3>
        <CourseForm courseId={courseId} />
      </CourseFormProvider>
    </div>
  );
};

ModalCourse.displayName = 'ModalCourse';
ModalCourse.propTypes = {
  courseId: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};

ModalCourse.defaultProps = {
  courseId: null,
};

export { ModalCourse };
