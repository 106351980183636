import * as React from 'react';

import S from './loading-spinner.module.scss';

const LoadingSpinner = () => (
  <div className={S.spinnerWrapper}>
    <div className={S.spinner} />
  </div>
);

LoadingSpinner.displayName = 'LoaderPresenter';

export { LoadingSpinner };
