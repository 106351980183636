import * as React from 'react';
import { useTranslation } from 'react-i18next';

import S from './calendar-legend.module.scss';

const CalendarLegend = () => {
  const { t } = useTranslation();

  return (
    <div className={S.calLegend}>
      <div className={S.legendTop}>
        <div className={S.calLegendItem}>
          <div className={S.blockRed} />
          <label>{t('bookingCalendar.unfilled_booking')}</label>
        </div>
        <div className={S.calLegendItem}>
          <div className={S.blockPurple} />
          <label>{t('bookingCalendar.action_needed')}</label>
        </div>
        <div className={S.calLegendItem}>
          <div className={S.blockGreen} />
          <label>{t('bookingCalendar.filled_booking')}</label>
        </div>
      </div>
      <div className={S.legendBottom}>
        <div className={S.calLegendItem}>
          <div className={S.blockOrange} />
          <label>{t('bookingCalendar.event')}</label>
        </div>
        <div className={S.calLegendItem}>
          <div className={S.blockYellow} />
          <label>Promo</label>
        </div>
        <div className={S.calLegendItem}>
          <div className={S.blockPink} />
          <label>{t('bookingCalendar.audition')}</label>
        </div>
      </div>
    </div>
  );
};

CalendarLegend.displayName = 'CalendarLegendPresenter';

export { CalendarLegend };
