import { gql } from 'apollo-boost';

export const ALL_GROUPS_GQL = gql`
    query($businessId: ID!){
        groups(businessId: $businessId){
            id
            name
            number_of_dancers
            order
            dancers {
                id
                first_image{ id, thumb medium }
            }
        }
    }
`;
