import { gql } from 'apollo-boost';

export const CHAT_USER_FRAGMENT = gql`
  fragment chatUserKeys on Conversation {
    user {
      id
      name
      verification_status
      deleted
      email @include(if: $isAdmin)
      first_image {
        id
        thumb
        medium
        is_verified
        rejection_text
      }
      images {
        id
        thumb
        medium
        is_verified
        rejection_text
      }
      suspended
      blocked_by(businessId: $blockedByBusinessId) {
        id
        name
      }
      in_group(businessId: $inGroupBusinessId)
    }
  }
`;
