import * as React from 'react';
import * as PropTypes from 'prop-types';

import S from 'components/pages-logout/login-input-field.module.scss';

type LoginInputField = {
  label: string;
  id: string;
  type: 'email' | 'password';
  value: string;
  name: string;
  isDisabled: boolean;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
  error: string;
};

const LoginInputField: React.FC<LoginInputField> = ({
  label,
  id,
  type,
  value,
  name,
  isDisabled,
  handleChange,
  handleBlur,
  error,
}): React.ReactElement => {
  return (
    <div className={S.preLoginInputField}>
      <label htmlFor={id}>{label}</label>
      <input
        title=''
        id={id}
        type={type}
        value={value}
        name={name}
        disabled={isDisabled}
        onChange={handleChange}
        onBlur={handleBlur}
        className={error ? S.error : ''}
      />

      <div>{error}</div>
    </div>
  );
};

LoginInputField.displayName = 'LoginInputField';

LoginInputField.propTypes = {
  label: PropTypes.string.isRequired,

  id: PropTypes.string.isRequired,
  // type:  PropTypes.oneOf(['email', 'password']).isRequired, // TS error
  value: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,

  error: PropTypes.string.isRequired,
};

export { LoginInputField };
