import { toast } from 'react-toastify';
import React, { useCallback, useMemo } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';

import S from './group-notification-table.module.scss';
import { GroupNotificationList } from './group-notification-list';
import * as GroupNoteCtx from '../group-notification-context';
import { DropDownWithSelected } from '../../../../common/drop-down-with-selected';
import { LoadingDiscoWithContainer } from '../../../../common/loading/loading-disco';
import { logError } from '../../../../../helpers/errors/bug-report';
import { DELETE_GROUP_NOTIFICATIONS } from '../../../../../graphql/mutations/group-announcement-delete';
import { GET_GROUP_NOTIFICATIONS_GQL } from '../../../../../graphql/queries/group-announcment-list-query';

const GroupNotificationTable = () => {
  const { t } = useTranslation();
  const {
    state: { search, selected, totalCount },
    dispatch,
  } = GroupNoteCtx.useGroupNoteCtx();

  const SEARCH_VARIABLES = useMemo(() => ({ cursor: '', search }), [search]);

  const SEARCH_QUERY = useMemo(
    () => ({
      query: GET_GROUP_NOTIFICATIONS_GQL,
      variables: SEARCH_VARIABLES,
    }),
    [SEARCH_VARIABLES]
  );

  const handleDeSelect = useCallback(() => dispatch(GroupNoteCtx.setGroupNoteField('selected', [])), [dispatch]);

  const { data, loading, error, fetchMore } = useQuery(GET_GROUP_NOTIFICATIONS_GQL, {
    fetchPolicy: 'network-only',
    variables: SEARCH_VARIABLES,
    onError: (err) => logError(err, 'GET_GROUP_NOTIFICATIONS_GQL', GroupNotificationTable.displayName),
    onCompleted: ({ admin_group_announcements }) => {
      dispatch(
        GroupNoteCtx.setGroupNoteFields({
          pagination: admin_group_announcements.pageInfo,
          totalCount: admin_group_announcements.totalCount,
        })
      );
    },
  });

  const [deleteAnnouncements] = useMutation(DELETE_GROUP_NOTIFICATIONS, {
    onError: (err) => {
      logError(err, 'DELETE_GROUP_NOTIFICATIONS', GroupNotificationTable.displayName);
      toast.error(t('notificationsPage.delete.failure'));
    },
    onCompleted: () => toast.success(t('notificationsPage.delete.success')),
  });

  const updateState = useCallback(
    (cache, ids) => {
      const { admin_group_announcements } = cache.readQuery(SEARCH_QUERY);

      cache.writeQuery({
        ...SEARCH_QUERY,
        data: {
          admin_group_announcements: {
            ...admin_group_announcements,
            nodes: admin_group_announcements.nodes.filter(({ id }) => !ids.includes(id)),
          },
        },
      });

      dispatch(
        GroupNoteCtx.setGroupNoteFields({
          selected: selected.filter((id) => !ids.includes(id)),
          totalCount: totalCount - ids.length,
        })
      );
    },
    [dispatch, totalCount, selected, SEARCH_QUERY]
  );

  const handleDeleteSelected = useCallback(
    () =>
      deleteAnnouncements({
        variables: { ids: selected },
        update: (cache) => updateState(cache, selected),
      }),
    [deleteAnnouncements, selected, updateState]
  );

  const handleDeleteAnnouncement = useCallback(
    (id) =>
      deleteAnnouncements({
        variables: { ids: [id] },
        update: (cache) => updateState(cache, [id]),
      }),
    [deleteAnnouncements, updateState]
  );

  if (loading) return <LoadingDiscoWithContainer />;
  if (error) return <h1 className={S.errorPage}>{t('errors.generic')}</h1>;

  return (
    <>
      <div className={S.groupNotificationTableHeader}>
        <DropDownWithSelected totalCount={totalCount} selectedCount={selected.length} onDeselect={handleDeSelect} />

        {selected.length > 0 && (
          <button className={S.invertedBtnRed} onClick={handleDeleteSelected}>
            {t('notificationsPage.delete.selected')}
          </button>
        )}
      </div>

      <GroupNotificationList
        handleDelete={handleDeleteAnnouncement}
        fetchMore={fetchMore}
        list={data.admin_group_announcements.nodes}
      />
    </>
  );
};

GroupNotificationTable.displayName = 'GroupNotificationTable';

export { GroupNotificationTable };
