import React, { FC, ReactNode, useState } from 'react';
import classNames from 'classnames';
import S from './style.module.scss';

type Option = {
  value: string;
  text: string;
  title: string;
  icon: ReactNode;
  disabled: boolean;
};
interface Props {
  button: string | ReactNode;
  options: [Option];
  containerClassName: string;
  buttonClassName: string;
  dropdownClassName: string;
  onSelect: (value: string) => void;
}

const DropDownButton: FC<Props> = ({
  button,
  options,
  containerClassName,
  buttonClassName,
  dropdownClassName,
  onSelect,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleOpen = () => setIsExpanded(true);
  const handleClose = () => setIsExpanded(false);
  const handleOnSelect = (value: string) => {
    handleClose();
    onSelect(value);
  };

  return (
    <div className={classNames([S.container, containerClassName])} onMouseLeave={handleClose}>
      {!isExpanded && (
        <button onClick={handleOpen} className={classNames([S.button, buttonClassName])}>
          {button}
        </button>
      )}
      {isExpanded && (
        <div className={classNames([S.select])}>
          {options.map((option: Option, idx: number) => (
            <div
              key={idx}
              className={classNames(S.option, { [S.disabled]: option.disabled })}
              onClick={!option.disabled ? handleOnSelect.bind(this, option.value) : () => null}
              title={option.title}
            >
              {option.icon && option.icon}
              {option.text}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default DropDownButton;
