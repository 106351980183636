import React, { useMemo } from 'react';
import * as PropTypes from 'prop-types';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { FaSyncAlt } from 'react-icons/fa';
import { RouteCreator } from '../../../../../routes/route-constants';
import { BookingIcon } from '../../../../../common/icons/booking-icon';
import { getCurrentTimeAtTimezone } from '../../../../../../helpers/times';

// USE START & END RATHER THAN shift START_TIME / END_TIME because it's based off
const CalDayView = ({
  end,
  start,
  onRedirect,
  timezone,
  shift: {
    id,
    slots,
    shift_type,
    is_audition,
    recurring,
    accepted_shift_applications_count,
    pending_shift_applications_count,
    checked_in_shift_applications_count,
    checked_out_shift_applications_count,
    has_applications
  },
}) => {
  const { t } = useTranslation();
  const ACCEPTED_COUNT = useMemo(
    () =>
      accepted_shift_applications_count + checked_in_shift_applications_count + checked_out_shift_applications_count,
    [accepted_shift_applications_count, checked_in_shift_applications_count, checked_out_shift_applications_count]
  );

  const HAS_ENDED = useMemo(
    () => getCurrentTimeAtTimezone(timezone).isAfter(end) && ACCEPTED_COUNT > 0,
    [timezone, end, ACCEPTED_COUNT]
  );

  const bookingLength = moment(end).diff(moment(start), 'minutes', true);

  return (
    <div className='flex flex-col p-2 bg-black/30'>
      <div className='flex justify-between'>
        <div className='flex justify-start'>
          {moment.tz(start, timezone).format('h:mm')}-{moment.tz(end, timezone).format('h:mm')}
          {recurring && (
            <FaSyncAlt size='14px' title={t('bookingCalendar.repeats_weekly')} className='pt-1 pl-1 text-gray-300' />
          )}
        </div>
        <div>
          {slots ? (
            <span title={`${pending_shift_applications_count} ${t('common.pending')}`}>
              {ACCEPTED_COUNT}/{slots}
            </span>
          ) : (
            t('bookingCalendar.new_booking')
          )}
        </div>
        <div className='pt-1'>
          {has_applications && (
            <BookingIcon
              pendingCount={pending_shift_applications_count || 0}
              hasEnded={HAS_ENDED && ACCEPTED_COUNT > 0}
              onClick={() => onRedirect(RouteCreator.applicationRequests(id, is_audition))}
            />
          )}
        </div>
        {bookingLength <= 30 && (
          <div className='capitalize'>{shift_type ? t(`bookingCalendar.${shift_type.toLowerCase()}`) : ''}</div>
        )}
      </div>
      {bookingLength > 30 && (
        <div className='capitalize'>{shift_type ? t(`bookingCalendar.${shift_type.toLowerCase()}`) : ''}</div>
      )}
    </div>
  );
};

CalDayView.displayName = 'DayViewPresenter';
CalDayView.propTypes = {
  onRedirect: PropTypes.func.isRequired,
  timezone: PropTypes.string.isRequired,
  start: PropTypes.instanceOf(Date),
  end: PropTypes.instanceOf(Date),
  shift: PropTypes.shape({
    id: PropTypes.string,
    slots: PropTypes.number,
    shift_type: PropTypes.string,
    recurring: PropTypes.bool,
    accepted_shift_applications_count: PropTypes.number,
    pending_shift_applications_count: PropTypes.number,
    rejected_shift_applications_count: PropTypes.number,
    checked_in_shift_applications_count: PropTypes.number,
    checked_out_shift_applications_count: PropTypes.number,
  }),
};

export { CalDayView };
