import moment from 'moment';
import esLocale from 'moment/locale/es';

export const setMomentLocale = (locale) => {
  const map = {
    es: esLocale,
  };
  const localization = map[locale] ? [map[locale]] : [];

  moment.locale(locale, localization);
};
