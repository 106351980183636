import * as DancerSearchCTX from '../../dancer-search-context';
import { useTranslation } from 'react-i18next';

import { FaSort } from 'react-icons/fa';
import IFS from '../../../../../common/form/form-parts/input-form-select.module.scss';
import classNames from 'classnames';

import S from './filter.module.scss';

export const FilterAdminDancerFreeAgent = () => {
  const { dispatch } = DancerSearchCTX.useDancerSearchCtx(true);
  const { t } = useTranslation();

  const options = [
    { label: t('common.all'), value: '' },
    { label: t('common.registered'), value: 'false' },
    { label: t('common.free_agent'), value: 'true' },
  ];

  const handleOnChange = ({ target }) => {
    const value = !target.value ? null : target.value === 'true' ? true : false;
    dispatch(DancerSearchCTX.setDancerSearchField('freeAgentFilter', value));
  };

  return (
    <div>
      <span>{t('common.registration_status')}</span>
      <div className={IFS.wrapper}>
        <select onChange={handleOnChange} className={classNames(S.select, S.control)}>
          {options.map(({ label, value }) => (
            <option key={value} value={value}>
              {label}
            </option>
          ))}
        </select>
        <FaSort />
      </div>
    </div>
  );
};
