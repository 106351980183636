import * as React from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import S from './text-count-selected.module.scss';

const TextSelectedPresenter = ({ totalCount, subCount }) => {
  const { t } = useTranslation();

  return (
    <div className={S.textCountSelected}>
      <span>{subCount}</span> {t('common.of')}
      <span>{totalCount}</span> {t('common.selected')}
    </div>
  );
};

TextSelectedPresenter.displayName = 'TextSelectedPresenter';

TextSelectedPresenter.propTypes = {
  subCount: PropTypes.number.isRequired,
  totalCount: PropTypes.number.isRequired,
};

export { TextSelectedPresenter };
