import { gql } from 'apollo-boost';

export const GET_PRE_APPROVED_BOOKINGS = gql`
    query($groupId: ID!){
        preapproved_shifts(groupId: $groupId){
            non_recurring_shifts {
                id
                start_time
                end_time
                shift_type
            }
            recurring_shifts {
                id
                start_time
                end_time
                shift_type
            }
        }
    }
`;
