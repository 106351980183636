import { GridDropZone, GridItem } from 'react-grid-dnd';
import * as PropTypes from 'prop-types';
import classNames from 'classnames';
import React, { useState } from 'react';

import S from './club-gallary.module.scss';
import * as ClubFormCtx from '../club-form-context';
import { GridImage } from '../../../../common/images/grid-image';

const START_INDEX = 1;

const ClubGallery = ({ isDisabled, hasPermission, order, handleDelete, handleAdd }) => {
  const { state } = ClubFormCtx.useClubFormCtx();
  const IMAGES = state.form.images;
  const [disabledChanges, setDisableChanges] = useState(false);

  function testDown(index) {
    const INDEX = IMAGES[index + START_INDEX];
    setDisableChanges(INDEX.preview === null || INDEX.isLoading);
  }

  function testUp() {
    setDisableChanges(true);
  }

  return (
    <GridDropZone
      id='gallery'
      rowHeight={150}
      boxesPerRow={6}
      className={classNames(S.imgGalleryWrapper, { [S.disabled]: !hasPermission })}
      disableDrag={!hasPermission || isDisabled || disabledChanges}
      disableDrop={!hasPermission || isDisabled || disabledChanges}
    >
      {order.map((item, index) => (
        <div key={item.id} onMouseDown={() => testDown(index)} onMouseUp={testUp} className={S.galleryGridWrapper}>
          <GridItem key={item.id} id={item.id} className={S.gridItem} style={{ cursor: 'default' }}>
            <GridImage
              alt='Club Photo'
              hasPermission={hasPermission}
              isLoading={IMAGES[index + START_INDEX].isLoading}
              previewSrc={IMAGES[index + START_INDEX].preview}
              handleFileChange={(e) => handleAdd(e, index, item, 'gallery')}
              handleDeleteFile={() => handleDelete(index, 'gallery')}
              itemIndex={item.id}
            />
          </GridItem>
        </div>
      ))}
    </GridDropZone>
  );
};

ClubGallery.displayName = 'ClubGallery';

ClubGallery.propTypes = {
  order: PropTypes.arrayOf(PropTypes.object).isRequired,
  hasPermission: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool.isRequired,

  handleAdd: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
};

export { ClubGallery };
