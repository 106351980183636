import React, { useCallback, useState } from 'react';
import { FaUsers } from 'react-icons/fa';
import * as classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import S from './blast-notifications-page.module.scss';
import { NotificationList } from './notification-list';
import { EntertainerIcon } from '../../../common/icons/entertainer-icon';
import { BlastNotificationIcon } from '../../../common/icons/blast-notifications';
import { useModal } from '../../../global-hooks';
import { UI_MODALS } from '../../../../helpers/enums';

const TYPES = {
  ALL: 'all_users',
  DANCERS: 'all_dancers',
  WORKERS: 'all_workers',
  EMPLOYEES: 'all_employees_by_club',
};

const BlastNotificationsPage = () => {
  const { t } = useTranslation();
  const { setModal } = useModal();
  const [type, setType] = useState(TYPES.ALL);

  const handleMsgModal = useCallback(() => setModal(UI_MODALS.BLAST_NOTIFY), [setModal]);

  return (
    <div className={S.pageWrapper}>
      <header className={S.blastPageHeader}>
        <h1 className={S.h1Heading}>{t('notificationsPage.title')}</h1>
        <button className={S.addMsgBtn} onClick={handleMsgModal}>
          <BlastNotificationIcon height={14} width={14} /> {t('notificationsPage.send')}
        </button>
      </header>

      <div className={S.blastBtnGroup}>
        <button
          className={classNames(S.filterBtn, { [S.active]: type === TYPES.ALL })}
          onClick={() => setType(TYPES.ALL)}
        >
          <FaUsers />
          {t('notificationsPage.all_users')}
        </button>
        <button
          className={classNames(S.filterBtn, { [S.active]: type === TYPES.WORKERS })}
          onClick={() => setType(TYPES.WORKERS)}
        >
          <FaUsers />
          {t('notificationsPage.workers')}
        </button>
        <button
          className={classNames(S.filterBtn, { [S.active]: type === TYPES.DANCERS })}
          onClick={() => setType(TYPES.DANCERS)}
        >
          <EntertainerIcon height={14} width={14} />
          {t('notificationsPage.dancers')}
        </button>
      </div>

      <NotificationList type={type} />
    </div>
  );
};

BlastNotificationsPage.displayName = 'NotificationPagePresenter';

export { BlastNotificationsPage };
