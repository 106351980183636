import moment from 'moment';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { logError } from '../../../../helpers/errors/bug-report';

import * as ClubFormCtx from './club-form-context';
import { setClubFromCache } from './club-services';
import { RouteConstants } from '../../../routes/route-constants';
import { useNavReRender } from '../../../global-hooks';
import { useBusiness } from '../../../../graphql/graph-hooks';
import { BUSINESS_DETAIL_GQL } from '../../../../graphql/queries/business-page-query';
import { CREATE_BUSINESS } from '../../../../graphql/mutations/business-create';
import { UPDATE_BUSINESS } from '../../../../graphql/mutations/business-update';
import { TimePatterns } from '../../../../helpers/times';
import { US_STATES } from '../../../../helpers/constants';
import * as ClubValidations from '../../../../helpers/validations/club-validation';
import { useTranslation } from 'react-i18next';

function createSchedule(schedule) {
  return schedule.map(({ open, close }, day) => {
    const OPEN = ['closed', 'allDay'].includes(open)
      ? '2000-01-01T11:00:00.000Z'
      : moment(open, 'hh:mmA').format(TimePatterns.isoFormat);
    const CLOSE = ['closed', 'allDay'].includes(open) ? OPEN : moment(close, 'hh:mmA').format(TimePatterns.isoFormat);

    return {
      day,
      closed: open === 'closed',
      allDay: open === 'allDay',
      open: OPEN,
      close: CLOSE,
    };
  });
}

function createImgList(imgList) {
  return imgList.reduce((acm, { image_attachment, preview }) => {
    if (!image_attachment) return acm;
    return [
      ...acm,
      {
        fileName: image_attachment.name,
        mimeType: image_attachment.type.split('/')[1].toUpperCase(),
        data: preview.split(',')[1],
      },
    ];
  }, []);
}

export const useClubForm = (isNewForm) => {
  const { reRenderNav } = useNavReRender();
  const { t } = useTranslation();
  // const { dispatch: globalDispatch, state: { app } } = useGlobalCtx();
  const {
    dispatch,
    state: { form },
  } = ClubFormCtx.useClubFormCtx();
  const { id } = useBusiness();
  const HISTORY = useHistory();

  const [createBusiness, { loading: createProcessing }] = useMutation(CREATE_BUSINESS, {
    notifyOnNetworkStatusChange: true,
    variables: {
      ...form,
      minDancersForFreeAgentSearch: Number(form.minDancersForFreeAgentSearch),
      stageCount: Number(form.stageCount),
      sqFootage: Number(form.sqFootage),
      maxBookings: Number(form.maxBookings),
      hasRegLink: !!form.regLink,
    },
    onError: (err) => {
      logError(err, 'CREATE_BUSINESS', 'useClubForm');
      toast.error(`${t('errors.server.500')}: ${t('clubPage.create.failure')}`);
    },
    onCompleted: () => {
      toast.success(t('clubPage.create.success'));
      HISTORY.push(RouteConstants.clubs);
    },
  });

  const [updateBusiness, { loading: updateProcessing }] = useMutation(UPDATE_BUSINESS, {
    notifyOnNetworkStatusChange: true,
    variables: {
      ...form,
      minDancersForFreeAgentSearch: Number(form.minDancersForFreeAgentSearch),
      stageCount: Number(form.stageCount),
      sqFootage: Number(form.sqFootage),
      maxBookings: Number(form.maxBookings),
      hasRegLink: !!form.regLink,
    },
    onError: (err) => {
      logError(err, 'UPDATE_BUSINESS', 'useClubForm');
      toast.error(`${t('errors.server.500')}: ${t('clubPage.update.failure')}`);
    },
    update: (
      cache,
      {
        data: {
          business_update: { business },
        },
      }
    ) => {
      cache.writeData({
        id: `Business:${business.id}`,
        data: business,
      });
      // force global rerender
      reRenderNav();
      // globalDispatch(setAppData(app));
    },
    onCompleted: () => {
      toast.success(t('clubPage.update.success'));
      dispatch(ClubFormCtx.setClubField('isDisabled', true));
    },
  });

  const useGetBusiness = () =>
    useQuery(BUSINESS_DETAIL_GQL, {
      skip: isNewForm,
      variables: { businessId: id },
      fetchPolicy: 'network-only',
      onError: (err) => logError(err, 'BUSINESS_DETAIL_GQL', 'useClubForm'),
      onCompleted: (data) => {
        if (isNewForm) {
          dispatch(ClubFormCtx.setClubField('isDisabled', false));
        } else {
          setClubFromCache(dispatch, data.business);
        }
      },
    });

  const useSubmitForm = async (e) => {
    e.preventDefault();

    const ERROR_OBJ = {
      name: ClubValidations.validateClubName(form.name),
      orgId: ClubValidations.validateClubOrgId(form.orgId),
      street1: ClubValidations.validateClubStreet1(form.street1),
      street2: ClubValidations.validateClubStreet2(form.street2),
      city: ClubValidations.validateClubCity(form.city),
      state: ClubValidations.validateClubState(
        form.state.trim(),
        US_STATES.map(({ value }) => value)
      ),
      postal: ClubValidations.validateClubZip(form.postal),
      phone: ClubValidations.validateClubPhone(form.phone),
      url: ClubValidations.validateClubUrl(form.url),
      sqFootage: ClubValidations.validateInteger(form.sqFootage),
      stageCount: ClubValidations.validateInteger(form.stageCount),
      regLink: ClubValidations.validateClubUrl(form.regLink),
    };

    if (Object.values(ERROR_OBJ).some((val) => val)) {
      dispatch(ClubFormCtx.setClubField('errors', ERROR_OBJ));
      return;
    }

    if (isNewForm) {
      await createBusiness({
        variables: {
          schedule: createSchedule(Object.values(form.schedule)),
          images: createImgList(Object.values(form.images)),
        },
      });
    } else {
      await updateBusiness({
        variables: { schedule: createSchedule(Object.values(form.schedule)) },
      });
    }
  };

  return {
    useGetBusiness,
    useSubmitForm,
    isProcessing: createProcessing || updateProcessing,
  };
};
