import S from './rich-toast.module.scss';

interface Props {
  title?: string;
  message: string | string[];
}

const RichToast = (props: Props) => {
  const { title, message } = props;
  const msgArr = Array.isArray(message) ? message : [message];
  return (
    <div>
      {title && <div className={S.title}>{title}</div>}
      {msgArr.map((msg, idx) => (
        <p key={idx}>{msg}</p>
      ))}
    </div>
  );
};

export default RichToast;
