const getBase64Image = (url: string, setImageData: (imageData: string) => void) => {
  const img = new Image();
  img.setAttribute('crossOrigin', 'anonymous');
  img.onload = () => {
    const canvas = document.createElement('canvas');
    canvas.width = img.width;
    canvas.height = img.height;
    const ctx = canvas.getContext('2d');
    if (ctx) {
      ctx.drawImage(img, 0, 0);
    }
    const dataURL = canvas.toDataURL('image/png');
    setImageData(dataURL);
  };
  img.src = url;
};

export { getBase64Image };
