import React, { useCallback, useMemo, useRef, useState } from 'react';
import * as PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';

import { useQuery } from '@apollo/react-hooks';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import momentTimezonePlugin from '@fullcalendar/moment-timezone';

import S from './calendar.module.scss';
import { CalendarPageHeader } from './calendar-header';
import * as CalendarServices from './calendar-services';
import { LoadingDiscoWithContainer } from '../../../../common/loading/loading-disco';
import { mergeMiniCalData } from '../../../../common/mini-calendar/mini-calendar-services';
import { MiniCalendar } from '../../../../common/mini-calendar/mini-calendar/mini-calendar';
import { ModalBookingProvider } from '../../../../modals/modal-booking/modal-booking-context';
import { BookingModel } from '../../../../modals/modal-booking/component/booking-modal';
import { useBusiness, useHasPermission, useUser } from '../../../../../graphql/graph-hooks';
import { CALENDAR_PAGE_BOOKING_LIST_GQL } from '../../../../../graphql/queries/shift-for-calendar-page';
import { logError } from '../../../../../helpers/errors/bug-report';
import * as TimeHelpers from '../../../../../helpers/times';
import { CAL_VIEWS, PERMISSIONS } from '../../../../../helpers/enums';
import { ENVConstant } from '../../../../../helpers/constants';
import { ClosedLabel } from 'components/pages-login/clubs/closed-label/closed-label';

const plugins = [dayGridPlugin, timeGridPlugin, interactionPlugin, momentTimezonePlugin];

const DEFAULT_VIEW = CAL_VIEWS.WEEK;

const Calendar = ({ onRedirect, date }) => {
  const { t, i18n } = useTranslation();

  const CAL_REF = useRef();
  const { admin } = useUser();
  const HAS_BOOKING_PERMISSION = useHasPermission(PERMISSIONS.MANAGE_BOOKINGS);
  const { id, timezone, hours_of_operation, closed } = useBusiness();
  const CAN_EDIT = admin || !closed;
  const NOW = TimeHelpers.getCurrentTimeAtTimezone(timezone);

  const [isInit, setIsInit] = useState(true);
  const [currentView, setCurrentView] = useState(DEFAULT_VIEW);
  const [eventData, setEventData] = useState({ bookingId: null });
  const [showModal, setShowModal] = useState(false);
  const [viewDate, setViewDate] = useState(date || moment()); //isoString
  const [dataObj, setDataObj] = useState({});
  const [events, setEvents] = useState([]);
  const { range } = useMemo(() => CalendarServices.getCalendarDateData(DEFAULT_VIEW, date, timezone), [date, timezone]);
  const IS_DAY = useMemo(() => currentView === CAL_VIEWS.DAY, [currentView]);

  const BUSINESS_HOURS = useMemo(
    () => CalendarServices.convertBusinessHoursForCalendar(hours_of_operation),
    [hours_of_operation]
  );
  const EARLIEST_OPENING = useMemo(
    () => CalendarServices.getEarliestOpeningTime(currentView, hours_of_operation, viewDate),
    [currentView, hours_of_operation, viewDate]
  );

  const LATEST_CLOSING = useMemo(
    () => CalendarServices.getLatestClosingTime(currentView, hours_of_operation, viewDate),
    [currentView, hours_of_operation, viewDate]
  );

  const handleEventClick = useCallback(({ event }) => {
    setEventData({ bookingId: event.extendedProps.id });
    setShowModal(true);
  }, []);

  const handleNewEventClick = useCallback(({ startStr, endStr }) => {
    setEventData({
      bookingId: null,
      start_time: startStr,
      end_time: endStr,
    });
    setShowModal(true);
  }, []);

  const handleScrollTo = useCallback(() => {
    const SCROLL_TO =
      currentView === CAL_VIEWS.DAY
        ? CalendarServices.getBusinessDayStart(hours_of_operation, viewDate)
        : CalendarServices.getBusinessWeekStart(hours_of_operation);
    CAL_REF.current.getApi().scrollToTime(SCROLL_TO);
  }, [currentView, hours_of_operation, viewDate]);

  const handleCloseModal = useCallback(() => {
    setEventData({ bookingId: null });
    setShowModal(false);
    CAL_REF.current.getApi().unselect();
  }, []);

  const { loading, error, fetchMore } = useQuery(CALENDAR_PAGE_BOOKING_LIST_GQL, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables: { ...range, businessID: id },
    onError: (err) => logError(err, 'CALENDAR_PAGE_BOOKING_LIST_GQL', Calendar.displayName),
    onCompleted: ({ shifts: { nodes } }) => {
      if (isInit) {
        setIsInit(false);
        setEvents(CalendarServices.normalizeCalEvents(nodes, currentView));
        setDataObj(mergeMiniCalData(nodes, {}, timezone));
        handleScrollTo();
      }
    },
  });

  const handleChangeDateRange = useCallback(
    async (view, date) => {
      const { targetDate, range } = CalendarServices.getCalendarDateData(view, date, timezone);

      await fetchMore({
        variables: range,
        updateQuery: (_, { fetchMoreResult }) => {
          setDataObj(mergeMiniCalData(fetchMoreResult.shifts.nodes, {}, timezone));
          setEvents(CalendarServices.normalizeCalEvents(fetchMoreResult.shifts.nodes, view));
          CAL_REF.current.getApi().gotoDate(targetDate);
          handleScrollTo();
          return fetchMoreResult;
        },
      });
    },
    [fetchMore, handleScrollTo, timezone]
  );

  const handleViewChange = useCallback(
    async (newView) => {
      const { targetDate } = CalendarServices.getCalendarDateData(newView, viewDate, timezone);
      setCurrentView(newView);
      setViewDate(targetDate);

      CAL_REF.current.getApi().changeView(newView);
      await handleChangeDateRange(newView, targetDate);
    },
    [viewDate, handleChangeDateRange, timezone]
  );

  const handleIntervalChange = useCallback(
    async (method) => {
      const INTERVAL = CalendarServices.getCalendarViewInterval(currentView);
      const DATE = moment(viewDate)[method](1, INTERVAL).toISOString();
      setViewDate(DATE);
      await handleChangeDateRange(currentView, DATE);
    },
    [currentView, viewDate, handleChangeDateRange]
  );

  if (isInit && loading) {
    return <LoadingDiscoWithContainer />;
  }
  if (error) {
    return <h1 className={S.errorPage}>{t('bookingCalendar.error_loading_calendar')}</h1>;
  }

  const getMinTime = () => {
    if (ENVConstant.calendarDisplayClassicDays === 'true') {
      return '00:00:00';
    } else {
      return EARLIEST_OPENING;
    }
  };

  const getMaxTime = () => {
    if (ENVConstant.calendarDisplayClassicDays === 'true') {
      return '24:00:00';
    } else {
      return LATEST_CLOSING;
    }
  };

  const handleCreateNewBlankBooking = () => {
    setEventData({
      bookingId: null,
      start_time: moment(date).tz(timezone).set('hours', 0).set('minutes', 0),
      end_time: moment(date).tz(timezone).set('hours', 0).set('minutes', 30),
    });
    setShowModal(true);
  };
  return (
    <>
      <div className={S.calGrid}>
        {closed && <ClosedLabel />}
        <CalendarPageHeader
          view={currentView}
          date={viewDate}
          isLoading={loading}
          setShowModal={setShowModal}
          onIntervalChange={handleIntervalChange}
          handleViewChange={handleViewChange}
          canEdit={CAN_EDIT}
          handleCreateNewBlankBooking={handleCreateNewBlankBooking}
        />
        <div className={S.calWrapper}>
          <div className={S.calArea}>
            {loading ? (
              <LoadingDiscoWithContainer />
            ) : (
              <FullCalendar
                weekends
                eventLimit
                nowIndicator
                selectable
                selectMirror
                header={false}
                height='parent'
                locale={i18n.language}
                slotLabelFormat={{
                  hour: 'numeric',
                  minute: '2-digit',
                  omitZeroMinute: false,
                  meridiem: 'long',
                }}
                ref={CAL_REF}
                slotDuration={IS_DAY ? '00:20:00' : '00:30:00'}
                plugins={plugins}
                events={events}
                defaultView={currentView}
                eventRender={(info) => CalendarServices.renderBooking(info, timezone, onRedirect)}
                selectAllow={({ startStr }) =>
                  CalendarServices.handleSelectAllow(startStr, timezone, CAN_EDIT && HAS_BOOKING_PERMISSION)
                }
                nextDayThreshold={currentView === CAL_VIEWS.MONTH ? '23:59:59' : '00:00:00'}
                eventClick={handleEventClick}
                businessHours={BUSINESS_HOURS}
                timeZone={timezone}
                now={NOW.toISOString()}
                defaultDate={viewDate}
                select={handleNewEventClick}
                minTime={getMinTime()}
                maxTime={getMaxTime()}
                firstDay={i18n.language === 'es' ? 1 : 7}
              />
            )}
          </div>
          {IS_DAY && (
            <div className={S.miniCal}>
              <MiniCalendar
                calendarRef={CAL_REF}
                isLoading={loading}
                data={dataObj}
                timezone={timezone}
                jsDate={moment(viewDate).toDate()}
                dateChange={(date) => {
                  setViewDate(moment(date).toISOString());
                  CAL_REF.current.getApi().gotoDate(moment.tz(date, timezone).add(12, 'hours').toISOString());
                  handleScrollTo();
                }}
                monthChange={({ activeStartDate }) => {
                  const METHOD = moment(viewDate).isSameOrAfter(activeStartDate) ? 'subtract' : 'add';
                  const DATE_METHOD = moment(viewDate).isSameOrAfter(activeStartDate) ? 'startOf' : 'endOf';
                  const VIEW_DATE = moment(viewDate).startOf('month')[METHOD](1, 'month').toISOString();
                  const RANGE_DATE = moment(viewDate)[DATE_METHOD]('month')[METHOD](4, 'days').toISOString();

                  setViewDate(VIEW_DATE);
                  handleChangeDateRange(CAL_VIEWS.MONTH, RANGE_DATE);
                }}
              />
            </div>
          )}
        </div>
      </div>

      {showModal && (
        <div className={S.darkOverlay}>
          <ModalBookingProvider>
            <BookingModel
              onClose={handleCloseModal}
              refetch={() => handleChangeDateRange(currentView, viewDate)}
              {...eventData}
            />
          </ModalBookingProvider>
        </div>
      )}
    </>
  );
};

Calendar.displayName = 'Calendar';

Calendar.propTypes = {
  date: PropTypes.string.isRequired,
  onRedirect: PropTypes.func.isRequired,
};

export { Calendar };
