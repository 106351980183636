import * as PropTypes from 'prop-types';
import { useMutation } from '@apollo/react-hooks';
import { TRANSLATE } from 'graphql/mutations/translate';
import { logError } from 'helpers/errors/bug-report';
import { toast } from 'react-toastify';
import { Translate } from 'types/feed';
import classNames from 'classnames';
import { ButtonSpinner } from 'components/common/button_spinner';
import { useTranslation } from 'react-i18next';

interface Props {
  stringOriginal: string | undefined;
  langFrom: string;
  langTo: string;
  flatLeft?: boolean;
  flatRight?: boolean;
  onTranslate: (translatedString: string) => void;
}
const TranslateButton: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const { stringOriginal, langFrom, langTo, onTranslate, flatLeft, flatRight } = props;
  const [translate, { loading: translateLoading }] = useMutation(TRANSLATE, {
    onCompleted: (value: Translate) => onTranslate(value.translate.translate.string_translated),
    onError: (err) => {
      logError(err, 'TRANSLATE', TranslateButton.displayName);
      toast.error(t('errors.server.500'));
    },
  });

  return (
    <button
      onClick={async () => {
        if (stringOriginal) {
          await translate({
            variables: {
              input: { langFrom, langTo, stringOriginal },
            },
          });
        }
      }}
      disabled={translateLoading}
      className={classNames(
        '-ml-px h-9 w-24 relative inline-flex items-center space-x-2 px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
        { ['rounded-none']: flatLeft || flatRight },
        { ['rounded-r-md']: flatLeft },
        { ['rounded-l-md']: flatRight }
      )}
    >
      {translateLoading ? <ButtonSpinner /> : t('common.translate')}
    </button>
  );
};

TranslateButton.displayName = 'TranslateButton';

TranslateButton.propTypes = {
  stringOriginal: PropTypes.string.isRequired,
  langFrom: PropTypes.oneOf(['en', 'es']).isRequired,
  langTo: PropTypes.oneOf(['en', 'es']).isRequired,
  onTranslate: PropTypes.func.isRequired,
  flatLeft: PropTypes.bool,
  flatRight: PropTypes.bool,
};

export { TranslateButton };
