import { useCallback, useEffect, useMemo } from 'react';
import { useApolloClient, useMutation } from '@apollo/react-hooks';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import * as CourseCtx from './course-context';
import { useModal } from '../../global-hooks';
import { COURSE_FRAGMENT } from '../../../graphql/fragments/course-fragment';
import { COURSE_CREATE } from '../../../graphql/mutations/course-create';
import { COURSE_DELETE } from '../../../graphql/mutations/course-delete';
import { COURSE_UPDATE } from '../../../graphql/mutations/course-update';
import { COURSES_LIST_GQL } from '../../../graphql/queries/course-list-query';
import { logError } from '../../../helpers/errors/bug-report';
import * as CourseValidations from '../../../helpers/validations/course-validations';

export const useCourseForm = (courseId, categoryList) => {
  const { t } = useTranslation();
  const CLIENT = useApolloClient();
  const { closeModal } = useModal();
  const {
    dispatch,
    state: { form },
  } = CourseCtx.useCourseFormCtx();

  const CATEGORY_ID_LIST = useMemo(() => categoryList.map(({ value }) => value), [categoryList]);

  const [createVideo, { loading: processingCreate }] = useMutation(COURSE_CREATE, {
    variables: form,
    update: (
      cache,
      {
        data: {
          course_create: { course },
        },
      }
    ) => {
      const { courses } = cache.readQuery({ query: COURSES_LIST_GQL });
      cache.writeQuery({
        query: COURSES_LIST_GQL,
        data: { courses: [...courses, course] },
      });
    },
    onCompleted: () => {
      toast.success(t('modals.course.create.success'));
      closeModal();
    },
    onError: (err) => {
      logError(err, 'COURSE_CREATE', useCourseForm);
      toast.error(t('modals.course.create.failure'));
    },
  });

  const [editVideo, { loading: processingEdit }] = useMutation(COURSE_UPDATE, {
    variables: { courseId, ...form },
    update: (
      cache,
      {
        data: {
          course_update: { course },
        },
      }
    ) => {
      cache.writeData({
        id: `Course:${course.id}`,
        data: course,
      });
    },
    onCompleted: () => {
      toast.success(t('modals.course.update.success'));
      closeModal();
    },
    onError: (err) => {
      logError(err, 'COURSE_UPDATE', useCourseForm);
      toast.error(t('modals.course.update.failure'));
    },
  });

  const [deleteVideo, { loading: processingDelete }] = useMutation(COURSE_DELETE, {
    variables: { courseId },
    update: (cache) => {
      const { courses } = cache.readQuery({ query: COURSES_LIST_GQL });
      cache.writeQuery({
        query: COURSES_LIST_GQL,
        data: { courses: courses.filter(({ id }) => id !== courseId) },
      });
    },
    onCompleted: () => {
      toast.success(t('modals.course.delete.success'));
      closeModal();
    },
    onError: (err) => {
      logError(err, 'COURSE_DELETE', useCourseForm);
      toast.error(t('modals.course.delete.failure'));
    },
  });

  useEffect(() => {
    if (courseId) {
      const course = CLIENT.readFragment({
        fragment: COURSE_FRAGMENT,
        id: `Course:${courseId}`,
      });

      dispatch(
        CourseCtx.setCourseField('form', {
          title: course?.title || '',
          videoUrl: course?.youtube_url || '',
          imageUrl: course?.image_url || '',
          description: course?.description || '',
          categories: course?.course_categories.map(({ id }) => id),
        })
      );
    }
  }, [courseId, CLIENT, dispatch]);

  const handleChange = useCallback(
    ({ target: { value, name } }) => {
      dispatch(CourseCtx.setCourseFormField(name, value));
      dispatch(CourseCtx.setCourseErrorField(name, CourseValidations.validateCourseField(name, value)));
    },
    [dispatch]
  );

  const handleBlur = useCallback(
    ({ target: { value, name } }) => {
      const VALUE = value.trim();
      dispatch(CourseCtx.setCourseFormField(name, VALUE));
      dispatch(CourseCtx.setCourseErrorField(name, CourseValidations.validateCourseField(name, VALUE)));
    },
    [dispatch]
  );

  const handleSelection = useCallback(
    ({ name, value }) => {
      const ERROR = CourseValidations.validateCourseCategory(value, CATEGORY_ID_LIST);
      dispatch(CourseCtx.setCourseFormField(name, value));
      dispatch(CourseCtx.setCourseErrorField(name, ERROR));
    },
    [dispatch, CATEGORY_ID_LIST]
  );

  const handleDeleteVideo = useCallback(async () => await deleteVideo(), [deleteVideo]);

  const handleSubmission = async (e) => {
    e.preventDefault();
    const ERROR_OBJ = {
      title: CourseValidations.validateCourseField('title', form.title),
      videoUrl: CourseValidations.validateCourseField('videoUrl', form.videoUrl),
      imageUrl: CourseValidations.validateCourseField('imageUrl', form.imageUrl),
      categories: CourseValidations.validateCourseCategory(form.categories, CATEGORY_ID_LIST),
    };

    if (Object.values(ERROR_OBJ).every((val) => val === '')) courseId ? await editVideo() : await createVideo();
    else dispatch(CourseCtx.setCourseField('errors', ERROR_OBJ));
  };

  return {
    isDisabled: processingCreate || processingDelete || processingEdit,
    handleChange,
    handleBlur,
    handleSelection,
    handleDeleteVideo,
    handleSubmission,
  };
};
