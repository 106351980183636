import React from 'react';
import { BarChart } from '../../../common/chart_bar';
import { LoadingDiscoWithContainer } from '../../../common/loading/loading-disco';
import { useQuery } from 'react-query';
import { APIConstants } from '../../../../helpers/constants';
import { SerieData } from '../../../../types/charts.d';
import { useTranslation } from 'react-i18next';

export const SignupData = () => {
  const { t } = useTranslation();
  const token = localStorage.getItem('token');
  const { data, isLoading, error } = useQuery<SerieData[]>(['signupData'], () =>
    fetch(`${APIConstants.BI_API_URL}/new-dancers`, {
      headers: [['Authorization', `Bearer ${token}`]],
      credentials: 'omit',
    }).then((res) => res.json())
  );
  return (
    <div className='h-96 shadow ring-1 ring-gray-200 ring-opacity-5 md:rounded-lg'>
      <h2 className='mx-auto py-2 pl-6 text-lg font-medium leading-6 text-gray-400'>
        {t('statistics.new_dancers_by_month')}
      </h2>
      {error ? (
        <div>{t('errors.generic')}</div>
      ) : !data || isLoading ? (
        <LoadingDiscoWithContainer />
      ) : (
        <BarChart stacked data={data} colorArray={['#488f31', '#83af70']} />
      )}
    </div>
  );
};
