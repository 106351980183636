import { gql } from 'apollo-boost';

export const DANCER_CAROUSEL_GQL = gql`
  fragment DancerCarousel on Dancer {
    name
    images {
      id
      thumb
      large
      is_verified
      rejection_text
    }
  }
`;
