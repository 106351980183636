import * as React from 'react';
import { AiOutlineLoading } from 'react-icons/ai';

import S from './spinner-icon.module.scss';

const SpinnerIcon = () => <AiOutlineLoading className={S.loadingSpinner} />;

SpinnerIcon.displayName = 'SpinnerIcon';

export { SpinnerIcon };
