import React, { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import S from './filter-dancer-location.module.scss';
import * as DancerSearchCTX from '../../dancer-search-context';

let autoComplete = null;

const FilterDancerLocation = () => {
  const { t } = useTranslation();
  const {
    dispatch,
    state: { locationInput },
  } = DancerSearchCTX.useDancerSearchCtx();

  const INPUT_REF = useRef(autoComplete);

  useEffect(() => {
    const handlePlaceChanged = () => {
      const place = autoComplete.getPlace();

      dispatch(
        DancerSearchCTX.setDancerSearchFields({
          locationInput: place?.formatted_address || '',
          location: place?.geometry ? { lon: place?.geometry.location.lng(), lat: place?.geometry.location.lat() } : undefined,
        })
      );
    };

    if (window?.google?.maps?.places?.Autocomplete) {
      autoComplete = new window.google.maps.places.Autocomplete(INPUT_REF.current, {
        types: ['(cities)'],
        componentRestrictions: { country: 'us' },
      });
      autoComplete.addListener('place_changed', handlePlaceChanged);
    }
  }, [dispatch]);

  const handleInputChange = useCallback(
    (e) => {
      if(!e.target.value) {
        autoComplete.set('place', null);
      }
      dispatch(DancerSearchCTX.setDancerSearchField('locationInput', e.target.value));
    },
    [dispatch]
  );

  return (
    <div className={S.locationFilter}>
      <span>{t('common.city')}</span>
      <input
        type='text'
        placeholder={t('common.enter_city')}
        value={locationInput}
        onChange={handleInputChange}
        ref={INPUT_REF}
      />
    </div>
  );
};

FilterDancerLocation.displayName = 'FilterDancerLocation';

export { FilterDancerLocation };
