import { gql } from 'apollo-boost';

export const UPDATE_INVITE_CODE = gql`
  mutation registration_code_update(
    $id: ID!
    $code: String
    $description: String
    $isActive: Boolean!
    $locationId: ID
  ) {
    registration_code_update(
      input: { id: $id, code: $code, description: $description, active: $isActive, locationId: $locationId }
    ) {
      registration_code {
        id
        active
        code
        users_count
        description
        location {
          id
          city
          state
          country
          google_places_id
        }
        business {
          id
        }
        user {
          id
          name
          first_image {
            id
            thumb
            medium
          }
        }
      }
    }
  }
`;
