import * as classNames from 'classnames';
import * as React from 'react';
import * as PropTypes from 'prop-types';

import S from './input-form-textarea.module.scss';

const InputFormTextarea = ({ id, name, rows, value, hasError, isDisabled, placeholder, handleChange, handleBlur }) => {
  return (
    <textarea
      id={id}
      name={name}
      rows={rows}
      value={value}
      placeholder={placeholder}
      className={classNames(S.textFormInput, { [S.error]: hasError })}
      disabled={isDisabled}
      onChange={handleChange}
      onBlur={handleBlur}
    />
  );
};

InputFormTextarea.displayName = 'InputFormTextarea';

InputFormTextarea.propTypes = {
  id: PropTypes.string,
  placeholder: PropTypes.string,
  rows: PropTypes.number.isRequired,
  value: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  hasError: PropTypes.bool.isRequired,
  handleBlur: PropTypes.func,
  handleChange: PropTypes.func.isRequired,
};

InputFormTextarea.defaultProps = {
  id: null,
  placeholder: null,
  handleBlur: null,
};

export { InputFormTextarea };
