import * as PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { FaTrashAlt } from 'react-icons/fa';
import { useHistory } from 'react-router';
import { useMutation } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';

import { RouteConstants } from '../../../../../routes/route-constants';
import { USER_CLUB_ID } from '../../../../../../graphql/local-state';
import { DELETE_GROUP_GQL } from '../../../../../../graphql/mutations/group-delete';
import { ALL_GROUPS_GQL } from '../../../../../../graphql/queries/group-list';
import S from '../../../../../../sass/buttons/modern-btn.module.scss';

const BtnGroupDelete = ({ groupId, disabled }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [deleteGroup] = useMutation(DELETE_GROUP_GQL, {
    variables: { groupId },
    update: (cache) => {
      const { userClubId } = cache.readQuery({ query: USER_CLUB_ID });
      const { groups } = cache.readQuery({ query: ALL_GROUPS_GQL, variables: { businessId: userClubId } });
      cache.writeQuery({
        query: ALL_GROUPS_GQL,
        variables: { businessId: userClubId },
        data: { groups: groups.filter(({ id }) => id !== groupId) },
      });
    },
    onCompleted: () => history.push(RouteConstants.groupSearch),
  });

  const handleDelete = useCallback(async () => {
    if (window.confirm(t('groupsPage.actions.delete.confirm'))) {
      await deleteGroup();
    }
  }, [deleteGroup]);

  return (
    <button disabled={disabled} onClick={handleDelete} className={S.modernBtn}>
      <FaTrashAlt /> {t('groupsPage.actions.delete.title')}
    </button>
  );
};

BtnGroupDelete.displayName = 'BtnGroupDelete';

BtnGroupDelete.propTypes = {
  groupId: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

export { BtnGroupDelete };
