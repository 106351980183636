import { useApolloClient } from '@apollo/react-hooks';
import * as PropTypes from 'prop-types';
import React, { useRef } from 'react';

import S from './modal-verification.module.scss';
import { ModalVerificationForm } from './modal-verification-form';
import { ModalVerificationImages } from './modal-verification-images';
import { CloseButton } from '../../common/buttons/close-button';
import { GET_VERIFICATION_FRAGMENT } from '../../../graphql/fragments/verification-fragment';
import { useOverlayClick } from '../../../helpers/hooks/use-overlay-click';
import { useBusiness } from 'graphql/graph-hooks';

const ModalVerification = ({ onClose, onSuccess, id, search, sortOrder }) => {
  const MODAL_REF = useRef();
  const CLIENT = useApolloClient();
  const { id: businessId } = useBusiness();

  const DATA = CLIENT.readFragment({
    id: `VerificationCheck:${id}`,
    fragment: GET_VERIFICATION_FRAGMENT,
    variables: { isAdmin: true, hasLocation: false, inGroupBusinessId: businessId },
    fragmentName: 'VerificationCheckFragment',
  });

  useOverlayClick(MODAL_REF, onClose);

  return (
    <div ref={MODAL_REF} className={S.verificationModal}>
      <CloseButton onClick={onClose} />
      <ModalVerificationImages
        images={DATA?.dancer?.images || []}
        name={DATA?.dancer.name}
        sentence={DATA?.sentence}
        test_img={DATA?.first_image?.medium}
      />
      <ModalVerificationForm
        handleClose={onClose}
        {...DATA}
        search={search}
        sortOrder={sortOrder}
        handleOnSuccess={onSuccess}
      />
    </div>
  );
};

ModalVerification.displayName = 'ModalVerification';

ModalVerification.propTypes = {
  onClose: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  search: PropTypes.string,
  sortOrder: PropTypes.string,
};

export { ModalVerification };
