import React, { useCallback, useState } from 'react';
import classNames from 'classnames';
import S from './photo-verification-page.module.scss';
import { PhotoVerificationList } from './photo-verification-list/photo-verification-list';
import { PHOTO_VERIFICATION_STAGES } from '../../../helpers/enums';
import posthog from 'posthog-js';
import { useTranslation } from 'react-i18next';
import SortSelect from 'components/common/sort-select/sort-select';
import { SearchInput } from '../../common/search-input';
import { debounce } from 'lodash';

const PhotoVerificationPage = () => {
  const { t } = useTranslation();
  const [stage, setState] = useState(PHOTO_VERIFICATION_STAGES.PENDING);
  const handleChangeState = useCallback((e) => setState(e.target.dataset.stage), [setState]);
  const [sortOrder, setSortOrder] = useState('DESC');
  const handleSortOrderChange = useCallback((e) => setSortOrder(e.target.value), [setSortOrder]);
  const [search, setSearch] = useState('');
  const debouncedSetSearch = debounce((text) => setSearch(text), 500);
  const handleSearchChange = (e) => debouncedSetSearch(e.target.value);

  posthog.capture('PhotoVerificationsPage');

  return (
    <div className={S.pageWrapper}>
      <div className={S.verificationHeader}>
        <h1 className={S.h1Heading}>{t('photoVerificationPage.title')}</h1>

        <SortSelect label={t('photoVerificationPage.order')} value={sortOrder} onChange={handleSortOrderChange} />
        <SearchInput
          className={S.verificationSearchBar}
          placeholder={t('photoVerificationPage.search')}
          onChange={handleSearchChange}
        />

        <div className={`${S.verificationBtnGroup} flex`}>
          {Object.keys(PHOTO_VERIFICATION_STAGES).map((stageKey) => {
            const stageName = PHOTO_VERIFICATION_STAGES[stageKey];
            return (
              <button
                key={`VERIFICATION_STAGE_${stageName}`}
                className={classNames(S.invertedBtnBlue, { [S.active]: stage === stageName })}
                data-stage={stageName}
                onClick={handleChangeState}
              >
                {t(`common.${stageKey.toLowerCase()}`)}
              </button>
            );
          })}
        </div>
      </div>

      <PhotoVerificationList
        stage={stage === 'true' ? true : stage === 'false' ? false : null}
        sortOrder={sortOrder}
        search={search}
      />
    </div>
  );
};

PhotoVerificationPage.displayName = 'PhotoVerificationPage';

export { PhotoVerificationPage };
