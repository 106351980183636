import { PHONE_PATTERN, POSTAL_PATTERN, URL_PATTERN } from '../regex-patterns';
import i18next from 'i18next';

const { t } = i18next;

const CLUB_CONFIG = {
  name: { min: 3, max: 120 },
  city: { min: 3, max: 120 },
  street_1: { min: 3, max: 120 },
  street_2: { max: 120 },
};

export const validateClubName = (text) => {
  if (text === '') return t('errors.validation.required', { field_name: t('common.name') });
  else if (text.length < CLUB_CONFIG.name.min)
    return t('errors.validation.min_length', { field_name: t('common.name'), min_length: CLUB_CONFIG.name.min });
  else if (text.length > CLUB_CONFIG.name.max)
    return t('errors.validation.max_length', { field_name: t('common.name'), max_length: CLUB_CONFIG.name.max });
  else return '';
};

export const validateClubCity = (text) => {
  if (text === '') return t('errors.validation.required', { field_name: t('common.city') });
  else if (text.length < CLUB_CONFIG.city.min)
    return t('errors.validation.min_length', { field_name: t('common.city'), min_length: CLUB_CONFIG.city.min });
  else if (text.length > CLUB_CONFIG.city.max)
    return t('errors.validation.max_length', { field_name: t('common.city'), max_length: CLUB_CONFIG.city.max });
  else return '';
};

export const validateClubStreet1 = (text) => {
  if (text === '') return t('errors.validation.required', { field_name: t('common.address') });
  else if (text.length < CLUB_CONFIG.street_1.min)
    return t('errors.validation.min_length', { field_name: t('common.address'), min_length: CLUB_CONFIG.street_1.min });
  else if (text.length > CLUB_CONFIG.street_1.max)
    return t('errors.validation.max_length', { field_name: t('common.address'), max_length: CLUB_CONFIG.street_1.max });
  else return '';
};

export const validateClubUrl = (url) => {
  if (url && url !== '' && !URL_PATTERN.test(url)) return t('errors.validation.invalid_url');
  else return '';
};

export const validateClubStreet2 = (text) => {
  if (text && text.length > CLUB_CONFIG.street_2.max)
    return t('errors.validation.max_length', { field_name: t('common.address'), max_length: CLUB_CONFIG.street_2.max });
  else return '';
};

export const validateClubOrgId = (id, list) => {
  if (id === '') return t('errors.validation.required', { field_name: t('clubPage.biz_orga') });
  else if (list && !list.includes(id)) return t('errors.validation.invalid_option');
  else return '';
};

export const validateClubPhone = (number) => {
  if (number === '') return t('errors.validation.required', { field_name: t('common.phone') });
  else if (!PHONE_PATTERN.test(number)) return t('errors.phone.invalid_pattern');
  else return '';
};

export const validateClubState = (id, list) => {
  if (id === '') return t('common.select_option');
  else if (!list.includes(id)) return t('errors.validation.invalid_option');
  else return '';
};

export const validateClubZip = (zip) => {
  if (zip === '') return t('errors.validation.required', { field_name: t('common.address') });
  else if (!POSTAL_PATTERN.test(zip)) return t('errors.validation.invalid', { field_name: t('common.zip_code') });
  else return '';
};

export const validateInteger = (num) => {
  if (!num || !Number.isInteger(Number(num))) {
    return t('errors.validation.not_integer');
  } else if (num <= 0) {
    return t('errors.validation.min', { number: 0 });
  } else {
    return '';
  }
};
