import * as React from 'react';
import * as PropTypes from 'prop-types';
import { FaSort } from 'react-icons/fa';

import S from './input-form-select.module.scss';

const InputFormSelect = ({ id, name, value, hasError, options, onChange, isDisabled }) => {
  const checkedValue = options.find((option) => option.value === value)?.value;
  return (
    <div className={S.wrapper}>
      <select
        id={id}
        name={name}
        value={checkedValue}
        onChange={onChange}
        disabled={isDisabled}
        className={hasError ? S.error : null}
      >
        {options.map(({ label, value }, i) => (
          <option key={`${id}_${i}`} value={value}>
            {label}
          </option>
        ))}
      </select>
      <FaSort />
    </div>
  );
};

InputFormSelect.displayName = 'SelectFormInput';

InputFormSelect.propTypes = {
  value: PropTypes.string,
  hasError: PropTypes.bool,
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,

  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string,
    })
  ),
  onChange: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
};

InputFormSelect.defaultProps = {
  gridArea: null,
  value: '',
  hasError: false,
  isDisabled: false,
};

export { InputFormSelect };
