import React, { FC } from 'react';
import classNames from 'classnames';

import { ButtonSpinner } from 'components/common/button_spinner';

type Props = {
  title: string;
  onClick?: () => void;
  color: string;
  isLoading?: boolean;
  disabled?: boolean;
  type?: 'button' | 'submit' | 'reset';
  className?: string;
};

const Button: FC<Props> = ({ title, onClick, color, className, type = 'button', disabled, isLoading }) => {
  const currentColor = disabled || isLoading ? 'gray' : color;
  const classes = `flex justify-center w-40 mr-6 py-2 border border-transparent text-sm text-center font-medium rounded-md shadow-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2 bg-${currentColor}-600 hover:bg-${currentColor}-700 focus:ring-${currentColor}-500`;
  return (
    <button disabled={disabled || isLoading} onClick={onClick} className={classNames(classes, className)} type={type}>
      {isLoading && <ButtonSpinner />} {title}
    </button>
  );
};

export default Button;
