import { FC } from 'react';
import ImageGallery, { ReactImageGalleryProps, ReactImageGalleryItem } from 'react-image-gallery';
import S from './multimedia-gallery.module.scss';
import { MediaType } from 'types/getstream';
import { FaPlay } from 'react-icons/fa';
interface MediaItem {
  order: number;
  url: string;
  media_type: MediaType;
}
interface Media {
  media: MediaItem[];
}

interface Props extends Media, Partial<ReactImageGalleryProps> {}

const MultimediaGallery: FC<Props> = ({ media, ...props }) => {
  const renderVideo = ({ original }: ReactImageGalleryItem) => {
    return (
      <div className='z-50 flex justify-center'>
        <video controls muted className={S.video}>
          <source src={original} />
        </video>
      </div>
    );
  };

  const renderThumb = (isVideo: boolean, className: string, { thumbnail }: ReactImageGalleryItem) => {
    return (
      <div
        className={'h-20 w-full bg-cover bg-center flex items-center justify-center ' + className}
        style={{ backgroundImage: `url(${thumbnail}), url(${window.location.origin}/img/video-placeholder.png)` }}
      >
        {isVideo && <FaPlay size='16' className={`absolute ${className}`} />}
      </div>
    );
  };

  const items = media.map(({ url, media_type }): ReactImageGalleryItem => {
    const [mediaUrl, transformation] = url.split('?tr=');
    return {
      original: url,
      thumbnail: media_type === 'VIDEO' ? `${mediaUrl}/ik-thumbnail.jpg` : url,
      renderThumbInner:
        media_type === 'VIDEO'
          ? renderThumb.bind(this, true, transformation.indexOf('rt-360') > -1 ? 'rotate-180' : '')
          : renderThumb.bind(this, false, ''),
      renderItem: media_type === 'VIDEO' ? renderVideo : undefined,
    };
  });

  return <ImageGallery {...props} items={items} additionalClass={S.image} />;
};

export default MultimediaGallery;
