import React, { FC, useMemo } from 'react';
import { Form, Formik, Field } from 'formik';
import { useMutation } from '@apollo/react-hooks';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { FormTextField } from '../../common/form/form-text-field';

import { COURSE_CATEGORY_CREATE } from '../../../graphql/mutations/course-category-create';
import { COURSES_CATEGORY_GQL } from '../../../graphql/queries/course-category-query';

import S from './modal-course-category.module.scss';

interface Props {
  onClose: () => void;
  categories: [{ name: string }];
}

const initialValues = { name: '' };

const CreateForm: FC<Props> = ({ categories, onClose }) => {
  const { t } = useTranslation();
  const [createCategory] = useMutation(COURSE_CATEGORY_CREATE, {
    update: (
      cache,
      {
        data: {
          course_category_add: { course_category },
        },
      }
    ) => {
      cache.writeQuery({
        query: COURSES_CATEGORY_GQL,
        data: { course_categories: [...categories, course_category] },
      });
    },
    onError: (err) => {
      toast.error(`${t('errors.server.500')}. ${t('modals.course.category.creation_failed')}`);
    },
    onCompleted: () => {
      toast.success(t('modals.course.category.created'));
      onClose();
    },
  });

  const handleOnSubmit = async ({ name }: { name: string }) => {
    await createCategory({ variables: { name: name.trim() } });
  };

  const existingcategories = useMemo(() => categories.map(({ name }) => name.toUpperCase()), [categories]);

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required(t('errors.validation.required', { field_name: t('common.name') }))
      .min(3, t('errors.validation.min_length', { field_name: t('common.name'), min_length: 3 }))
      .max(50, t('errors.validation.max_length', { field_name: t('common.name'), max_length: 50 }))
      .test('unique', t('errors.validation.unique', { field_name: t('common.name') }), function (name) {
        return !!!existingcategories.find((c) => c === name?.toUpperCase());
      }),
  });

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleOnSubmit}>
      {({ values, touched, errors, isValid, isSubmitting, handleChange, handleBlur }) => {
        return (
          <Form>
            <Field
              type='text'
              id='name'
              name='name'
              label={t('modals.course.category.name')}
              value={values.name}
              as={FormTextField}
              isDisabled={isSubmitting}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
            <div className={S.error}>{touched.name && errors.name && errors.name}</div>

            <div className={S.actionRow}>
              <button type='button' disabled={isSubmitting} onClick={onClose} className={S.invertedBtnRed}>
                {t('common.cancel')}
              </button>
              <button type='submit' disabled={isSubmitting || !isValid} className={S.invertedBtnGreen}>
                {t('common.create')}
              </button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default CreateForm;
