import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { RecoveryForm } from 'components/pages-logout/recovery/recovery-form';
import S from '../pre-login-parts.module.scss';

const RecoveryPage: React.FC = (): React.ReactElement => {
  const { t } = useTranslation();
  const [hasSubmitted, setHasSubmitted] = useState(false);

  return !hasSubmitted ? (
    <RecoveryForm onSuccess={setHasSubmitted} />
  ) : (
    <>
      <h1 className={S.pageHeading}>{t('recoveryPage.email_sent')}</h1>
      <div className={S.mainText}>{t('recoveryPage.check_email_for_instructs')}</div>
      <Link to={'/'} className={S.activeLink}>
        {t('recoveryPage.back_to_main')}
      </Link>
    </>
  );
};

RecoveryPage.displayName = 'RecoveryPage';

export { RecoveryPage };
