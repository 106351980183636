import { BOOKING_TYPES } from '../../../helpers/constants';
import i18n from 'i18next';

export const SET_MANY_BOOKING_FIELDS = 'SET_MANY_BOOKING_FIELDS';
export const SET_BOOKING_ERROR_KEY = 'SET_BOOKING_ERROR_KEY';
export const SET_BOOKING_FORM_KEY = 'SET_BOOKING_FORM_KEY';

export const BOOKING_CONFIRM_ACTIONS = {
  EDIT: 'EDIT',
  DELETE: 'DELETE',
};

export const BOOKING_MODAL_ACTIONS = {
  EDIT: 'EDIT',
  CREATE: 'CREATE',
  DELETE: 'DELETE',
};

export const BOOKING_TYPE_OPTIONS = [
  { label: i18n.t('modals.booking.selectType'), value: '' },
  { label: i18n.t('modals.booking.promo'), value: BOOKING_TYPES.PROMO },
  { label: i18n.t('modals.booking.audition'), value: BOOKING_TYPES.AUDITION },
  { label: i18n.t('modals.booking.event'), value: BOOKING_TYPES.EVENT },
  { label: i18n.t('modals.booking.day'), value: BOOKING_TYPES.DAY },
  { label: i18n.t('modals.booking.evening'), value: BOOKING_TYPES.EVENING },
  { label: i18n.t('modals.booking.night'), value: BOOKING_TYPES.NIGHT },
  { label: i18n.t('modals.booking.late_night_showtime'), value: BOOKING_TYPES.LATE_NIGHT },
];

export const MODAL_BOOKING_INIT_STATE = {
  errors: {
    date: '',
    start_time: '',
    end_time: '',
    shift_type: '',
    recurring: '',
    hardCap: '',
    slots: '',
    title: '',
    description: '',
    image: '',
    checkOut: '',
    selector: '',
  },
  meta: {
    initial: {
      start_time: new Date().toISOString(),
      end_time: new Date().toISOString(),
    },
    isEditable: false,
    hasOptions: true,
    confirm: null,
    isMany: false,
  },
  form: {
    bookingId: null,
    date: '',
    start_time: '',
    end_time: '',
    slots: '10',
    checkOut: '0',
    hardCap: false,
    recurring: false,
    booking_type: '',
    title: '',
    description: '',
    duplicates: [],
    preapprovedIds: [],
    notifyIds: [],
    preview: null,
    selector: '',
    image_attachment: null,
  },
};
