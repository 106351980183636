import { gql } from 'apollo-boost';

export const SHIFT_APPLICATION_FRAGMENT = gql`
  fragment shiftApplicationFragment on ShiftApplication {
    id
    status
    is_invite
    accepted_by {
      id
      name
    }
    checked_in_at
    checked_in_by {
      id
      name
    }
    checked_out_at
    checked_out_by {
      id
      name
    }
    marked_missed_at
    marked_missed_by {
      id
      name
    }
    rating_sub_one
    rating_sub_two
    rating_sub_three
    shift {
      id
      accepted_shift_applications_count
      checked_in_shift_applications_count
      checked_out_shift_applications_count
      standby_shift_applications_count
      invited_shift_applications_count
      standby_shift_applications_count
    }
    dancer {
      id
      name
      first_image {
        id
        thumb
        medium
      }
    }
  }
`;
