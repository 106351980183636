import * as React from 'react';
import * as PropTypes from 'prop-types';
import * as classNames from 'classnames';
import i18n from 'i18next';

import S from './no-image-view.module.scss';

const NoImageView = ({ isRound }) => (
  <div
    className={classNames(S.emptyImg, {
      [S.round]: isRound,
    })}
  >
    {i18n.t('common.no_image')}
  </div>
);

NoImageView.propTypes = {
  isRound: PropTypes.bool,
};

NoImageView.defaultProps = {
  isRound: false,
};

export { NoImageView };
