import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/react-hooks';

import { useBusiness, useUser } from 'graphql/graph-hooks';
import useApplications from './useApplications';

import { GET_BOOKING_GQL } from 'graphql/queries/booking-page-query';

import PageContainer from 'components/common/page/page-container';
import PageHeader from 'components/common/page/page-header';
import PageBody from 'components/common/page/page-body';

import ShiftInfo from './ShiftInfo';
import Tabs from './Tabs';
import Toolbar from './Toolbar';
import List from './List';
import LoadMore from './LoadMore';

import { BOOKING_STATUS } from 'helpers/enums';
import { getRequestsVariables } from './helpers';
import type { RequestParams, Shift } from './types';

const ApplicationRequestsPage: FC = () => {
  const { id: businessId } = useBusiness();
  const { admin: isAdmin } = useUser();
  const { t } = useTranslation();
  const { type, bookingId } = useParams<RequestParams>();
  const [activeTab, setActiveTab] = useState(BOOKING_STATUS.PENDING);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [search, setSearch] = useState<string>('');
  const [filter, setFilter] = useState<BOOKING_STATUS | 'ALL'>('ALL');
  const [sort, setSort] = useState<'asc' | 'desc'>('asc');
  const { counts, requests } = useApplications({
    bookingId,
    isAdmin,
    businessId,
    type,
    status: activeTab,
    search,
    filter,
    sort,
  });

  const booking = useQuery(GET_BOOKING_GQL, {
    fetchPolicy: 'cache-and-network',
    variables: getRequestsVariables({
      bookingId,
      isAdmin,
      businessId,
    }),
    skip: !bookingId,
  });

  const shift: Shift = booking?.data?.shift;

  const handleToggleSelectAllIds = () => {
    setSelectedIds(selectedIds.length ? [] : requests.nodes.map(({ id }) => id));
  };

  const handleOnActionSuccess = (ids: string[], action: string) => {
    if (ids) {
      setSelectedIds(selectedIds.filter((id) => !ids.includes(id)));
      if (action === 'inviteCancel') {
        requests.removeById(ids);
      }
    }
    if (!bookingId) {
      counts.refetch();
    } else if (action === 'inviteCancel') {
      requests.refetch();
    }
  };

  useEffect(() => {
    setSelectedIds([]);
    setFilter('ALL');
  }, [type, activeTab]);

  return (
    <PageContainer>
      <PageHeader title={t('manageBookings.manage_requests')} />

      <PageBody isLoading={false} error={''} className='mt-10'>
        {shift && (
          <ShiftInfo
            type={shift.shift_type}
            slots={shift.slots}
            hardcapped={shift.hardcapped}
            applications={shift.shift_applications}
            timezone={shift.business.timezone}
            start={shift.start_time}
            end={shift.end_time}
          />
        )}
        <Tabs activeTab={activeTab} onChange={setActiveTab} counts={counts.data} bookingId={bookingId} />
        <Toolbar
          showSort={!!booking.data}
          sort={sort}
          onSortChange={setSort}
          search={search}
          onSearchChange={setSearch}
          showFilter={activeTab === BOOKING_STATUS.ACCEPTED && !!booking.data}
          filter={filter}
          onFilterChange={setFilter}
          selected={requests.nodes.filter(({ id }) => selectedIds.includes(id))}
          totalNum={requests.nodes.length}
          toggleSelectAllIds={handleToggleSelectAllIds}
          onActionSuccess={handleOnActionSuccess}
        />
        <List
          data={requests.nodes}
          isLoading={requests.isLoading && !requests.isFetchingMore}
          error={requests.error}
          onActionSuccess={handleOnActionSuccess}
          onUserRemoved={requests.refetch}
          selectedIds={selectedIds}
          setSelectedIds={setSelectedIds}
        />
        <LoadMore
          hasNextPage={requests.pageInfo.hasNextPage}
          isLoading={requests.isLoading}
          isFetchingMore={requests.isFetchingMore}
          fetchMore={requests.fetchMore}
        />
      </PageBody>
    </PageContainer>
  );
};

export default ApplicationRequestsPage;
