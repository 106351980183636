import React, { useState, useCallback, useMemo } from 'react';
import { GridContextProvider, swap } from 'react-grid-dnd';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import S from './page-group-search.module.scss';
import { GroupList } from './groups-list';
import { useModal } from '../../../global-hooks';
import { LoadingDiscoWithContainer } from '../../../common/loading/loading-disco';
import { SearchInput } from '../../../common/search-input';
import { USER_CLUB_ID } from '../../../../graphql/local-state';
import { SEND_GROUP_ORDER_GQL } from '../../../../graphql/mutations/group-set-order';
import { ALL_GROUPS_GQL } from '../../../../graphql/queries/group-list';
import { UI_MODALS } from '../../../../helpers/enums';
import { filterViaText } from '../../../../helpers/filter';
import { logError } from '../../../../helpers/errors/bug-report';

const PageGroupSearch = ({ businessId }) => {
  const { t } = useTranslation();

  const [filterText, setFilterText] = useState('');
  const [order, setOrder] = useState([]);
  const { initModal } = useModal();
  const {
    data: { userClubId },
  } = useQuery(USER_CLUB_ID);
  const CAN_RE_ORDER = filterText === '';

  const { loading, error } = useQuery(ALL_GROUPS_GQL, {
    fetchPolicy: 'network-only',
    variables: { businessId: userClubId },
    onCompleted: ({ groups }) => {
      setOrder(groups);
      const atg = new URLSearchParams(window.location.search).get('atg');
      const dancerId = new URLSearchParams(window.location.search).get('dId');
      if (atg && dancerId) {
        initModal(UI_MODALS.MANAGE_DANCER_GROUPS, { dancerId, businessId });
      }
    },
    onError: (err) => {
      logError(err, 'ALL_GROUPS_GQL', PageGroupSearch.displayName);
      toast.error(`${t('errors.server.500')}: ${t('dancerPage.groups_fetch_failed')}`);
    },
  });

  const [updateGroupOrder] = useMutation(SEND_GROUP_ORDER_GQL, {
    onCompleted: () => toast.success(t('dancerPage.group_reorder_success')),
    onError: (err) => {
      logError(err, 'SEND_GROUP_ORDER_GQL', PageGroupSearch.displayName);
      toast.error(`${t('errors.server.500')}: ${t('dancerPage.group_reorder_failed')}`);
    },
  });

  const handleFilterTextChange = useCallback((e) => setFilterText(e.target.value), [setFilterText]);

  const handleGroup = useCallback(
    () =>
      initModal(UI_MODALS.CREATE_GROUP, {
        addGroup: (group) => setOrder([...order, group]),
      }),
    [initModal, order]
  );

  const GROUPS = useMemo(() => filterViaText(order, 'name', filterText), [order, filterText]);

  const handleGridChange = useCallback(
    async (id, start, end) => {
      const NEW_ORDER = swap(order, start, end);
      const OLD_ORDER_IDS = order.map(({ id }) => id);
      const NEW_ORDER_IDS = NEW_ORDER.map(({ id }) => id);
      if (OLD_ORDER_IDS.join(' ') !== NEW_ORDER_IDS.join(' ')) {
        setOrder(NEW_ORDER);
        await updateGroupOrder({
          variables: { groupIds: NEW_ORDER_IDS },
        });
      }
    },
    [order, updateGroupOrder]
  );

  if (loading) {
    return <LoadingDiscoWithContainer />;
  }
  if (error) {
    return <h1 className={S.errorMessage}>{t('dancerPage.groups_fetch_failed')}</h1>;
  }

  return (
    <div className={S.pageWrapper}>
      <div className={S.header}>
        <h1 className={S.h1Heading}>{t('common.groups')}</h1>
        <button className={S.createGroupBtn} onClick={handleGroup}>
          {t('dancerPage.group_create')}
        </button>

        <SearchInput placeholder={t('dancerPage.search_groups')} gridArea='filter' onChange={handleFilterTextChange} />
      </div>
      {GROUPS.length ? (
        <p className={S.draggingMemo}>
          {CAN_RE_ORDER ? t('dancerPage.groups_rearrange_instructions') : t('dancerPage.groups_reorder_disabled')}
        </p>
      ) : (
        <p className={S.draggingMemo}>{t('common.empty_list')}</p>
      )}

      <GridContextProvider onChange={handleGridChange}>
        <GroupList groups={GROUPS} isEditable={CAN_RE_ORDER} />
      </GridContextProvider>
    </div>
  );
};

PageGroupSearch.displayName = 'PageGroupSearch';
PageGroupSearch.propTypes = {
  businessId: PropTypes.string.isRequired,
};

export { PageGroupSearch };
