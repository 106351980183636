import { Link } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { ResetForm } from 'components/pages-logout/reset/reset-form';
import { getDeviceStore } from 'helpers/mobile-dectection';

import S from '../pre-login-parts.module.scss';

const ResetPage: React.FC = (): React.ReactElement => {
  const { t } = useTranslation();

  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [isMobile, setIsMobile] = useState(true);

  useEffect(() => {
    setIsMobile(getDeviceStore());
    return () => setIsMobile(false);
  }, []);

  return !hasSubmitted ? (
    <ResetForm onSuccess={setHasSubmitted} />
  ) : (
    <>
      <h1 className={S.pageHeading}>{t('reset.success.heading')}</h1>
      <div className={S.mainText}>{t(isMobile ? 'reset.success.mobile' : 'reset.success.message')}</div>

      {!isMobile && (
        <Link to={'/'} className={S.activeLink}>
          {t('reset.back')}
        </Link>
      )}
    </>
  );
};

ResetPage.displayName = 'ResetPage';

export { ResetPage };
