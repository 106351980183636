import { gql } from 'apollo-boost';

export const INVITE_GENERALLY = gql`
    mutation business_invite($businessId: ID! $dancerIds: [ID!]!){
        business_invite(input: {
            businessId: $businessId
            userIds: $dancerIds
        }) {
            clientMutationId
        }
    }
`;
