import { gql } from 'apollo-boost';

export const GET_DANCER_DETAILS_GQL = gql`
    query(
        $businessId:ID!
        $dancerId:ID!
        $cursor: String
        $status: [ShiftApplicationStatus!]
    ){
        shift_applications(
            first: 36
            after: $cursor,
            filters:{
                businessId: $businessId
                userId:     $dancerId
                status:     $status
            }
        ){
            pageInfo{
                hasNextPage
                endCursor
            }
            totalCount
            nodes{
                id
                rating_sub_one
                rating_sub_two
                rating_sub_three
                status
                shift {
                    id
                    shift_type
                    start_time
                    end_time
                }
            }
        }
    }
`;
