import React, { useEffect, useState, useRef, useCallback } from 'react';
import * as PropTypes from 'prop-types';
import classNames from 'classnames';
import { toast } from 'react-toastify';

import { ImgLoading } from './img-loading';
import S from './img-field.module.scss';
import { NoImageView } from './no-image-view';
import { ImgPlaceholder } from './img-placeholder';
import { Image } from './img-file';
import { DeleteImgIcon } from '../icons/delete-img-icon';
import i18n from 'i18next';

const ImageField = ({
  alt,
  size,
  setFile,
  isRound,
  isDisabled,
  isLoading,
  gridArea = null,
  preview = null,
  hasPermission,
  deleteFile,
}) => {
  const [dragCounter, setDragCounter] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [isBusy, setIsBusy] = useState(false);

  const DROP_REF = useRef(null);
  const FILE_REF = useRef(null);

  const handleDragIn = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();

      setDragCounter(1);
      if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
        setIsDragging(true);
      }
    },
    [setDragCounter, setIsDragging]
  );

  const handleDragOut = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();

      setDragCounter(0);
      if (dragCounter > 0) return;
      setIsDragging(false);
    },
    [setDragCounter, dragCounter, setIsDragging]
  );

  const handleDrag = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const handleFileChange = useCallback(
    (file) => {
      const TYPE_LIST = file.type.split('/');

      if (hasPermission && TYPE_LIST[0] === 'image' && ['png', 'jpg', 'jpeg'].includes(TYPE_LIST[1])) {
        let reader = new FileReader();

        reader.onloadend = async () => {
          setIsBusy(true);

          await setFile({
            image_attachment: file,
            preview: reader.result,
          });
          setIsBusy(false);
        };

        reader.readAsDataURL(file);
      } else toast.info(i18n.t('errors.unsupported_image_format', { allowed: '.jpeg or .png' }));
    },
    [setFile, hasPermission]
  );

  const handleSetFile = useCallback(() => FILE_REF.current.click(), []);

  const handleFileInputChange = (e) => {
    if (e.currentTarget.files && e.currentTarget.files.length > 0) {
      handleFileChange(e.currentTarget.files[0]);
    }
  };

  useEffect(() => {
    function handleDrop(e) {
      e.preventDefault();
      e.stopPropagation();

      setIsDragging(false);
      if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
        if (!isDisabled) handleFileChange(e.dataTransfer.files[0]);
        e.dataTransfer.clearData();
        setDragCounter(0);
      }
    }

    setDragCounter(0);
    let div = DROP_REF.current;

    div.addEventListener('dragenter', handleDragIn);
    div.addEventListener('dragleave', handleDragOut);
    div.addEventListener('dragover', handleDrag);
    div.addEventListener('drop', handleDrop);

    return () => {
      div.removeEventListener('dragenter', handleDragIn);
      div.removeEventListener('dragleave', handleDragOut);
      div.removeEventListener('dragover', handleDrag);
      div.removeEventListener('drop', handleDrop);
    };
  }, [handleDragIn, handleDragOut, handleDrag, isDisabled, handleFileChange]);

  return (
    <div ref={DROP_REF} style={{ gridArea }} className={classNames(S.imgField, { [S.isRound]: isRound })}>
      <input ref={FILE_REF} type='file' name='file' accept='image/*' onChange={handleFileInputChange} />

      {(isBusy || isLoading) && <ImgLoading />}

      {preview && (
        <>
          <Image
            src={preview}
            alt={alt}
            styles={{ height: size, width: size }}
            classname={classNames(S.imgWrapper, {
              [S.opaque]: isDragging,
              [S.isRound]: isRound,
            })}
          />

          {preview && !isDisabled && !isLoading && <DeleteImgIcon className={S.trashIcon} handleDelete={deleteFile} />}
        </>
      )}

      {!preview && !isDisabled && (
        <ImgPlaceholder hasIcon alt={alt} size={size} isRound={isRound} handleBtnClick={handleSetFile} />
      )}

      {!preview && isDisabled && <NoImageView isRound />}
    </div>
  );
};

ImageField.displayName = 'ImageField';
ImageField.propTypes = {
  isRound: PropTypes.bool,
  gridArea: PropTypes.string,
  preview: PropTypes.string,

  alt: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
  hasPermission: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,

  setFile: PropTypes.func.isRequired,
  deleteFile: PropTypes.func.isRequired,
};

ImageField.defaultProps = {
  gridArea: null,
  preview: null,
  isRound: false,
};

export { ImageField };
