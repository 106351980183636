import { LoadingDisco } from 'components/common/loading/loading-disco';

const Loader = () => {
  return (
    <div className='absolute top-14 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50'>
      <LoadingDisco />
    </div>
  );
};

export default Loader;
