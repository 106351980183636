import React from 'react';

const Checkmark = () => (
  <svg
    width="24" 
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5 13L9 17L19 7"
      fill="none" 
      stroke="black" 
      strokeWidth="7" 
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5 13L9 17L19 7"
      fill="none"
      stroke="green" 
      strokeWidth="5" 
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Checkmark;
