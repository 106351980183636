import { FC, useRef } from 'react';
import Cropper, { ReactCropperElement } from 'react-cropper';
import { useTranslation } from 'react-i18next';

import { CloseButton } from 'components/common/buttons/close-button';
import { LoadingDisco } from 'components/common/loading/loading-disco';

import S from './modal-image-crop.module.scss';

interface Props {
  onClose: () => void;
  onCropped: (data: string) => void;
  imageSrc: string;
  isLoading: boolean;
  initialAspectRatio: number;
  aspectRatio: number;
}

export const ModalImageCrop: FC<Props> = ({
  onClose,
  onCropped,
  isLoading,
  imageSrc,
  initialAspectRatio,
  aspectRatio,
}) => {
  const { t } = useTranslation();
  const MODAL_REF = useRef(null);
  const CROPPER_REF = useRef<ReactCropperElement>(null);
  const error = !isLoading && !imageSrc;

  const onCrop = () => {
    const cropper = CROPPER_REF.current?.cropper;
    if (cropper) {
      const croppedImageData = cropper.getCroppedCanvas().toDataURL();
      onCropped(croppedImageData);
      onClose();
    }
  };

  return (
    <div className={S.modal} ref={MODAL_REF}>
      <div className='mb-2'>
        <CloseButton onClick={onClose} />
        <div>
          <div className='font-bold'>{t('modals.image_crop.crop_image')}</div>
        </div>
      </div>
      {error && (
        <div className='p-4 text-red-500 text-center'>{`${t('errors.generic')}. ${t('errors.try_again')}`}</div>
      )}
      {isLoading && <LoadingDisco />}
      {imageSrc && (
        <Cropper
          ref={CROPPER_REF}
          src={imageSrc}
          style={{ maxHeight: '80vh', width: '100%' }}
          initialAspectRatio={initialAspectRatio}
          aspectRatio={aspectRatio}
          guides={false}
          viewMode={1}
        />
      )}
      {!error && (
        <div className={S.buttonRow}>
          <button className={S.invertedBtnGreen} type='button' onClick={onCrop}>
            {t('modals.image_crop.crop_and_upload_image')}
          </button>

          <button className={S.invertedBtnRed} type='button' onClick={onClose}>
            {t('common.cancel')}
          </button>
        </div>
      )}
    </div>
  );
};
