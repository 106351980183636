import * as React from 'react';
import { useTranslation } from 'react-i18next';

import S from './no-items.module.scss';

export const NoItems = () => {
  const { t } = useTranslation();
  return (
    <div className={S.noItems}>
      <div>{t('common.empty_list')}</div>
    </div>
  );
};
