import React, { useCallback } from 'react';
import { FaUserPlus } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

import { useGroupCtx } from '../../group-details-context';
import { useModal } from '../../../../../global-hooks';
import { UI_MODALS } from '../../../../../../helpers/enums';
import S from '../../../../../../sass/buttons/modern-btn.module.scss';

const BtnGroupInvite = ({ disabled }) => {
  const { t } = useTranslation();
  const {
    state: { selected },
  } = useGroupCtx();

  const { initModal } = useModal();
  const handleInvite = useCallback(
    () => initModal(UI_MODALS.INVITE_DANCER, { dancers: selected }),
    [initModal, selected]
  );

  return (
    <button className={S.modernBtn} onClick={handleInvite} disabled={selected.length === 0 || disabled}>
      <FaUserPlus /> {t('groupsPage.actions.invite')}
    </button>
  );
};

BtnGroupInvite.displayName = 'BtnGroupInvite';

export { BtnGroupInvite };
