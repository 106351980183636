import { useState, useEffect, useMemo } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { ApolloError } from 'apollo-boost';
import orderBy from 'lodash/orderBy';

import { useFetchMore as useFetchMoreRequests } from 'components/global-hooks';

import { APPLICATION_COUNT } from 'graphql/queries/shift_application_count';
import { SHIFT_APPLICATIONS_GQL } from 'graphql/queries/shift-applications-query';
import { GET_BOOKING_GQL } from 'graphql/queries/booking-page-query';

import {
  getCountsVariables,
  getRequestsVariables,
  initialCounts,
  initialPageInfo,
  filterApplications,
  getFormattedApplicationCounts,
  getApplicationCountKey,
} from './helpers';
import { BOOKING_STATUS } from 'helpers/enums';
import type { Dancer, PageInfoType, ShiftApplication, UseApplicationsProps } from './types';

const useApplications = ({
  bookingId,
  isAdmin,
  businessId,
  type,
  status,
  search,
  filter,
  sort,
}: UseApplicationsProps) => {
  const [pageInfo, setPageInfo] = useState<PageInfoType>(initialPageInfo);
  const [applicationCounts, setApplicationCounts] = useState(initialCounts);
  const [totalCount, setTotalCount] = useState(0);
  const [nodes, setNodes] = useState<ShiftApplication[]>([]);

  const handleOnError = (error: ApolloError) => {
    console.log('useApplications error:', error);
  };

  const QUERY = useMemo(
    () => (!bookingId ? SHIFT_APPLICATIONS_GQL(type === 'auditions') : GET_BOOKING_GQL),
    [bookingId, type]
  );

  const requests = useQuery(QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: getRequestsVariables({
      bookingId,
      isAdmin,
      businessId,
      status: status === BOOKING_STATUS.INVITED ? BOOKING_STATUS.PENDING : status,
      search,
      cursor: '',
      invited: status === BOOKING_STATUS.INVITED ? true : undefined,
    }),
    onError: handleOnError,
  });

  const counts = useQuery(APPLICATION_COUNT, {
    fetchPolicy: 'cache-and-network',
    variables: getCountsVariables({ businessId, type }),
    onError: handleOnError,
    skip: !!bookingId,
  });

  const {
    isLoading: isFetchingMore,
    fetchMore: fetchMoreRequests,
    error: fetchMoreError,
  } = useFetchMoreRequests('shift_applications');

  const removeById = (ids: string[]) => {
    const filtered = nodes.filter(({ id }) => !ids.includes(id));
    setNodes(filtered);
    counts.refetch();
  };

  const updateStatus = (ids: string[], status: BOOKING_STATUS) => {
    const updated = nodes.map((node) => (ids.includes(node.id) ? { ...node, status } : node));
    setNodes(updated);
  };

  useEffect(() => {
    // Requested by type
    if (requests?.data?.shift_applications) {
      const { pageInfo, totalCount, nodes } = requests.data.shift_applications;
      setPageInfo(pageInfo);
      setTotalCount(totalCount);
      if (!requests.loading && !isFetchingMore) {
        setNodes(nodes);
      }
    }
    // Requested by bookingID
    if (requests?.data?.shift) {
      const { shift_applications, ...shift } = requests.data.shift;
      const appCounts: { [key: string]: number } = { ...initialCounts };
      const applications = orderBy(
        shift_applications.filter(
          ({ dancer }: { dancer: Dancer }) => !search || dancer.name.toLowerCase().includes(search.toLowerCase())
        ),
        (app) => app.dancer.name,
        [sort]
      ).map((application: ShiftApplication) => {
        const key = getApplicationCountKey(application);
        appCounts[`${key}_count`]++;
        return {
          ...application,
          shift,
        };
      });
      setApplicationCounts(appCounts);
      setNodes(applications);
    }
  }, [requests?.data, requests?.loading, isFetchingMore, sort, search]);

  useEffect(() => {
    const countKey = status === BOOKING_STATUS.INVITED ? 'invited_pending' : status.toLowerCase();
    if (
      counts?.data?.shift_application_counts &&
      totalCount !== counts.data.shift_application_counts[`${countKey}_count`]
    ) {
      counts.refetch();
    }
    setApplicationCounts(counts?.data?.shift_application_counts);
  }, [totalCount, counts?.data?.shift_application_counts, counts?.refetch]);

  return {
    counts: {
      data: getFormattedApplicationCounts(!bookingId, applicationCounts),
      refetch: counts.refetch,
    },
    requests: {
      pageInfo,
      fetchMore: fetchMoreRequests.bind(this, requests.fetchMore, { cursor: pageInfo.endCursor }),
      nodes: filterApplications(bookingId, status, nodes, filter),
      error: requests.error || fetchMoreError,
      isLoading: requests.loading,
      isFetchingMore: isFetchingMore,
      updateStatus,
      removeById,
      refetch: requests.refetch,
    },
  };
};

export default useApplications;
