import React, { useRef } from 'react';
import * as PropTypes from 'prop-types';

import S from './modal-pre-approve.module.scss';
import { ModalPreApproveForm } from './modal-pre-approval-form';
import { CloseButton } from '../../common/buttons/close-button';
import { useOverlayClick } from '../../../helpers/hooks/use-overlay-click';
import { useTranslation } from 'react-i18next';

const ModalPreApprove = ({ onClose, groupId }) => {
  const { t } = useTranslation();
  const MODAL_REF = useRef();
  useOverlayClick(MODAL_REF, onClose);

  return (
    <div ref={MODAL_REF} className={S.preApproveModal}>
      <CloseButton onClick={onClose} />
      <h3 className={S.modalHeading}>{t('modals.group_pre_approve.pre_approve_group')}</h3>
      <ModalPreApproveForm {...{ onClose, groupId }} />
    </div>
  );
};

ModalPreApprove.displayName = 'ModalPreApprove';
ModalPreApprove.propTypes = {
  onClose: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
};

export { ModalPreApprove };
