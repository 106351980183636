import * as Constants from './modal-booking-constants';

export const setBookingField = (field = '', payload) => ({
  type: Constants.SET_BOOKING_FORM_KEY,
  field,
  payload
});

export const setBookingError = (field = '', payload) => ({
  type: Constants.SET_BOOKING_ERROR_KEY,
  field,
  payload
});

export const setFormFields = (fields = {}) => ({
  type: Constants.SET_MANY_BOOKING_FIELDS,
  field: 'form',
  payload: fields
});

export const setMetaFields = (fields = {}) => ({
  type: Constants.SET_MANY_BOOKING_FIELDS,
  field: 'meta',
  payload: fields
});

export const setErrorFields = (fields = {}) => ({
  type: Constants.SET_MANY_BOOKING_FIELDS,
  field: 'errors',
  payload: fields,
});
