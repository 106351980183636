import { useApolloClient } from '@apollo/react-hooks';
import React, { useRef, useState } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import CategoryCreateForm from './modal-create.form';
import { CategoryDeleteForm } from './modal-delete-form';
import S from './modal-course-category.module.scss';
import { Tabs } from '../../common/tabs';
import { CloseButton } from '../../common/buttons/close-button';
import { useOverlayClick } from '../../../helpers/hooks/use-overlay-click';
import { COURSES_CATEGORY_GQL } from '../../../graphql/queries/course-category-query';

const ModalCourseCategory = ({ onClose }) => {
  const { t } = useTranslation();
  const MODAL_REF = useRef();
  useOverlayClick(MODAL_REF, onClose);
  const [activeTab, setActiveTab] = useState('CREATE');

  const CLIENT = useApolloClient();
  const { course_categories } = CLIENT.readQuery({ query: COURSES_CATEGORY_GQL });

  return (
    <div ref={MODAL_REF} className={S.modalCourseCategory}>
      <CloseButton onClick={onClose} />
      <h3 className={S.modalHeading}>{t('modals.course.edit_categories')}</h3>
      <Tabs
        activeTab={activeTab}
        handleTabClick={(tab) => setActiveTab(tab)}
        tabs={[
          { key: 'CREATE', label: t('common.create') },
          { key: 'DELETE', label: t('common.delete') },
        ]}
      />
      {activeTab === 'CREATE' && <CategoryCreateForm onClose={onClose} categories={course_categories} />}
      {activeTab === 'DELETE' && <CategoryDeleteForm onClose={onClose} categories={course_categories} />}
    </div>
  );
};

ModalCourseCategory.displayName = 'ModalCourse';
ModalCourseCategory.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export { ModalCourseCategory };
