import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import CommentItem from './comment-item';

import { GetstreamGSComment } from 'types/getstream';

interface Props {
  comments: GetstreamGSComment[];
  rejected: GetstreamGSComment[];
}

const CommentList: FC<Props> = ({ comments, rejected }) => {
  const { t } = useTranslation();
  const mergedComments = [...comments, ...rejected.filter(({ parent }) => !parent)].sort(
    (a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
  );

  return (
    <div className='mt-4'>
      {t('admin_nav.comments')}:
      {mergedComments.map((comment) => {
        const replies: GetstreamGSComment[] = comment.latest_children?.comment || [];
        const mergedReplies = replies.concat(rejected.filter(({ parent }) => parent === comment.id));
        const likes = comment.latest_children?.like?.length || 0;
        mergedReplies.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());
        return (
          <React.Fragment key={comment.id}>
            <CommentItem comment={comment} likes={likes} />
            <div className='ml-12'>
              {mergedReplies.length > 0 &&
                mergedReplies.map((comment, idx) => (
                  <CommentItem key={idx} comment={comment} likes={comment.latest_children?.like?.length || 0} />
                ))}
            </div>
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default CommentList;
