import * as React from 'react';

import { GroupDetailsPage } from './component/group-details-page';

const SET_GROUP_FIELD = 'SET_GROUP_FIELD';

//DATA WILL NOT PERSIST
export const GROUP_INIT_STATE = {
  selected: [],
  filter: '',
  isAsc: true,
};

export const setGroupField  = (field, payload) => ({ type: SET_GROUP_FIELD, field, payload });

function pageGroupDetailReducer(state, { type, field, payload }) {
  switch (type) {
    case SET_GROUP_FIELD:
      return {...state, [field]: payload};

    default:
      throw new Error(`Unsupported action type: ${type}`);
  }
}

const PAGE_BOOKING_CTX = React.createContext();


export function PageGlobalProvider(props) {
  const [state, dispatch] = React.useReducer(pageGroupDetailReducer, GROUP_INIT_STATE);
  const value = React.useMemo(() => [state, dispatch], [state]);

  return (
    <PAGE_BOOKING_CTX.Provider value={value} {...props} >
      <GroupDetailsPage />
    </PAGE_BOOKING_CTX.Provider>
  );
}

export function useGroupCtx() {
  const context = React.useContext(PAGE_BOOKING_CTX);

  if (!context) {
    throw new Error(`${useGroupCtx.name} must be used within a ${pageGroupDetailReducer.name}`);
  }

  const [state, dispatch] = context;
  return { state, dispatch };
}
