import { GETSTREAM_ADMIN_POST_STATUS } from 'helpers/enums';

const { PENDING, PUBLISHED, CANCELLED, PAUSED } = GETSTREAM_ADMIN_POST_STATUS;

export const colorMap = {
  [PENDING]: 'yellow',
  [PUBLISHED]: 'green',
  [CANCELLED]: 'red',
  [PAUSED]: 'gray',
};
export const labelMap = {
  [PENDING]: 'PENDING',
  [PUBLISHED]: 'PUBLISHED',
  [CANCELLED]: 'CANCELLED',
  [PAUSED]: 'PAUSED',
};
