import * as PropTypes from 'prop-types';
import ColorButton from '../color-button';
import { ButtonSpinner } from 'components/common/button_spinner';
import { useTranslation } from 'react-i18next';

const LoadMoreButton = ({ disabled, isLoading, onClick }) => {
  const { t } = useTranslation();
  return (
    <ColorButton color='blue' type='filled' onClick={onClick} disabled={disabled || isLoading}>
      {isLoading ? (
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
          {t('common.loading')}&nbsp;&nbsp;
          <ButtonSpinner />
        </div>
      ) : (
        t('common.load_more')
      )}
    </ColorButton>
  );
};

LoadMoreButton.propTypes = {
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

export default LoadMoreButton;
