import { gql } from 'apollo-boost';

export const DELETE_SHIFT_GQL = gql`
    mutation DeleteBooking(
        $bookingId: ID!
        $recurring: Boolean
        $separateSeries: Boolean
    ){
        shift_delete(input:{
            shiftId: $bookingId
            deleteRecurring: $recurring
            detachFromSiblingRecurrences: $separateSeries
        }) { clientMutationId }
    }
`;
