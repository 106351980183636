import React, { useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';

import S from './employee-search-page.module.scss';
import { ManagerTable } from './manager-table';
import { RouteConstants } from '../../../routes/route-constants';
import { AlphabetizeBtn } from '../../../common/buttons/alphabetize-btn';
import { LoadingDiscoWithContainer } from '../../../common/loading/loading-disco';
import { SearchInput } from '../../../common/search-input';
import { useModal } from '../../../global-hooks';
import { useUser, useBusiness } from '../../../../graphql/graph-hooks';
import { BUSINESS_EMPLOYEES_GQL } from '../../../../graphql/queries/employee-list-query';
import { UI_MODALS } from '../../../../helpers/enums';
import { ClosedLabel } from 'components/pages-login/clubs/closed-label/closed-label';

const EmployeeSearchPage = () => {
  const { t } = useTranslation();

  const { id: businessId, closed } = useBusiness();

  const { setModal } = useModal();
  const [search, setSearch] = useState('');
  const [isAsc, setIsAsc] = useState(true);
  const { admin } = useUser();
  const handleSearchChange = useCallback((e) => setSearch(e.target.value), [setSearch]);

  const handleExistingEmployeeBtn = useCallback(() => setModal(UI_MODALS.EXISTING_MODAL), [setModal]);

  const CAN_EDIT = admin || !closed;

  const { data, loading, error } = useQuery(BUSINESS_EMPLOYEES_GQL, {
    fetchPolicy: 'network-only',
    variables: { businessId },
  });

  if (loading) {
    return (
      <div className={S.pageWrapper}>
        <LoadingDiscoWithContainer />
      </div>
    );
  }
  if (error) {
    return (
      <div className={S.pageWrapper}>
        <h1 className={S.errorMessage}>{t('errors.generic')}</h1>
      </div>
    );
  }

  return (
    <div className={S.employeeSearchWrapper}>
      {closed && <ClosedLabel />}
      <header className={S.employeeSearchHeader}>
        <div className={S.left}>
          <h1 className={S.h1Heading}>{t('common.employees')}</h1>
          {CAN_EDIT && (
            <>
              <Link className={S.addEmployeeLink} to={RouteConstants.employeeNew}>
                {t('common.add_new')}
              </Link>
              <button className={S.invertedBtnGreen} onClick={handleExistingEmployeeBtn}>
                {t('common.add_existing')}
              </button>
            </>
          )}
        </div>
        <div className={S.right}>
          <AlphabetizeBtn isAsc={isAsc} handleClick={() => setIsAsc(!isAsc)} />

          <SearchInput placeholder={t('common.search_employees')} gridArea='filter' onChange={handleSearchChange} />
        </div>
      </header>

      <ManagerTable
        isAsc={isAsc}
        search={search}
        businessId={businessId}
        list={data?.business?.employments.filter(({ employee }) => employee)}
        canEdit={CAN_EDIT}
      />
    </div>
  );
};

EmployeeSearchPage.displayName = 'EmployeeSearchPage';

export { EmployeeSearchPage };
