import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import * as Icons from 'react-icons/all';

import { useBusiness } from 'graphql/graph-hooks';

import { BOOKING_STATUS } from 'helpers/enums';
import { ShiftApplication } from '../types';

import { Time } from '../helpers';

const Times: FC<Partial<ShiftApplication>> = ({
  status,
  is_invite,
  accepted_by,
  checked_in_at,
  checked_in_by,
  checked_out_at,
  checked_out_by,
  marked_missed_at,
  marked_missed_by,
}) => {
  const { timezone } = useBusiness();
  const { t } = useTranslation();
  return (
    <div className='mt-2'>
      {accepted_by && (
        <p className='mt-0'>
          {t(`common.${status === BOOKING_STATUS.REJECTED ? 'rejected' : 'accepted'}`)} {t('common.by')}:{' '}
          <span className='text-business-purple'>{accepted_by.name}</span>
        </p>
      )}
      {checked_in_at && (
        <p className='mt-0'>
          {t('common.checked_in')}: <span className='text-business-purple'>{Time(checked_in_at, timezone)}</span>{' '}
          {`${t('common.by')}`} <span className='text-business-purple'>{checked_in_by?.name}</span>
        </p>
      )}
      {checked_out_at && (
        <p className='mt-0'>
          {t('common.checked_out')}: <span className='text-business-purple'>{Time(checked_out_at, timezone)}</span>{' '}
          {`${t('common.by')}`} <span className='text-business-purple'>{checked_out_by?.name}</span>
        </p>
      )}
      {marked_missed_at && (
        <p className='mt-0'>
          {t('common.marked_missed')}: <span className='text-business-purple'>{Time(marked_missed_at, timezone)}</span>{' '}
          {`${t('common.by')}`} <span className='text-business-purple'>{marked_missed_by?.name}</span>
        </p>
      )}
      {is_invite && <Icons.GiMegaphone size='20' title={t('common.invited')} />}
    </div>
  );
};

export default Times;
