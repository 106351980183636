import { FC } from 'react';
import { GetstreamComment } from 'types/getstream';
import CommentItem from './CommentItem';
import { useTranslation } from 'react-i18next';

interface Props {
  data: GetstreamComment[];
  onApprove: (id: string) => void;
  onReject: (id: string) => void;
  onView: (id: string) => void;
}

const CommentList: FC<Props> = ({ data, onApprove, onReject, onView }) => {
  const { t } = useTranslation();
  if (data.length === 0) {
    return <div className='text-center'> {t('social.comments.empty_list')} </div>;
  }

  return (
    <div className='my-10'>
      {data.map((item: GetstreamComment) => (
        <CommentItem comment={item} key={item.id} onApprove={onApprove} onReject={onReject} onView={onView} />
      ))}
    </div>
  );
};

export default CommentList;
