import React from 'react';
import PostItem from './PostItem';
import { GetstreamPost } from '../../../../types/getstream';
import { useTranslation } from 'react-i18next';

interface Props {
  data: GetstreamPost[];
  onApprove: (id: string) => void;
  onReject: (id: string) => void;
  onView: (id: string) => void;
  onDelete: (id: string) => void;
  onToggleComments: (event: React.MouseEvent<HTMLInputElement>) => Promise<void>;
}

const PostList: React.FC<Props> = ({ data, onView, onApprove, onReject, onDelete, onToggleComments }) => {
  const { t } = useTranslation();
  if (data.length === 0) {
    return <div className='mt-10 text-center'>{t('social.posts.empty_list')}</div>;
  }

  return (
    <div className='my-10'>
      {data.map((post: GetstreamPost) => (
        <PostItem
          post={post}
          key={post.id}
          onApprove={onApprove}
          onReject={onReject}
          onDelete={onDelete}
          onView={onView}
          onToggleComments={onToggleComments}
        />
      ))}
    </div>
  );
};

export default PostList;
