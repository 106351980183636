import React from 'react';
import i18next from 'i18next';

import ColorButton from '../../../../common/buttons/color-button';

import { GETSTREAM_ADMIN_POST_STATUS } from 'helpers/enums';
import { GetstreamPost, GetstreamScheduledPostActionPayload } from 'types/getstream';

const { PUBLISHED, PENDING, PAUSED, CANCELLED } = GETSTREAM_ADMIN_POST_STATUS;
import { ColorButtonColors, ColorButtonTypes } from '../../../../common/buttons/color-button';

const { t } = i18next;

interface ButtonProps {
  id: string;
  action: (payload: GetstreamScheduledPostActionPayload) => void;
  color: ColorButtonColors;
  type: ColorButtonTypes;
  text: string;
}

const ActionButton: React.FC<ButtonProps> = ({ id, action, type, color, text }) => {
  const handleOnClick = () => {
    action({ variables: { input: { id } } });
  };
  return (
    <ColorButton color={color} type={type} className='uppercase w-24' onClick={handleOnClick}>
      {text}
    </ColorButton>
  );
};

interface ActionButtonsProps {
  id: string;
  status: keyof typeof GETSTREAM_ADMIN_POST_STATUS;
  onEdit: (payload: GetstreamScheduledPostActionPayload) => void;
  onPause: (payload: GetstreamScheduledPostActionPayload) => void;
  onUnpause: (payload: GetstreamScheduledPostActionPayload) => void;
  onCancel: (payload: GetstreamScheduledPostActionPayload) => void;
  onDelete: (payload: GetstreamScheduledPostActionPayload) => void;
  onClone: (payload: GetstreamScheduledPostActionPayload) => void;
  onView: (data: GetstreamScheduledPostActionPayload) => void;
}

export const ActionButtons: React.FC<ActionButtonsProps> = ({
  id,
  status,
  onEdit,
  onPause,
  onUnpause,
  onCancel,
  onDelete,
  onClone,
  onView,
}) => {
  const edit = { action: onEdit, text: t('common.edit'), color: 'blue' };
  const pause = { action: onPause, text: t('social.scheduler.action.pause'), color: 'yellow' };
  const unpause = { action: onUnpause, text: t('social.scheduler.action.unpause'), color: 'green' };
  const cancel = { action: onCancel, text: t('common.cancel'), color: 'red' };
  const remove = { action: onDelete, text: t('common.delete'), color: 'red', type: 'filled' };
  const clone = { action: onClone, text: t('social.scheduler.action.clone'), color: 'blue', type: 'inverted' };
  const view = { action: onView, text: t('common.view'), color: 'blue', type: 'inverted' };

  const actionMap: any = {
    [PENDING]: [edit, pause, clone, cancel],
    [PAUSED]: [edit, unpause, clone, cancel],
    [CANCELLED]: [clone, remove],
    [PUBLISHED]: [view, clone, remove],
  };

  return actionMap[status]
    ? actionMap[status].map(({ action, text, color, type = 'inverted' }: ButtonProps) => {
        return <ActionButton key={`${id}-${text}`} id={id} action={action} color={color} type={type} text={text} />;
      })
    : null;
};
