import React from 'react';
import * as PropTypes from 'prop-types';
import { FaTimes } from 'react-icons/fa';

import S from './close-button.module.scss';

type CloseBtnProps = {
  onClick: () => void;
};

const CloseButton: React.FC<CloseBtnProps> = ({ onClick }): React.ReactElement => {
  return (
    <button className={S.closeBtn} type='button' onClick={onClick}>
      <FaTimes />
    </button>
  );
};

CloseButton.displayName = 'CloseButton';

CloseButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export { CloseButton };
