import { useRef } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { CloseButton } from '../../common/buttons/close-button';
import { useOverlayClick } from '../../../helpers/hooks/use-overlay-click';

import S from './modal-confirmation.module.scss';

const ModalConfirmation = ({ onClose, title, message, onConfirmation }) => {
  const { t } = useTranslation();

  title = title || t('common.are_you_sure');

  const MODAL_REF = useRef(null);

  useOverlayClick(MODAL_REF, onClose);

  return (
    <div className={S.modal} ref={MODAL_REF}>
      <CloseButton onClick={onClose} />
      <h3 className={S.modalHeading}>{title}</h3>

      <div className={S.message}>{message}</div>

      <div className={S.buttonRow}>
        <button className={S.invertedBtnGreen} type='button' onClick={onConfirmation}>
          {t('common.confirm')}
        </button>
        <button className={S.invertedBtnRed} type='button' onClick={onClose}>
          {t('common.cancel')}
        </button>
      </div>
    </div>
  );
};

ModalConfirmation.displayName = 'ModalConfirmation';
ModalConfirmation.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onConfirmation: PropTypes.func.isRequired,
};

export { ModalConfirmation };
