import { gql } from 'apollo-boost';

export const INVITE_CODE_REQUEST_ACCEPT = gql`
  mutation InviteCodeRequestAccept($input: InviteCodeRequestAcceptInput!) {
    invite_code_request_accept(input: $input) {
      invite_code_request {
        id
        invite_code {
          id
          code
        }
        status
      }
    }
  }
`;
export const INVITE_CODE_REQUEST_REJECT = gql`
  mutation InviteCodeRequestsReject($input: InviteCodeRequestRejectInput!) {
    invite_code_request_reject(input: $input) {
      invite_code_request {
        id
        invite_code {
          id
          code
        }
        status
        notes
      }
    }
  }
`;
