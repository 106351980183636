import * as React from 'react';
import * as PropTypes from 'prop-types';
import ImageGallery from 'react-image-gallery';
import { useTranslation } from 'react-i18next';

import S from './modal-verification-images.module.scss';
import { Image } from '../../common/images/img-file';
import defaultUserPic from '../../../assets/user-placeholder-mask.png';

const ModalVerificationImages = ({ images, name, sentence, test_img }) => {
  const { t } = useTranslation();
  return (
    <div className={S.imageArea}>
      <div className={S.carouselArea}>
        {images ? (
          <ImageGallery
            showBullets
            showFullscreenButton={false}
            showPlayButton={false}
            onErrorImageURL={defaultUserPic}
            items={images.map(({ thumb: thumbnail, medium: original }) => ({ original, thumbnail }))}
          />
        ) : (
          <Image alt={name} fallback='USER' className={S.defaultImg} />
        )}
      </div>

      <div className={S.centerWrapper}>
        <Image src={test_img} fallback='USER' classname={S.testImg} alt={`${name}'s verification`} />

        <div className={S.sentenceArea}>
          <h4>{t('modals.verification.sentence')}:</h4>
          <p>{t(`verificationPage.sentence.${sentence}`)}</p>
        </div>
      </div>
    </div>
  );
};

ModalVerificationImages.displayName = 'ModalVerificationImages';
ModalVerificationImages.propTypes = {
  name: PropTypes.string.isRequired,
  images: PropTypes.array.isRequired,
  sentence: PropTypes.string.isRequired,
  test_img: PropTypes.string.isRequired,
};

export { ModalVerificationImages };
