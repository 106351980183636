import React, { useCallback } from 'react';
import * as PropTypes from 'prop-types';

import { ModalDancer } from './modal-dancer/modal-dancer';
import { ModalCourse } from './modal-course/modal-course';
import { ModalCourseCategory } from './modal-course-category/modal-course-category';
import { EmailModal } from './modal-email/email-modal';
import { ModalExistingWrapper } from './modal-existing-employee/component/modal-existing-wrapper';
import { ModalGroupAddDancers } from './modal-group-add-dancers/modal-group-add-dancers';
import { ModalManageDancerGroups } from './modal-manage-dancer-groups/modal-manage-dancer--groups';
import { ModalGroupCreate } from './modal-group-create/modal-group-create';
import { ModalPreApprove } from './modal-group-pre-approve/modal-pre-approve';
import { ModalInviteDancers } from './modal-invite/modal-invite';
import { ModalInviteCode } from './modal-invite-code/modal-invite-code';
import { ModalPreviousBookings } from './modal-previous-bookings/modal-previous-bookings';
import { ModalNotification } from './modal-message/modal-notification';
import { ModalAddOrganization } from './modal-organization-add/modal-add-organization';
import { ModalSuspendDancer } from './modal-suspend/modal-suspend-dancer';
import { ModalBug } from './modal-bug/modal-bug';
import { ModalVideo } from './modal-video/modal-video';
import { ModalConfirmation } from './modal-confirmation/modal-confirmation';
import { ModalSocialPost } from './modal-social-post/modal-social-post';
import { ModalImageCrop } from './modal-image-crop/modal-image-crop';
import { ModalVerification } from './modal-verification/modal-verification';
import { ModalUnverify } from './modal-unverify';
import ModalGeneric from './modal-generic';
import { useModal } from '../global-hooks';
import { UI_MODALS } from '../../helpers/enums';
import { useGlobalCtx } from '../global-context';

const ModalSwitcher = ({ type, ctx }) => {
  const { initModal, closeModal } = useModal();
  const {
    state: { modal },
  } = useGlobalCtx();
  const handleClose = useCallback(() => {
    const { type, context } = modal.history?.length && modal.history[modal.history.length - 1];
    if (type) {
      initModal(type, context, true);
    } else {
      closeModal();
    }
  }, [initModal, modal]);

  if (type === UI_MODALS.DANCER) {
    return <ModalDancer onClose={handleClose} {...ctx} />;
  } else if (type === UI_MODALS.COURSE_FORM) {
    return <ModalCourse onClose={handleClose} {...ctx} />;
  } else if (type === UI_MODALS.VIDEO_PLAYER) {
    return <ModalVideo onClose={handleClose} {...ctx} />;
  } else if (type === UI_MODALS.COURSE_CATEGORY) {
    return <ModalCourseCategory onClose={handleClose} />;
  } else if (type === UI_MODALS.EMAIL_INVITES) {
    return <EmailModal onClose={handleClose} />;
  } else if (type === UI_MODALS.MANAGE_DANCER_GROUPS) {
    return <ModalManageDancerGroups onClose={handleClose} {...ctx} />;
  } else if (type === UI_MODALS.ADD_TO_GROUP) {
    return <ModalGroupAddDancers onClose={handleClose} {...ctx} />;
  } else if (type === UI_MODALS.CREATE_GROUP) {
    return <ModalGroupCreate onClose={handleClose} {...ctx} />;
  } else if (type === UI_MODALS.INVITE_CODE) {
    return <ModalInviteCode onClose={handleClose} {...ctx} />;
  } else if (type === UI_MODALS.INVITE_DANCER) {
    return <ModalInviteDancers onClose={handleClose} {...ctx} />;
  } else if (type === UI_MODALS.PRE_APPROVED) {
    return <ModalPreApprove onClose={handleClose} {...ctx} />;
  } else if (type === UI_MODALS.SUSPEND_DANCER) {
    return <ModalSuspendDancer onClose={handleClose} {...ctx} />;
  } else if (type === UI_MODALS.EXISTING_MODAL) {
    return <ModalExistingWrapper onClose={handleClose} />;
  } else if (type === UI_MODALS.BLAST_NOTIFY) {
    return <ModalNotification onClose={handleClose} {...ctx} />;
  } else if (type === UI_MODALS.ADD_TO_ORG) {
    return <ModalAddOrganization onClose={handleClose} />;
  } else if (type === UI_MODALS.BUG) {
    return <ModalBug onClose={handleClose} {...ctx} />;
  } else if (type === UI_MODALS.PREVIOUS_BOOKINGS) {
    return <ModalPreviousBookings onClose={handleClose} {...ctx} />;
  } else if (type === UI_MODALS.CONFIRMATION) {
    return <ModalConfirmation onClose={handleClose} {...ctx} />;
  } else if (type === UI_MODALS.SOCIAL_POST) {
    return <ModalSocialPost onClose={handleClose} {...ctx} />;
  } else if (type === UI_MODALS.IMAGE_CROP) {
    return <ModalImageCrop onClose={handleClose} {...ctx} />;
  } else if (type === UI_MODALS.VERIFICATIONS) {
    return <ModalVerification onClose={handleClose} {...ctx} />;
  } else if (type === UI_MODALS.GENERIC) {
    return <ModalGeneric onClose={handleClose} {...ctx} />;
  } else if (type === UI_MODALS.UNVERIFY) {
    return <ModalUnverify onClose={handleClose} {...ctx} />;
  } else return null;
};

ModalSwitcher.displayName = 'ModalSwitcher';
ModalSwitcher.propTypes = {
  type: PropTypes.string.isRequired,
  ctx: PropTypes.object,
};

ModalSwitcher.defaultProps = {
  ctx: {},
};

export { ModalSwitcher };
