import { gql } from 'apollo-boost';

export const CREATE_BUG = gql`
    mutation(
        $description: String!
        $version: String!
    ){
        bug_report_create(
            input:{
                bugReport:{
                    osType: "web"
                    appVersion:  $version
                    description: $description
                }
            }
        ){
            clientMutationId
        }
    }
`;
