import { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { createColumnHelper, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import posthog from 'posthog-js';
import { LoadingDisco, LoadingDiscoWithContainer } from 'components/common/loading/loading-disco';
import { InviteCodeRequest } from 'types';
import { GET_INVITE_CODE_REQUEST_LIST, RESEND_INVITE_CODE_EMAIL } from 'graphql/queries/invite-list';
import { logError } from 'helpers/errors/bug-report';
import { ModalInviteAccept } from './modal_invite_accept';
import { ModalInviteReject } from './modal_invite_reject';
import moment from 'moment';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import { INVITE_CODE_STATUSES } from '../../../helpers/enums';
import { useTranslation } from 'react-i18next';

import PageContainer from 'components/common/page/page-container';
import PageHeader from 'components/common/page/page-header';
import PageBody from 'components/common/page/page-body';

const InviteRequestsPage = () => {
  const { t } = useTranslation();
  const [statusView, setStatusView] = useState<string>(INVITE_CODE_STATUSES.PENDING);
  const [pageInfo, setPageInfo] = useState({ endCursor: '', hasNextPage: false });
  const [selectedCodeRequest, setSelectedCodeRequest] = useState<InviteCodeRequest>({
    id: '0',
    name: '',
    email: '',
    phone_number: '',
    message: '',
    created_at: '',
    city: '',
    invite_code: { id: '0', code: '', promotional: false, users_count: 0 },
    status: INVITE_CODE_STATUSES.PENDING,
  } as InviteCodeRequest);

  const [showInviteModal, setShowInviteModal] = useState(false);
  const [showRejectModal, setShowRejectModal] = useState(false);

  const { data, loading, called, fetchMore } = useQuery<{
    invite_code_requests: {
      totalCount: number;
      pageInfo: { startCursor: string; endCursor: string; hasNextPage: boolean; hasPreviousPage: boolean };
      nodes: InviteCodeRequest[];
    };
  }>(GET_INVITE_CODE_REQUEST_LIST, {
    fetchPolicy: 'network-only',
    variables: {
      first: 25,
      status: statusView,
    },
    onCompleted: (data) => setPageInfo(data.invite_code_requests.pageInfo),
    onError: (err) => logError(err, 'GET_INVITE_CODE_REQUEST_LIST', InviteRequestsPage.displayName),
  });

  const [resendInviteCodeRequest, { loading: isSubmitting }] = useMutation(RESEND_INVITE_CODE_EMAIL, {
    onCompleted: () => {
      toast.success(t('inviteRequestsPage.resend.success'));
    },
    onError: (err: Error) => {
      logError(err, 'RESEND_INVITE_CODE_EMAIL', InviteRequestsPage.displayName);
      toast.error(t('inviteRequestsPage.resend.success'));
    },
  });

  posthog.capture('InviteRequestsPage');

  const columnHelper = createColumnHelper<InviteCodeRequest>();

  const columns = [
    columnHelper.accessor('name', { header: t('common.name') }),
    columnHelper.accessor('city', { header: t('common.city') }),
    columnHelper.accessor('email', { header: t('common.email_address') }),
    columnHelper.accessor('phone_number', { header: t('common.phone') }),
    columnHelper.accessor('message', { header: t('common.message') }),
    columnHelper.accessor('affiliated_clubs', { header: t('common.clubs') }),
    columnHelper.display({
      id: 'actions',
      cell: ({ row }) => {
        if (row.original.status === INVITE_CODE_STATUSES.PENDING) {
          return (
            <>
              <button
                type='button'
                className='m-4 w-32 items-center rounded border border-transparent bg-green-600 px-2.5 py-0.5 text-xs font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2'
                onClick={(e) => {
                  e.preventDefault();
                  setSelectedCodeRequest(row.original);
                  setShowInviteModal(true);
                }}
                onMouseEnter={() => {
                  setSelectedCodeRequest(row.original);
                }}
              >
                {t('common.invite')}
              </button>
              <button
                type='button'
                className='m-4 w-32 items-center rounded border border-transparent bg-red-600 px-2.5 py-0.5 text-xs font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2'
                onClick={(e) => {
                  e.preventDefault();
                  setSelectedCodeRequest(row.original);
                  setShowRejectModal(true);
                }}
                onMouseEnter={() => {
                  setSelectedCodeRequest(row.original);
                }}
              >
                {t('common.reject')}
              </button>
            </>
          );
        } else {
          return (
            <>
              <div className='text-white text-center'>
                {t(`common.${row.original.status.toLowerCase()}`).toUpperCase()} -{' '}
                {moment(row.original.updated_at).format('YYYY-MM-DD HH:mm')}
              </div>
              {row.original.status === INVITE_CODE_STATUSES.ACCEPTED ? (
                <>
                  <div className='text-white text-center'>{row.original.invite_code?.code}</div>
                  <div className='flex justify-center'>
                    <button
                      type='button'
                      disabled={isSubmitting}
                      className='m-4 w-32 items-center rounded border border-transparent bg-blue-600 px-2.5 py-0.5 text-xs font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2'
                      onClick={(e) => {
                        e.preventDefault();
                        resendInviteCodeRequest({ variables: { input: { id: row.original.id } } });
                      }}
                      onMouseEnter={() => {
                        setSelectedCodeRequest(row.original);
                      }}
                    >
                      {t('inviteRequestsPage.send_again')}
                    </button>
                  </div>
                </>
              ) : (
                <div className='text-white text-center text-xs'>{row.original.notes}</div>
              )}
            </>
          );
        }
      },
    }),
  ];

  const table = useReactTable({
    data: data?.invite_code_requests.nodes ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const getMore = async () =>
    await fetchMore({
      variables: {
        first: 20,
        status: statusView,
        after: pageInfo.endCursor,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) return previousResult;

        setPageInfo(fetchMoreResult.invite_code_requests.pageInfo);

        return {
          invite_code_requests: {
            ...fetchMoreResult.invite_code_requests,
            nodes: [...previousResult.invite_code_requests.nodes, ...fetchMoreResult.invite_code_requests.nodes],
          },
        };
      },
    });

  return (
    <PageContainer>
      <PageHeader title={t('inviteRequestsPage.title')}>
        <div>
          <span className='isolate inline-flex rounded-md shadow-sm'>
            <button
              type='button'
              onClick={() => setStatusView(INVITE_CODE_STATUSES.PENDING)}
              className={classNames(
                'relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500',
                statusView === INVITE_CODE_STATUSES.PENDING && 'bg-indigo-200'
              )}
            >
              {t('common.pending')}
            </button>
            <button
              type='button'
              onClick={() => setStatusView(INVITE_CODE_STATUSES.ACCEPTED)}
              className={classNames(
                'relative -ml-px inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500',
                statusView === INVITE_CODE_STATUSES.ACCEPTED && 'bg-indigo-200'
              )}
            >
              {t('common.accepted')}
            </button>
            <button
              type='button'
              onClick={() => setStatusView(INVITE_CODE_STATUSES.REJECTED)}
              className={classNames(
                'relative -ml-px inline-flex items-center rounded-r-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500',
                statusView === INVITE_CODE_STATUSES.REJECTED && 'bg-indigo-200'
              )}
            >
              {t('common.rejected')}
            </button>
          </span>
        </div>
      </PageHeader>
      <PageBody isLoading={loading}>
        <div className='mt-8 flex flex-col'>
          <div className='-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8'>
            <div className='inline-block min-w-full py-2 align-middle px-10'>
              <div className='overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg'>
                <table className='min-w-full divide-y divide-gray-300'>
                  <thead className='bg-gray-700'>
                    {table.getHeaderGroups().map((headerGroup) => (
                      <tr key={headerGroup.id}>
                        {headerGroup.headers.map((header) => (
                          <th key={header.id} className='px-3 py-3.5 text-left text-sm font-semibold text-gray-300'>
                            {header.isPlaceholder
                              ? null
                              : flexRender(header.column.columnDef.header, header.getContext())}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody>
                    {table.getRowModel().rows.map((row, idx) => (
                      <tr key={row.id} className={idx % 2 === 0 ? '' : 'bg-slate-800'}>
                        {row.getVisibleCells().map((cell) => (
                          <td key={cell.id} className='whitespace-nowrap px-3 py-4 text-sm text-gray-300'>
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                  <tfoot>
                    {table.getFooterGroups().map((footerGroup) => (
                      <tr key={footerGroup.id}>
                        {footerGroup.headers.map((header) => (
                          <th key={header.id}>
                            {header.isPlaceholder
                              ? null
                              : flexRender(header.column.columnDef.footer, header.getContext())}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div className='flex mx-auto justify-center my-8'>
          {!called && loading ? (
            <LoadingDisco />
          ) : (
            !loading &&
            pageInfo.hasNextPage && (
              <button
                onClick={getMore}
                className='finline px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
              >
                {t('common.load_more')}
              </button>
            )
          )}
        </div>
      </PageBody>

      <ModalInviteAccept
        isOpen={showInviteModal}
        closeModal={() => setShowInviteModal(false)}
        codeRequest={selectedCodeRequest}
      />
      <ModalInviteReject
        isOpen={showRejectModal}
        closeModal={() => setShowRejectModal(false)}
        codeRequest={selectedCodeRequest}
      />
    </PageContainer>
  );
};

InviteRequestsPage.displayName = 'InviteRequestsPage';

export { InviteRequestsPage };
