import * as PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import S from './employee-invites-page.module.scss';
import { InviteList } from './employee-invites-list';
import { useModal } from '../../../global-hooks';
import { useUser, useBusiness } from '../../../../graphql/graph-hooks';
import { PERMISSIONS, UI_MODALS } from '../../../../helpers/enums';
import { useHasPermission } from '../../../../graphql/graph-hooks';
import { ClosedLabel } from 'components/pages-login/clubs/closed-label/closed-label';
import { SearchInput } from 'components/common/search-input';
import { InputFormSelect } from '../../../common/form/form-parts/input-form-select';

const InvitePagePresenter = ({ type }) => {
  const HAS_PERMISSION = useHasPermission(PERMISSIONS.MANAGE_REG_CODES);
  const { setModal, initModal } = useModal();
  const { admin } = useUser();
  const { closed } = useBusiness();
  const { t } = useTranslation();
  const [search, setSearch] = useState('');
  const handleSearchChange = useCallback((e) => setSearch(e.target.value), [setSearch]);
  const [active, setActive] = useState('');
  const handleActiveChange = useCallback((e) => setActive(e.target.value), [setActive]);

  const handleEmailInvite = useCallback(() => setModal(UI_MODALS.EMAIL_INVITES), [setModal]);

  const CAN_EDIT = admin || !closed;

  return (
    <div className={S.pageWrapper}>
      {closed && <ClosedLabel />}
      <header className={S.invitesPageHeader}>
        <h1 className={S.h1Heading}>{t('employeePage.invite_codes')}</h1>
        {HAS_PERMISSION && CAN_EDIT && (
          <button
            className={S.invertedBtnBlue}
            onClick={() => initModal(UI_MODALS.INVITE_CODE, { codeId: null, type, filter: { active, search } })}
          >
            {t('common.new')} {t('employeePage.code').toLowerCase()}
          </button>
        )}
        <SearchInput
          className={S.invitesSearchBar}
          placeholder={t('employeePage.search')}
          onChange={handleSearchChange}
        />
        <div className={S.invitesHeaderSelect}>
          <label htmlFor='sortField'>{t('employeePage.activeSelectLabel')}</label>
          <InputFormSelect
            id='sortField'
            name='sort'
            value={active}
            onChange={handleActiveChange}
            options={[
              { value: 'true', label: t('employeePage.active') },
              { value: 'false', label: t('employeePage.inactive') },
              { value: '', label: t('employeePage.both') },
            ]}
          />
        </div>
        {/* Hide the button, but it might come back, as per sc-5471 */}
        {false && type === 'ADMIN' && (
          <button className={S.invertedBtnBlue} onClick={handleEmailInvite}>
            {t('common.invite_by_email')}
          </button>
        )}
      </header>

      <div className={S.inviteList}>
        <div className={S.invitesTableHeader}>
          <div>
            {t('common.employee')} / {t('employeePage.code')}
          </div>
          {HAS_PERMISSION && <div>{t('common.actions')}</div>}
        </div>

        <InviteList
          hasPermission={type === 'EMPLOYEE' || HAS_PERMISSION}
          type={type}
          canEdit={CAN_EDIT}
          search={search}
          active={active == '' ? undefined : active == 'true'}
        />
      </div>
    </div>
  );
};

InvitePagePresenter.displayName = 'InvitePagePresenter';
InvitePagePresenter.propTypes = {
  type: PropTypes.oneOf(['CLUB', 'ADMIN', 'EMPLOYEE']).isRequired,
};

export { InvitePagePresenter };
