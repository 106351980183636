import moment from 'moment';
import * as PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { FaGlobe, FaBars, FaBug } from 'react-icons/fa';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { UI_MODALS } from '../../helpers/enums';
import { useModal } from '../global-hooks';

import S from './nav.module.scss';
import { AdminLinkArea } from './nav-links/admin-links';
import { IconNavLink } from './nav-links/icon-nav-link';
import { NavClubArea } from './nav-links/nav-club-area';
import { NavPPLogo } from './nav-pp-logo';
import { NavSelectedClubArea } from './nav-selected-club-area';
import { NavUserArea } from './nav-user-area';
import { ExtraLinks } from '../common/links';
import { useGlobalCtx } from '../global-context';
import { RouteConstants } from '../routes/route-constants';
import { useUser } from '../../graphql/graph-hooks';
import { TimePatterns } from '../../helpers/times';

const Nav = () => {
  const { admin } = useUser();
  const {
    state: { app },
  } = useGlobalCtx();
  const { initModal } = useModal();
  const { t } = useTranslation();
  const [showNav, setShowNav] = useState(true);
  const handleNav = useCallback(() => setShowNav(!showNav), [showNav]);
  return (
    <>
      {!showNav && (
        <div className={S.iconWrapper}>
          <FaBars onClick={handleNav} />
        </div>
      )}
      <nav className={classNames(S.navWrapper, { [S.hide]: !showNav })}>
        <>
          <NavPPLogo onCloseButtonClick={handleNav} />
          <NavUserArea />
          <div className={S.manageArea}>
            {admin && (
              <>
                <p>{t('common.admin')}</p>
                <AdminLinkArea {...app} />
              </>
            )}

            <p>{t('common.manage')}</p>
            <IconNavLink text={t('common.clubs')} icon={<FaGlobe size='20' />} path={RouteConstants.clubs} />
          </div>

          <NavSelectedClubArea />
          <NavClubArea />
          <button className={S.bugBtn} onClick={() => initModal(UI_MODALS.BUG)}>
            <FaBug size='20' /> {t('common.report_a_bug')}
          </button>

          <ExtraLinks />
          {app.version && <p className={S.build}>Build: {moment(app.version).format(TimePatterns.timestamp)}</p>}
        </>
      </nav>
    </>
  );
};

Nav.displayName = 'Nav';
Nav.propTypes = { businessId: PropTypes.string };
Nav.defaultProps = { businessId: null };

export { Nav };
