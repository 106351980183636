import { gql } from 'apollo-boost';

import { MESSAGE_FRAGMENT } from '../fragments/message-fragment';

export const GET_MESSAGES = gql`
    ${MESSAGE_FRAGMENT}
    query($chatId: ID!){
        messages(conversationId: $chatId){...messageFragment}
    }
`;

export const GET_MESSAGES_PAGINATED = gql`
    ${MESSAGE_FRAGMENT}
    query($conversationId: ID!, $last: Int!, $before: String){
      messages_paginated(conversationId: $conversationId, last: $last, before: $before){
        nodes {
        ...messageFragment
        }
        pageInfo {
          startCursor
          hasPreviousPage
          hasNextPage
        }
      }
  }
`;