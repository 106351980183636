import { Link } from 'react-router-dom';
import * as PropTypes from 'prop-types';
import * as classNames from 'classnames';
import React, { useCallback } from 'react';
import { FaList, FaGripHorizontal } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

import S from './club-search-header.module.scss';
import { RouteConstants } from '../../../routes/route-constants';
import { AlphabetizeBtn } from '../../../common/buttons/alphabetize-btn';
import { SearchInput } from '../../../common/search-input';
import { useModal } from '../../../global-hooks';
import { UI_MODALS } from '../../../../helpers/enums';
import { useUser } from '../../../../graphql/graph-hooks';

const ClubSearchHeader = ({ isList, filter, setFilter, setIsList, isAsc, setIsAsc }) => {
  const { t } = useTranslation();
  const { setModal } = useModal();
  const { admin } = useUser();

  const handleAddOrg = useCallback(() => setModal(UI_MODALS.ADD_TO_ORG), [setModal]);
  const handleFilter = useCallback((e) => setFilter(e.target.value), [setFilter]);
  const handleView = useCallback((bool) => setIsList(bool), [setIsList]);

  return (
    <div className={S.Header}>
      <div className={S.HeadingLeft}>
        <h1 className={S.h1Heading}>{t('common.clubs')}</h1>
        {admin && (
          <>
            <Link className={S.AddClubLink} to={RouteConstants.newClub}>
              {t('clubPage.add_club')}
            </Link>
            <button className={S.AddOrgBtn} onClick={handleAddOrg}>
              {t('clubPage.add_org')}
            </button>
          </>
        )}
      </div>
      <div className={S.HeadingRight}>
        <AlphabetizeBtn isAsc={isAsc} handleClick={() => setIsAsc(!isAsc)} />

        <button className={classNames(S.IconBtn, { [S.active]: isList })} onClick={() => handleView(true)}>
          <FaList />
        </button>

        <button className={classNames(S.IconBtn, { [S.active]: !isList })} onClick={() => handleView(false)}>
          <FaGripHorizontal />
        </button>

        <SearchInput placeholder={t('clubPage.filter_clubs')} onChange={handleFilter} />
      </div>
    </div>
  );
};

ClubSearchHeader.displayName = 'ClubSearchHeader';

ClubSearchHeader.propTypes = {
  filter: PropTypes.string.isRequired,
  isList: PropTypes.bool.isRequired,
  isAsc: PropTypes.bool.isRequired,
  setFilter: PropTypes.func.isRequired,
  setIsList: PropTypes.func.isRequired,
  setIsAsc: PropTypes.func.isRequired,
};

export { ClubSearchHeader };
