import * as PropTypes from 'prop-types';
import * as React from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';

import S from './pre-approved-wrapper.module.scss';
import { PreApprovedList } from './pre-approved-list';
import { BtnPreApproveGroup } from '../../group-actions/button-pre-approve';
import { useHasPermission } from '../../../../../../../graphql/graph-hooks';
import { GET_PRE_APPROVED_BOOKINGS } from '../../../../../../../graphql/queries/pre-approved-shift-list';
import { PERMISSIONS } from '../../../../../../../helpers/enums';

const PreApprovedBookings = ({ groupId, timezone }) => {
  const { t } = useTranslation();
  const CLIENT = useApolloClient();
  const HAS_PERMISSION = useHasPermission(PERMISSIONS.MANAGE_BOOKINGS);
  const DATA = CLIENT.readQuery({ query: GET_PRE_APPROVED_BOOKINGS, variables: { groupId } });
  const { non_recurring_shifts, recurring_shifts } = DATA.preapproved_shifts;

  return (
    <>
      {HAS_PERMISSION && <BtnPreApproveGroup groupId={groupId} />}
      {non_recurring_shifts.length === 0 && recurring_shifts.length === 0 ? (
        <h3 className={S.noBookingWrapper}>{t('groupsPage.no_bookings')}</h3>
      ) : (
        <ul className={S.bookingListWrapper}>
          <PreApprovedList
            groupId={groupId}
            shifts={non_recurring_shifts}
            timezone={timezone}
            canUpdate={HAS_PERMISSION}
          />

          <PreApprovedList
            groupId={groupId}
            shifts={recurring_shifts}
            timezone={timezone}
            canUpdate={HAS_PERMISSION}
            isRecurring
          />
        </ul>
      )}
    </>
  );
};

PreApprovedBookings.displayName = 'PreApprovedBookings';

PreApprovedBookings.propTypes = {
  groupId: PropTypes.string.isRequired,
  timezone: PropTypes.string.isRequired,
};

export { PreApprovedBookings };
