import * as PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { useModalBookingCtx } from '../../../modal-booking-context';
import { FormSelectField } from '../../../../../common/form/form-select-field';
import { useBusiness } from '../../../../../../graphql/graph-hooks';

const BookingSelectorField = ({ isDisabled, handleChange }) => {
  const { state } = useModalBookingCtx();
  const { t, i18n } = useTranslation();
  const { timezone } = useBusiness();

  const {
    start_time,
    recurring_shift: { siblings },
  } = state.meta.initial;

  const SELECTOR_OPTIONS = useMemo(() => {
    let OPTIONS = [
      { value: '', label: t('bookingCalendar.select_booking_option') },
      { value: 'one', label: t('bookingCalendar.this_booking_only') },
    ];

    if (siblings.length > 1) {
      OPTIONS = [
        ...OPTIONS,
        {
          value: 'series',
          label: t('bookingCalendar.this_booking_every', {
            day: moment.tz(start_time, timezone).locale(i18n.language).format('dddd'),
          }),
        },
      ];
    }
    return [...OPTIONS, { value: 'all', label: t('bookingCalendar.all_bookings_in_series') }];
  }, [siblings, start_time, timezone]);

  return (
    <FormSelectField
      id='edit_booking_selector'
      label={t('bookingCalendar.apply_changes_to')}
      gridArea='occurrence'
      name='selector'
      value={state.form.selector}
      error={t(state.errors.selector)}
      isDisabled={isDisabled}
      options={SELECTOR_OPTIONS}
      handleChange={handleChange}
    />
  );
};

BookingSelectorField.displayName = 'BookingSelectorField';
BookingSelectorField.propTypes = {
  isDisabled: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export { BookingSelectorField };
