import { useRef } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import S from './modal-manage-dancer-groups.module.scss';
import { AddToGroupsForm } from './modal-manage-dancer-groups-form';
import { CloseButton } from '../../common/buttons/close-button';
import { useOverlayClick } from '../../../helpers/hooks/use-overlay-click';

const ModalManageDancerGroups = (props) => {
  const { t } = useTranslation();
  const MODAL_REF = useRef();
  useOverlayClick(MODAL_REF, props.onClose);

  return (
    <div ref={MODAL_REF} className={S.modalAddGroups}>
      <CloseButton onClick={props.onClose} />
      <h3 className={S.modalHeading}>{t('dancerPage.manage_user_groups')}</h3>

      <AddToGroupsForm {...props} />
    </div>
  );
};

ModalManageDancerGroups.displayName = 'ModalManageDancerGroups';

ModalManageDancerGroups.propTypes = {
  onClose: PropTypes.func.isRequired,
  dancerId: PropTypes.string.isRequired,
  businessId: PropTypes.string.isRequired,
};

export { ModalManageDancerGroups };
