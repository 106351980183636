import * as React from 'react';
import * as PropTypes from 'prop-types';

import S from './pre-approved-booking-list-wrapper.module.scss';
import * as TimeHelpers from '../../../helpers/times';
import { useTranslation } from 'react-i18next';

const PreApprovedBookingListWrapper = ({ date, children }) => {
  const { t } = useTranslation();
  return (
    <div className={S.bookingListWrapper}>
      <div className={S.bookingListTitle}>{t('modals.invites_bookings.available_bookings')}</div>
      <div className={S.bookingListDate}>
        {TimeHelpers.getFormattedJsTime(date, TimeHelpers.TimePatterns.shortDateFormatted)}
      </div>
      <ul className={S.bookingList}>{children}</ul>
    </div>
  );
};

PreApprovedBookingListWrapper.displayName = 'PreApprovedBookingListWrapper';
PreApprovedBookingListWrapper.propTypes = {
  date: PropTypes.object.isRequired,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
};

export { PreApprovedBookingListWrapper };
