import React from 'react';
import * as PropTypes from 'prop-types';

import S from './img-loading.module.scss';
import { LoadingDisco } from '../loading/loading-disco';
import { Overlay } from '../overlays/overlay';

const ImgLoading = ({ size }) => {
  return (
    <Overlay styles={{ height: size, width: size }}>
      <LoadingDisco className={S.fullSizeDisco} />
    </Overlay>
  );
};

ImgLoading.displayName = 'ImgOverlay';
ImgLoading.propTypes = { size: PropTypes.string };
ImgLoading.defaultProps = { size: null };

export { ImgLoading };
