import { useMutation } from '@apollo/react-hooks';
import { logError } from 'helpers/errors/bug-report';
import React, { useCallback } from 'react';
import * as PageChatCtx from 'components/pages-login/chat/chat-ctx';
import { useBusiness, useUser } from 'graphql/graph-hooks';
import { CREATE_MESSAGE } from 'graphql/mutations/message-create';
import { MESSAGE_MARK_AS_READ } from 'graphql/mutations/message-mark-as-read';
import { GET_MESSAGES_PAGINATED } from 'graphql/queries/messages-query';
import * as CachedConversations from 'graphql/cache/conversations';
import * as CachedMessages from 'graphql/cache/messages';
import { checkForFilterError } from 'helpers/filtered-words';
import { useTranslation } from 'react-i18next';
import useSuggestions, { SuggestionListType } from 'helpers/hooks/useSuggestions';

import S from './chat-msg-footer.module.scss';
import { InputFormText } from '../../../common/form/form-parts/input-form-text';
import RichToast from 'components/common/rich-toast';
import { toast } from 'react-toastify';

type ChatMsgFooterProps = {
  target: MsgSender;
  conversationId: string;
};

const ChatMsgFooter: React.FC<ChatMsgFooterProps> = ({ target, conversationId }) => {
  const { id: userId, admin: isAdmin } = useUser();
  const {
    dispatch,
    state: { lastMsgId, chatBizId: businessId, message },
  } = PageChatCtx.usePageChatCtx();
  const { t } = useTranslation();

  const { id: blockedByBusinessId } = useBusiness();
  const { checkText } = useSuggestions(SuggestionListType.MANAGER);

  const handleError = (error: any) => {
    const filterError = checkForFilterError(error, t('chatPage.send.error'));
    toast.error(<RichToast title={filterError.title} message={filterError.message} />);
  };

  const [markAsRead] = useMutation(MESSAGE_MARK_AS_READ, {
    variables: { messageId: lastMsgId },
    onError: handleError,
    update: (
      cache,
      {
        data: {
          message_mark_as_read: { message },
        },
      }
    ) => {
      const conversations = CachedConversations.getCachedConversations(
        cache,
        businessId,
        userId,
        isAdmin,
        blockedByBusinessId
      );
      const MESSAGES = CachedMessages.getCachedMessages(cache, conversationId).map((msg) => ({
        ...msg,
        read: msg.read || msg.sender.id === target?.id,
      }));

      CachedMessages.setCachedMessages(cache, conversationId, MESSAGES);

      //Uses writeQuery instead of writeData so userList updates
      //userList Array won't update if conversations aren't changed
      CachedConversations.setCachedConversations(
        cache,
        businessId,
        userId,
        isAdmin,
        blockedByBusinessId,
        conversations.map((convoObj) =>
          convoObj.id === conversationId
            ? {
                ...convoObj,
                updated_at: message.updated_at,
                business_unread_messages_count: 0,
              }
            : convoObj
        )
      );

      dispatch(PageChatCtx.setChatFields({ firstUnreadId: null, lastMsgId: null }));
    },
  });

  const [createMessage, { loading: processingSend }] = useMutation(CREATE_MESSAGE, {
    update: (
      cache: any,
      {
        data: {
          message_create: { message },
        },
      }
    ) => {
      const data = cache.readQuery({ query: GET_MESSAGES_PAGINATED, variables: { conversationId, last: 20 } });
      cache.writeQuery({
        query: GET_MESSAGES_PAGINATED,
        variables: { conversationId, last: 20 },
        data: {
          messages_paginated: {
            __typename: 'MessageConnection',
            nodes: [...data.messages_paginated.nodes, message],
          },
        },
      });
    },
    onCompleted: ({ message_create: { message } }) => {
      dispatch(PageChatCtx.setChatFields({ message: '', scrollToBottom: true }));
    },
    onError: handleError,
  });

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      if (message) {
        try {
          await checkText(message);
          await createMessage({ variables: { conversationId, message } });
        } catch (error) {
          handleError(error);
        }
      }
      console.log(message);
    },
    [conversationId, createMessage, message]
  );

  const handleFocus = useCallback(async () => {
    if (lastMsgId) {
      await markAsRead({ variables: { messageId: lastMsgId } });
    }
  }, [markAsRead, lastMsgId]);

  return (
    <form onSubmit={handleSubmit} className={S.chatForm}>
      <InputFormText
        name='chatMsg'
        value={message}
        hasError={false}
        handleFocus={handleFocus}
        isDisabled={processingSend}
        placeholder={t('common.message_user', { user: target?.name })}
        handleChange={(e) => dispatch(PageChatCtx.setChatField('message', e.target.value))}
      />
      <button type='submit' disabled={processingSend} className={S.filledBtnPink}>
        {t('common.send')}
      </button>
    </form>
  );
};

ChatMsgFooter.displayName = 'ChatMsgFooter';

export { ChatMsgFooter };
