import React, { useCallback, useState, useMemo } from 'react';
import * as PropTypes from 'prop-types';
import { useLocation } from 'react-router';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';

import S from './sub-links.module.scss';
import { NavBadges } from '../nav-badges';

const SubLinks = ({ links, label, Icon, color }) => {
  const { pathname } = useLocation();
  const HAS_ROUTE = useMemo(
    () => links.reduce((acm, { path }) => (pathname === path ? true : acm), false),
    [links, pathname]
  );

  const [isOpen, setIsOpen] = useState(HAS_ROUTE);

  const handleClick = useCallback(() => {
    if (!HAS_ROUTE) setIsOpen(!isOpen);
  }, [isOpen, HAS_ROUTE]);

  return (
    <div
      className={classNames(S.subLinkWrapper, {
        [S.active]: HAS_ROUTE,
        [S[color]]: !!color,
      })}
    >
      <button type='button' onClick={handleClick} className={classNames(S.subLinkToggle, { [S[color]]: !!color })}>
        {Icon}
        {label}
        <span
          className={classNames(S.arrow, {
            [S.hasRoute]: HAS_ROUTE,
            [S.open]: isOpen,
          })}
        />
      </button>
      {(isOpen || HAS_ROUTE) &&
        links.map(({ path, label, notification }) => (
          <NavLink key={`SUB_LINK_${path}`} className={S.miniNavLink} activeClassName={S.activeLink} to={path} exact>
            {label}
            {notification && <NavBadges count={notification.count} color={notification.color} />}
          </NavLink>
        ))}
    </div>
  );
};

SubLinks.displayName = 'SubLinks';

SubLinks.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string.isRequired,
  Icon: PropTypes.object.isRequired,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
};

SubLinks.defaultProps = {
  color: null,
};

export { SubLinks };
