import * as React from 'react';
import * as PropTypes from 'prop-types';
import classNames from 'classnames';

import S from './checkbox-span.module.scss';

const CheckboxSpan = ({ isChecked, isForm, disabled = false, ...rest }) => {
  return isForm ? (
    <label className={classNames(S.formCheckbox, { [S.active]: isChecked, [S.disabled]: disabled })} {...rest}>
      {isChecked && <span />}
    </label>
  ) : (
    <label className={S.checkboxSpanLabel}>
      <span className={isChecked ? S.active : null} />
    </label>
  );
};

CheckboxSpan.displayName = 'CheckboxSpan';

CheckboxSpan.propTypes = {
  id: PropTypes.string,
  isForm: PropTypes.bool,
  isChecked: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
};

CheckboxSpan.defaultProps = {
  isForm: false,
};

export { CheckboxSpan };
