import React from 'react';
import PropTypes from 'prop-types';
import * as ClubValidations from '../../../../../helpers/validations/club-validation';
import { useTranslation } from 'react-i18next';

import S from './address-column.module.scss';
import * as ClubFormCtx from '../club-form-context';
import { FormError } from '../../../../common/form/form-parts/form-error';
import { FormLabel } from '../../../../common/form/form-parts/form-label';
import { InputFormText } from '../../../../common/form/form-parts/input-form-text';

const AddressColumn = ({ isDisabled }) => {
  const { t } = useTranslation();
  const {
    dispatch,
    state: { form, errors },
  } = ClubFormCtx.useClubFormCtx();

  return (
    <div className={S.addressColumn}>
      <FormLabel
        id='club_form_address1'
        label={t('common.address')}
        isDisabled={isDisabled}
        hasError={!!(errors.street1 || errors.street2)}
      />
      <InputFormText
        id='club_form_address1'
        name='street1'
        value={form.street1}
        isDisabled={isDisabled}
        hasError={!!errors.street1}
        handleBlur={({ target: { name, value } }) => {
          dispatch(ClubFormCtx.setClubFormField(name, value.trim()));
          dispatch(ClubFormCtx.setClubFormError(name, ClubValidations.validateClubStreet1(value.trim())));
        }}
        handleChange={({ target: { name, value } }) => {
          dispatch(ClubFormCtx.setClubFormField(name, value));
          dispatch(ClubFormCtx.setClubFormError(name, ClubValidations.validateClubStreet1(value)));
        }}
      />
      <InputFormText
        id='club_form_address2'
        name='street2'
        value={form.street2}
        isDisabled={isDisabled}
        hasError={!!errors.street2}
        handleBlur={({ target: { name, value } }) => {
          dispatch(ClubFormCtx.setClubFormField(name, value.trim()));
          dispatch(ClubFormCtx.setClubFormError(name, ClubValidations.validateClubStreet2(value.trim())));
        }}
        handleChange={({ target: { name, value } }) => {
          dispatch(ClubFormCtx.setClubFormField(name, value));
          dispatch(ClubFormCtx.setClubFormError(name, ClubValidations.validateClubStreet2(value)));
        }}
      />
      <FormError error={errors.street1 || errors.street2 || ''} />
    </div>
  );
};

AddressColumn.displayName = 'AddressColumn';

AddressColumn.propTypes = {
  isDisabled: PropTypes.bool.isRequired,
};

export { AddressColumn };
