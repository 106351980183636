import moment from 'moment';
import * as PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';

import S from './page-calendar.module.scss';
import { Calendar } from './calendar/calendar';
import { useBusiness } from '../../../../graphql/graph-hooks';
import * as TimeHelpers from '../../../../helpers/times';
import posthog from 'posthog-js';

const PageCalendar = ({ history, location }) => {
  const { timezone, id } = useBusiness();

  posthog.capture('BookingCalendarPage', { businessId: id });

  const DATE = useMemo(() => {
    if (location.search) {
      const MOMENT = moment.tz(
        new URLSearchParams(document.location.search).get('date'),
        TimeHelpers.TimePatterns.date,
        timezone
      );
      if (moment.isMoment(MOMENT)) {
        return MOMENT.toISOString();
      }
    }
    return TimeHelpers.getCurrentTimeAtTimezone(timezone).toISOString();
  }, [location.search, timezone]);

  const onRedirect = useCallback((path) => history.push(path), [history]);

  return (
    <div className={S.pageWrapper}>
      <Calendar onRedirect={onRedirect} date={DATE} />
    </div>
  );
};

PageCalendar.displayName = 'PageCalendar';
PageCalendar.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export { PageCalendar };
