import * as React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { LanguageSelect } from '../common/language-select';
import { LoginPage } from './login/login-page';
import { RecoveryPage } from './recovery/recovery-page';
import { ResetPage } from './reset/reset-page';
import { ExtraLinks } from '../common/links';
import logo from '../../assets/white_logo.png';

import S from './logout-layout.module.scss';

const LogoutLayout: React.FC = (): React.ReactElement => {
  return (
    <main className={S.mainTemplate}>
      <img src={logo} alt='Pole Position Logo' />
      <LanguageSelect />

      <Switch>
        <Route exact path='/recovery' component={RecoveryPage} />
        <Route exact path='/reset' component={ResetPage} />
        <Route exact path='/login' component={LoginPage} />
        <Route path='/' component={() => <Redirect to={`/login${window.location.search}`} />} />
      </Switch>

      <ExtraLinks isFullText />
    </main>
  );
};

LogoutLayout.displayName = 'LogoutLayout';

export { LogoutLayout };
