import { gql } from 'apollo-boost';

export const ADD_SECTION = gql`
  mutation VipSectionCreate($input: VipSectionCreateInput!) {
    vip_section_create(input: $input) {
      vip_section {
        id
      }
    }
  }
`;

export const UPDATE_SECTION = gql`
  mutation VipSectionUpdate($input: VipSectionUpdateInput!) {
    vip_section_update(input: $input) {
      vip_section {
        id
      }
    }
  }
`;

export const DELETE_SECTION = gql`
  mutation VipSectionDelete($input: VipSectionDeleteInput!) {
    vip_section_delete(input: $input) {
      message
    }
  }
`;

export const ADD_OFFER = gql`
  mutation VipOfferCreate($input: VipOfferCreateInput!) {
    vip_offer_create(input: $input) {
      vip_offer {
        id
      }
    }
  }
`;

export const UPDATE_OFFER = gql`
  mutation VipOfferUpdate($input: VipOfferUpdateInput!) {
    vip_offer_update(input: $input) {
      vip_offer {
        id
      }
    }
  }
`;

export const DELETE_OFFER = gql`
  mutation VipOfferDelete($input: VipOfferDeleteInput!) {
    vip_offer_delete(input: $input) {
      message
    }
  }
`;

export const ADD_LINK = gql`
  mutation VipOfferLinkCreate($input: VipOfferLinkCreateInput!) {
    vip_offer_link_create(input: $input) {
      vip_offer_link {
        id
      }
    }
  }
`;

export const UPDATE_LINK = gql`
  mutation VipOfferLinkUpdate($input: VipOfferLinkUpdateInput!) {
    vip_offer_link_update(input: $input) {
      vip_offer_link {
        id
      }
    }
  }
`;

export const DELETE_LINK = gql`
  mutation VipOfferLinkDelete($input: VipOfferLinkDeleteInput!) {
    vip_offer_link_delete(input: $input) {
      message
    }
  }
`;
