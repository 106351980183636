import { useMemo } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { ALL_BUSINESS_GQL } from 'graphql/queries/business-get-all';
import * as DancerSearchCTX from '../../dancer-search-context';
import classNames from 'classnames';
import { FaSort } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

import S from './filter.module.scss';
import IFS from '../../../../../common/form/form-parts/input-form-select.module.scss';

export const FilterDancerBusiness = () => {
  const { t } = useTranslation();
  const { dispatch } = DancerSearchCTX.useDancerSearchCtx(true);

  const { data } = useQuery(ALL_BUSINESS_GQL, { fetchPolicy: 'network-only' });

  const options = useMemo(
    () =>
      data?.businesses
        ? [
            { value: '', label: t('common.all') },
            ...data.businesses.map(({ id, name }) => ({ label: name, value: id.toString() })),
          ]
        : [],
    [data?.businesses]
  );

  const handleOnChange = ({ target }) => {
    dispatch(DancerSearchCTX.setDancerSearchField('businessIdFilter', target.value));
  };

  return (
    <div>
      <span>{t('common.business')}</span>
      <div className={IFS.wrapper}>
        <select onChange={handleOnChange} className={classNames(S.select, S.control)}>
          {options.map(({ label, value }) => (
            <option key={value} value={value}>
              {label}
            </option>
          ))}
        </select>
        <FaSort />
      </div>
    </div>
  );
};
