import React, { FC, ReactNode, useRef } from 'react';
import { CloseButton } from 'components/common/buttons/close-button';
import { useOverlayClick } from 'helpers/hooks/use-overlay-click';
import classNames from 'classnames';
import S from './styles.module.scss';

type Props = {
  title?: string;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'full';
  onClose: () => void;
  children: ReactNode;
};

const ModalGeneric: FC<Props> = ({ title, size = 'sm', onClose, children }) => {
  const MODAL_REF = useRef(null);
  useOverlayClick(MODAL_REF, onClose);

  return (
    <div className={classNames(S.modal, S[size])} ref={MODAL_REF}>
      <CloseButton onClick={onClose} />
      {title && <h3 className={S.modalHeading}>{title}</h3>}
      {children}
    </div>
  );
};

export default ModalGeneric;
