import * as PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef } from 'react';

import S from './dancer-menu.module.scss';

const DancerMenuDropDown = ({ onClose, list, handleClick }) => {
  let wrapperRef = useRef();

  const outsideClick = useCallback(
    ({ target }) => {
      if (!wrapperRef?.current?.contains(target)) onClose();
    },
    [onClose, wrapperRef]
  );

  useEffect(() => {
    document.addEventListener('mouseup', outsideClick);
    return () => document.removeEventListener('mouseup', outsideClick);
  }, [onClose, outsideClick]);

  return (
    <div ref={wrapperRef} className={S.menuWrapper}>
      {list.map(({ label, onSelect }, i) => (
        <button key={i} type='button' onClick={() => handleClick(onSelect)}>
          {label}
        </button>
      ))}
    </div>
  );
};

DancerMenuDropDown.displayName = 'DancerMenuDropDown';
DancerMenuDropDown.propTypes = {
  onClose: PropTypes.func.isRequired,
  handleClick: PropTypes.func.isRequired,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      onSelect: PropTypes.func.isRequired,
    })
  ),
};
DancerMenuDropDown.defaultProps = {};

export { DancerMenuDropDown };
