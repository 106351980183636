import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import S from './dancer-sort-buttons.module.scss';
import * as DancerSearchCtx from '../../dancer-search-context';
import { DANCER_SORTS } from '../../dancer-search-types';
import { OrderBtn } from '../../../../../common/buttons/order-btn';
import { InputFormSelect } from '../../../../../common/form/form-parts/input-form-select';
import { ORDER, PERMISSIONS } from '../../../../../../helpers/enums';
import { useHasPermission } from '../../../../../../graphql/graph-hooks';

const DancerSortButtons = () => {
  const { t } = useTranslation();
  const {
    dispatch,
    state: {
      hasBlocked,
      sort: { order, orderBy },
    },
  } = DancerSearchCtx.useDancerSearchCtx();
  const IS_ASC = useMemo(() => order === ORDER.ASC, [order]);
  const CAN_VIEW_BLOCKED = useHasPermission(PERMISSIONS.BLOCK_DANCERS);

  const handleOrderChange = useCallback(
    () => dispatch(DancerSearchCtx.setDancerSortFields({ order: IS_ASC ? ORDER.DESC : ORDER.ASC })),
    [dispatch, IS_ASC]
  );

  const handleChange = useCallback(
    (e) => dispatch(DancerSearchCtx.setDancerSortFields({ order: ORDER.ASC, orderBy: e.target.value })),
    [dispatch]
  );

  const handleBlocked = useCallback(
    (e) => dispatch(DancerSearchCtx.setDancerSearchField('hasBlocked', e.target.value)),
    [dispatch]
  );

  return (
    <div className={S.sortBtnGroup}>
      {CAN_VIEW_BLOCKED && (
        <>
          <label htmlFor='blockedDancers'>{t('dancerPage.dancers')}</label>
          <InputFormSelect
            id='blockedDancers'
            name='blocked'
            value={String(hasBlocked)}
            onChange={handleBlocked}
            options={[
              { value: 'false', label: t('common.unblocked') },
              { value: '', label: t('common.all') },
              { value: 'true', label: t('common.blocked') },
            ]}
          />
        </>
      )}
      <>
        <label htmlFor='sortField'>{t('common.sort')}</label>
        <InputFormSelect
          id='sortField'
          name='sort'
          value={orderBy}
          onChange={handleChange}
          options={[
            { value: DANCER_SORTS.NAME, label: t('common.alphabetically') },
            { value: DANCER_SORTS.SCORE, label: t('common.score') },
            { value: DANCER_SORTS.CREATED_AT, label: t('common.created_at') },
          ]}
        />
        <OrderBtn handleClick={handleOrderChange} isAsc={IS_ASC} />
      </>
    </div>
  );
};

DancerSortButtons.displayName = 'DancerSortButtons';

export { DancerSortButtons };
