import { gql } from 'apollo-boost';

export const UNVERIFY_DANCER = gql`
  mutation dancer_unverify(
    $dancerId: Int!
    $rejectionReason: VerificationRejectionReasonEnum
    $rejectedPictureIds: [ID!]
  ) {
    dancer_unverify(
      input: { dancerId: $dancerId, rejectionReason: $rejectionReason, rejectedPictureIds: $rejectedPictureIds }
    ) {
      dancer {
        id
        verification_status
        images {
          id
          is_verified
        }
      }
    }
  }
`;
