import React, { useCallback } from 'react';
import * as PropTypes from 'prop-types';
import * as classNames from 'classnames';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import S from './booking-duplicate-field.module.scss';
import { useModalBookingCtx } from '../../../modal-booking-context';
import { setBookingField } from '../../../modal-booking-actions';
import { getDayFromIndex } from '../../../../../../helpers/times';

const BookingDuplicateField = ({ isDisabled, timezone }) => {
  const { t, i18n } = useTranslation();
  const { state, dispatch } = useModalBookingCtx();
  const DUPLICATE_ARY = state.form.duplicates;
  const START_DAY = moment(state.form.date).tz(timezone).day();

  const handleChange = useCallback(
    (e) => {
      e.preventDefault();
      const VALUE = Number(e.target.value);
      dispatch(
        setBookingField(
          'duplicates',
          DUPLICATE_ARY.includes(VALUE) ? DUPLICATE_ARY.filter((d) => d !== VALUE) : [...DUPLICATE_ARY, VALUE]
        )
      );
    },
    [dispatch, DUPLICATE_ARY]
  );

  const getDayValue = (dayOfWeek, start) => (dayOfWeek < start ? dayOfWeek + 7 - start : dayOfWeek - start);

  return (
    <div
      className={classNames(S.bookingDuplicateField, {
        [S.disabled]: isDisabled,
      })}
    >
      <label
        className={classNames(S.bookingFormLabel, {
          [S.disabled]: isDisabled,
        })}
      >
        {t('bookingCalendar.days')}
      </label>
      <div>
        {[0, 1, 2, 3, 4, 5, 6].map((i) => {
          const VALUE = getDayValue(i, START_DAY);
          return (
            <button
              key={`duplicate_day_${i}`}
              value={VALUE}
              disabled={isDisabled || VALUE === 0}
              onClick={handleChange}
              className={classNames(S.duplicateBtn, {
                [S.active]: DUPLICATE_ARY.includes(VALUE),
              })}
            >
              {getDayFromIndex(i, 'dddd', i18n.language).charAt(0)}
            </button>
          );
        })}
      </div>
    </div>
  );
};

BookingDuplicateField.displayName = 'BookingDuplicateField';
BookingDuplicateField.propTypes = {
  isDisabled: PropTypes.bool.isRequired,
  timezone: PropTypes.string.isRequired,
};

export { BookingDuplicateField };
