import * as PropTypes from 'prop-types';
import { useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useApolloClient, useMutation, useQuery } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';
import S from './invite-form.module.scss';
import { useInviteForm } from './use-invite-form';
import { StagedCheckbox } from '../../common/checkboxes/staged-checkbox';
import { FormTextField } from '../../common/form/form-text-field';
import { FormTextareaField } from '../../common/form/form-textarea-field';
import * as GraphHooks from 'graphql/graph-hooks';
import { CREATE_INVITE_CODE } from 'graphql/mutations/registration-code-create';
import { DELETE_INVITE_CODE } from 'graphql/mutations/registration-code-delete';
import { UPDATE_INVITE_CODE } from 'graphql/mutations/registration-code-update';
import { CREATE_LOCATION } from 'graphql/mutations/location-create';
import { GET_INVITE_CODES } from '../../../graphql/queries/invite-list';
import { PERMISSIONS } from '../../../helpers/enums';
import LocationSearchInput from 'components/common/location-search-input';
import { GET_LOCATION } from 'graphql/queries/location-query';

const InviteForm = ({ type, onClose, codeId, filter: { search, active } }) => {
  const CLIENT = useApolloClient();
  const HAS_PERMISSIONS = GraphHooks.useHasPermission(PERMISSIONS.SEND_INVITES);
  const { id: userId } = GraphHooks.useUser();
  const { id: businessId } = GraphHooks.useBusiness();
  const { t } = useTranslation();

  const { values, errors, setErrors, hasErrors, handleValidation, handleChange } = useInviteForm(codeId);
  let variables = {
    isClubAdmin: HAS_PERMISSIONS,
    search,
    active: active ? (active === 'true' ? true : false) : undefined,
  };

  if (type === 'ADMIN') {
    variables = { ...variables, admin: true, hasBusiness: false };
  }
  if (type === 'CLUB') {
    variables = { ...variables, businessId };
  }
  if (type === 'EMPLOYEE') {
    variables = {
      ...variables,
      businessId,
      userId,
    };
  }
  const [deleteMutation, deleteQL] = useMutation(DELETE_INVITE_CODE, {
    variables: { id: codeId },
    onCompleted: () => {
      try {
        const { registration_codes } = CLIENT.readQuery({ query: GET_INVITE_CODES, variables });
        CLIENT.writeQuery({
          query: GET_INVITE_CODES,
          variables,
          data: {
            registration_codes: [...registration_codes.filter(({ id }) => id !== codeId)],
          },
        });
        onClose();
        toast.success(t('modals.inviteCode.delete.success'));
      } catch (error) {
        throw error;
      }
    },
    onError: (err) => toast.error(err?.graphQLErrors?.[0]?.message ?? t('errors.server.500')),
  });

  const [createMutation, createQL] = useMutation(CREATE_INVITE_CODE, {
    variables: {
      userId,
      businessId: type === 'ADMIN' ? undefined : businessId,
    },
    onCompleted: ({ registration_code_create }) => {
      try {
        const { registration_codes } = CLIENT.readQuery({ query: GET_INVITE_CODES, variables });
        CLIENT.writeQuery({
          query: GET_INVITE_CODES,
          variables,
          data: {
            registration_codes: [...registration_codes, registration_code_create.registration_code],
          },
        });
        onClose();
        toast.success(t('modals.inviteCode.create.success'));
      } catch (err) {
        throw err;
      }
    },
    onError: (err) => {
      setErrors({ ...errors, ...err.graphQLErrors?.[0]?.extensions });
      toast.error(err?.graphQLErrors?.[0]?.message ?? t('errors.server.500'));
    },
  });

  const [updateMutation, updateQL] = useMutation(UPDATE_INVITE_CODE, {
    variables: { id: codeId },
    onCompleted: ({ registration_code }) => {
      CLIENT.writeData({ id: codeId, data: registration_code });
      onClose();
      toast.success(t('modals.inviteCode.update.success'));
    },
    onError: (err) => {
      setErrors({ ...errors, ...err.graphQLErrors?.[0]?.extensions });
      toast.error(err?.graphQLErrors?.[0]?.message ?? t('errors.server.500'));
    },
  });

  const [createLocation, { loading: creatingLocation }] = useMutation(CREATE_LOCATION);

  const location = useQuery(GET_LOCATION, {
    fetchPolicy: 'network-only',
    variables: { googlePlacesId: values.location?.googlePlacesId },
    skip: !values.location?.googlePlacesId,
  });

  const IS_LOADING = updateQL.loading || createQL.loading || deleteQL.loading || creatingLocation || location.loading;

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      if (handleValidation(values)) {
        return;
      }

      const variables = {
        code: values.code,
        description: values.description,
        isActive: values.isActive,
        locationId: values.location && !!location.data?.location ? location.data.location.id : null,
      };

      try {
        // Location
        if (!variables.locationId && values.location) {
          const newLocation = await createLocation({ variables: values.location });
          variables.locationId = newLocation.data.location_create.location.id;
        }
        // Code
        codeId ? await updateMutation({ variables }) : await createMutation({ variables });
      } catch (error) {
        toast.error(t('errors.server.500'));
      }
    },
    [codeId, updateMutation, createMutation, values, location]
  );

  return (
    <form onSubmit={handleSubmit} className={S.inviteForm}>
      {codeId && type !== 'ADMIN' && <div className={S.inviteCode}>{values?.code ?? ''}</div>}
      {(HAS_PERMISSIONS || !codeId) && (
        <>
          <FormTextField
            id='invite_form_code'
            name='code'
            label={t('employeePage.invite_code')}
            placeholder={t('employeePage.invite_code')}
            value={values.code}
            error={errors?.code}
            handleChange={handleChange.bind(this, 'code')}
            isDisabled={IS_LOADING}
          />
          {variables.hasBusiness === false && (
            <LocationSearchInput
              label={t('common.city')}
              initialValue={values.location}
              placeholder={t('common.city')}
              onSelect={handleChange.bind(this, 'location')}
            />
          )}
        </>
      )}

      <FormTextareaField
        id='invite_form_description'
        name='description'
        label={t('common.description')}
        placeholder={t('common.description')}
        value={values.description}
        error={errors?.description}
        isDisabled={IS_LOADING}
        handleChange={handleChange.bind(this, 'description')}
      />

      {codeId && (
        <div className={S.actionRow}>
          <span>{t('common.active')}?</span>
          <StagedCheckbox
            stage={values.isActive ? 'checked' : 'unchecked'}
            handleChange={handleChange.bind(this, 'isActive')}
          />
        </div>
      )}

      <div className={S.buttonRow}>
        {codeId ? (
          <button type='button' className={S.filledBtnRed} onClick={deleteMutation} disabled={IS_LOADING}>
            {t('common.delete')}
          </button>
        ) : (
          <button type='button' className={S.invertedBtnBlue} onClick={onClose}>
            {t('common.cancel')}
          </button>
        )}

        <button type='submit' className={S.filledBtnBlue} disabled={IS_LOADING || hasErrors}>
          {t(`common.${codeId ? 'update' : 'create'}`)}
        </button>
      </div>
    </form>
  );
};

InviteForm.displayName = 'InviteForm';

InviteForm.propTypes = {
  codeId: PropTypes.string,
  type: PropTypes.oneOf(['CLUB', 'ADMIN', 'EMPLOYEE']).isRequired,
  onClose: PropTypes.func.isRequired,
};

InviteForm.defaultProps = {
  codeId: null,
};

export { InviteForm };
