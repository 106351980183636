import { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import moment from 'moment';
import classNames from 'classnames';
import { DOB_REQUEST_ACCEPT, DOB_REQUEST_REJECT } from 'graphql/mutations/dob_request_update';
import { ButtonSpinner } from 'components/common/button_spinner';

import { logError } from '../../../../helpers/errors/bug-report';

import { VERIFICATION_STAGES } from '../../../../helpers/enums';
import { DobRequestItemProps } from '../types';

import S from './dob-request-item.module.scss';

const DobRequestItem = ({ dobRequest, status, className, refetch }: DobRequestItemProps) => {
  const { t } = useTranslation();
  const [rejectDropdown, setRejectDropdown] = useState<boolean>(false);

  const [acceptVerification, { loading: acceptLoading }] = useMutation(DOB_REQUEST_ACCEPT, {
    onError: (err) => {
      logError(err, DOB_REQUEST_ACCEPT, 'DobRequestItem');
      toast.error(t('dobChangePage.accept.failed'));
    },
    onCompleted: () => {
      refetch({ first: 20, status: status });
      toast.success(t('dobChangePage.accept.success'));
    },
  });

  const [rejectVerification, { loading: rejectLoading }] = useMutation(DOB_REQUEST_REJECT, {
    onError: (err) => {
      logError(err, DOB_REQUEST_REJECT, 'DobRequestItem');
      toast.success(t('dobChangePage.reject.failure'));
    },
    onCompleted: () => {
      refetch({ first: 20, status: status });
      toast.success(t('dobChangePage.reject.success'));
    },
  });

  const rejectChange = async (requestId: string, rejectReason: string) => {
    await rejectVerification({
      variables: {
        input: {
          id: requestId,
          rejectionText: rejectReason,
        },
      },
    });
  };

  const isUpdating = acceptLoading || rejectLoading;

  return (
    <div className={`flex px-8 py-4 ${className}`} key={`dob_${dobRequest.id}`}>
      <div className='flex-1 flex-col'>
        <div className={S.verificationDancerInfo}>
          <div className={S.dataRow}>
            <label>{t('common.name')}:</label>
            <span className={S.name}>{dobRequest.user.name}</span>
          </div>

          <div className={S.dataRow}>
            <label>{t('common.email')}:</label>
            <span className={S.sentence}>{dobRequest.user.email}</span>
          </div>
          <div className={S.dataRow}>
            <label>{t('dobChangePage.current_dob')}:</label>
            <span className={S.sentence}>{moment(dobRequest.user.dob).format('MM-DD-YYYY')}</span>
          </div>
          <div className={S.dataRow}>
            <label>{t('dobChangePage.new_dob')}:</label>
            <span className={S.sentence}>{moment(dobRequest.dob).format('MM-DD-YYYY')}</span>
          </div>
        </div>
      </div>

      <div className='flex-1 flex-col'>
        <div className='flex mt-2 static'>
          {dobRequest.image && (
            <img
              alt={dobRequest.user.name}
              src={dobRequest.image.thumb}
              className='relative mr-8 h-24 hover:z-50 hover:translate-y-24 hover:scale-300 hover:transition-transform hover:z-50'
            />
          )}
        </div>
      </div>
      <div className='flex flex-col justify-around pl-2'>
        {status === VERIFICATION_STAGES.ACCEPTED && (
          <div>
            {t('dobChangePage.accepted_on')} {moment(dobRequest.updated_at).format('MM-DD-YYYY HH:mm')}
          </div>
        )}
        {status === VERIFICATION_STAGES.REJECTED && (
          <div>
            {t('dobChangePage.rejected_on')} {moment(dobRequest.updated_at).format('MM-DD-YYYY HH:mm')}
            <br />
            {dobRequest.rejection_text}
          </div>
        )}
        {status === VERIFICATION_STAGES.PENDING && (
          <>
            <button
              type='button'
              disabled={isUpdating}
              onClick={() => {
                acceptVerification({
                  variables: {
                    input: {
                      id: dobRequest.id,
                    },
                  },
                });
                setRejectDropdown(false);
              }}
              className='inline-flex items-center rounded-lg bg-green-600 w-40 px-4 py-1 font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2'
            >
              {isUpdating ? <ButtonSpinner /> : t('dobChangePage.approve_change')}
            </button>
            <button
              className={classNames(
                'inline-flex items-center rounded-lg text-white text-center bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium px-4 py-1 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800',
                isUpdating && 'cursor-not-allowed bg-slate-600 hover:none'
              )}
              disabled={isUpdating}
              type='button'
              onClick={() => {
                setRejectDropdown(!rejectDropdown);
              }}
            >
              {isUpdating ? <ButtonSpinner /> : t('dobChangePage.reject_change')}
              <svg
                className='ml-2 w-4 h-4'
                aria-hidden='true'
                fill='none'
                stroke='currentColor'
                viewBox='0 0 24 24'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M19 9l-7 7-7-7'></path>
              </svg>
            </button>
            <div className='relative'>
              <div
                className={classNames(
                  !rejectDropdown && 'hidden',
                  'absolute z-10 w-40 -top-6 -right-0 bg-white rounded divide-y divide-gray-100 shadow dark:bg-gray-700 w-max'
                )}
              >
                <ul className='py-1 text-sm text-gray-700 dark:text-gray-200'>
                  <li>
                    <button
                      type='button'
                      disabled={isUpdating}
                      onClick={() => {
                        rejectChange(dobRequest.id, 'The ID provided is invalid or unreadable.');
                        setRejectDropdown(false);
                      }}
                      className='w-full text-left py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white'
                    >
                      {t('dobChangePage.invalid_id')}
                    </button>
                  </li>
                  <li>
                    <button
                      type='button'
                      disabled={isUpdating}
                      onClick={() => {
                        rejectChange(dobRequest.id, 'DOB entered does not match DOB on ID.');
                        setRejectDropdown(false);
                      }}
                      className='w-full text-left py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white'
                    >
                      {t('dobChangePage.no_match')}
                    </button>
                  </li>
                  <li>
                    <button
                      type='button'
                      disabled={isUpdating}
                      onClick={() => {
                        rejectChange(dobRequest.id, 'Underage performers are not allowed on the platform.');
                        setRejectDropdown(false);
                      }}
                      className='w-full text-left py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white'
                    >
                      {t('dobChangePage.underage')}
                    </button>
                  </li>
                  <li>
                    <button
                      type='button'
                      disabled={isUpdating}
                      onClick={() => {
                        rejectChange(
                          dobRequest.id,
                          'We cannot approve this change at this time. You can contact support@poleposition.app for further clarifications.'
                        );
                        setRejectDropdown(false);
                      }}
                      className='w-full text-left py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white'
                    >
                      {t('dobChangePage.other')}...
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default DobRequestItem;
