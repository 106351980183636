import { useRef, useCallback, useState } from 'react';
import * as PropTypes from 'prop-types';
import { FaPlus } from 'react-icons/fa';

import { setDancerSearchField, useDancerSearchCtx } from '../../dancer-search-context';
import { CheckBoxList } from '../../../../../common/checkbox-list';
import { useOutsideClick } from '../../../../../../helpers/hooks/use-outside-click';

import S from './filter-dropdown.module.scss';

const FilterDropDown = ({ options, selected, fieldName }) => {
  const [isOpen, setIsOpen] = useState(false);
  const DD_REF = useRef(null);
  const {
    dispatch,
  } = useDancerSearchCtx();

  const handleClick = useCallback(() => setIsOpen(!isOpen), [isOpen]);

  useOutsideClick(DD_REF, () => {
    setTimeout(() => {
      if (isOpen) setIsOpen(false);
    }, 50);
  });

  return (
    <>
      <button onClick={handleClick} className={S.filterDropDownBtn}>
        <FaPlus className={isOpen ? S.isOpen : S.isClosed} />
      </button>

      {isOpen && (
        <div ref={DD_REF} className={S.filterDropDown}>
          <CheckBoxList
            list={options}
            name='styles'
            selected={selected}
            handleChange={(val) => dispatch(setDancerSearchField(fieldName, val))}
          />
        </div>
      )}
    </>
  );
};

FilterDropDown.displayName = 'FilterDropDown';
FilterDropDown.propTypes = {
  options: PropTypes.array.isRequired,
  selected: PropTypes.array.isRequired,
  fieldName: PropTypes.string.isRequired,
};

export { FilterDropDown };
