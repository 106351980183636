import React from 'react';
import { compose, withProps } from 'recompose';
import { GoogleMap, Marker, withGoogleMap, withScriptjs } from 'react-google-maps';
import { useQuery } from 'react-query';
import { APIConstants } from 'helpers/constants';
import MarkerClusterer from 'react-google-maps/lib/components/addons/MarkerClusterer';
import { useTranslation } from 'react-i18next';

interface MarkerData {
  latitude: number;
  longitude: number;
}

export const VisitorsMapped = compose(
  withProps({
    googleMapURL: 'https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places',
    loadingElement: <div className='h-full' />,
    containerElement: <div className='h-136' />,
    mapElement: <div className='h-full' />,
  }),
  withScriptjs,
  withGoogleMap
)((props) => {
  const { t } = useTranslation();
  const token = localStorage.getItem('token');
  const { data, isLoading, error } = useQuery<MarkerData[]>(['visitsOnMap'], () =>
    fetch(`${APIConstants.BI_API_URL}/visitors-map`, {
      headers: [['Authorization', `Bearer ${token}`]],
      credentials: 'omit',
    }).then((res) => res.json())
  );

  return (
    <div className='h-146 shadow ring-1 ring-gray-200 ring-opacity-5 md:rounded-lg'>
      <h2 className='mx-auto py-2 pl-6 text-lg font-medium leading-6 text-gray-400'>{t('statistics.visitor_map')}</h2>
      <GoogleMap defaultZoom={5} defaultCenter={{ lat: 39.742, lng: -104.9915 }}>
        <MarkerClusterer averageCenter enableRetinaIcons gridSize={60}>
          {data &&
            !isLoading &&
            !error &&
            data.map((item, idx) => <Marker key={idx} position={{ lat: item.latitude, lng: item.longitude }} />)}
        </MarkerClusterer>
      </GoogleMap>
    </div>
  );
});
