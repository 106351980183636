import { gql } from 'apollo-boost';

export const GET_ORGANIZATIONS_BY_ID_GQL = gql`
    query (
        $organizationId: ID!,
    ) {
        organization(id: $organizationId) {
            id
            name
        }
    }
`;
