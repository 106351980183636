import React from 'react';
import PropTypes from 'prop-types';

import S from './form-error.module.scss';

const FormError = ({ error }) => {
  return <p className={S.errorMsg}>{error}</p>;
};

FormError.displayName = 'FormError';

FormError.propTypes = {
  error: PropTypes.string,
};

FormError.defaultProps = {
  error: '',
};

export { FormError };
