import React from 'react';
import { VIP_OFFER_LINK_TYPES } from 'helpers/constants';

interface Props {
  type: keyof typeof VIP_OFFER_LINK_TYPES;
  title: string
}

const btnClassMap = {
  pink_button: 'p-2 w-64 bg-worker-pink',
  blue_button: 'p-2 w-64 bg-business-blue',
  link: 'px-0'
};

const OfferLink: React.FC<Props> = ({type, title}): React.ReactElement => {    
  return (
    <button className={`rounded-md font-bold text-white ${btnClassMap[type as keyof typeof btnClassMap]}`}>
      {title}
    </button>
  );
};

export default OfferLink;