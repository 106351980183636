import { gql } from 'apollo-boost';

export const GET_EMPLOYEE_GQL = gql`
    query($employmentId:ID!) {
        employee(employmentId: $employmentId) {
            id
            name
            admin
            email
            phone_number
            notes
            roles
            first_image { id medium }
        }
    }
`;
