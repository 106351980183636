import { useApolloClient } from '@apollo/react-hooks';
import React, { useMemo, useState } from 'react';
import * as PropTypes from 'prop-types';
import { Image } from '../../common/images/img-file';
import { useTranslation } from 'react-i18next';

import S from './course-form.module.scss';
import { useCourseForm } from './use-course-form';
import * as CourseCtx from './course-context';
import { useModal } from '../../global-hooks';
import { FormLabel } from '../../common/form/form-parts/form-label';
import { FormTextareaField } from '../../common/form/form-textarea-field';
import { FormTextField } from '../../common/form/form-text-field';
import { DropdownCheckboxField } from '../../common/form/form-checkbox-dropdown';
import { COURSES_CATEGORY_GQL } from '../../../graphql/queries/course-category-query';
import { setCourseErrorField } from './course-context';
import { ButtonSpinner } from 'components/common/button_spinner';
import classNames from 'classnames';

const CourseForm = ({ courseId }) => {
  const { t } = useTranslation();
  const CLIENT = useApolloClient();
  const { closeModal } = useModal();
  const {
    state: { form, errors },
    dispatch,
  } = CourseCtx.useCourseFormCtx();
  const { course_categories } = CLIENT.readQuery({ query: COURSES_CATEGORY_GQL });

  const [videoUrl, setVideoUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const CATEGORY_LIST = useMemo(
    () => course_categories.map(({ id, name }) => ({ value: id, label: name })),
    [course_categories]
  );

  const { isDisabled, handleBlur, handleChange, handleSelection, handleDeleteVideo, handleSubmission } = useCourseForm(
    courseId,
    CATEGORY_LIST
  );

  const handleOnVideoError = () => {
    setIsLoading(false);
    dispatch(setCourseErrorField('videoUrl', 'errors.validation.invalid_url'));
  };

  const handleOnLoadStart = () => {
    setIsLoading(true);
  };

  const handleOnLoadEnd = () => {
    setIsLoading(false);
  };

  const handleVideoUrlCheck = () => {
    setVideoUrl(null);
    setTimeout(setVideoUrl.bind(this, form.videoUrl));
  };

  const formHasErrors = !!Object.values(errors).find((err) => err !== '');

  return (
    <form className={S.courseForm} onSubmit={handleSubmission}>
      <FormTextField
        id='course_title'
        label={t('modals.course.title')}
        name='title'
        gridArea='title'
        value={form.title}
        error={errors.title}
        isDisabled={isDisabled}
        handleChange={handleChange}
        handleBlur={handleBlur}
      />

      <DropdownCheckboxField
        id='course_categories'
        label={t('modals.course.categories')}
        name='categories'
        gridArea='categories'
        collection={CATEGORY_LIST}
        value={form.categories}
        error={errors.categories}
        isDisabled={isDisabled}
        handleChange={handleSelection}
      />

      <FormTextField
        id='course_url'
        label={t('modals.course.video_url')}
        name='videoUrl'
        gridArea='videoUrl'
        value={form.videoUrl}
        error={t(errors.videoUrl)}
        isDisabled={isDisabled || isLoading}
        handleChange={handleChange}
        handleBlur={handleVideoUrlCheck}
      />

      <FormTextField
        id='course_image_url'
        label={t('modals.course.image_url')}
        name='imageUrl'
        gridArea='imgUrl'
        value={form.imageUrl}
        error={errors.imageUrl}
        isDisabled={isDisabled}
        handleChange={handleChange}
        handleBlur={handleBlur}
      />

      <div>
        <FormLabel label={t('modals.course.preview_image')} isDisabled={isDisabled} />
        <div className={S.imagePreviewWrapper}>
          <Image alt='video preview' fallback='VIDEO' src={form.imageUrl} classname={S.videoImg} />
          {videoUrl && (
            <video
              onError={handleOnVideoError}
              onLoadStart={handleOnLoadStart}
              onLoadedData={handleOnLoadEnd}
              className={S.video}
            >
              <source src={videoUrl} />
            </video>
          )}
        </div>
      </div>

      <FormTextareaField
        id='course_description'
        label={t('modals.course.description')}
        name='description'
        gridArea='description'
        value={form.description}
        error={errors.description}
        isDisabled={isDisabled}
        handleChange={handleChange}
        handleBlur={handleBlur}
      />

      <div className={S.courseActionRow}>
        <button
          type='button'
          disabled={isDisabled || isLoading}
          className={S.invertedBtnRed}
          onClick={() => (courseId ? handleDeleteVideo() : closeModal())}
        >
          {t(courseId ? 'common.delete' : 'common.cancel')}
        </button>

        <button
          type='submit'
          className={classNames([S.invertedBtnGreen, S.buttonWithSpinner])}
          disabled={isDisabled || isLoading || formHasErrors}
        >
          {isLoading && <ButtonSpinner />} {t('common.submit')}
        </button>
      </div>
    </form>
  );
};

CourseForm.displayName = 'CourseForm';
CourseForm.propTypes = {
  courseId: PropTypes.string,
};

CourseForm.defaultProps = {
  courseId: null,
};

export { CourseForm };
