import React, { useCallback, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import classNames from 'classnames';
import S from './verification-page.module.scss';
import { VerificationList } from './verification-list/verification-list';
import { VERIFICATION_STAGES } from '../../../helpers/enums';
import { logError } from '../../../helpers/errors/bug-report';
import { SearchInput } from '../../common/search-input';
import { useTranslation } from 'react-i18next';
import SortSelect from '../../common/sort-select/sort-select';
import debounce from 'lodash/debounce';

const VerificationPage = () => {
  const { t } = useTranslation();
  const [stage, setState] = useState(VERIFICATION_STAGES.PENDING);
  const [search, setSearch] = useState('');
  const debouncedSetSearch = debounce(text => setSearch(text), 500);
  const handleSearchChange = (e) => debouncedSetSearch(e.target.value);
  const handleChangeState = useCallback((e) => setState(e.target.dataset.stage), [setState]);
  const [sortOrder, setSortOrder] = useState('DESC');
  const handleSortOrderChange = useCallback((e) => setSortOrder(e.target.value), [setSortOrder]);
  const [itemCount, setItemCount] = useState(0);

  return (
    <div className={S.pageWrapper}>
      <div className={S.verificationHeader}>
        <h1 className={S.h1Heading}>
          {`${stage.toLowerCase()} ${t('verificationPage.verifications')} (${itemCount})`}
        </h1>
        <SortSelect label={t('verificationPage.order')} value={sortOrder} onChange={handleSortOrderChange} />
        <SearchInput
          className={S.verificationSearchBar}
          placeholder={t('verificationPage.search')}
          // gridArea="filter"
          value={search}
          onChange={handleSearchChange}
        />
        <div className={S.verificationBtnGroup}>
          {Object.values(VERIFICATION_STAGES).map((stageName) => (
            <button
              key={`VERIFICATION_STAGE_${stageName}`}
              className={classNames(S.invertedBtnBlue, { [S.active]: stage === stageName })}
              data-stage={stageName}
              onClick={handleChangeState}
            >
              {t(`common.${stageName.toLowerCase()}`)}
            </button>
          ))}
        </div>
      </div>
      <VerificationList stage={stage} search={search.length > 0 ? search : null} sortOrder={sortOrder} setItemCount={setItemCount} />
    </div>
  );
};

VerificationPage.displayName = 'VerificationPage';

export { VerificationPage };
