import * as React from 'react';
import * as PropTypes from 'prop-types';

import S from './email-field-email.module.scss';

const EmailModalFieldPresenter = ({ index, error, email, hasButton, checkEmail, updateEmail, removeEmail }) => {
  return (
    <li className={S.emailListItem}>
      <div className={S.inputRow}>
        <label className={S.emailInputLabel} htmlFor={`modal_email_${index}`}>
          {index + 1}
        </label>
        <input
          className={S.inputField}
          value={email}
          type='email'
          onChange={updateEmail}
          onBlur={checkEmail}
          id={`modal_email_${index}`}
        />
        {hasButton && (
          <button className={S.removeBtn} onClick={removeEmail}>
            <div className={S.icon} />
          </button>
        )}
      </div>
      <div className={S.errorRow}>{error}</div>
    </li>
  );
};

EmailModalFieldPresenter.displayName = 'EmailModalFieldPresenter';

EmailModalFieldPresenter.propTypes = {
  email: PropTypes.string.isRequired,
  error: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  hasButton: PropTypes.bool.isRequired,
  removeEmail: PropTypes.func.isRequired,
  updateEmail: PropTypes.func.isRequired,
  checkEmail: PropTypes.func.isRequired,
};

export { EmailModalFieldPresenter };
