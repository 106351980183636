import { gql } from 'apollo-boost';

import { COURSE_FRAGMENT } from '../fragments/course-fragment';

export const COURSES_PAGE_GQL = gql`
    ${COURSE_FRAGMENT}
    {
        course_categories{
            id
            name
        }
        courses{ ...courseKeys }
    }
`;
