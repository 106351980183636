import { gql } from 'apollo-boost';

export const GET_USER_FRAGMENT = gql`
  fragment userFragment on User {
    id
    name
    email
    admin
    user_type
    locale
    employments {
      id
      employable_id
      business {
        name
      }
    }
    first_image {
      id
      thumb
      medium
    }
    roles
    getstream_user {
      auth_token
    }
  }
`;
