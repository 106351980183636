import { useQuery } from '@apollo/react-hooks';
import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import S from './checkbox-list.module.scss';
import * as ClubFormCtx from '../club-form-context';
import { FormLabel } from '../../../../common/form/form-parts/form-label';
import { InputFormCheckbox } from '../../../../common/form/form-parts/input-form-checkbox';
import { GET_PROFILE_OPTS_GQL } from '../../../../../graphql/queries/profile-options';

const ProfileOptions = ({ isDisabled }) => {
  const { t } = useTranslation();
  const [options, setOptions] = useState([]);
  const {
    state: {
      form: { profileOptions },
    },
    dispatch,
  } = ClubFormCtx.useClubFormCtx();
  useQuery(GET_PROFILE_OPTS_GQL, {
    onCompleted: ({ profile_options }) => setOptions(profile_options),
  });

  const handleClick = useCallback(
    (id) => {
      dispatch(
        ClubFormCtx.setClubFormField(
          'profileOptions',
          profileOptions.includes(id) ? profileOptions.filter((fid) => fid !== id) : [...profileOptions, id]
        )
      );
    },
    [profileOptions, dispatch]
  );

  return (
    <div className={S.checkboxList} style={{ gridArea: 'features' }}>
      <FormLabel label={t('clubPage.club_features')} isDisabled={isDisabled} hasError={false} />
      {options.map(({ id, trans_key }) => (
        <InputFormCheckbox
          key={`club_form_features_${id}`}
          id={`club_form_features_${id}`}
          label={t(`profileOptions.${trans_key}`)}
          value={id}
          name='profileOptions'
          isChecked={profileOptions.includes(id)}
          isDisabled={isDisabled}
          handleChange={() => handleClick(id)}
        />
      ))}
    </div>
  );
};

ProfileOptions.displayName = 'ClubFeatures';

ProfileOptions.propTypes = {
  isDisabled: PropTypes.bool.isRequired,
};

export { ProfileOptions };
