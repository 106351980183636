import { FC, ReactNode } from 'react';
import { LoadingDisco } from '../loading/loading-disco';

type Props = {
  isLoading: boolean;
  error?: string;
  children: ReactNode;
  className?: string;
};

const PageBody: FC<Props> = ({ isLoading, error, children, className = '' }) => {
  return (
    <div className={`relative min-h-screen ${className}`}>
      {isLoading && (
        <div className='flex flex-col align-center justify-center absolute top-0 bottom-0 left-0 right-0 z-50'>
          <div className='-translate-y-1/2'>
            <LoadingDisco />
          </div>
        </div>
      )}

      {children}

      {error && (
        <div className='flex justify-center'>
          <h1 className='text-red-500 self-center'>{error}</h1>
        </div>
      )}
    </div>
  );
};

export default PageBody;
