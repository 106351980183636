import { gql } from 'apollo-boost';
import { LOCATION_FRAGMENT } from './dancer-location-fragment';

export const DANCER_GRID_FRAGMENT = gql`
  ${LOCATION_FRAGMENT}
  fragment DancerGridItem on Dancer {
    id
    name
    suspended
    deleted
    verification_status
    blocked_by(businessId: $blockedByBusinessId) {
      id
      name
    }
    in_group(businessId: $inGroupBusinessId)
    first_image {
      id
      thumb
      medium
      is_verified
      rejection_text
    }
    images {
      id
      thumb
      medium
      is_verified
      rejection_text
    }
    email @include(if: $isAdmin)
    ...LocationKeys @include(if: $hasLocation)
  }
`;
