import { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { RouteConstants } from 'components/routes/route-constants';
import { UPDATE_OFFER } from 'graphql/mutations/vip';
import { logError } from 'helpers/errors/bug-report';
import { camelizeKeys } from 'humps';
import { useHistory, useLocation } from 'react-router';
import { toast } from 'react-toastify';
import { OfferForm } from './offer_form';
import { LoadingDiscoWithContainer } from 'components/common/loading/loading-disco';
import { ImageInput } from 'types';
import { getBase64Image } from 'helpers/image_to_base64';
import { useTranslation } from 'react-i18next';

const VipOfferEdit: React.FC = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation<{ offer: VipOffer }>();
  const [imageData, setImageData] = useState<string>('');

  const [updateOffer, { loading: isSubmitting }] = useMutation<VipOfferCreate>(UPDATE_OFFER, {
    onCompleted: () => {
      history.push(RouteConstants.vipPromos);
      toast.success(t('vip.offer.update.success'));
    },
    onError: (err) => {
      logError(err, 'ADD_OFFER', VipOfferEdit.displayName);
      toast.error(t('vip.offer.update.failure'));
    },
  });

  const {
    state: { offer },
  } = location;

  if (offer.image) {
    getBase64Image(offer.image.large, setImageData);
  }

  if (offer.image && imageData === '') return <LoadingDiscoWithContainer />;

  const imageInput: ImageInput = {
    data: imageData,
    file_name: offer.image ? offer.image.data_file_name : '',
    mime_type: 'PNG',
  };

  const initialValues: VipOfferUpdate = {
    id: offer.id,
    name: {
      en: offer.name.en,
      es: offer.name.es,
    },
    section_id: offer.section.id,
    description: {
      en: offer.description.en,
      es: offer.description.es,
    },
    image_input: offer.image ? imageInput : undefined,
    is_active: offer.is_active,
    sort_order: offer.sort_order,
  };

  return (
    <OfferForm
      pageTitle={t('vip.offer.create.title')}
      initialValues={initialValues}
      isSubmitting={isSubmitting}
      submitHandler={(values) => updateOffer({ variables: { input: camelizeKeys(values) } })}
    />
  );
};

VipOfferEdit.displayName = 'VipOfferEdit';

export { VipOfferEdit };
