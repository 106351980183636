import React, { forwardRef, useState, useEffect } from 'react';
import ImageGallery from 'react-image-gallery';
import { useApolloClient } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';

import defaultUserPic from '../../../assets/user-placeholder-mask.png';
import { DANCER_CAROUSEL_GQL } from '../../../graphql/fragments/dancer-imgs-fragment';
import { useUser } from '../../../graphql/graph-hooks';
import { FaTimes } from 'react-icons/fa';

import classNames from 'classnames';

import S from './modal-dancer-carousel.module.scss';
import Checkmark from 'components/checkMark';

const ModalDancerCarousel = ({ dancerId, blocked, inGroup, disabled, customControls }, ref) => {
  const { admin } = useUser();
  const CLIENT = useApolloClient();
  const { t } = useTranslation();

  const dancer = CLIENT.readFragment({
    fragment: DANCER_CAROUSEL_GQL,
    fragmentName: 'DancerCarousel',
    id: `Dancer:${dancerId}`,
  });

  const [selectedImage, setSelectedImage] = useState(dancer.images[0]);

  const handleOnSlide = (idx) => {
    setSelectedImage(dancer.images[idx]);
  };

  useEffect(() => {
    handleOnSlide(ref.current.getCurrentIndex());
  }, [disabled]);

  const imageLength = dancer?.images?.length || 0;

  useEffect(() => {
    if (ref.current.getCurrentIndex() !== 0) {
      handleOnSlide(0);
    }
  }, [imageLength]);

  const verificationLabel = selectedImage.is_verified ? (
    <>
      <Checkmark />
      <span className={S.textOutline}>{t('common.photo_accepted')}</span>

    </>
  ) : (
    <>
      <FaTimes className={classNames(S.icon, S.iconOutline)} />
      <span className={S.textOutline}>{t('common.photo_rejected')}</span>
    </>
  );
  const verificationsClasses = selectedImage.is_verified ? 'bg-green-600 text-green-600 outline-black' : 'bg-rose-200 text-red-600';

  return (
    <div className={S.carouselArea}>
      {disabled && <div className={'flex-1 bg-opacity-50 bg-black absolute inset-x-0 inset-y-0 z-10'} />}
      {dancer.images.length > 0 ? (
        <div className={classNames({ [S.blocked]: blocked, [S.inGroup]: inGroup })}>
          {selectedImage.is_verified !== null && (
            <div
              className={`flex items-center gap-2 w-full px-2 py-1 absolute  font-bold uppercase z-10 bg-opacity-10  ${verificationsClasses}`}
            >
              {verificationLabel}
            </div>
          )}
          <ImageGallery
            ref={ref}
            showBullets
            showFullscreenButton={false}
            showPlayButton={false}
            onErrorImageURL={defaultUserPic}
            items={dancer.images.map(({ thumb, large }) => ({
              original: large,
              thumbnail: thumb,
            }))}
            renderCustomControls={() => (admin ? customControls() : null)}
            onSlide={handleOnSlide}
          />
        </div>
      ) : (
        <div className={S.carouselImgContainer}>
          <img src={defaultUserPic} alt={dancer.name} />
        </div>
      )}
    </div>
  );
};

export default forwardRef(ModalDancerCarousel);
