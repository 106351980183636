import React, { useCallback, useRef, useState } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import S from './modal-previous-bookings.module.scss';
import { DancerAppTable } from './dancer-application-table/dancer-app-table';
import { Tabs } from '../../common/tabs';
import { CloseButton } from '../../common/buttons/close-button';
import { BOOKING_STATUS } from '../../../helpers/enums';
import { useOverlayClick } from '../../../helpers/hooks/use-overlay-click';

const ModalPreviousBookings = ({ onClose, dancerId }) => {
  const { t } = useTranslation();
  const MODAL_REF = useRef(null);
  const [activeTab, setActiveTab] = useState('all');
  const TABS = [
    { label: t('common.all'), key: 'all' },
    { label: t('common.accepted'), key: BOOKING_STATUS.ACCEPTED },
    { label: t('common.pending'), key: BOOKING_STATUS.PENDING },
    { label: t('common.rejected'), key: BOOKING_STATUS.REJECTED },
  ];
  const handleTabSelect = useCallback((tab) => setActiveTab(tab), []);

  useOverlayClick(MODAL_REF, onClose);

  return (
    <div className={S.previousBookingsModal} ref={MODAL_REF}>
      <CloseButton onClick={onClose} />
      <h3 className={S.modalHeading}>{t('dancerPage.previous_bookings')}</h3>
      <Tabs activeTab={activeTab} tabs={TABS} handleTabClick={handleTabSelect} />

      <DancerAppTable dancerId={dancerId} activeTab={activeTab} />
    </div>
  );
};

ModalPreviousBookings.displayName = 'ModalPreviousBookings';
ModalPreviousBookings.propTypes = {
  dancerId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export { ModalPreviousBookings };
