import React, { useCallback } from 'react';
import * as PropTypes from 'prop-types';
import { useQuery } from '@apollo/react-hooks';
import { logError } from '../../../../../helpers/errors/bug-report';
import { useTranslation } from 'react-i18next';

import S from './accepted-list-item.module.scss';
import { Image } from '../../../../common/images/img-file';
import { LoadingSpinner } from '../../../../common/loading/loading-spinner';
import { useModal } from '../../../../global-hooks';
import { GET_INVITED_DANCER_FROM_CODE_GQL } from '../../../../../graphql/queries/regristration-code-dancers-list';
import { UI_MODALS } from '../../../../../helpers/enums';

const AcceptedListItem = ({ codeId }) => {
  const { initModal } = useModal();
  const { t } = useTranslation();
  const { loading, error, data } = useQuery(GET_INVITED_DANCER_FROM_CODE_GQL, {
    variables: { codeId },
    onError: (err) => logError(err, 'GET_INVITED_DANCER_FROM_CODE_GQL', AcceptedListItem.displayName),
  });

  const handleViewProfile = useCallback((id) => initModal(UI_MODALS.DANCER, { dancerId: id }), [initModal]);

  return (
    <div className={S.acceptedItem}>
      {loading ? (
        <LoadingSpinner />
      ) : error ? (
        <h3 className={S.errorPage}>{t('employeePage.unable_to_load_invited_users')}</h3>
      ) : (
        data.registration_code.dancers.map(({ id, first_image, name }) => (
          <div key={id} className={S.acceptedDancer} onClick={() => handleViewProfile(id)}>
            <Image src={first_image?.thumb} alt={name} fallback='USER' />
            <p>{name}</p>
          </div>
        ))
      )}
    </div>
  );
};

AcceptedListItem.displayName = 'AcceptedListItem';
AcceptedListItem.propTypes = {
  codeId: PropTypes.string.isRequired,
};

export { AcceptedListItem };
