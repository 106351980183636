import { useCallback } from 'react';
import * as React from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import S from '../existing-modal.module.scss';
import * as ExistingCtx from '../../modal-existing-context';
import { Image } from '../../../../common/images/img-file';

const ExistingEmployeeItem = ({ id, name, first_image, roles, options }) => {
  const { t } = useTranslation();
  const { state, dispatch } = ExistingCtx.useExistingCtx();
  const handleClubSelection = useCallback(
    (e) => dispatch(ExistingCtx.setExistingRoleField(id, e.target.value)),
    [dispatch, id]
  );

  return (
    <li className={S.employeeListItem}>
      <Image alt={name} src={first_image?.thumb} fallback='USER' />
      <div className={S.nameArea}>
        {name}
        <span>
          {roles?.[state.selectedClubId]?.slug
            ? t(`employeePage.roles.${roles?.[state.selectedClubId]?.slug}`)
            : t('common.unknown')}
        </span>
      </div>
      <select className={S.dropDown} value={state.form[id] || ''} onChange={handleClubSelection}>
        {options.map(({ label, value }, i) => (
          <option key={`role_${id}_${i}`} value={value}>
            {t(label)}
          </option>
        ))}
      </select>
    </li>
  );
};

ExistingEmployeeItem.displayName = 'ExistingEmployeeItem';
ExistingEmployeeItem.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  roles: PropTypes.object,
  first_image: PropTypes.object,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
};

export { ExistingEmployeeItem };
