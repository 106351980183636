import React, { useCallback, useRef, useState } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import * as PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import S from './modal-add-organization.module.scss';
import { useModal } from '../../global-hooks';
import { FormTextField } from '../../common/form/form-text-field';
import { ADD_ORG_GQL } from '../../../graphql/mutations/organization-add';
import { validateOrgName } from '../../../helpers/validations/organization-validations';
import { useOverlayClick } from '../../../helpers/hooks/use-overlay-click';
import { logError } from '../../../helpers/errors/bug-report';
import { GET_ORGANIZATIONS_GQL } from '../../../graphql/queries/get-org-query';

const ModalAddOrganization = ({ onClose }) => {
  const { t } = useTranslation();
  const MODAL_REF = useRef();
  const { closeModal } = useModal();
  const [name, setName] = useState('');
  const [error, setError] = useState('');
  const [nameList, setNameList] = useState([]);

  useQuery(GET_ORGANIZATIONS_GQL, {
    fetchPolicy: 'network-only',
    onError: (err) => logError(err, 'GET_ORGANIZATIONS_GQL', ModalAddOrganization.displayName),
    onCompleted: ({ organizations }) => setNameList(organizations.map(({ name }) => name)),
  });

  const [submitOrg, { loading }] = useMutation(ADD_ORG_GQL, {
    update: (
      cache,
      {
        data: {
          organization_create: { organization },
        },
      }
    ) => {
      cache.writeData({
        id: `Organization:${organization.id}`,
        data: organization,
      });
    },
    onError: (err) => {
      logError(err, 'ADD_ORG_GQL', ModalAddOrganization.displayName);
      toast.error('Server Error: Organization could not be added');
      toast.error(`${t('errors.server.500')}. ${t('modals.organization.add.failure')}`);
    },
    onCompleted: () => {
      toast.success(t('modals.organization.add.success'));
      closeModal();
    },
  });

  const handleChange = useCallback(
    (e) => {
      setName(e.target.value);
      setError(validateOrgName(e.target.value, nameList));
    },
    [nameList]
  );

  const handleBlur = useCallback(
    (e) => {
      const INPUT = e.target.value.trim();
      setName(INPUT);
      setError(validateOrgName(INPUT, nameList));
    },
    [nameList]
  );

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      const ERROR = validateOrgName(name.trim(), nameList);
      ERROR ? setError(ERROR) : submitOrg({ variables: { name: name.trim() } });
    },
    [submitOrg, name, nameList]
  );

  useOverlayClick(MODAL_REF, onClose);

  return (
    <form className={S.addOrgModal} onSubmit={handleSubmit} ref={MODAL_REF}>
      <h3 className={S.modalHeading}>{t('clubPage.add_org')}</h3>
      <FormTextField
        id='modal_organization_name'
        label={t('modals.organization.name')}
        name='name'
        placeholder={t('modals.organization.name')}
        error={error}
        value={name}
        isDisabled={loading}
        handleChange={handleChange}
        handleBlur={handleBlur}
      />
      <div className={S.buttonRow}>
        <button type='button' disabled={loading} className={S.invertedBtnRed} onClick={onClose}>
          {t('common.cancel')}
        </button>
        <button type='submit' disabled={loading} className={S.invertedBtnGreen}>
          {t('common.submit')}
        </button>
      </div>
    </form>
  );
};

ModalAddOrganization.displayName = 'ModalAddOrganization';

ModalAddOrganization.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export { ModalAddOrganization };
