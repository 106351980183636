import { gql } from 'apollo-boost';

export const GET_ORGANIZATIONS_GQL = gql`
    {
        organizations {
            id
            name
        }
    }
`;
