import React from 'react';
import PropTypes from 'prop-types';

const GroupNotificationIcon = ({height, width}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    height={height}
    width={width}
    viewBox="0 0 30 30"
    preserveAspectRatio="xMidYMid meet"
  >
    <g fill="none" fillRule="evenodd">
      <mask fill="currentColor">
        <path d="M0 0h26.4v23.1H0z"/>
      </mask>
      <path
        fill="currentColor"
        d="M12.54 5.852a1.997 1.997 0 11.002-3.993 1.997 1.997 0 01-.002 3.993m9.993-2.707C19.955 1.05 16.843 0 13.2 0 9.556 0 6.445 1.05 3.867 3.145 1.29 5.243 0 7.769 0 10.725c0 2.544.98 4.795 2.94 6.755-.207.757-.551 1.556-1.032 2.397-.482.843-.869 1.444-1.16 1.805-.293.36-.5.601-.619.722-.12.12-.146.265-.077.438a.505.505 0 00.154.18.343.343 0 00.207.078c2.611 0 5.035-.876 7.27-2.63 1.753.654 3.591.98 5.517.98.526 0 1.038-.028 1.541-.072-.525-1.662-1.39-4.717-1.237-6.35-.921.436-1.752 1.059-2.532 1.729.246.174.964.996 1.39 1.494a.698.698 0 01-.77 1.108c-.938-.344-2.797-1.121-3.387-1.687-.474-.454-.487-1.298 0-1.75 1.215-1.125 2.491-2.19 3.95-2.955-.449-1.035-1.174-2.734-1.453-3.355-.46-1.023-3.997-3.724-4.908-4.515a.528.528 0 01.575-.877l3.45 1.642a7.658 7.658 0 005.724.343 202.199 202.199 0 013.937-1.267.515.515 0 01.593.213.502.502 0 01-.143.674c-.974.675-3.704 2.529-5.032 3.036.118.264.586 1.51.956 2.504.277.742.367 1.538.275 2.324-.253 2.145.089 5.799.295 7.447 2.289-.448 4.329-1.383 6.109-2.831 2.578-2.097 3.867-4.623 3.867-7.58 0-2.956-1.29-5.482-3.867-7.58"
      />
    </g>
  </svg>
);

GroupNotificationIcon.displayName = 'GroupNotificationIcon';

GroupNotificationIcon.propTypes = {
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired
};

export { GroupNotificationIcon };
