import { gql } from 'apollo-boost';

import { MESSAGE_FRAGMENT } from './message-fragment';
import { CHAT_USER_FRAGMENT } from './chat-user-fragment';

export const CONVERSATION_FRAGMENT = gql`
  ${MESSAGE_FRAGMENT}
  ${CHAT_USER_FRAGMENT}

  fragment conversationFragment on Conversation {
    id
    updated_at
    business_unread_messages_count
    last_message_sent
    last_message {
      ...messageFragment
    }
    ...chatUserKeys
  }
`;
