import React, { useCallback, useState, useMemo } from 'react';
import { useMutation } from '@apollo/react-hooks';
import * as PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import S from './modal-course-category.module.scss';
import { FormSelectField } from '../../common/form/form-select-field';
import { COURSE_CATEGORY_DELETE } from '../../../graphql/mutations/course-category-delete';
import { COURSES_LIST_GQL } from '../../../graphql/queries/course-list-query';
import { COURSES_CATEGORY_GQL } from '../../../graphql/queries/course-category-query';
import { logError } from '../../../helpers/errors/bug-report';

const CategoryDeleteForm = ({ onClose, categories }) => {
  const { t } = useTranslation();
  const [selected, setSelected] = useState({ id: '', name: '' });
  const COLLECTION = useMemo(() => categories.map(({ id, name }) => ({ value: id, label: name })), [categories]);

  const [deleteCategory, { loading }] = useMutation(COURSE_CATEGORY_DELETE, {
    variables: { categoryId: selected.id },
    update: (cache) => {
      const NOW = new Date().toISOString();
      const { courses } = cache.readQuery({ query: COURSES_LIST_GQL });

      cache.writeQuery({
        query: COURSES_LIST_GQL,
        data: {
          courses: courses.map((course) => {
            const UPDATED_LIST = course.course_categories.filter(({ id }) => id !== selected.id);
            const HAS_CATEGORY = course.course_categories.length !== UPDATED_LIST.length;
            return {
              ...course,
              course_categories: UPDATED_LIST,
              updated_at: HAS_CATEGORY ? NOW : course.updated_at,
            };
          }),
        },
      });

      cache.writeQuery({
        query: COURSES_CATEGORY_GQL,
        data: { course_categories: categories.filter(({ id }) => id !== selected.id) },
      });
    },
    onError: (err) => {
      logError(err, 'COURSE_CATEGORY_DELETE', CategoryDeleteForm.displayName);
      toast.error(`${t('errors.server.500')}. ${t('modals.course.category.deletion_failed')}`);
    },
    onCompleted: () => {
      toast.success(t('modals.course.category.deleted'));
      onClose();
    },
  });

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      const CONFIRM = window.confirm(t('modals.course.category.confirm_deletion', { name: selected.name }));

      if (CONFIRM) await deleteCategory();
    },
    [selected, deleteCategory]
  );

  return (
    <form onSubmit={handleSubmit}>
      <FormSelectField
        id='CATEGORY_CREATE_NAME'
        name='name'
        label={t('modals.course.category.name')}
        value={selected.id}
        isDisabled={loading}
        options={[{ value: '', label: t('modals.course.category.select') }, ...COLLECTION]}
        handleChange={(e) =>
          setSelected({
            id: e.target.value,
            name: e.target.options[e.target.selectedIndex].text,
          })
        }
      />

      <div className={S.actionRow}>
        <button type='button' disabled={loading} onClick={onClose} className={S.invertedBtnBlue}>
          {t('common.cancel')}
        </button>
        <button type='submit' disabled={!selected.id || loading} className={S.invertedBtnRed}>
          {t('common.delete')}
        </button>
      </div>
    </form>
  );
};

CategoryDeleteForm.displayName = 'CategoryDeleteForm';
CategoryDeleteForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  categories: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export { CategoryDeleteForm };
