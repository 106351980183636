import * as PropTypes from 'prop-types';
import React, { useState } from 'react';
import { GridDropZone, GridItem } from 'react-grid-dnd';
import { FaGripVertical } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { RouteCreator } from '../../../routes/route-constants';

import S from './groups-list.module.scss';
import { GroupGrid } from '../../../common/group-grid';

const GroupList = ({ groups, isEditable }) => {
  const [canMove, setCanMove] = useState(false);

  return (
    <GridDropZone
      id='groupDropZone'
      className={S.groupList}
      boxesPerRow={4}
      rowHeight={310}
      disableDrag={!isEditable || !canMove}
      disableDrop={!isEditable || !canMove}
    >
      {groups.map(({ id, name, number_of_dancers, dancers }) => (
        <GridItem key={`GROUP_${id}`} className={S.gridItem} onMouseUp={() => setCanMove(false)}>
          <div className={S.group}>
            <Link to={RouteCreator.groupDetails(id)}>
              <GroupGrid groupId={id} dancers={dancers} count={number_of_dancers} />
            </Link>

            <div className={S.groupBottomRow}>
              {isEditable && <FaGripVertical onMouseDown={() => setCanMove(true)} />}
              <p className='w-4/6 truncate ...' title={name}>
                {name}
              </p>
            </div>
          </div>
        </GridItem>
      ))}
    </GridDropZone>
  );
};

GroupList.propTypes = {
  isEditable: PropTypes.bool.isRequired,
  groups: PropTypes.array.isRequired,
};

export { GroupList };
