import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { AddressColumn } from './address-column';
import * as ClubFormCtx from '../club-form-context';
import { FormSelectField } from '../../../../common/form/form-select-field';
import { FormTextField } from '../../../../common/form/form-text-field';
import { US_STATES } from '../../../../../helpers/constants';
import * as ClubValidations from '../../../../../helpers/validations/club-validation';

const AddressRow = ({ isDisabled }) => {
  const { t } = useTranslation();
  const {
    dispatch,
    state: { form, errors },
  } = ClubFormCtx.useClubFormCtx();
  const STATE_IDS = useMemo(() => US_STATES.map(({ value }) => value), []);

  return (
    <>
      <AddressColumn isDisabled={isDisabled} />

      <FormTextField
        id='club_form_city'
        gridArea='city'
        name='city'
        label={t('common.city')}
        error={errors.city}
        isDisabled={isDisabled}
        value={form.city}
        handleBlur={({ target: { name, value } }) => {
          dispatch(ClubFormCtx.setClubFormField(name, value.trim()));
          dispatch(ClubFormCtx.setClubFormError(name, ClubValidations.validateClubCity(value.trim())));
        }}
        handleChange={({ target: { name, value } }) => {
          dispatch(ClubFormCtx.setClubFormField(name, value));
          dispatch(ClubFormCtx.setClubFormError(name, ClubValidations.validateClubCity(value)));
        }}
      />

      <FormSelectField
        id='club_form_state'
        gridArea='state'
        label={t('common.state')}
        name='state'
        value={form.state}
        error={errors.state}
        isDisabled={isDisabled}
        options={[{ label: t('common.select_option'), value: '' }, ...US_STATES]}
        handleChange={({ target: { name, value } }) => {
          dispatch(ClubFormCtx.setClubFormField(name, value.trim()));
          dispatch(ClubFormCtx.setClubFormError(name, ClubValidations.validateClubState(value.trim(), STATE_IDS)));
        }}
      />

      <FormTextField
        id='club_form_postal'
        gridArea='zip'
        name='postal'
        label={t('common.postal_code')}
        error={errors.postal}
        isDisabled={isDisabled}
        value={form.postal}
        handleBlur={({ target: { name, value } }) => {
          dispatch(ClubFormCtx.setClubFormField(name, value.trim()));
          dispatch(ClubFormCtx.setClubFormError(name, ClubValidations.validateClubZip(value.trim())));
        }}
        handleChange={({ target: { name, value } }) => {
          dispatch(ClubFormCtx.setClubFormField(name, value));
          dispatch(ClubFormCtx.setClubFormError(name, ClubValidations.validateClubZip(value)));
        }}
      />
    </>
  );
};

AddressRow.displayName = 'AddressRow';
AddressRow.propTypes = {
  isDisabled: PropTypes.bool.isRequired,
};

export { AddressRow };
