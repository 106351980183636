import { GET_GROUP_GQL } from '../../../../graphql/queries/group-info';

export const removeDancerFromGroup = (cache, dancerId, { groupId, isAdmin, businessId }) => {
  const variables = {groupId, isAdmin, hasLocation: false, blockedByBusinessId: businessId };
  const { group } = cache.readQuery({query: GET_GROUP_GQL, variables});

  cache.writeQuery({
    query: GET_GROUP_GQL,
    variables,
    data: {
      group: {
        ...group,
        number_of_dancers: group.number_of_dancers - 1,
        default: group.default,
        dancers: group.dancers.filter(({id}) => id !== dancerId)
      }
    }
  });
};
