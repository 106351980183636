import { GridDropZone, GridItem } from 'react-grid-dnd';
import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import * as classNames from 'classnames';

import S from './club-logo.module.scss';
import * as ClubFormCtx from '../club-form-context';
import { GridImage } from '../../../../common/images/grid-image';

const ClubLogo = ({ hasPermission, isDisabled, order, canMove, handleDelete, handleAdd }) => {
  const {
    state: {
      form: { images },
    },
  } = ClubFormCtx.useClubFormCtx();
  const [disabledChanges, setDisableChanges] = useState(false);

  function testDown() {
    const IMG = images[0];
    setDisableChanges(!canMove || IMG.preview === null || IMG.isLoading);
  }

  function testUp() {
    setDisableChanges(true);
  }

  return (
    <GridDropZone
      id='logo'
      rowHeight={150}
      boxesPerRow={1}
      className={classNames(S.clubLogoWrapper, { [S.disabled]: isDisabled })}
      disableDrag={!hasPermission || isDisabled || disabledChanges}
      disableDrop={!hasPermission || isDisabled || disabledChanges}
    >
      {order.map((item, i) => (
        <div key={item.id} onMouseDown={testDown} onMouseUp={testUp}>
          <GridItem id={item.id}>
            <GridImage
              isRound
              hasIcon
              alt='Club Logo'
              hasPermission={hasPermission}
              isLoading={images[i].isLoading}
              previewSrc={images[i].preview}
              handleFileChange={(e) => handleAdd(e, i, item, 'logo')}
              handleDeleteFile={() => handleDelete(i, 'logo')}
              itemIndex={item.id}
            />
          </GridItem>
        </div>
      ))}
    </GridDropZone>
  );
};

ClubLogo.displayName = 'ClubLogo';

ClubLogo.propTypes = {
  order: PropTypes.arrayOf(PropTypes.object).isRequired,
  canMove: PropTypes.bool.isRequired,
  hasPermission: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  handleDelete: PropTypes.func.isRequired,
  handleAdd: PropTypes.func.isRequired,
};

export { ClubLogo };
