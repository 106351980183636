import { gql } from 'apollo-boost';

export const GET_INVITE_CODE_REQUEST_LIST = gql`
  query InviteCodeRequests(
    $status: InviteCodeRequestStatus!
    $first: Int
    $after: String
    $last: Int
    $before: String
  ) {
    invite_code_requests(first: $first, after: $after, last: $last, before: $before, status: $status) {
      pageInfo {
        hasNextPage
        startCursor
        hasPreviousPage
        endCursor
      }
      totalCount
      nodes {
        id
        name
        phone_number
        email
        message
        created_at
        updated_at
        city
        affiliated_clubs
        status
        notes
        invite_code {
          id
          code
        }
      }
    }
  }
`;

export const RESEND_INVITE_CODE_EMAIL = gql`
  mutation InviteCodeRequestResend($input: InviteCodeRequestResendInput!) {
    invite_code_request_resend(input: $input) {
      invite_code_request {
        id
      }
    }
  }
`;

export const GET_INVITE_CODES = gql`
  query (
    $userId: ID
    $businessId: ID
    $admin: Boolean
    $hasBusiness: Boolean
    $isClubAdmin: Boolean!
    $search: String
    $active: Boolean
  ) {
    registration_codes(
      admin: $admin
      hasBusiness: $hasBusiness
      forUser: $userId
      forBusiness: $businessId
      search: $search
      active: $active
    ) {
      id
      active
      code @include(if: $isClubAdmin)
      users_count
      description
      location {
        id
        city
        state
        country
        google_places_id
      }
      business {
        id
      }
      user {
        id
        name
        first_image {
          id
          thumb
          medium
        }
      }
    }
  }
`;

export const REGISTRATION_CODE_LIST = gql`
  query RegistrationCodes($active: Boolean, $hasBusiness: Boolean, $forUser: ID, $forBusiness: ID) {
    registration_codes(active: $active, hasBusiness: $hasBusiness, forUser: $forUser, forBusiness: $forBusiness) {
      id
      code
      business {
        id
        name
        city
      }
      user {
        id
        name
        email
      }
      promotional
      description
      users_count
    }
  }
`;
