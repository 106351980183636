import * as DancerSearchCTX from '../../dancer-search-context';
import { CheckboxSpan } from 'components/common/checkboxes/checkbox-span';
import { useTranslation } from 'react-i18next';
import S from './filter.module.scss';

export const FilterDancerDeleted = () => {
  const { t } = useTranslation();
  const {
    dispatch,
    searchParams: { deleted },
  } = DancerSearchCTX.useDancerSearchCtx(true);

  const handleOnChange = () => {
    dispatch(DancerSearchCTX.setDancerSearchField('deletedFilter', !deleted));
  };

  return (
    <div className={S.leftWrapper}>
      <span>{t('common.deleted')}</span>
      <div className={S.control} onClick={handleOnChange}>
        <CheckboxSpan isForm={true} isChecked={deleted} />
      </div>
    </div>
  );
};
