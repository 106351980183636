import * as React from 'react';
import * as PropTypes from 'prop-types';

import S from './tabs.module.scss';

const Tabs = ({ activeTab, handleTabClick, tabs, isDisabled }) => {
  return (
    <div className={S.tabs}>
      {tabs.map(({ key, label }) => (
        <button
          type='button'
          disabled={isDisabled}
          key={`applicant_tab_${key}`}
          className={activeTab === key ? S.active : S.inactive}
          onClick={() => handleTabClick(key)}
        >
          {label}
        </button>
      ))}
      <div className={S.empty} />
    </div>
  );
};

Tabs.displayName = 'TabPresenter';
Tabs.propTypes = {
  isDisabled: PropTypes.bool,
  activeTab: PropTypes.string.isRequired,
  handleTabClick: PropTypes.func.isRequired,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    })
  ),
};

Tabs.defaultProps = {
  isDisabled: false,
};

export { Tabs };
