import { gql } from 'apollo-boost';

export const LOCATION_FRAGMENT = gql`
    fragment LocationKeys on Dancer {
        locations {
            id
            latitude
            longitude
            city
            state
            google_places_id
        }
    }
`;
