import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { useApolloClient, useQuery } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';
import S from './club-search-page.module.scss';
import { ClubCollection } from './club-collection';
import { ClubSearchHeader } from './club-search-header';
import { RouteConstants } from '../../../routes/route-constants';
import { LoadingDiscoWithContainer } from '../../../common/loading/loading-disco';
import { ALL_BUSINESS_GQL } from '../../../../graphql/queries/business-get-all';
import { logError } from '../../../../helpers/errors/bug-report';
import { sortByCollectionName } from '../../../../helpers/sorts';

const ClubSearchPage = ({ businessId }) => {
  const HISTORY = useHistory();
  const { t } = useTranslation();
  const [filter, setFilter] = useState('');
  const [isAsc, setIsAsc] = useState(true);
  const [isList, setIsList] = useState(true);
  const CLIENT = useApolloClient();

  const { loading, error, data } = useQuery(ALL_BUSINESS_GQL, {
    fetchPolicy: 'network-only',
    onCompleted: ({ businesses }) => {
      const atg = new URLSearchParams(window.location.search).get('atg');
      const BIZ_ID = new URLSearchParams(window.location.search).get('bId');
      const dancerId = new URLSearchParams(window.location.search).get('dId');

      if (atg && dancerId && BIZ_ID) {
        const BUSINESS_ID = businesses.find(({ id }) => id === businessId);

        if (BUSINESS_ID) {
          CLIENT.writeData({ data: { userClubId: businessId } });
          HISTORY.push({
            pathname: RouteConstants.groupSearch,
            search: window.location.search,
          });
        }
      } else if (!businessId) {
        const BUSINESS_ID = sortByCollectionName(isAsc, businesses)[0].id;
        CLIENT.writeData({ data: { userClubId: BUSINESS_ID } });
      }
    },
    onError: (err) => {
      logError(err, 'ALL_BUSINESS_GQL', ClubSearchPage.displayName);
      toast.error(`${t('errors.server.500')}: ${t('clubPage.club_data_error')}`);
    },
  });

  if (loading) {
    return <LoadingDiscoWithContainer />;
  }
  if (error) {
    return <h1 className={S.errorPage}>{t('errors.server.500')}</h1>;
  }

  const CLUBS = data.businesses.filter(({ name }) => name.toLowerCase().includes(filter.toLowerCase()));

  return (
    <div className={S.pageWrapper}>
      <ClubSearchHeader
        isList={isList}
        filter={filter}
        setFilter={setFilter}
        setIsList={setIsList}
        isAsc={isAsc}
        setIsAsc={setIsAsc}
      />

      {CLUBS.length === 0 ? (
        <h3 className={S.warning}>{t('clubPage.no_clubs')}</h3>
      ) : (
        <ClubCollection businessId={businessId} isList={isList} clubs={data.businesses} filter={filter} isAsc={isAsc} />
      )}
    </div>
  );
};

ClubSearchPage.displayName = 'ClubSearchPage';
ClubSearchPage.propTypes = { businessId: PropTypes.string };
ClubSearchPage.defaultProps = { businessId: null };

export { ClubSearchPage };
