import { gql } from 'apollo-boost';

export const SHIFT_PROMO_UPDATE = gql`
    mutation PromoUpdate(
        $eventId:ID!
        $startTime: DateTime!
        $endTime: DateTime!
        $slots: Int!
        $title: String!
        $description: String!
        $preapprovedIds: [ID!]!
        $autoCheckoutInterval: Int
        $hardcapped: Boolean!
        $recurring: Boolean
        $separateSeries: Boolean
    ){
        shift_promo_update(input:{
            recurring: $recurring,
            detachFromSiblingRecurrences: $separateSeries,
            promoUpdateParams:{
                id: $eventId,
                startTime: $startTime,
                endTime: $endTime,
                preapprovedGroupIds: $preapprovedIds
                slots: $slots,
                title: $title,
                description: $description
                autoCheckoutInterval: $autoCheckoutInterval
                hardcapped: $hardcapped
            },
        }){
            promo {
                id
                start_time
                end_time
                title
                description
                slots
                business { id }
                preapproved_group_ids
                auto_checkout_interval
            }
        }
    }
`;
