import runtimeEnv from '@mars/heroku-js-runtime-env';
import { EMPLOYEE_ROLES } from './enums';
import { RouteConstants } from 'components/routes/route-constants';

// export const ANDROID_STORE = 'https://play.google.com/store/apps/details?id=com.indywork.poleposition';
// export const IOS_STORE = 'https://apps.apple.com/us/app/pole-position-the-app/id1470164373';
const ENV = runtimeEnv();

export const APIConstants = {
  BI_API_URL: ENV.REACT_APP_BI_API_URL,
  sentry_dsn: ENV.REACT_APP_SENTRY_DSN,
  logrocket_dsn: ENV.REACT_APP_LOGROCKET_DSN,
  posthog_key: ENV.REACT_APP_POSTHOG_KEY,
  environment: ENV.REACT_APP_ENVIRONMENT,
  app_version: ENV.REACT_APP_APP_VERSION,
  domain: ENV.REACT_APP_SERVER_DOMAIN,
  socket_protocol: ENV.REACT_APP_WEBSOCKER_PROTOCOL,
  base_api_url: `${ENV.REACT_APP_SERVER_PROTOCOL}://${ENV.REACT_APP_SERVER_DOMAIN}/api`,
  graphq_ql: `${ENV.REACT_APP_SERVER_PROTOCOL}://${ENV.REACT_APP_SERVER_DOMAIN}/graphql`,
  getstream: {
    // https://eu-west-api.stream-io-api.com/api/v1.0/reaction/?api_key=wsmag6xa2r2s
    api: {
      baseUrl: `https://${ENV.REACT_APP_GETSTREAM_API_LOCATION}-${ENV.REACT_APP_GETSTREAM_API_HOSTNAME}`,
      fullUrl: `https://${ENV.REACT_APP_GETSTREAM_API_LOCATION}-${ENV.REACT_APP_GETSTREAM_API_HOSTNAME}/api/v1.0`,
      key: ENV.REACT_APP_GETSTREAM_API_KEY,
    },
    authToken: ENV.REACT_APP_GETSTREAM_AUTH_TOKEN,
  },
  imagekit: {
    urlEndpoint: ENV.REACT_APP_IMAGEKIT_API_URL,
    publicKey: ENV.REACT_APP_IMAGEKIT_PUBLIC_KEY,
  },
};

export const ENVConstant = {
  calendarDisplayClassicDays: ENV.REACT_APP_CALENDAR_DISPLAY_CLASSIC_DAYS,
  // we need this always on after all :/
  checkin_after_shift_ended: true || ENV.REACT_APP_CHECKIN_AFTER_SHIFT_ENDED === 'true',
};

export const US_STATES = [
  { value: 'AL', label: 'Alabama' },
  { value: 'AK', label: 'Alaska' },
  { value: 'AS', label: 'American Samoa' },
  { value: 'AZ', label: 'Arizona' },
  { value: 'AR', label: 'Arkansas' },
  { value: 'CA', label: 'California' },
  { value: 'CO', label: 'Colorado' },
  { value: 'CT', label: 'Connecticut' },
  { value: 'DC', label: 'District of Columbia' },
  { value: 'DE', label: 'Delaware' },
  { value: 'FL', label: 'Florida' },
  { value: 'GA', label: 'Georgia' },
  { value: 'GU', label: 'Guam' },
  { value: 'HI', label: 'Hawaii' },
  { value: 'ID', label: 'Idaho' },
  { value: 'IL', label: 'Illinois' },
  { value: 'IN', label: 'Indiana' },
  { value: 'IA', label: 'Iowa' },
  { value: 'KS', label: 'Kansas' },
  { value: 'KY', label: 'Kentucky' },
  { value: 'LA', label: 'Louisiana' },
  { value: 'ME', label: 'Maine' },
  { value: 'MD', label: 'Maryland' },
  { value: 'MA', label: 'Massachusetts' },
  { value: 'MI', label: 'Michigan' },
  { value: 'MN', label: 'Minnesota' },
  { value: 'MS', label: 'Mississippi' },
  { value: 'MO', label: 'Missouri' },
  { value: 'MT', label: 'Montana' },
  { value: 'NE', label: 'Nebraska' },
  { value: 'NV', label: 'Nevada' },
  { value: 'NH', label: 'New Hampshire' },
  { value: 'NJ', label: 'New Jersey' },
  { value: 'NM', label: 'New Mexico' },
  { value: 'NY', label: 'New York' },
  { value: 'NC', label: 'North Carolina' },
  { value: 'ND', label: 'North Dakota' },
  { value: 'OH', label: 'Ohio' },
  { value: 'OK', label: 'Oklahoma' },
  { value: 'OR', label: 'Oregon' },
  { value: 'PA', label: 'Pennsylvania' },
  { value: 'PR', label: 'Puerto Rico' },
  { value: 'RI', label: 'Rhode Island' },
  { value: 'SC', label: 'South Carolina' },
  { value: 'SD', label: 'South Dakota' },
  { value: 'TN', label: 'Tennessee' },
  { value: 'TX', label: 'Texas' },
  { value: 'UT', label: 'Utah' },
  { value: 'VT', label: 'Vermont' },
  { value: 'VA', label: 'Virginia' },
  { value: 'VI', label: 'U.S. Virgin Islands' },
  { value: 'WA', label: 'Washington' },
  { value: 'WV', label: 'West Virginia' },
  { value: 'WI', label: 'Wisconsin' },
  { value: 'WY', label: 'Wyoming' },
];

export const OPERATION_TIMES = [
  { value: '12:00AM', label: '12:00 am' },
  { value: '12:30AM', label: '12:30 am' },
  { value: '01:00AM', label: '01:00 am' },
  { value: '01:30AM', label: '01:30 am' },
  { value: '02:00AM', label: '02:00 am' },
  { value: '02:30AM', label: '02:30 am' },
  { value: '03:00AM', label: '03:00 am' },
  { value: '03:30AM', label: '03:30 am' },
  { value: '04:00AM', label: '04:00 am' },
  { value: '04:30AM', label: '04:30 am' },
  { value: '05:00AM', label: '05:00 am' },
  { value: '05:30AM', label: '05:30 am' },
  { value: '06:00AM', label: '06:00 am' },
  { value: '06:30AM', label: '06:30 am' },
  { value: '07:00AM', label: '07:00 am' },
  { value: '07:30AM', label: '07:30 am' },
  { value: '08:00AM', label: '08:00 am' },
  { value: '08:30AM', label: '08:30 am' },
  { value: '09:00AM', label: '09:00 am' },
  { value: '09:30AM', label: '09:30 am' },
  { value: '10:00AM', label: '10:00 am' },
  { value: '10:30AM', label: '10:30 am' },
  { value: '11:00AM', label: '11:00 am' },
  { value: '11:30AM', label: '11:30 am' },
  { value: '12:00PM', label: '12:00 pm' },
  { value: '12:30PM', label: '12:30 pm' },
  { value: '01:00PM', label: '01:00 pm' },
  { value: '01:30PM', label: '01:30 pm' },
  { value: '02:00PM', label: '02:00 pm' },
  { value: '02:30PM', label: '02:30 pm' },
  { value: '03:00PM', label: '03:00 pm' },
  { value: '03:30PM', label: '03:30 pm' },
  { value: '04:00PM', label: '04:00 pm' },
  { value: '04:30PM', label: '04:30 pm' },
  { value: '05:00PM', label: '05:00 pm' },
  { value: '05:30PM', label: '05:30 pm' },
  { value: '06:00PM', label: '06:00 pm' },
  { value: '06:30PM', label: '06:30 pm' },
  { value: '07:00PM', label: '07:00 pm' },
  { value: '07:30PM', label: '07:30 pm' },
  { value: '08:00PM', label: '08:00 pm' },
  { value: '08:30PM', label: '08:30 pm' },
  { value: '09:00PM', label: '09:00 pm' },
  { value: '09:30PM', label: '09:30 pm' },
  { value: '10:00PM', label: '10:00 pm' },
  { value: '10:30PM', label: '10:30 pm' },
  { value: '11:00PM', label: '11:00 pm' },
  { value: '11:30PM', label: '11:30 pm' },
];

export const NOTIFICATION_TYPES = {
  ALL: { value: 'all_users', label: 'notificationsPage.all_users' },
  WORKERS: { value: 'all_workers', label: 'notificationsPage.workers' },
  DANCERS: { value: 'all_dancers', label: 'notificationsPage.dancers' },
  EMPLOYEES: { value: 'all_employees_by_club', label: 'notificationsPage.all_employees_by_club' },
};

export const EMPLOYEE_ROLE_OPTIONS = [
  { value: '', label: 'employeePage.roles.select_role' },
  { value: 'booking_agent', label: 'employeePage.roles.booking_agent' },
  { value: 'manager', label: 'employeePage.roles.manager' },
  { value: 'general_manager', label: 'employeePage.roles.general_manager' },
  { value: 'regional_manager', label: 'employeePage.roles.regional_manager' },
  { value: 'executive', label: 'employeePage.roles.executive' },
];

export const EMPLOYEE_LVLS = {
  [EMPLOYEE_ROLES.EXECUTIVE]: 10,
  [EMPLOYEE_ROLES.REGIONAL_MANAGER]: 8,
  [EMPLOYEE_ROLES.GENERAL_MANAGER]: 6,
  [EMPLOYEE_ROLES.MANAGER]: 4,
  [EMPLOYEE_ROLES.BOOKING_AGENT]: 2,
};

export const INVITE_TABS = {
  GENERAL: 'General',
  BOOKINGS: 'Bookings',
};

export const BOOKING_TYPES = {
  PROMO: 'promo',
  AUDITION: 'audition',
  EVENT: 'event',
  DAY: 'day',
  EVENING: 'evening',
  NIGHT: 'night',
  LATE_NIGHT: 'late_night',
};

export const VIP_OFFER_LINK_TYPES = {
  link: 'vip.offer.link.text_link',
  pink_button: 'vip.offer.link.pink_button',
  blue_button: 'vip.offer.link.blue_button',
};

export const ON_SUSPEND_KEEP_DANCER_PAGES = [
  RouteConstants.adminConversations,
  RouteConstants.adminDancersSearch,
  RouteConstants.dancersSearch,
];
export const PHOTO_VERIFICATION_REJECTION_REASON_TRANSLATION = {
  LOW_QUALITY: {
    en: 'Low quality photo.',
    es: 'Foto de baja calidad.',
  },
  OFFENSIVE: {
    en: 'The photo is offensive.',
    es: 'La foto es ofensiva.',
  },
  MULTIPLE_PEOPLE: {
    en: 'The photo features more that a single person.',
    es: 'La foto muestra más que una sola persona.',
  },
  PROFESSIONAL_GUIDELINES: {
    en: "Doesn't meet professional guidelines.",
    es: 'No cumple con las pautas profesionales.',
  },
};
export const PHOTO_VERIFICATION_ACTIONS_TRANSLATION = {
  ACCEPT: {
    en: 'Accept entertainer',
    es: 'Acepta animador',
  },
  REJECT: {
    en: 'Reject entertainer',
    es: 'Rechazar animadora',
  },
  UNVERIFY: {
    en: 'Unverify entertainer',
    es: 'Unverify animadora',
  },
};
