import { useCallback } from 'react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { SearchInput } from '../../../../common/search-input';
import { useDebounce } from '../../../../../helpers/hooks/use-debounce';

import { setGroupNoteField, useGroupNoteCtx } from '../group-notification-context';

const GroupNotificationSearch = () => {
  const { t } = useTranslation();
  const {
    state: { search },
    dispatch,
  } = useGroupNoteCtx();
  const handleChange = useCallback((text) => dispatch(setGroupNoteField('search', text)), [dispatch]);

  const { value, onChange } = useDebounce(search, handleChange);

  return <SearchInput placeholder={t('notificationsPage.search_announcements')} onChange={onChange} />;
};

GroupNotificationSearch.displayName = 'GroupNotificationSearch';

export { GroupNotificationSearch };
