import * as PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { useProfilePageCtx } from './user-profile-ctx';
import { useProfileForm } from './use-profile-form';
import { ProfileImage } from './profile-image';
import S from './profile-form.module.scss';
import { FormTextField } from '../../../common/form/form-text-field';
import { FormSelectField } from '../../../common/form/form-select-field';

const ProfileForm = ({ isActive, onClose }) => {
  const {
    state: { form, errors },
  } = useProfilePageCtx();
  const { t } = useTranslation();

  const { isDisabled, handleChange, handleOnBlur, handleSubmit, handleCancel } = useProfileForm(onClose);

  const IS_DISABLED = useMemo(() => !isActive || isDisabled, [isActive, isDisabled]);

  return (
    <div className='flex'>
      <div>
        <ProfileImage isDisabled={!isActive} isProcessing={isDisabled} />
      </div>
      <form onSubmit={handleSubmit} className='flex flex-col w-136'>
        <div className='text-lg font-semibold mt-4 mb-4'>{t('common.user_info')}</div>
        <div className='flex flex-row w-full justify-between'>
          <FormTextField
            id='employeeFormNameField'
            name='name'
            label={t('common.full_name')}
            isDisabled={IS_DISABLED}
            value={form.name}
            error={errors.name}
            handleChange={handleChange}
            handleBlur={handleOnBlur}
          />

          <FormTextField
            id='employeeFormEmailField'
            name='email'
            label={t('common.email_address')}
            classname='w-52'
            isDisabled={IS_DISABLED}
            value={form.email}
            error={errors.email}
            handleChange={handleChange}
            handleBlur={handleOnBlur}
          />

          <FormSelectField
            id='employeeLanguageField'
            label={t('common.language')}
            name='locale'
            classname='w-28'
            value={form.locale}
            error={errors.locale}
            isDisabled={IS_DISABLED}
            options={[
              { value: 'en', label: t('common.english') },
              { value: 'es', label: t('common.spanish') },
            ]}
            handleChange={handleChange}
          />
        </div>
        <div className='text-lg font-semibold mt-8 mb-4'>{t('employeePage.profile.change_password')}</div>
        <div className='flex flex-row w-full justify-between'>
          <FormTextField
            id='employeeFormPasswordField'
            name='password'
            label={t('common.password')}
            type='password'
            classname='w-min-40 w-[40%]'
            isDisabled={IS_DISABLED}
            value={form.password}
            error={errors.password}
            handleChange={handleChange}
            handleBlur={handleOnBlur}
          />

          <FormTextField
            id='employeeFormConfirmField'
            label={t('common.confirm_password')}
            name='confirm'
            type='password'
            classname='w-min-40 w-[40%]'
            isDisabled={IS_DISABLED}
            value={form.confirm}
            error={errors.confirm}
            handleChange={handleChange}
            handleBlur={handleOnBlur}
          />
        </div>
        <div>
          {isActive && (
            <div className='flex flex-row py-8 justify-end w-full'>
              <button
                type='reset'
                onClick={handleCancel}
                className={classNames(S.invertedBtnRed, 'min-w-28 h-10 text-base')}
              >
                {t('common.cancel')}
              </button>
              <button type='submit' className={classNames(S.invertedBtnGreen, 'min-w-28 h-10 ml-5 text-base')}>
                {t('common.submit')}
              </button>
            </div>
          )}
        </div>
      </form>
    </div>
  );
};

ProfileForm.displayName = 'ProfileForm';
ProfileForm.propTypes = {
  isActive: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export { ProfileForm };
