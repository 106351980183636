import moment from 'moment';
import * as ClubFormCtx from './club-form-context';

export const shiftImagesLeft = (imageObj, index) =>
  Object.values(imageObj).reduce(
    (acm, cur, i) =>
      i <= index
        ? acm
        : {
            ...acm,
            [i - 1]: cur,
            [i]: { image_attachment: null, preview: null, id: null, isLoading: false },
          },
    imageObj
  );

export const swapImgsLeft = (imgObj, sourceIndex, targetIndex, isLoading) => {
  const IMG_LIST = Object.values(imgObj);
  const SMALLER_INDEX = sourceIndex < targetIndex ? sourceIndex : targetIndex;
  let LARGER_INDEX = sourceIndex > targetIndex ? sourceIndex : targetIndex;
  if (LARGER_INDEX > 6) LARGER_INDEX = 6;

  if (sourceIndex < targetIndex) {
    // shift left
    const SMALLER = imgObj[SMALLER_INDEX];
    return IMG_LIST.reduce((acm, cur, i) => {
      if (i < SMALLER_INDEX || i > LARGER_INDEX) return { ...acm };
      else if (i === SMALLER_INDEX) return { ...acm, [i]: { ...acm[i + 1], isLoading } };
      else if (i === LARGER_INDEX) return { ...acm, [i]: { ...SMALLER, isLoading } };
      else return { ...acm, [i]: { ...imgObj[i + 1], isLoading } };
    }, imgObj);
  } else {
    // shift right
    const LARGER = imgObj[LARGER_INDEX];
    return IMG_LIST.reduce((acm, cur, i) => {
      if (i < SMALLER_INDEX || i > LARGER_INDEX) return { ...acm };
      else if (i === SMALLER_INDEX) return { ...acm, [i]: { ...LARGER, isLoading } };
      else return { ...acm, [i]: { ...imgObj[i - 1], isLoading } };
    }, imgObj);
  }
};

export const setClubFromCache = (dispatch, {
  active,
  allow_dancer_reviews,
  allow_under_21_dancers,
  audition_info,
  city,
  dancer_count,
  dancers_are_free_agents,
  hours_of_operation,
  id,
  images,
  max_consecutive_shift_count,
  min_dancers_for_free_agent_search,
  name,
  notes,
  number_of_stages,
  organization,
  phone_number,
  profile_options,
  required_documents,
  special_requirements_link,
  square_footage,
  state,
  street_1,
  street_2,
  url,
  zip
}) => {
  dispatch(
    ClubFormCtx.setClubFormFields({
      id: id,
      name: name,
      street1: street_1,
      street2: street_2,
      city: city,
      state: state,
      postal: zip,
      phone: phone_number,
      url: url,
      schedule: hours_of_operation.reduce((acm, { day, open, close, all_day, closed, id }) => {
        let openTime;

        if (all_day) openTime = 'allDay';
        else if (closed) openTime = 'closed';
        else openTime = moment.utc(open).format('hh:mmA');

        return {
          ...acm,
          [day]: {
            id,
            open: openTime,
            close: moment.utc(close).format('hh:mmA'),
          },
        };
      }, {}),

      orgId: organization.id,
      isActive: active,
      allowReviews: allow_dancer_reviews,
      under21Allowed: allow_under_21_dancers,
      stageCount: String(number_of_stages),
      sqFootage: String(square_footage),
      maxBookings: String(max_consecutive_shift_count) || '0',
      profileOptions: profile_options.map(({ id }) => id) ?? [],
      requiredDocs: required_documents ?? [],
      auditionInfo: audition_info ?? '',
      notes: notes ?? '',
      regLink: special_requirements_link,
      hasFreeAgents: dancers_are_free_agents || false,
      minDancersForFreeAgentSearch: min_dancers_for_free_agent_search,
      dancerCount: dancer_count,
      images: images.reduce(
        (acm, { thumb, position, id }) => {
          return { ...acm, [position]: { image_attachment: null, preview: thumb, id, isLoading: false } };
        },
        {
          0: { image_attachment: null, preview: null, isLoading: false, id: null },
          1: { image_attachment: null, preview: null, isLoading: false, id: null },
          2: { image_attachment: null, preview: null, isLoading: false, id: null },
          3: { image_attachment: null, preview: null, isLoading: false, id: null },
          4: { image_attachment: null, preview: null, isLoading: false, id: null },
          5: { image_attachment: null, preview: null, isLoading: false, id: null },
          6: { image_attachment: null, preview: null, isLoading: false, id: null },
        }
      ),
    })
  );
};
