import { gql } from 'apollo-boost';

export const DELETE_BUSINESS = gql`
    mutation business_delete($id: ID!){
        business_delete(input: {
            businessId: $id
            clientMutationId: $id
        }) { clientMutationId }
    }
`;
