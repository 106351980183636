import * as React from 'react';
import { useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';

const ExtraLinks = ({ isFullText }) => {
  const { t } = useTranslation();
  return (
    <footer>
      <a
        href='https://s3-us-west-1.amazonaws.com/indywork-documents/PrivacyPolicy.pdf'
        rel='noopener noreferrer'
        target='_blank'
      >
        {isFullText ? t('common.privacy_policy') : t('common.privacy')}
      </a>
      <a href='https://polepositionmanager.zendesk.com/hc/en-us' rel='noopener noreferrer' target='_blank'>
        FAQ
      </a>
      <a
        href='https://s3-us-west-1.amazonaws.com/indywork-documents/Terms.pdf'
        rel='noopener noreferrer'
        target='_blank'
      >
        {isFullText ? t('common.terms_conditions') : t('common.terms')}
      </a>
    </footer>
  );
};

ExtraLinks.displayName = 'ExtraLinks';
ExtraLinks.propTypes = {
  isFullText: PropTypes.bool.isRequired,
};

ExtraLinks.defaultProps = {
  isFullText: false,
};

export { ExtraLinks };
