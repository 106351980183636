import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { FaUser, FaClock, FaBell } from 'react-icons/fa';
import * as PropTypes from 'prop-types';

const BookingIcon = ({ hasEnded, pendingCount, onClick }) => {
  const { t } = useTranslation();
  return (
    <div className={['flex flex-row']} onClick={onClick}>
      <FaUser size='14px' className='pr-0.5' title={t('bookingCalendar.has_applications')} />
      {pendingCount > 0 && !hasEnded && (
        <FaBell size='14px' className='text-yellow-500 animate-ping' title={`${pendingCount} ${t('common.pending')}`} />
      )}
      {hasEnded && <FaClock size='14px' className='text-gray-300' title={t('bookingCalendar.shift_ended')} />}
    </div>
  );
};

BookingIcon.propTypes = {
  pendingCount: PropTypes.number.isRequired,
  hasEnded: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
};

BookingIcon.defaultProps = {
  onClick: null,
  hasEnded: false,
  hasNotifications: false,
};

export { BookingIcon };
