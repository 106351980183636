import * as React from 'react';
import * as PropTypes from 'prop-types';

import S from './input-form-checkbox.module.scss';

const InputFormCheckbox = ({ isChecked, id, handleChange, isDisabled, name, label, gridArea }) => {
  return (
    <div className={S.inputFormCheckbox} style={{ gridArea }}>
      <input id={id} name={name} type='checkbox' checked={isChecked} disabled={isDisabled} onChange={handleChange} />
      <label htmlFor={id}>
        <span />
        {label}
      </label>
    </div>
  );
};

InputFormCheckbox.displayName = 'InputFormCheckbox';

InputFormCheckbox.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  gridArea: PropTypes.string,
  label: PropTypes.string.isRequired,
  isChecked: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
};

InputFormCheckbox.defaultProps = {};

export { InputFormCheckbox };
