import { checkText } from 'graphql/queries/suggestions';

export const SuggestionListType = {
  MANAGER: 'MANAGER',
  DANCER_FEED: 'DANCER_FEED',
  DANCER_BIO: 'DANCER_BIO',
} as const;

type ListType = keyof typeof SuggestionListType;

const useSuggestions = (type: ListType) => {
  const check = async (text: string) => {
    try {
      const result = await checkText(text, type);
      if (result.data.data.suggestions.length) {
        throw { flagged_words: result.data.data.suggestions };
      }
    } catch (error) {
      throw error;
    }
  };

  return {
    checkText: check,
  };
};

export default useSuggestions;
