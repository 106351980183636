import { DANCER_GRID_FRAGMENT } from '../fragments/dancer-grid-fragment';
import { gql } from 'apollo-boost';

export const GET_INVITE_DANCER = gql`
  ${DANCER_GRID_FRAGMENT}

  query ($id: ID!, $isAdmin: Boolean!, $hasLocation: Boolean!, $blockedByBusinessId: ID, $inGroupBusinessId: ID) {
    dancer(id: $id) {
      ...DancerGridItem
      favorite_clubs {
        id
      }
    }
  }
`;
