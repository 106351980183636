import React, { useState } from 'react';
import * as PropTypes from 'prop-types';

import S from './notification-item.module.scss';

const NotificationItem = ({ id, name, time, message }) => {
  const [isOpen, setIsOpen] = useState({
    en: false,
    es: false,
  });

  return (
    <div className={S.itemWrapper}>
      <div className={S.itemLabel}>
        {time} - <span>{name}</span>
      </div>
      {['en', 'es'].map(
        (lang) =>
          message[lang].message !== '' &&
          message[lang].title !== '' && (
            <button
              className={S.itemTitle}
              key={`announcement_${id}_${lang}`}
              onClick={() => setIsOpen({ ...isOpen, [lang]: !isOpen[lang] })}
            >
              <label>{message[lang].title}</label>
              {isOpen[lang] && <div>{message[lang].message}</div>}
            </button>
          )
      )}
    </div>
  );
};

NotificationItem.displayName = 'AnnouncementItemPresenter';
const messageObj = PropTypes.shape({
  title: PropTypes.string,
  message: PropTypes.string,
});

NotificationItem.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  time: PropTypes.string.isRequired,
  message: PropTypes.shape({
    en: messageObj,
    es: messageObj,
  }),
};

export { NotificationItem };
