import { FC } from 'react';
import * as DancerSearchCTX from '../../dancer-search-context';
import { CheckboxSpan } from 'components/common/checkboxes/checkbox-span';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import S from './filter.module.scss';

interface Props {
  disabled: boolean;
}

export const FilterDancerFreeAgent: FC<Props> = ({ disabled }) => {
  const { t } = useTranslation();
  const {
    dispatch,
    searchParams: { includeFreeAgents },
  } = DancerSearchCTX.useDancerSearchCtx(false);

  const handleOnChange = () => {
    if (disabled) {
      return;
    }
    dispatch(DancerSearchCTX.setDancerSearchField('includeFreeAgents', !includeFreeAgents));
  };

  return (
    <div className={classNames(S.leftWrapper, S.control, { [S.disabled]: disabled })}>
      <span>{t('common.free_agent')}</span>
      <div className={S.control} onClick={handleOnChange}>
        <CheckboxSpan isForm={true} isChecked={includeFreeAgents} />
      </div>
    </div>
  );
};
