import React, { useCallback } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import S from './confirmation-modal.module.scss';
import { useModalBookingCtx } from '../modal-booking-context';
import { setMetaFields } from '../modal-booking-actions';

const RecurringConfirmationModal = ({ handleDelete }) => {
  const { dispatch, state } = useModalBookingCtx();
  const { t } = useTranslation();

  const handleSingleClick = useCallback(() => handleDelete({ variables: { recurring: false } }), [handleDelete]);

  const handleSeriesClick = useCallback(
    () => handleDelete({ variables: { recurring: true, separateSeries: true } }),
    [handleDelete]
  );

  const handleAllClick = useCallback(
    () => handleDelete({ variables: { recurring: true, separateSeries: false } }),
    [handleDelete]
  );

  const handleCancelClick = useCallback(
    () =>
      dispatch(
        setMetaFields({
          confirm: null,
          isMany: false,
          isEditable: false,
        })
      ),
    [dispatch]
  );

  return (
    <div className={S.confirmationModal}>
      <button className={S.invertedBtnBlue} onClick={handleSingleClick}>
        {t('bookingCalendar.this_booking_only').toUpperCase()}
      </button>
      {state.meta?.initial?.recurring_shift?.siblings?.length > 1 && (
        <button className={S.invertedBtnBlue} onClick={handleSeriesClick}>
          {t('bookingCalendar.this_booking_weekly').toUpperCase()}
        </button>
      )}
      <button className={S.invertedBtnBlue} onClick={handleAllClick}>
        {t('bookingCalendar.all_bookings_in_series').toUpperCase()}
      </button>
      <button className={S.invertedBtnRed} onClick={handleCancelClick}>
        {t('common.cancel').toUpperCase()}
      </button>
    </div>
  );
};

RecurringConfirmationModal.displayName = 'RecurringConfirmationModal';
RecurringConfirmationModal.propTypes = {
  handleDelete: PropTypes.func.isRequired,
};

export { RecurringConfirmationModal };
