import { useQuery } from '@apollo/react-hooks';
import { useCallback, useEffect, useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import _sortBy from 'lodash/sortBy';
import { useTranslation } from 'react-i18next';

import S from './filter-dancer-styles.module.scss';
import { FilterDropDown } from './filter-dropdown';
import * as DancerSearchCTX from '../../dancer-search-context';
import { GET_DANCER_SKILLS_GQL } from 'graphql/queries/dancer-skills';

const DancerSkillsFilter = () => {
  const { t, i18n } = useTranslation();
  const {
    dispatch,
    state: { skillsFilter },
  } = DancerSearchCTX.useDancerSearchCtx();

  const [untranslatedMap, setUntranslatedMap] = useState({});
  const [filterMap, setFilterMap] = useState({});
  const [untranslatedList, setUntranslatedList] = useState([]);
  const [filterList, setFilterList] = useState([]);

  const { loading } = useQuery(GET_DANCER_SKILLS_GQL, {
    fetchPolicy: 'cache-and-network',
    onCompleted: ({ skills }) => {
      const { map, list } = skills.reduce(
        (acm, { id, display_name }) => ({
          list: [...acm.list, { label: display_name, value: id }],
          map: { ...acm.map, [id]: display_name },
        }),
        { list: [], map: {} }
      );

      setUntranslatedMap(map);
      setUntranslatedList(_sortBy(list, 'label'));
    },
  });

  useEffect(() => {
    const translatedMap = Object.keys(untranslatedMap).reduce(
      (acm, key) => ({ ...acm, [key]: untranslatedMap[key][i18n.language] }),
      {}
    );
    setFilterMap(translatedMap);

    const translatedList = untranslatedList.map(({ label, value }) => ({
      label: label[i18n.language],
      value,
    }));
    setFilterList(translatedList);
  }, [i18n.language, untranslatedList, untranslatedMap]);

  const handleRemoveSkill = useCallback(
    (skillId) =>
      dispatch(
        DancerSearchCTX.setDancerSearchField(
          'skillsFilter',
          skillsFilter.filter((id) => id !== skillId)
        )
      ),
    [dispatch, skillsFilter]
  );

  if (loading) return null;
  return (
    <div className={S.dancerStyles}>
      <div className={S.leftWrapper}>
        <span>{t('common.skills')}</span>
        <FilterDropDown options={filterList} selected={skillsFilter} fieldName={'skillsFilter'} />
      </div>
      <div className={S.removalArea}>
        {skillsFilter.map((id) => (
          <button key={`REMOVE_FILTER_${id}`} onClick={() => handleRemoveSkill(id)}>
            {filterMap[id]}
            <FaTimes />
          </button>
        ))}
      </div>
    </div>
  );
};

DancerSkillsFilter.displayName = 'DancerSkillsFilter';

export { DancerSkillsFilter };
