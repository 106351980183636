import * as React from 'react';

import * as Constants from './modal-booking-constants';

function modalBookingReducer(state, { type, field, payload }) {
  switch (type) {
    case Constants.SET_BOOKING_ERROR_KEY:
      return { ...state, errors: { ...state.errors, [field]: payload } };

    case Constants.SET_BOOKING_FORM_KEY:
      return { ...state, form: { ...state.form, [field]: payload } };

    case Constants.SET_MANY_BOOKING_FIELDS:
      return { ...state, [field]: { ...state[field], ...payload } };

    default:
      throw new Error(`Unsupported action type: ${type}`);
  }
}

const MODAL_BOOKING_CTX = React.createContext();

export function ModalBookingProvider(props) {
  const [state, dispatch] = React.useReducer(modalBookingReducer, Constants.MODAL_BOOKING_INIT_STATE);
  const value = React.useMemo(() => [state, dispatch], [state]);

  return <MODAL_BOOKING_CTX.Provider value={value} {...props} />;
}

export function useModalBookingCtx() {
  const context = React.useContext(MODAL_BOOKING_CTX);

  if (!context) {
    throw new Error(`${useModalBookingCtx.name} must be used within a ${ModalBookingProvider.name}`);
  }

  const [state, dispatch] = context;
  return { state, dispatch };
}
