import * as React from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { BookingDateField } from './booking-form-fields/booking-date-field';
import { useModalBookingCtx } from '../../modal-booking-context';
import { FormSelectField } from '../../../../common/form/form-select-field';
import { FormNumberField } from '../../../../common/form/form-number-field';
import { OPERATION_TIMES } from '../../../../../helpers/constants';

const RowBookingTime = ({ isDisabled, handleChange }) => {
  const {
    state: { form, errors },
  } = useModalBookingCtx();
  const { t } = useTranslation();

  const options = [{ label: t('common.select_option') }].concat(OPERATION_TIMES);

  return (
    <>
      <BookingDateField isDisabled={isDisabled} value={form.date} />

      <FormSelectField
        id='modal_booking_start'
        label={t('common.start')}
        gridArea='starts'
        name='start_time'
        value={form.start_time}
        error={t(errors.start_time)}
        isDisabled={isDisabled}
        options={options}
        handleChange={handleChange}
      />

      <FormSelectField
        id='modal_booking_end'
        label={t('common.end')}
        gridArea='ends'
        name='end_time'
        value={form.end_time}
        error={t(errors.end_time)}
        isDisabled={isDisabled}
        options={options}
        handleChange={handleChange}
      />

      <FormNumberField
        id='modal_booking_checkOut'
        value={parseInt(form.checkOut)}
        error={t(errors.checkOut)}
        handleChange={handleChange}
        isDisabled={isDisabled}
        gridArea='checkOut'
        name='checkOut'
        label={t('bookingCalendar.auto_check_out')}
        min={0}
      />
    </>
  );
};

RowBookingTime.displayName = 'RowBookingTime';
RowBookingTime.propTypes = {
  isDisabled: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export { RowBookingTime };
