import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Form, Formik, Field, ErrorMessage } from 'formik';
import classNames from 'classnames';
import { logError } from 'helpers/errors/bug-report';
import { toast } from 'react-toastify';
import { useMutation } from '@apollo/react-hooks';
import { camelizeKeys } from 'humps';
import { ButtonSpinner } from 'components/common/button_spinner';
import { InviteCodeRequest } from 'types';
import { INVITE_CODE_REQUEST_REJECT } from 'graphql/mutations/invite-code';
import { GET_INVITE_CODE_REQUEST_LIST } from 'graphql/queries/invite-list';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { INVITE_CODE_STATUSES } from 'helpers/enums';

interface Props {
  isOpen: boolean;
  closeModal: () => void;
  codeRequest: InviteCodeRequest;
}

const ModalInviteReject: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const { isOpen, closeModal, codeRequest } = props;

  const validationSchema = Yup.object().shape({
    notes: Yup.string().required(t('errors.validation.required', { field_name: t('common.notes') })),
  });

  const [rejectInviteCodeRequest, { loading: isSubmitting }] = useMutation(INVITE_CODE_REQUEST_REJECT, {
    onCompleted: () => {
      toast.success(t('inviteRequestsPage.update.success'));
      closeModal();
    },
    refetchQueries: [
      {
        query: GET_INVITE_CODE_REQUEST_LIST,
        variables: {
          first: 25,
          status: INVITE_CODE_STATUSES.PENDING,
        },
      },
    ],
    onError: (err: Error) => {
      let toastMessage = t('inviteRequestsPage.update.failure');
      const errMsgParts = err.toString().split('GraphQL error: ');
      const errorMessage = errMsgParts[errMsgParts.length - 1];
      switch (errorMessage) {
        case 'validation errors':
          toastMessage = t('errors.validation.generic');
          break;
      }
      toast.error(toastMessage);
      logError(err, 'INVITE_CODE_REQUEST_REJECT', ModalInviteReject.displayName);
    },
  });

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as='div' className='relative z-10' onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
        </Transition.Child>

        <div className='fixed z-10 inset-0 overflow-y-auto'>
          <div className='flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <Dialog.Panel className='relative bg-gray-900 rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-3xl sm sm:w-full sm:p-6'>
                <div>
                  <div className='mt-3 sm:mt-5'>
                    <Dialog.Title as='h3' className='text-lg leading-6 text-center font-medium text-gray-300'>
                      {t('inviteRequestsPage.reject_invite_code_request')}
                    </Dialog.Title>
                    <div className='mt-2'>
                      <div className='mt-5 sm:mt-6'>
                        <Formik
                          initialValues={{ id: codeRequest.id, notes: '' }}
                          validationSchema={validationSchema}
                          onSubmit={(values) => {
                            const camelizedValues = camelizeKeys(values);
                            rejectInviteCodeRequest({ variables: { input: camelizedValues } });
                          }}
                        >
                          {({ touched, errors, isValid }) => {
                            return (
                              <Form>
                                <div className='bg-gray-800 shadow px-4 py-5 sm:rounded-lg sm:p-6 sm:col-span-3 mb-2'>
                                  <div className='md:grid md:grid-cols-3 md:gap-6'>
                                    <div className='sm:col-span-3 mb-2'>
                                      <label htmlFor='notes' className='block text-sm font-medium text-gray-500'>
                                        {t('common.notes')}
                                      </label>
                                      <div className='mt-1 flex rounded-md shadow-sm'>
                                        <Field
                                          as='textarea'
                                          rows={4}
                                          name='notes'
                                          id='notes'
                                          className={classNames(
                                            'px-4 py-2 mt-1 block w-full pl-3 pr-10 text-base text-gray-800 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md',
                                            { ['border-2  border-red-500']: touched.notes && errors.notes }
                                          )}
                                        />
                                      </div>
                                      <ErrorMessage name='notes' className='text-red-500 text-sm' component='div' />
                                    </div>
                                  </div>
                                </div>

                                <div className='flex justify-between mt-4'>
                                  <button
                                    type='button'
                                    className='w-40 rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:text-sm'
                                    onClick={() => {
                                      closeModal();
                                    }}
                                  >
                                    {t('common.cancel')}
                                  </button>
                                  <button
                                    type='submit'
                                    disabled={!isValid || isSubmitting}
                                    className={classNames([
                                      isValid ? 'bg-green-600 hover:bg-green-700' : 'bg-gray-600',
                                      'w-40 ml-3 inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:text-sm',
                                    ])}
                                  >
                                    {isSubmitting && <ButtonSpinner />} {t('common.submit')}
                                  </button>
                                </div>
                              </Form>
                            );
                          }}
                        </Formik>
                      </div>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

ModalInviteReject.displayName = 'ModalInviteReject';

ModalInviteReject.propTypes = {};

export { ModalInviteReject };
