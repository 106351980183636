import * as React from 'react';
import classNames from 'classnames';
import * as PropTypes from 'prop-types';
import logo_only from '../../../assets/logo_white_P.svg';

export const LoadingDisco = ({ className }) => (
  <div className='z-50 animate-pulse mx-auto my-auto'>
    <img
      src={logo_only}
      alt={'Pole Position Logo'}
      className={classNames(['opacity-50 h-48 w-48 mx-auto my-auto'], className)}
    />
  </div>
);

LoadingDisco.displayName = 'LoadingDisco';
LoadingDisco.propTypes = { className: PropTypes.string };

export const LoadingDiscoWithContainer = () => {
  return (
    <div className='flex absolute w-full h-full items-center justify-center mx-auto'>
      <LoadingDisco />
    </div>
  );
};

LoadingDiscoWithContainer.displayName = 'LoadingDiscoWithContainer';
