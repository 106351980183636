import React, { useReducer, createContext, useMemo, useContext } from 'react';
import { ChatPage } from 'components/pages-login/chat/chat-page';

const SET_PAGE_CHAT_FIELD = 'SET_PAGE_CHAT_FIELD';
const SET_PAGE_CHAT_FIELDS = 'SET_PAGE_CHAT_FIELDS';

interface ChatStateType {
  isAdminChat: boolean;
  conversationId: string | null;
  targetId: string | null;
  filter: string;
  message: string;
  firstUnreadId: string | null;
  lastMsgId: string | null;
  chatBizId: string;
  scrollToBottom: boolean;
}

const PAGE_CHAT_INIT_STATE = {
  isAdminChat: false,
  conversationId: null,
  targetId: null,
  filter: '',
  message: '',
  firstUnreadId: null,
  lastMsgId: null,
  chatBizId: null,
  scrollToBottom: true
};

type ActionType = {
  type: string;
  field?: string | null;
  payload: any;
};

export const setChatField = (field: string, payload: unknown): ActionType => ({
  type: SET_PAGE_CHAT_FIELD,
  field,
  payload,
});

export const setChatFields = (payload: unknown): ActionType => ({
  type: SET_PAGE_CHAT_FIELDS,
  field: null,
  payload,
});

function pageChatReducer(state: ChatStateType, { type, field, payload }: ActionType) {
  switch (type) {
    case SET_PAGE_CHAT_FIELD:
      if (!field) return state;
      return { ...state, [field]: payload };

    case SET_PAGE_CHAT_FIELDS:
      return { ...state, ...payload };

    default:
      throw new Error(`Unsupported action type: ${type}`);
  }
}

const PAGE_CTX = createContext<[ChatStateType, React.Dispatch<ActionType>] | null>(null);

export function PageChatProvider(props: { isAdminChat: boolean; chatBizId: string }): React.ReactElement {
  const [state, dispatch] = useReducer(pageChatReducer, { ...PAGE_CHAT_INIT_STATE, ...props });

  const value = useMemo<[ChatStateType, React.Dispatch<ActionType>]>(() => [state, dispatch], [state]);

  return (
    <PAGE_CTX.Provider value={value} {...props}>
      <ChatPage {...props} />
    </PAGE_CTX.Provider>
  );
}

export function usePageChatCtx(): { dispatch: React.Dispatch<ActionType>; state: ChatStateType } {
  const context = useContext(PAGE_CTX);

  if (!context) {
    throw new Error(`${usePageChatCtx.name} must be used within a ${pageChatReducer.name}`);
  }

  const [state, dispatch] = context;
  return { state, dispatch };
}
