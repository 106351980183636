import React from 'react';
import PropTypes from 'prop-types';

import { FormLabel } from './form-parts/form-label';
import { FormError } from './form-parts/form-error';
import { InputFormSelect } from './form-parts/input-form-select';

const FormSelectField = ({ id, label, name, value, error, gridArea, classname, isDisabled, options, handleChange }) => {
  return (
    <div className={classname} style={{ gridArea }}>
      <FormLabel id={id} label={label} isDisabled={isDisabled} hasError={!!error} />
      <InputFormSelect
        id={id}
        name={name}
        value={value}
        hasError={!!error}
        onChange={handleChange}
        options={options}
        isDisabled={isDisabled}
      />
      <FormError error={error} />
    </div>
  );
};

FormSelectField.displayName = 'FormSelectField';

FormSelectField.propTypes = {
  id: PropTypes.string.isRequired,
  gridArea: PropTypes.string,
  classname: PropTypes.string,
  error: PropTypes.string,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  isDisabled: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string.isRequired,
    })
  ),
};

FormSelectField.defaultProps = {
  classname: null,
  gridArea: null,
  options: [],
  error: '',
};

export { FormSelectField };
