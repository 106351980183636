import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import S from './chat-user-item.module.scss';
import { Image } from 'components/common/images/img-file';
import * as PageChatCtx from 'components/pages-login/chat/chat-ctx';

type LastMsg = {
  body: string;
};

type ChatListItemProps = {
  isSelected: boolean;
  id: string;
  updated_at: string;
  user: TargetSenderKeys;
  last_message: LastMsg | null;
  business_unread_messages_count: number;
  avatarOnClick: (id: string) => void;
};

export const ChatListItem: React.FC<ChatListItemProps> = ({
  isSelected,
  id: conversationId,
  user: { id, name, first_image, suspended, blocked_by, in_group: inGroup },
  updated_at,
  last_message,
  business_unread_messages_count: unreadCount,
  avatarOnClick,
}): React.ReactElement => {
  const { dispatch } = PageChatCtx.usePageChatCtx();
  const { t, i18n } = useTranslation();
  return (
    <li
      className={isSelected ? S.chatItemActive : S.chatItemUnActive}
      onClick={() => dispatch(PageChatCtx.setChatFields({ conversationId }))}
    >
      <div className={S.photoArea}>
        <Image
          classname={classNames({ [S.greyOut]: suspended, [S.inGroup]: inGroup })}
          src={first_image?.thumb}
          alt={name}
          fallback='USER'
          onClick={() => avatarOnClick(id)}
        />
        {unreadCount > 0 && <span>{unreadCount > 9 ? '!' : unreadCount}</span>}
      </div>
      <div className={S.detailArea}>
        <h4>{name}</h4>
        <span>{moment(updated_at).locale(i18n.language).fromNow(true)}</span>
        {last_message?.body && <p className={S.lastMsg}>{last_message.body}</p>}
        {(suspended || blocked_by) && (
          <div className={S.discipline}>
            {suspended && <div className={S.suspended}>({t('common.paused')})</div>}
            {blocked_by && <div className={S.blocked}>(86&apos;d by {blocked_by.name})</div>}
          </div>
        )}
      </div>
    </li>
  );
};

ChatListItem.propTypes = {
  id: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired,
  updated_at: PropTypes.string.isRequired,
  //Typescript has an issue with shape in a shape https://github.com/DefinitelyTyped/DefinitelyTyped/issues/34692
  // user: PropTypes.shape({
  //   name: PropTypes.string.isRequired,
  //   first_image: PropTypes.object,
  //   suspended: PropTypes.boolean,
  // }).isRequired,
  last_message: PropTypes.shape({
    body: PropTypes.string.isRequired,
  }),
  business_unread_messages_count: PropTypes.number.isRequired,
};
