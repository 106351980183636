import { useQuery } from '@apollo/react-hooks';
import { useCallback, useEffect, useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import _sortBy from 'lodash/sortBy';
import { useTranslation } from 'react-i18next';

import S from './filter-dancer-styles.module.scss';
import { FilterDropDown } from './filter-dropdown';
import * as DancerSearchCTX from '../../dancer-search-context';
import { GET_PROFILE_OPTS_GQL } from '../../../../../../graphql/queries/profile-options';

const DancerStylesFilter = () => {
  const { t, i18n } = useTranslation();
  const {
    dispatch,
    state: { styleFilter },
  } = DancerSearchCTX.useDancerSearchCtx();

  const [untranslatedMap, setUntranslatedMap] = useState({});
  const [untranslatedList, setUntranslatedList] = useState([]);
  const [filterMap, setFilterMap] = useState({});
  const [filterList, setFilterList] = useState([]);
  const { loading } = useQuery(GET_PROFILE_OPTS_GQL, {
    onCompleted: ({ profile_options }) => {
      const { map, list } = profile_options.reduce(
        (acm, { id, trans_key }) => ({
          list: [...acm.list, { label: trans_key, value: id }],
          map: { ...acm.map, [id]: trans_key },
        }),
        { list: [], map: {} }
      );

      setUntranslatedMap(map);
      setUntranslatedList(_sortBy(list, 'sort_order'));
    },
  });

  useEffect(() => {
    const translatedMap = Object.keys(untranslatedMap).reduce(
      (acm, key) => ({ ...acm, [key]: t(untranslatedMap[key]) }),
      {}
    );
    setFilterMap(translatedMap);

    const translatedList = untranslatedList.map(({ label, value }) => ({
      label: t(`profileOptions.${label}`),
      value,
    }));
    setFilterList(translatedList);
  }, [untranslatedMap, untranslatedList, i18n.language]);

  const handleRemoveStyle = useCallback(
    (styleId) =>
      dispatch(
        DancerSearchCTX.setDancerSearchField(
          'styleFilter',
          styleFilter.filter((id) => id !== styleId)
        )
      ),
    [dispatch, styleFilter]
  );

  if (loading) return null;
  return (
    <div className={S.dancerStyles}>
      <div className={S.leftWrapper}>
        <span>{t('common.preferences')}</span>
        <FilterDropDown options={filterList} selected={styleFilter} fieldName={'styleFilter'} />
      </div>
      <div className={S.removalArea}>
        {styleFilter.map((id) => (
          <button key={`REMOVE_FILTER_${id}`} onClick={() => handleRemoveStyle(id)}>
            {filterMap[id]}
            <FaTimes />
          </button>
        ))}
      </div>
    </div>
  );
};

DancerStylesFilter.displayName = 'DancerStylesFilter';

export { DancerStylesFilter };
