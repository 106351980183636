import React, { FC, useCallback, useRef, useState, useEffect } from 'react';
import { useDebounce } from 'helpers/hooks/use-debounce';
import classNames from 'classnames';
import S from './style.module.scss';
import Option from './option';
import { FormLabel } from '../form/form-parts/form-label';
import type { Props, Prediction, Suggestion } from './types';

const KEY_CODES = {
  DOWN: 40,
  UP: 38,
  ESCAPE: 27,
  ENTER: 13,
};

const suggestionDescription = (suggestion: Suggestion) => {
  return suggestion ? `${suggestion?.city}, ${suggestion?.state}, ${suggestion?.country}` : '';
};

const LocationSearchInput: FC<Props> = ({ containerClassName, label, onSelect, initialValue, placeholder }) => {
  const service = (window as any).google;
  const searchInputRef = useRef<HTMLInputElement>(null);
  const [searchText, setSearchText] = useState('');
  const [suggestions, setSuggestions] = useState<Suggestion[]>([]);
  const [activeIndex, setActiveIndex] = useState(-1);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [ignoreBlur, setIgnoreBlur] = useState(false);

  const handleOpen = () => setShowSuggestions(true);
  const handleClose = () => {
    setShowSuggestions(false);
    clearSuggestions();
  };

  const clearSuggestions = () => {
    setSuggestions([]);
    setActiveIndex(-1);
  };

  const onSearchChange = async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    setSearchText(e.target.value);
    debouncedOnChange(e);
  };

  const queryGoogleApi = async (input: string) => {
    if (!input) {
      setSuggestions([]);
      return;
    }
    try {
      await new service.maps.places.AutocompleteService().getPlacePredictions(
        { input, types: ['locality'], componentRestrictions: { country: 'us' } },
        (predictions: Prediction[]) => {
          const newSuggestions: Suggestion[] = predictions
            ? predictions.map((prediction: Prediction) => ({
                googlePlacesId: prediction.place_id,
                city: prediction.structured_formatting.main_text,
                state: prediction.structured_formatting.secondary_text.split(',')[0],
                country: prediction.structured_formatting.secondary_text.split(',')[1],
              }))
            : [];
          setSuggestions(newSuggestions);
        }
      );
    } catch (error) {
      console.log('Google maps api error', error);
    }
  };

  const { onChange: debouncedOnChange } = useDebounce(searchText, queryGoogleApi);

  const onInputBlur = () => {
    if (!!searchInputRef.current?.value && searchInputRef.current.value !== suggestionDescription(initialValue)) {
      setSearchText(suggestionDescription(initialValue));
    }
    if (!searchInputRef.current?.value) {
      onSelect(null);
    }
    if (ignoreBlur) return;
    handleClose();
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    e.stopPropagation();
    const keyOperation = {
      [KEY_CODES.DOWN]: scrollDown,
      [KEY_CODES.UP]: scrollUp,
      [KEY_CODES.ENTER]: () => selectOption(activeIndex),
      [KEY_CODES.ESCAPE]: onEscPressed,
    };

    if (keyOperation[e.keyCode]) {
      keyOperation[e.keyCode]();
      // Prevent default behaviour, i.e. form submission
      e.preventDefault();
    } else {
      setActiveIndex(-1);
    }
  };

  const scrollUp = () => setActiveIndex((activeIndex + suggestions.length - 1) % suggestions.length);

  const scrollDown = () => setActiveIndex((activeIndex + 1) % suggestions.length);

  const onEscPressed = () => {
    searchInputRef.current?.blur();
  };

  const selectOption = useCallback(
    (index: number) => {
      if (index > -1) {
        const suggestion: Suggestion = suggestions[index];
        if (onSelect) {
          onSelect(suggestion);
        }
        setSearchText(suggestionDescription(suggestion));
      }
      handleClose();
    },
    [suggestions, onSelect]
  );

  const handleOptionOnClick = useCallback(
    (idx) => {
      selectOption(idx);
    },
    [selectOption]
  );

  const handleOptionOnMouseEnter = useCallback(
    (idx) => {
      setActiveIndex(idx);
    },
    [setActiveIndex]
  );

  useEffect(() => {
    setSearchText(suggestionDescription(initialValue));
  }, [initialValue]);

  return (
    <div className={classNames([S.container, containerClassName])}>
      <FormLabel label={label} isDisabled={false} />
      <input
        type='text'
        onBlur={onInputBlur}
        onFocus={handleOpen}
        onChange={onSearchChange}
        onKeyDown={onKeyDown}
        value={searchText}
        placeholder={placeholder}
        ref={searchInputRef}
      />
      {showSuggestions && !!suggestions.length && (
        <div
          className={classNames([S.select, 'absolute w-full'])}
          onTouchStart={() => setIgnoreBlur(true)}
          onMouseEnter={() => setIgnoreBlur(true)}
          onMouseLeave={() => setIgnoreBlur(false)}
        >
          {suggestions.map((suggestion, idx: number) => (
            <Option
              id={idx}
              key={idx}
              active={idx === activeIndex}
              value={suggestion}
              onClick={handleOptionOnClick}
              onMouseEnter={handleOptionOnMouseEnter}
              formatLabel={suggestionDescription}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default LocationSearchInput;
