import * as React from 'react';
import { useTranslation } from 'react-i18next';

import S from './group-notification-page.module.scss';
import { GroupNotificationTable } from './group-notification-table';
import { GroupNotificationSearch } from './group-notification-search';
import { PageGroupNotificationsProvider } from '../group-notification-context';

const GroupNotificationPage = () => {
  const { t } = useTranslation();
  return (
    <PageGroupNotificationsProvider>
      <div className={S.pageWrapper}>
        <header className={S.groupNotificationHeader}>
          <h1 className={S.h1Heading}>{t('notificationsPage.group_announcements')}</h1>
          <GroupNotificationSearch />
        </header>

        <GroupNotificationTable />
      </div>
    </PageGroupNotificationsProvider>
  );
};

GroupNotificationPage.displayName = 'GroupNotificationPage';

export { GroupNotificationPage };
