import classNames from 'classnames';
import { FaExclamationTriangle } from 'react-icons/fa';

const ImgMissing = ({ text, classNames = 'w-24 h-24' }) => {
  return (
    <div
      className={`flex flex-col bg-white border-red-400 font-bold text-red-400 mb-1  justify-center items-center ${classNames}`}
    >
      <FaExclamationTriangle size={24} className='text-red-400' />
      {text}
    </div>
  );
};

export default ImgMissing;
