import { gql } from 'apollo-boost';

export const DOB_REQUEST_UPDATE_GQL = gql`
  mutation dob_request_update($input: DobRequestUpdateInput!) {
    dob_request_update(input: $input) {
      dob_request {
        id
        status
      }
    }
  }
`;

export const DOB_REQUEST_ACCEPT = gql`
  mutation DobRequestAccept($input: DobRequestAcceptInput!) {
    dob_request_accept(input: $input) {
      dob_request {
        id
        status
      }
    }
  }
`;

export const DOB_REQUEST_REJECT = gql`
  mutation DobRequestReject($input: DobRequestRejectInput!) {
    dob_request_reject(input: $input) {
      dob_request {
        id
        status
      }
    }
  }
`;
