import * as React from 'react';
import PropTypes from 'prop-types';

import S from './dark-overlay.module.scss';

const Overlay = ({ children, isLight, styles }) => (
  <div style={styles} className={isLight ? S.lightOverlay : S.darkOverlay}>
    {children}
  </div>
);

Overlay.displayName = 'Overlay';

Overlay.propTypes = {
  isLight: PropTypes.bool,
  children: PropTypes.element.isRequired,
  styles: PropTypes.object,
};

Overlay.defaultProps = {
  isLight: false,
  styles: null,
};

export { Overlay };
