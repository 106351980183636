import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';

import * as ClubFormCtx from '../club-form-context';
import { FormSelectField } from '../../../../common/form/form-select-field';
import { FormError } from 'components/common/form/form-parts/form-error';
import { useUser } from '../../../../../graphql/graph-hooks';
import { GET_ORGANIZATIONS_GQL } from '../../../../../graphql/queries/get-org-query';
import { GET_ORGANIZATIONS_BY_ID_GQL } from '../../../../../graphql/queries/get-org-by-id';
import { validateClubOrgId } from '../../../../../helpers/validations/club-validation';

const Organization = ({ isDisabled, error }) => {
  const { t } = useTranslation();
  const [options, setOptions] = useState([]);
  const [orgIds, setOrgIds] = useState([]);
  const { admin } = useUser();
  const {
    dispatch,
    state: { form, errors },
  } = ClubFormCtx.useClubFormCtx();

  useQuery(GET_ORGANIZATIONS_BY_ID_GQL, {
    skip: admin,
    variables: { organizationId: form.orgId },
    onCompleted: (data) => {
      if (data) {
        setOrgIds([data.organization.id]);
        setOptions([
          {
            label: data.organization.name,
            value: data.organization.id,
          },
        ]);
      }
    },
  });

  useQuery(GET_ORGANIZATIONS_GQL, {
    skip: !admin,
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data) {
        setOrgIds(data.organizations.map(({ id }) => id));
        setOptions([
          { value: '', label: t('common.select_option') },
          ...data.organizations.map(({ id, name }) => ({ label: name, value: id })),
        ]);
      }
    },
  });
  const gridArea = 'organization';
  return (
    <div style={{ gridArea }}>
      <FormSelectField
        id='club_form_orgId'
        label={t('clubPage.biz_orga')}
        gridArea={gridArea}
        name='orgId'
        value={form.orgId}
        error={errors.organizationId}
        isDisabled={isDisabled}
        options={options}
        handleChange={({ target: { name, value } }) => {
          dispatch(ClubFormCtx.setClubFormField(name, value));
          dispatch(ClubFormCtx.setClubFormError(name, validateClubOrgId(value, orgIds)));
        }}
      />
      <FormError error={error} />
    </div>
  );
};

Organization.displayName = 'BasicInfoForm';

Organization.propTypes = {
  isDisabled: PropTypes.bool.isRequired,
  error: PropTypes.string,
};

export { Organization };
