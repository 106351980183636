import * as React from 'react';
import * as PropTypes from 'prop-types';
import * as classNames from 'classnames';

import S from './form-checkbox.module.scss';

const FormCheckBox = (props) => {
  return (
    <label
      htmlFor={props.id}
      className={classNames(S.formCheckbox, {
        [S.disabled]: props.isDisabled,
      })}
    >
      {props.label}
      <input
        type='checkbox'
        id={props.id}
        value={props.value}
        name={props.name}
        disabled={props.isDisabled}
        checked={props.isChecked}
        onChange={props.handleChange}
      />
      <span className={S.checkMark} />
    </label>
  );
};

FormCheckBox.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]),
  isDisabled: PropTypes.bool,
  isChecked: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
};

FormCheckBox.defaultProps = {
  isDisabled: false,
  name: null,
};

export { FormCheckBox };
