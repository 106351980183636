import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useUser } from '../../../../graphql/graph-hooks';
import * as Constants from '../../../../helpers/constants';
import { getRolePermissionOptions } from '../../../../helpers/employee-services';
import { EMPLOYEE_ROLES } from '../../../../helpers/enums';
import * as Validations from '../../../../helpers/validations/user-validations';

export const useEmployeeForm = (businessId) => {
  const { t } = useTranslation();

  const [src, setSrc] = useState('');
  const [errors, setErrors] = useState({});
  const [permissions, setPermissions] = useState([]);
  const [roleOptions, setRole] = useState([]);
  const [values, setValues] = useState({ permissions: [], name: '', email: '', phone_number: '', notes: '' });
  const { roles: userRoles, admin } = useUser();
  const SLUG = userRoles?.[businessId]?.slug;

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === 'permissions') {
      setValues({
        ...values,
        [name]: values.permissions.includes(value)
          ? values.permissions.filter((key) => key !== value)
          : [...values.permissions, value],
      });
    } else if (name === 'role') {
      const PERMISSIONS = getRolePermissionOptions(value);
      setPermissions(PERMISSIONS.map((value) => ({ value, label: t(`employeePage.permissions.${value}`) })));
      setValues({ ...values, permissions: value === '' ? [] : PERMISSIONS, [name]: value });
      setErrors({ ...errors, role: Validations.validateRole(value) });
    } else {
      setValues({ ...values, [name]: value });
    }
  };

  const updateImage = useCallback(
    ({ preview, image_attachment }) => {
      setSrc(preview);
      setValues({ ...values, image_attachment });
    },
    [setSrc, values]
  );

  const validations = {
    name: Validations.validateName,
    email: Validations.validateEmail,
    notes: Validations.validateNotes,
    phone_number: Validations.validatePhone,
  };

  const handleOnBlur = useCallback(
    (e) => {
      const { name, value } = e.target;
      setValues({ ...values, [name]: value.trim() });
      setErrors({ ...errors, [name]: validations[name](value.trim()) });
    },
    [setValues, setErrors, errors, values, validations]
  );

  const isValid = useCallback(() => {
    const ERROR_OBJ = {
      name: Validations.validateName(values.name),
      email: Validations.validateEmail(values.email),
      phone_number: admin ? Validations.validatePhone(values.phone_number) : '',
      notes: admin ? Validations.validateNotes(values.notes) : '',
      role: Validations.validateRole(values.role),
    };

    setErrors(ERROR_OBJ);

    return !Object.values(ERROR_OBJ).some((msg) => msg !== '');
  }, [admin, values]);

  const setForm = useCallback(
    ({ id, name, email, phone_number, notes, first_image, roles }) => {
      let rolePermissions = [];

      setValues({
        userId: id,
        name: name || '',
        email: email || '',
        phone_number: phone_number || '',
        notes: notes || '',
        role: roles?.[businessId]?.slug || '',
        image_attachment: null,
        permissions: roles?.[businessId]?.permissions ?? [],
      });
      setSrc(first_image?.medium ?? null);

      if (admin) {
        setRole(
          Constants.EMPLOYEE_ROLE_OPTIONS.map(({ value }) => ({
            value,
            label: t(`employeePage.roles.${value || 'select_role'}`),
          }))
        );
        rolePermissions = getRolePermissionOptions(roles?.[businessId]?.slug || EMPLOYEE_ROLES.BOOKING_AGENT);
      } else {
        const EMPLOYEE_ROLE = roles?.[businessId]?.slug ?? '';
        const USER_LVL = Constants.EMPLOYEE_LVLS[SLUG];
        const EMPLOYEE_LVL = Constants.EMPLOYEE_LVLS?.[EMPLOYEE_ROLE] ?? '';

        if (EMPLOYEE_LVL >= USER_LVL) {
          setRole(
            Constants.EMPLOYEE_ROLE_OPTIONS.map(({ value }) => ({
              value,
              label: t(`employeePage.roles.${value || 'select_role'}`),
            }))
          );
        } else {
          const INDEX = Constants.EMPLOYEE_ROLE_OPTIONS.findIndex(({ value }) => value === SLUG);
          setRole(
            Constants.EMPLOYEE_ROLE_OPTIONS.slice(0, INDEX).map(({ value }) => ({
              value,
              label: t(`employeePage.roles.${value || 'select_role'}`),
            }))
          );
        }
        rolePermissions = getRolePermissionOptions(EMPLOYEE_ROLE);
      }

      setPermissions(rolePermissions.map((value) => ({ value, label: t(`employeePage.permissions.${value}`) })));
    },
    [businessId, admin, SLUG]
  );

  return {
    src,
    values,
    errors,
    handleChange,
    updateImage,
    handleOnBlur,
    permissions,
    roleOptions,
    isValid,
    setForm,
  };
};
