import { gql } from 'apollo-boost';

import { COURSE_FRAGMENT } from '../fragments/course-fragment';

export const COURSE_CREATE = gql`
    ${COURSE_FRAGMENT}
    mutation courseCreate(
        $title: String!
        $description: String!
        $videoUrl: String!
        $imageUrl: String!
        $categories: [ID!]
    ){
        course_create(
            input: {
              createParams:{
                  title: $title
                  description:$description
                  youtubeUrl:$videoUrl
                  imageUrl: $imageUrl
                  courseCategoryIds: $categories
              }
            }
        ){
            course {
                ...courseKeys
            }
        }
    }
`;
