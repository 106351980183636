import React, { useMemo } from 'react';
import * as PropTypes from 'prop-types';
import { FaCaretLeft, FaCaretRight } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import S from './calendar-header.module.scss';
import { CalendarLegend } from './calendar-legend';
import { CAL_VIEWS } from '../../../../../helpers/enums';
import { InputFormSelect } from '../../../../common/form/form-parts/input-form-select';

const CalendarPageHeader = ({
  view,
  date,
  isLoading,
  onIntervalChange,
  handleViewChange,
  canEdit,
  handleCreateNewBlankBooking,
}) => {
  const { t, i18n } = useTranslation();

  const getUiPageCalendarHeading = (view, date) => {
    const DATE = moment(date).locale(i18n.language);
    if (view === CAL_VIEWS.MONTH) return DATE.format('MMMM YYYY');
    else if (view === CAL_VIEWS.DAY) return DATE.format('dddd, MMM D, YYYY');
    else {
      const startDate = moment(date).locale(i18n.language).startOf('week');
      const endDate = moment(date).locale(i18n.language).endOf('week');

      if (startDate.isSame(endDate, 'month')) {
        return `${startDate.format('MMM D')} - ${endDate.format('D, YYYY')}`;
      } else {
        return `${startDate.format('MMM D')} - ${endDate.format('MMM D, YYYY')}`;
      }
    }
  };

  return (
    <div className={S.calHeader}>
      <div className={S.calHeadLeft}>
        <header className={S.header}>
          <h1 className={S.h1Heading}>{t('bookingCalendar.booking_calendar')}</h1>
          {canEdit && (
            <button className={S.invertedBtnGreen} onClick={handleCreateNewBlankBooking}>
              {t('bookingCalendar.create_booking')}
            </button>
          )}
        </header>

        <div className={S.calDate}>{getUiPageCalendarHeading(view, date)}</div>
      </div>
      <div className={S.calHeadRight}>
        <CalendarLegend />
        <div className={S.calDateControls}>
          <button disabled={isLoading} onClick={() => onIntervalChange('subtract')}>
            <FaCaretLeft />
          </button>
          <button disabled={isLoading} onClick={() => onIntervalChange('add')}>
            <FaCaretRight />
          </button>
        </div>
        <InputFormSelect
          id='calendar_view_type'
          name='cal_type'
          value={view}
          isDisabled={isLoading}
          onChange={(e) => handleViewChange(e.target.value)}
          options={[
            { value: CAL_VIEWS.DAY, label: t('common.day') },
            { value: CAL_VIEWS.WEEK, label: t('common.week') },
            { value: CAL_VIEWS.MONTH, label: t('common.month') },
          ]}
        />
      </div>
    </div>
  );
};

CalendarPageHeader.displayName = 'CalendarPageHeader';

CalendarPageHeader.propTypes = {
  view: PropTypes.string,
  date: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onIntervalChange: PropTypes.func.isRequired,
  handleViewChange: PropTypes.func.isRequired,
  canEdit: PropTypes.bool.isRequired,
  handleCreateNewBlankBooking: PropTypes.func.isRequired,
};

CalendarPageHeader.defaultProps = {
  view: CAL_VIEWS.WEEK,
};

export { CalendarPageHeader };
