import moment from 'moment';
import React, { useMemo } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FaSyncAlt } from 'react-icons/fa';
import { RouteCreator } from '../../../../../routes/route-constants';
import { BookingIcon } from '../../../../../common/icons/booking-icon';
import * as TimeHelpers from '../../../../../../helpers/times';

import S from './booking-styles.module.scss';

// USE START & END RATHER THAN shift START_TIME / END_TIME because it's based off
const CalWeekView = ({
  end,
  start,
  onRedirect,
  timezone,
  shift: {
    id,
    slots,
    shift_type,
    recurring,
    accepted_shift_applications_count,
    pending_shift_applications_count,
    checked_in_shift_applications_count,
    checked_out_shift_applications_count,
    has_applications,
  },
}) => {
  const { t } = useTranslation();
  const ACCEPTED_COUNT = useMemo(
    () =>
      accepted_shift_applications_count + checked_in_shift_applications_count + checked_out_shift_applications_count,
    [accepted_shift_applications_count, checked_in_shift_applications_count, checked_out_shift_applications_count]
  );

  const HAS_ENDED = useMemo(
    () => TimeHelpers.getCurrentTimeAtTimezone(timezone).isAfter(end) && ACCEPTED_COUNT > 0,
    [timezone, end, ACCEPTED_COUNT]
  );

  return (
    <div className={S.weeklyBookingGrid}>
      <div className='flex justify-between self-end bg-black/30 pt-1 mb-1'>
        <div className='flex flex-shrink-0 pl-1'>
          {has_applications && (
            <BookingIcon
              pendingCount={pending_shift_applications_count || 0}
              hasEnded={HAS_ENDED && ACCEPTED_COUNT > 0}
              onClick={() => onRedirect(RouteCreator.applicationRequests(id, shift_type === 'audition'))}
            />
          )}
        </div>
        <div className='flex-shrink font-light text-center'>
          {slots ? (
            slots === ACCEPTED_COUNT ? (
              <span title={`${ACCEPTED_COUNT}/${slots} ${t('bookingCalendar.filled')}`}>
                {ACCEPTED_COUNT}/{slots}
              </span>
            ) : (
              <span title={`${ACCEPTED_COUNT}/${slots} ${t('bookingCalendar.filled')}`}>
                {ACCEPTED_COUNT}/{slots}
              </span>
            )
          ) : (
            <span title={t('bookingCalendar.new_booking')}>{t('common.new')}</span>
          )}
        </div>
        {shift_type ? (
          <div title={t(`bookingCalendar.${shift_type.toLowerCase()}`).toUpperCase()} className='flex-shrink-0 w-3'>
            {shift_type.charAt(0).toUpperCase()}
          </div>
        ) : (
          ''
        )}
      </div>
      <div className={S.timeArea}>
        {moment.tz(start, timezone).format('h:mm')} - {moment.tz(end, timezone).format('h:mm')}{' '}
        {recurring && (
          <FaSyncAlt
            size='14px'
            title={t('bookingCalendar.repeats_weekly')}
            className='float-right font-light pr-1 text-gray-300'
          />
        )}
      </div>
    </div>
  );
};

CalWeekView.displayName = 'WeekViewPresenter';
CalWeekView.propTypes = {
  timezone: PropTypes.string.isRequired,
  onRedirect: PropTypes.func.isRequired,
  start: PropTypes.instanceOf(Date),
  end: PropTypes.instanceOf(Date),
  shift: PropTypes.shape({
    id: PropTypes.string,
    slots: PropTypes.number,
    recurring: PropTypes.bool,
    shift_type: PropTypes.string,
    start_time: PropTypes.string,
    end_time: PropTypes.string,
    accepted_shift_applications_count: PropTypes.number,
    pending_shift_applications_count: PropTypes.number,
    rejected_shift_applications_count: PropTypes.number,
    checked_in_shift_applications_count: PropTypes.number,
    checked_out_shift_applications_count: PropTypes.number,
  }),
};

export { CalWeekView };
