import React, { useState } from 'react';
import { useBusiness } from '../../../../../graphql/graph-hooks';
import { subMonths } from 'date-fns';
import { useQuery as useReactQuery } from '@apollo/react-hooks';
import { SerieData } from '../../../../../types/charts';
import { GET_SHIFT_STATS } from '../../../../../graphql/queries/analytics-query';
import Graph from './graph';
import { LoadingDisco } from '../../../../common/loading/loading-disco';
import DateRangePicker, { DateRange } from 'rsuite/DateRangePicker';
import { useTranslation } from 'react-i18next';
import { InputPicker } from 'rsuite';
import moment from 'moment';

const shiftCountColors: string[] = [
  'rgba(0, 0, 0, 0.6)',
  'rgba(29,166,45,0.6)',
  'rgba(204,11,125,0.6)',
  'rgba(59,93,166,0.6)',
  'rgba(193,51,48,0.6)',
  'rgba(89,88,88,0.6)',
  'rgba(255, 255, 255, 0.6)',
];

const scoreColors: string[] = [
  'rgba(29,166,45,0.8)',
  'rgba(204,11,125,0.8)',
  'rgba(59,93,166,0.8)',
];

const ClubScore = () => {
  const { t } = useTranslation();
  const { id: businessId, timezone } = useBusiness();

  const now = new Date();
  const lastMonth = subMonths(new Date(), 1);

  const [dateRange, setDateRange] = useState<DateRange | null>([lastMonth, now]);

  const start = dateRange ? dateRange[0] : lastMonth;
  const end = dateRange ? dateRange[1] : now;

  const startDate = moment.tz(start, timezone).startOf('day').toISOString();
  const endDate = moment.tz(end, timezone).endOf('day').toISOString();

  const {
    data: shiftStats,
    loading,
    error,
  } = useReactQuery(GET_SHIFT_STATS, {
    fetchPolicy: 'network-only',
    variables: {
      businessId: businessId,
      startDate: startDate,
      endDate: endDate,
    },
  });

  const [shiftType, setShiftType] = useState('day');

  const shiftData = shiftStats?.business_shift_stats.nodes;

  const dataByTypeDate: {
    [key: string]: {
      [key: string]: {
        [key: string]: number;
      };
    };
  } = {};

  const scoreByTypeDate: {
    [key: string]: {
      [key: string]: number;
    };
  } = {
    action_score: {},
    checkin_score: {},
    checkout_score: {},
  };

  // for each shift type, for each date, sum the counts
  shiftData?.forEach((shift: any) => {
    const date = shift.date.slice(0, 10);
    const type = shift.shift_type.toLowerCase();

    dataByTypeDate[type] ||= {
      pending_count: {},
      accepted_count: {},
      checked_in_count: {},
      checked_out_count: {},
      rejected_count: {},
      standby_count: {},
      failed_to_attend_count: {},
    };

    ['accepted', 'checked_in', 'checked_out', 'pending', 'rejected', 'standby', 'failed_to_attend'].forEach((item) => {
      dataByTypeDate[type][`${item}_count`][date] =
        (dataByTypeDate[type][`${item}_count`][date] || 0) + shift[`${item}_count`] || 0;
    });

    if (type === shiftType) {
      const data = dataByTypeDate[type];

      const total = ['accepted', 'pending', 'rejected', 'standby', 'failed_to_attend', 'checked_in', 'checked_out'].reduce((prev, curr) => {
        return prev + data[`${curr}_count`][date];
      }, 0);

      const actionTaken = total - data['pending_count'][date];

      scoreByTypeDate['action_score'][date] = actionTaken / total || 0;
      scoreByTypeDate['checkin_score'][date] =
        (data['checked_in_count'][date] + data['checked_out_count'][date] + data['failed_to_attend_count'][date]) / (data['accepted_count'][date] + data['checked_in_count'][date] + data['checked_out_count'][date] + data['failed_to_attend_count'][date]) || 0;
      scoreByTypeDate['checkout_score'][date] = data['checked_out_count'][date] / (data['checked_in_count'][date] + data['checked_out_count'][date]) || 0;
    }
  });

  const scoreGraphData: SerieData[] = Object.keys(scoreByTypeDate).map((key) => {
    return {
      label: t(`clubPage.analytics.${key}`),
      data: Object.keys(scoreByTypeDate[key]).map((date) => {
        return { date: date, value: (scoreByTypeDate[key][date] * 100) };
      }),
    };
  });

  const scoreDataAvailable = !!scoreGraphData.reduce((prev, curr) => {
    return prev + curr.data.length;
  }, 0);

  const dataByTypeStatus: {
    [key: string]: {
      [key: string]: { date: string; value: number }[];
    };
  } = {};

  // map the data to shift type -> shift status -> [{date, value}]
  for (const type in dataByTypeDate) {
    dataByTypeStatus[type] ||= {
      pending_count: [],
      accepted_count: [],
      checked_in_count: [],
      checked_out_count: [],
      rejected_count: [],
      standby_count: [],
      failed_to_attend_count: [],
    };

    for (const status in dataByTypeDate[type]) {
      for (const date in dataByTypeDate[type][status]) {
        dataByTypeStatus[type][status].push({ date: date, value: dataByTypeDate[type][status][date] });
      }
    }
  }

  const chartDataByType: {
    [key: string]: SerieData[];
  } = {};

  for (const type in dataByTypeStatus) {
    chartDataByType[type] ||= [];

    for (const status in dataByTypeStatus[type]) {
      chartDataByType[type].push({
        label: t(`clubPage.analytics.${status}`),
        data: dataByTypeStatus[type][status],
      });
    }
  }

  if (loading) {
    return <LoadingDisco />;
  }

  if (error) {
    return <div>{t('errors.generic')}</div>;
  }
  return (
    <>
      <div className='mt-6 flex items-center gap-4'>
        <div className='flex items-center gap-2'>
          <h5>{t('clubPage.analytics.picker.stat_type')}</h5>
          <InputPicker
            data={['day', 'evening', 'night', 'late_night'].map((value) => ({
              value,
              label: t(`modals.booking.${value}`),
            }))}
            defaultValue={shiftType}
            onChange={setShiftType}
            cleanable={false}
          />
        </div>
        <div className='flex items-center gap-2'>
          <h5>{t('clubPage.analytics.picker.stat_range')}</h5>
          <DateRangePicker onChange={setDateRange} disabled={loading} value={dateRange} />
        </div>
      </div>

      <div>
        <div>
          <h2 className='text-xl font-bold mt-6'>{t('clubPage.analytics.scores')}</h2>
          <div className='h-112'>
            <Graph
              data={scoreDataAvailable ? scoreGraphData : []}
              colorArray={scoreColors}
              chartType={'line'}
              stacked={false}
              formatters={
                {
                  scale: (value: number) => (value || 0).toFixed(1),
                  tooltip: (value: number) => (value || 0).toFixed(2),
                  cursor: (value: number) => (value || 0).toFixed(2),
                }
              }
            />
          </div>
        </div>
        <div>
          <h2 className='text-xl font-bold mt-20'>{t('clubPage.analytics.counts')}</h2>
          <div className='h-112'>
            <Graph data={chartDataByType[shiftType]} colorArray={shiftCountColors} chartType={'bar'} stacked={true} />{' '}
          </div>
        </div>
      </div>
    </>
  );
};

export default ClubScore;
