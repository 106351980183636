import * as React from 'react';
import * as PropTypes from 'prop-types';
import { FaTrash } from 'react-icons/fa';
import classNames from 'classnames';

import S from './delete-img-icon.module.scss';

const DeleteImgIcon = ({ classname, handleDelete }) => {
  return <FaTrash onClick={handleDelete} className={classNames(S.trash, { [classname]: classname })} />;
};

DeleteImgIcon.displayName = 'DeleteImgIcon';

DeleteImgIcon.propTypes = {
  handleDelete: PropTypes.func.isRequired,
  classname: PropTypes.string,
};

export { DeleteImgIcon };
