import { FC, ReactNode } from 'react';

type Props = {
  title: string;
  children?: ReactNode;
};

const PageHeader: FC<Props> = ({ title, children }) => {
  return (
    <header className='flex flex-row justify-between'>
      <h1 className="text-4xl font-bold font-['Roboto']">{title}</h1>
      {children}
    </header>
  );
};

export default PageHeader;
