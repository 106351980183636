import * as React from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import S from './row-booking-promo.module.scss';
import { useModalBookingCtx } from '../../modal-booking-context';
import { FormTextField } from '../../../../common/form/form-text-field';
import { FormTextareaField } from '../../../../common/form/form-textarea-field';

import bookingConfig from '../../../../../configs/forms/booking-form-config.json';

const RowBookingPromo = ({ isDisabled, handleChange }) => {
  const { state } = useModalBookingCtx();
  const { t } = useTranslation();
  return (
    <div className={S.bookingModalPromoRow}>
      <FormTextField
        id='modal_booking_promo_name'
        label={t('bookingCalendar.event_name')}
        gridArea='promoName'
        name='title'
        isDisabled={isDisabled}
        error={t(state.errors.title, { number: bookingConfig.bookingString.min })}
        value={state.form.title}
        handleChange={handleChange}
      />
      <FormTextareaField
        id='modal_booking_promo_description'
        label={t('bookingCalendar.event_description')}
        gridArea='promoDesc'
        name='description'
        isDisabled={isDisabled}
        error={t(state.errors.description, {
          field_name: t('bookingCalendar.event_description'),
          number: bookingConfig.bookingString.min,
          max_length: '500',
        })}
        value={state.form.description}
        handleChange={handleChange}
        rows={6}
      />
    </div>
  );
};

RowBookingPromo.displayName = 'RowBookingPromo';
RowBookingPromo.propTypes = {
  isDisabled: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export { RowBookingPromo };
