import { gql } from 'apollo-boost';

export const MESSAGE_FRAGMENT = gql`
    fragment messageFragment on Message {
        id
        body
        read
        created_at
        updated_at
        sender{ id name }
    }
`;
