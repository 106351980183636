import React, { useRef, useState } from 'react';
import * as PropTypes from 'prop-types';

import S from './checkbox-dropdown.module.scss';
import { CheckBoxList } from '../../checkbox-list';
import { useOutsideClick } from '../../../../helpers/hooks/use-outside-click';
import { useTranslation } from 'react-i18next';

const CheckBoxDropDown = ({ placeholder, list, name, disabled, handleChange, selected }) => {
  const SELECT_REF = useRef();
  const [isOpen, setIsOpen] = useState(false);
  useOutsideClick(SELECT_REF, () => setIsOpen(false));
  const { t } = useTranslation();

  return (
    <div className={S.checkboxDropDown} ref={SELECT_REF}>
      <button type='button' disabled={disabled} onClick={() => setIsOpen(!isOpen)} className={isOpen ? S.active : null}>
        {placeholder
          ? `${selected.length === 0 ? t('common.all') : selected.length} ${placeholder.toLowerCase()}`
          : `${selected.length} ${t('common.selected')}`}
      </button>
      {isOpen && <CheckBoxList list={list} handleChange={handleChange} name={name} selected={selected} />}
    </div>
  );
};

CheckBoxDropDown.displayName = 'CheckBoxDropDown';
CheckBoxDropDown.propTypes = {
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  name: PropTypes.string.isRequired,
  selected: PropTypes.array.isRequired,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,

  handleChange: PropTypes.func.isRequired,
};

CheckBoxDropDown.defaultProps = {
  disabled: false,
  placeholder: null,
};

export { CheckBoxDropDown };
