import * as React from 'react';
import * as PropTypes from 'prop-types';

import S from './staged-checkbox.module.scss';

function getCheckboxClass(stage) {
  const STAGE = stage.toUpperCase();
  if (STAGE === 'CHECKED') {
    return S.checked;
  } else if (STAGE === 'SOME') {
    return S.indeterminate;
  } else if (STAGE === 'FILL') {
    return S.filled;
  } else {
    return null;
  }
}

const StagedCheckbox = ({ disabled, stage, handleChange, readOnly }) => {
  return (
    <label className={`${S.stagedCheckbox} ${disabled && 'cursor-default'}`}>
      <input disabled={disabled} type='checkbox' readOnly={readOnly} checked={stage} onChange={handleChange} />
      <span className={getCheckboxClass(stage)} />
    </label>
  );
};

StagedCheckbox.displayName = 'CheckBoxPresenter';

StagedCheckbox.propTypes = {
  stage: PropTypes.string,
  readOnly: PropTypes.bool,
  handleChange: PropTypes.func,
  disabled: PropTypes.bool,
};

StagedCheckbox.defaultProps = {
  handleChange: null,
  stage: 'unchecked',
  readOnly: false,
};

export { StagedCheckbox };
