import { gql } from 'apollo-boost';

export const DELETE_INVITE_CODE = gql`
    mutation registration_code_update($id: ID!){
        registration_code_delete(input: {id: $id}) {
            clientMutationId,
            message
        }
    }
`;
