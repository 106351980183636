import React, { SyntheticEvent } from 'react';
import {
  GetstreamPost,
  GetstreamScheduledPost,
  GetstreamScheduledPostActionPayload,
  GetstreamScheduledPostStatus,
} from 'types/getstream';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { ActionButtons } from './action-buttons';

import { TimePatterns } from 'helpers/times';

import { colorMap, labelMap } from './action-maps';

interface Props {
  post: GetstreamScheduledPost;
  onEdit: (payload: GetstreamScheduledPostActionPayload) => void;
  onPause: (payload: GetstreamScheduledPostActionPayload) => void;
  onUnpause: (payload: GetstreamScheduledPostActionPayload) => void;
  onCancel: (payload: GetstreamScheduledPostActionPayload) => void;
  onDelete: (payload: GetstreamScheduledPostActionPayload) => void;
  onClone: (payload: GetstreamScheduledPostActionPayload) => void;
  onView: (data: GetstreamScheduledPostActionPayload) => void;
}

interface PostStatusProps {
  status: GetstreamScheduledPostStatus;
}

const PostStatus: React.FC<PostStatusProps> = ({ status }) => {
  const { t } = useTranslation();
  const classes = `font-bold uppercase text-${colorMap[status]}-400`;
  return <div className={classes}> {t(`common.${labelMap[status].toLowerCase()}`)} </div>;
};

const ScheduledPostItem: React.FC<Props> = ({
  post,
  onEdit,
  onPause,
  onUnpause,
  onCancel,
  onDelete,
  onClone,
  onView,
}) => {
  const setFallbackImg = (e: SyntheticEvent): void => {
    (e.target as HTMLImageElement).src = `${window.location.origin}/img/video-placeholder.png`;
  };

  const firstMedia = post.media.length ? post.media.reduce((acc, cur) => (acc.order < cur.order ? acc : cur)) : null;

  return (
    <div className='flex flex-row p-4 w-full hover:bg-gray-800 border-b-2 border-gray-800 last:border-b-0'>
      <div className='flex w-32 mx-10 justify-center'>
        {firstMedia && (
          <div className='relative self-center '>
            <img
              src={firstMedia.media_type === 'PHOTO' ? firstMedia?.url : firstMedia?.url + '/ik-thumbnail.jpg?tr=h-128'}
              className='w-full max-h-32'
              onError={setFallbackImg}
            />
            {post.media.length > 1 && (
              <div className='flex w-full h-full absolute top-0 bg-black/50 justify-center items-center font-bold'>
                +{post.media.length - 1}
              </div>
            )}
          </div>
        )}
      </div>

      <div className=' max-w-sm self-center mr-10'>
        <PostStatus status={post.status} />
        <div className=''>{moment(post.publish_at).format(TimePatterns.dateFormatted)} </div>
      </div>
      <div className='max-w-xl mr-10 line-clamp-3'>
        {post.title && <div className='max-w-sm font-bold mb-3'> {post.title} </div>}
        <div className='self-center line-clamp-3'>{post.text}</div>
        <div className='mt-3'>
          <a href={post.link.url} className='text-blue-400' target='_BLANK' rel='noreferrer'>
            {post.link.title}
          </a>
        </div>
      </div>

      <div className='self-center ml-auto'>
        <div className='flex flex-col gap-2'>
          <ActionButtons
            id={post.id}
            status={post.status}
            onEdit={onEdit}
            onCancel={onCancel}
            onDelete={onDelete}
            onPause={onPause}
            onUnpause={onUnpause}
            onClone={onClone}
            onView={onView}
          />
        </div>
      </div>
    </div>
  );
};

export default ScheduledPostItem;
