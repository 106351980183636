import React from 'react';
import PropTypes from 'prop-types';

const ManageRequestsIcon = ({height, width}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    height={height}
    width={width}
    viewBox="0 0 23 24"
    preserveAspectRatio="xMidYMid meet"
  >
    <g fill="none" fillRule="evenodd">
      <mask fill="currentColor">
        <path d="M.000121.0000968H23.958v22.658187H.000121z"/>
      </mask>
      <path
        fill="currentColor"
        d="M14.102429 3.6654288l-.86394 6.26296c-.045738.33154-.328878.57838-.663564.57838h-1.19185c-.334686 0-.617826-.24684-.663564-.57838l-.86394-6.26296c-.055418-.402688.257246-.761332.663564-.761332h2.91973c.406318 0 .718982.358644.663564.761332m-2.123308 11.822668c-.86878 0-1.573-.704462-1.573-1.573 0-.86878.70422-1.573 1.573-1.573.868538 0 1.573.70422 1.573 1.573 0 .868538-.704462 1.573-1.573 1.573m9.148084-15.488H2.830795c-1.556786 0-2.830674 1.273888-2.830674 2.830674v12.00441c0 1.556786 1.273888 2.830916 2.830674 2.830916h4.671326v4.328654c0 .572088.67639.875072 1.103278.493922l5.40144-4.822576h7.120366c1.556786 0 2.830916-1.27413 2.830916-2.830916v-12.00441c0-1.556786-1.27413-2.830674-2.830916-2.830674"
      />
    </g>
  </svg>
);


ManageRequestsIcon.displayName = 'ManageRequestsIcon';

ManageRequestsIcon.propTypes = {
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired
};

export { ManageRequestsIcon };
