import React, { FC } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { useHistory, useParams } from 'react-router';
import { toast } from 'react-toastify';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { GETSTREAM_ADMIN_POST_CREATE } from 'graphql/mutations/getstream';
import { RouteConstants } from 'components/routes/route-constants';
import useSubmitForm from '../useSubmitForm';

import PageContainer from 'components/common/page/page-container';
import PageHeader from 'components/common/page/page-header';
import PageBody from 'components/common/page/page-body';
import ScheduledPostForm from '../Form';
import RichToast from '../../../../common/rich-toast';

import { logError } from 'helpers/errors/bug-report';
import { checkForFilterError } from '../../../../../helpers/filtered-words';

import { GETSTREAM_ADMIN_POST } from 'graphql/queries/getstream';
import { GetstreamScheduledPost } from 'types/getstream';
import { FormProps } from '../types';

const ScheduledPostsAdd: FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { postId } = useParams<{ postId: string }>();

  const initialValues: FormProps = {
    title: '',
    text: '',
    link_title: '',
    link_url: '',
    publishAt: moment().startOf('hour').add(1, 'hour').toDate(),
    media: [],
  };

  const { loading, error, data } = useQuery<{
    getstream_admin_post: GetstreamScheduledPost;
  }>(GETSTREAM_ADMIN_POST, {
    fetchPolicy: 'network-only',
    variables: { id: postId },
    skip: !postId,
  });

  const [addPost] = useMutation(GETSTREAM_ADMIN_POST_CREATE, {
    onCompleted: () => {
      history.push(RouteConstants.socialScheduledPosts);
    },
    onError: (error) => {
      const filterError = checkForFilterError(error, t('errors.generic'));
      toast.error(<RichToast title={filterError.title} message={filterError.message} />);
      logError(error, 'GETSTREAM_ADMIN_POST_CREATE', 'PostSchedulerAdd');
    },
  });

  const { submit: submitForm, isLoading } = useSubmitForm(addPost);

  if (data?.getstream_admin_post) {
    const { title, text, link, media } = data?.getstream_admin_post;
    initialValues.title = title;
    initialValues.text = text;
    initialValues.link_title = link.title;
    initialValues.link_url = link.url;
    initialValues.media = media.sort((a, b) => (a.order > b.order ? 1 : b.order > a.order ? -1 : 0));
  }

  return (
    <PageContainer>
      <PageHeader title={t('social.scheduler.add_new_admin_post')} />
      <PageBody isLoading={loading} error={error && t('errors.generic')}>
        <div className='mt-6 max-w-5xl'>
          <ScheduledPostForm initialValues={initialValues} onSubmit={submitForm} isLoading={isLoading} />
        </div>
      </PageBody>
    </PageContainer>
  );
};

export default ScheduledPostsAdd;
