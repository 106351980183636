import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ProfilePageProvider } from './user-profile-ctx';
import { ProfileForm } from './profile-form';
import S from './profile-form.module.scss';

const ProfilePage = () => {
  const { t } = useTranslation();
  const [isActive, setIsActive] = useState(false);

  return (
    <ProfilePageProvider>
      <div className={S.userProfilePage}>
        <header>
          <h1 className={S.h1Heading}>{t('employeePage.profile.title')}</h1>
          {!isActive && (
            <button className={S.invertedBtnBlue} onClick={() => setIsActive(true)}>
              {t('employeePage.profile.update.title')}
            </button>
          )}
        </header>

        <ProfileForm isActive={isActive} onClose={() => setIsActive(false)} />
      </div>
    </ProfilePageProvider>
  );
};

ProfilePage.displayName = 'ProfilePage';

export { ProfilePage };
