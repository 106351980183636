import { gql } from 'apollo-boost';

export const BLOCK_DANCER = gql`
    mutation dancer_block(
        $businessId: Int!
        $dancerId: Int!
    ){
        dancer_block(input: {
            businessId: $businessId
            dancerId:   $dancerId
        }) {
            dancer {
                id
                blocked_by { id name }
            }
        }
    }
`;
