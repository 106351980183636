import { gql } from 'apollo-boost';

export const UNSUSPEND_DANCER = gql`
    mutation dancer_unsuspend($dancerId: ID!){
        dancer_unsuspend(input: { dancerId: $dancerId }) {
            clientMutationId
            dancer{
                suspended
            }
        }
    }
`;
