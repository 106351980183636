import React from 'react';
import PropTypes from 'prop-types';

const BlastNotificationIcon = ({height, width}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    height={height}
    width={width}
    viewBox="0 0 30 30"
    preserveAspectRatio="xMidYMid meet"
  >
    <g fill="none" fillRule="evenodd">
      <mask fill="currentColor">
        <path d="M0 0h26v22.75H0z"/>
      </mask>
      <path
        fill="currentColor"
        d="M21.527 14.987c-.253.254-.56.38-.923.38H15.8c0-.018.004-.045.014-.081a.363.363 0 00.013-.082v-1.058c0-1.032-.362-1.936-1.085-2.715a3.084 3.084 0 011.52-.407h.108c.416.145.814.217 1.194.217s.778-.072 1.195-.217h.109c.831 0 1.547.299 2.144.895.597.598.895 1.313.895 2.145 0 .362-.127.67-.38.923zm-6.568-.054c0 .362-.127.669-.38.923-.254.253-.561.38-.923.38H5.839c-.362 0-.67-.127-.923-.38a1.257 1.257 0 01-.38-.923v-.787c0-.87.303-1.606.91-2.212.605-.607 1.343-.91 2.212-.91h.244c.597.29 1.212.435 1.845.435a4.17 4.17 0 001.846-.435h.244c.87 0 1.606.303 2.213.91.606.606.91 1.343.91 2.212v.787zM7.603 4.972c.597-.598 1.312-.896 2.144-.896.832 0 1.547.298 2.145.896.597.597.896 1.311.896 2.144 0 .832-.3 1.547-.896 2.144-.598.597-1.313.895-2.145.895-.832 0-1.547-.298-2.144-.895-.597-.597-.896-1.312-.896-2.144 0-.833.299-1.547.896-2.144zm8.115.732a2.516 2.516 0 011.846-.76c.724 0 1.34.254 1.846.76.507.507.76 1.122.76 1.846s-.253 1.34-.76 1.845a2.513 2.513 0 01-1.846.76 2.513 2.513 0 01-1.846-.76 2.512 2.512 0 01-.759-1.845c0-.724.253-1.339.76-1.846zm6.473-2.606C19.652 1.033 16.588 0 13.001 0 9.41 0 6.346 1.033 3.807 3.098 1.27 5.163 0 7.652 0 10.562c0 2.507.965 4.723 2.895 6.653-.204.745-.543 1.532-1.016 2.361-.474.83-.855 1.422-1.142 1.778-.29.355-.492.591-.61.71-.12.118-.144.262-.076.432a.496.496 0 00.152.178c.067.05.135.076.203.076 2.572 0 4.96-.863 7.16-2.59 1.727.644 3.537.965 5.434.965 3.588 0 6.652-1.032 9.191-3.098 2.54-2.064 3.81-4.552 3.81-7.465 0-2.91-1.27-5.399-3.81-7.464z"
      />
    </g>
  </svg>
);

BlastNotificationIcon.displayName = 'BlastNotificationIcon';
BlastNotificationIcon.propTypes = {
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired
};

export { BlastNotificationIcon };
