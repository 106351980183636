import { gql } from 'apollo-boost';

export const GET_DOB_REQUEST_LIST = gql`
  query DobRequests($after: String, $first: Int, $status: DobRequestStatus!) {
    dob_requests(after: $after, first: $first, status: $status) {
      pageInfo {
        endCursor
        hasNextPage
      }
      nodes {
        id
        dob
        user {
          id
          name
          email
          dob
        }
        image {
          id
          thumb
          medium
          data_updated_at
        }
        created_at
        updated_at
        rejection_text
      }
    }
  }
`;
