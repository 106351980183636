import { useApolloClient, useMutation } from '@apollo/react-hooks';
import * as PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { toast } from 'react-toastify';
import { useModal } from 'components/global-hooks';
import { UI_MODALS } from 'helpers/enums';
import { useTranslation } from 'react-i18next';

import S from './club-collection.module.scss';
import { ClubSearchGridItem } from './club-search-grid-item';
import { ClubSearchListItem } from './club-search-list-item';
import { CLOSE_BUSINESS } from 'graphql/mutations/business-close';
import { OPEN_BUSINESS } from 'graphql/mutations/business-open';
import { ALL_BUSINESS_GQL } from '../../../../graphql/queries/business-get-all';
import { logError } from '../../../../helpers/errors/bug-report';
import { filterViaText } from '../../../../helpers/filter';
import { sortByCollectionName } from '../../../../helpers/sorts';

const ClubCollection = ({ isList, clubs, filter, isAsc, businessId }) => {
  const { t } = useTranslation();
  const CLIENT = useApolloClient();
  const { initModal, closeModal } = useModal();
  const currentClubItemRef = useRef();

  const getUpdatedBusinesses = (businesses, businessId, closed) => {
    const updatedBusinessIdx = businesses.findIndex(({ id }) => id === businessId);
    businesses[updatedBusinessIdx] = { ...businesses[updatedBusinessIdx], closed };
    return businesses;
  };

  const [closeClub] = useMutation(CLOSE_BUSINESS, {
    onError: (err) => logError(err, 'CLOSE_BUSINESS', ClubCollection.displayName),
    update: (
      cache,
      {
        data: {
          business_close: { clientMutationId },
        },
      }
    ) => {
      const { businesses } = cache.readQuery({ query: ALL_BUSINESS_GQL });

      const updatedBusinesses = getUpdatedBusinesses([...businesses], clientMutationId, true);

      cache.writeQuery({
        query: ALL_BUSINESS_GQL,
        data: { businesses: updatedBusinesses },
      });
      toast.success(t('clubPage.club_close_success'));
    },
  });

  const [openClub] = useMutation(OPEN_BUSINESS, {
    onError: (err) => logError(err, 'OPEN_BUSINESS', ClubCollection.displayName),
    update: (
      cache,
      {
        data: {
          business_open: { clientMutationId },
        },
      }
    ) => {
      const { businesses } = cache.readQuery({ query: ALL_BUSINESS_GQL });

      const updatedBusinesses = getUpdatedBusinesses([...businesses], clientMutationId, false);

      cache.writeQuery({
        query: ALL_BUSINESS_GQL,
        data: { businesses: updatedBusinesses },
      });
      toast.success(t('clubPage.club_open_success'));
    },
  });

  const handleClose = useCallback(
    (id) => {
      initModal(UI_MODALS.CONFIRMATION, {
        message: t('clubPage.set_club_closed'),
        onConfirmation: () => {
          closeModal();
          closeClub({ variables: { id } });
        },
      });
    },
    [closeClub]
  );

  const handleOpen = useCallback(
    (id) => {
      initModal(UI_MODALS.CONFIRMATION, {
        message: t('clubPage.set_club_opened'),
        onConfirmation: () => {
          closeModal();
          openClub({ variables: { id } });
        },
      });
    },
    [openClub]
  );

  const handleSelect = useCallback((id) => CLIENT.writeData({ data: { userClubId: id } }), [CLIENT]);

  const CLUB_LIST = useMemo(
    () => sortByCollectionName(isAsc, filterViaText(clubs, 'name', filter)),
    [clubs, isAsc, filter]
  );

  useEffect(() => {
    if (currentClubItemRef.current) {
      currentClubItemRef.current.scrollIntoView({ behavior: 'auto', block: 'start', inline: 'nearest' });
    }
  }, []);

  return isList ? (
    <>
      <div className={S.headerListItem}>
        <div className={S.colImg} />
        <div className={S.colName}>{t('common.name')}</div>
        <div className={S.colAddress}>{t('common.address')}</div>
        <div className={S.colPhone}>{t('common.phone')}</div>
        <div className={S.colActions}>{t('common.actions')}</div>
      </div>
      <ul className={S.clubList}>
        {CLUB_LIST.map((club) => (
          <ClubSearchListItem
            ref={club.id === businessId ? currentClubItemRef : null}
            businessId={club.id}
            key={`CLUB_LIST_${club.id}`}
            isActive={club.id === businessId}
            handleSelect={() => handleSelect(club.id)}
            handleClose={() => handleClose(club.id)}
            handleOpen={() => handleOpen(club.id)}
            {...club}
          />
        ))}
      </ul>
    </>
  ) : (
    <ul className={S.clubGrid}>
      {CLUB_LIST.map((club) => (
        <ClubSearchGridItem
          businessId={businessId}
          key={`CLUB_GRID_${club.id}`}
          isActive={club.id === businessId}
          handleSelect={() => handleSelect(club.id)}
          handleClose={() => handleClose(club.id)}
          handleOpen={() => handleOpen(club.id)}
          {...club}
        />
      ))}
    </ul>
  );
};

ClubCollection.displayName = 'ClubCollection';

ClubCollection.propTypes = {
  clubs: PropTypes.array.isRequired,
  filter: PropTypes.string.isRequired,
  businessId: PropTypes.string,
  isList: PropTypes.bool.isRequired,
  isAsc: PropTypes.bool.isRequired,
};

ClubCollection.defaultProps = {
  businessId: null,
};

export { ClubCollection };
