import moment from 'moment';
import * as TimeHelpers from '../../../helpers/times';

const DATE_FORMAT = TimeHelpers.TimePatterns.date;

export const mergeMiniCalData = (array, obj, timezone) =>
  array.reduce((acm, cur) => {
    const DATE = moment(cur.start_time).tz(timezone).format(DATE_FORMAT);
    if (obj[DATE]) return acm;
    return !acm[DATE] ? { ...acm, [DATE]: [cur] } : { ...acm, [DATE]: [...acm[DATE], cur] };
  }, obj);
