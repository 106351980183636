import moment from 'moment';
import bookingConfig from '../../../configs/forms/booking-form-config';
import { BOOKING_TYPES } from '../../../helpers/constants';
import { TIME_PATTERN_12HR, TIME_STAMP_PATTERN } from '../../../helpers/regex-patterns';
import { TimePatterns } from '../../../helpers/times';

import { setErrorFields } from './modal-booking-actions';

const _validateBool = (value) => (value === true || value === false ? '' : 'bookingCalendar.errors.invalid_value');

const _validateAgainstList = (arr = [], list = [], t) =>
  arr.every((v) => list.includes(v)) ? '' : 'bookingCalendar.errors.invalid_value_in_groups';

const _validateSlots = (num = 0) =>
  num >= 1
    ? num > 1000
      ? 'bookingCalendar.errors.no_more_than_1000'
      : ''
    : 'bookingCalendar.errors.one_slot_required';

const _validateCheckOut = (num = 0) => (num >= 0 ? '' : 'bookingCalendar.errors.positive_number_only');

const _validateHour = (time = '') => (TIME_PATTERN_12HR.test(time) ? '' : 'bookingCalendar.errors.invalid_format');

const _validateDate = (time = '') => (TIME_STAMP_PATTERN.test(time) ? '' : 'bookingCalendar.errors.invalid_format');

const _validateString = (string = '', maxLength) => {
  if (string.length < bookingConfig.bookingString.min) return 'bookingCalendar.errors.characters_required';
  if (string.length > maxLength) return 'errors.validation.max_length';
  return '';
};

const _validateTitle = (string) => {
  if (string.length < 3 || string.length > 120) {
    return 'bookingCalendar.errors.title';
  }
  return '';
};

const _validateBookingType = (value = '') => {
  if (value === '') {
    return 'bookingCalendar.errors.booking_type_required';
  } else if (Object.keys(BOOKING_TYPES).includes(value.toLowerCase())) {
    return 'bookingCalendar.errors.invalid_booking_type';
  } else {
    return '';
  }
};

const _validateSelector = (value = '', t) => {
  if (value === '') {
    return 'bookingCalendar.errors.make_selection';
  } else if (!['one', 'series', 'all'].includes(value)) {
    return 'bookingCalendar.errors.invalid_selection';
  } else {
    return '';
  }
};

export const validateBookingField = (field, value, list = []) => {
  switch (field) {
    case 'booking_type':
      return _validateBookingType(value);
    case 'date':
      return _validateDate(value);
    case 'recurring':
      return _validateBool(value);
    case 'hardCap':
      return _validateBool(value);
    case 'slots':
      return _validateSlots(value);
    case 'preapprovedIds':
      return _validateAgainstList(value, list);
    case 'notifyIds':
      return _validateAgainstList(value, list);
    case 'start_time':
      return _validateHour(value);
    case 'end_time':
      return _validateHour(value);
    case 'title':
      return _validateTitle(value);
    case 'description':
      return _validateString(value, 500);
    case 'checkOut':
      return _validateCheckOut(value);
    case 'selector':
      return _validateSelector(value);
    default:
      return 'bookingCalendar.errors.unknown_booking_field';
  }
};

export const checkBookingForErrors = (form, dispatch, groups) => {
  const IS_EVENT = form.booking_type === BOOKING_TYPES.EVENT;
  const ERROR_OBJ = {
    date: _validateDate(form.date),
    start_time: _validateHour(form.start_time),
    end_time: _validateHour(form.end_time),
    booking_type: _validateBookingType(form.booking_type),
    slots: _validateSlots(form.slots),
    checkOut: _validateCheckOut(form.checkOut),
    recurring: _validateBool(form.recurring),
    hardCap: _validateBool(form.hardCap),
    preapprovedIds: _validateAgainstList(form.preapprovedIds, groups),
    notifyIds: _validateAgainstList(form.notifyIds, groups),
    title: IS_EVENT ? _validateString(form.title) : '',
    description: IS_EVENT ? _validateString(form.description, 500) : '',
    selector: !IS_EVENT && form.bookingId && form.recurring ? _validateSelector(form.selector) : '',
  };

  dispatch(setErrorFields(ERROR_OBJ));
  return ERROR_OBJ;
};

function creatTimeStamp({ date, start_time, end_time }, timezone) {
  const DATE = moment(date).tz(timezone).format(TimePatterns.date);
  const START = moment.tz(`${DATE} ${start_time}`, TimePatterns.timestamp, timezone);
  const END = moment.tz(`${DATE} ${end_time}`, TimePatterns.timestamp, timezone);

  if (END.isSameOrBefore(START)) {
    END.add(1, 'day');
  }
  return {
    startTime: START.toISOString(),
    endTime: END.toISOString(),
  };
}

export const getBookingDataParsed = (businessId, form, bookingId, timezone, imageInput) => {
  const slots = Number(form.slots);
  const { startTime, endTime } = creatTimeStamp(form, timezone);
  const IS_EVENT = form.booking_type === BOOKING_TYPES.EVENT;
  const IS_PROMO = form.booking_type === BOOKING_TYPES.PROMO;

  const UPDATE_KEYS = {
    shiftType: form.booking_type.toUpperCase(),
    startTime,
    endTime,
    slots,
    hardcapped: form.hardCap,
    preapprovedIds: form.preapprovedIds,
    autoCheckoutInterval: form.checkOut === '0' ? null : +form.checkOut,
  };

  const EVENT_KEYS = {
    title: form.title,
    description: form.description,
  };

  const PROMO_KEYS = {
    title: form.title,
    description: form.description,
  };

  if (imageInput) {
    EVENT_KEYS.imageInput = imageInput;
  }

  if (bookingId) {
    // update shift/event
    return IS_EVENT ? { ...UPDATE_KEYS, ...EVENT_KEYS } : IS_PROMO ? { ...UPDATE_KEYS, ...PROMO_KEYS } : UPDATE_KEYS;
  } else {
    if (IS_EVENT) {
      return { ...UPDATE_KEYS, ...EVENT_KEYS, notifyIds: form.notifyIds }; //create event
    } else if (IS_PROMO) {
      return { ...UPDATE_KEYS, ...PROMO_KEYS, notifyIds: form.notifyIds, recurring: form.recurring };
    } else {
      return {
        // create Shift
        notificationGroupIds: form.notifyIds,
        recurring: form.recurring,
        shifts: [0, ...form.duplicates].map((dayNum) => ({
          businessId,
          slots,
          startTime: moment(startTime).add(dayNum, 'day').toISOString(),
          endTime: moment(endTime).add(dayNum, 'day').toISOString(),
          shiftType: form.booking_type.toUpperCase(),
          hardcapped: form.hardCap,
          preapprovedGroupIds: form.preapprovedIds,
          autoCheckoutInterval: form.checkOut === '0' ? null : +form.checkOut,
        })),
      };
    }
  }
};
