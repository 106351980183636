import { useCallback } from 'react';
import { toast } from 'react-toastify';
import { useMutation } from '@apollo/react-hooks';
import { useHistory, useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';

import { useGlobalCtx } from '../../global-context';
import { useModal } from '../../global-hooks';
import { RouteConstants } from '../../routes/route-constants';
import { useBusiness, useUser } from '../../../graphql/graph-hooks';
import { BLOCK_DANCER } from '../../../graphql/mutations/dancer-block';
import { DELETE_DANCER } from '../../../graphql/mutations/dancer-delete';
import { UNBLOCK_DANCER } from '../../../graphql/mutations/dancer-unblock';
import { UNSUSPEND_DANCER } from '../../../graphql/mutations/dancer-unsuspend';
import { CREATE_CONVERSATION } from '../../../graphql/mutations/conversation-create';
import { logError } from '../../../helpers/errors/bug-report';
import { PERMISSIONS, UI_MODALS } from '../../../helpers/enums';
import { DANCER_GRID_FRAGMENT } from '../../../graphql/fragments/dancer-grid-fragment';
import updateFragment from 'graphql/cache/updateFragment';
import { ON_SUSPEND_KEEP_DANCER_PAGES } from 'helpers/constants';
import { isFunction } from 'lodash';
import { ACTION_TYPES } from 'components/pages-login/ApplicationRequestsPage/constants';

// TODO: make it more JS-y ?
const updateDancerCachedBlockedBy = (cache, dancerId, blockedBy, businessId, isAdmin, hasLocation) => {
  const dancer = cache.readFragment({
    id: `Dancer:${dancerId}`,
    fragment: DANCER_GRID_FRAGMENT,
    variables: { blockedByBusinessId: businessId, inGroupBusinessId: businessId, isAdmin, hasLocation },
    fragmentName: 'DancerGridItem',
  });

  dancer.blocked_by = blockedBy;

  cache.writeFragment({
    id: `Dancer:${dancerId}`,
    fragment: DANCER_GRID_FRAGMENT,
    variables: { blockedByBusinessId: businessId, inGroupBusinessId: businessId, isAdmin, hasLocation },
    fragmentName: 'DancerGridItem',
    data: dancer,
  });
};

export const useMenuActions = (dancerId, appId, removeDancer, handleSelect, blockedByBusinessId) => {
  const { t } = useTranslation();

  const { pathname } = useLocation();
  const HISTORY = useHistory();
  const { initModal, closeModal } = useModal();
  const { admin, employments } = useUser();
  const { id: businessId } = useBusiness();
  const { state } = useGlobalCtx();
  const CHAT_BUSINESS_ID = admin ? employments[0].employable_id : businessId;

  const [deleteDancer] = useMutation(DELETE_DANCER, {
    variables: { dancerId },
    onCompleted: () => {
      toast.success(t('dancerPage.dancer_delete_success'));
      if (isFunction(removeDancer)) {
        removeDancer();
      }
      closeModal();
    },
    onError: (err) => {
      logError(err, 'DELETE_DANCE', 'useMenuActions');
      toast.error(`${t('errors.server.500')}: ${t('dancerPage.dancer_delete_failed')}`);
    },
  });

  const [blockDancer] = useMutation(BLOCK_DANCER, {
    variables: {
      dancerId: Number(dancerId),
      businessId: Number(businessId),
      isAdmin: admin,
      hasLocation: true,
    },
    update: (
      cache,
      {
        data: {
          dancer_block: {
            dancer: { blocked_by },
          },
        },
      }
    ) => {
      // hasLocation: true should not have any bearing on blocked_by data, which is what we care about.
      // Need to pass it because it is a variable referenced in the fragment.
      updateDancerCachedBlockedBy(cache, dancerId, blocked_by, businessId, admin, true);
    },
    onCompleted: () => {
      toast.success(t('dancerPage.dancer_block_success'));
      if (
        removeDancer &&
        !admin &&
        ((pathname === RouteConstants.dancersSearch && state.pageSearchParams.isBlocked === false) ||
          pathname !== RouteConstants.dancersSearch)
      )
        if (isFunction(removeDancer)) {
          removeDancer();
        }
      closeModal();
    },
    onError: (err) => {
      logError(err, 'BLOCK_DANCER', 'useMenuActions');
      toast.error(`${t('errors.server.500')}: ${t('dancerPage.dancer_block_failed')}`);
    },
  });

  const [unblockDancer] = useMutation(UNBLOCK_DANCER, {
    variables: { dancerId: Number(dancerId), businessId: Number(businessId) },
    update: (
      cache,
      {
        data: {
          dancer_unblock: {
            dancer: { blocked_by },
          },
        },
      }
    ) => {
      // hasLocation: true should not have any bearing on blocked_by data, which is what we care about
      // Need to pass it because it is a variable referenced in the fragment.
      updateDancerCachedBlockedBy(cache, dancerId, blocked_by, businessId, admin, true);
    },
    onCompleted: () => {
      toast.success(t('dancerPage.dancer_unblock_success'));
      closeModal();
    },
    onError: (err) => {
      logError(err, 'UNBLOCK_DANCER', 'useMenuActions');
      toast.error(`${t('errors.server.500')}: ${t('dancerPage.dancer_unblock_failed')}`);
    },
  });

  const [unSuspendDancer] = useMutation(UNSUSPEND_DANCER, {
    variables: { dancerId },
    update: (
      cache,
      {
        data: {
          dancer_unsuspend: { dancer },
        },
      }
    ) => {
      updateFragment(cache, dancer, {
        fragment: DANCER_GRID_FRAGMENT,
        id: `Dancer:${dancerId}`,
        variables: { isAdmin: admin, hasLocation: false, blockedByBusinessId, inGroupBusinessId: blockedByBusinessId },
        fragmentName: 'DancerGridItem',
      });
    },
    onCompleted: () => {
      toast.success(t('dancerPage.dancer_unsuspend_success'));
      if (!ON_SUSPEND_KEEP_DANCER_PAGES.includes(pathname) && isFunction(removeDancer)) {
        removeDancer();
      }
      closeModal();
    },
    onError: (err) => {
      logError(err, 'UNSUSPEND_DANCER', 'useMenuActions');
      toast.error(`${t('errors.server.500')}: ${t('dancerPage.dancer_unsuspend_failed')}`);
    },
  });

  const [createConvo] = useMutation(CREATE_CONVERSATION, {
    variables: {
      businessId: CHAT_BUSINESS_ID,
      dancerId,
      isAdmin: admin,
      blockedByBusinessId: CHAT_BUSINESS_ID,
      inGroupBusinessId: CHAT_BUSINESS_ID,
    },
    onCompleted: ({ conversation_create_or_add: { conversation } }) => {
      HISTORY.push({
        pathname: RouteConstants[admin ? 'adminConversations' : 'conversations'],
        search: `cid=${conversation.id}`,
      });
      closeModal();
    },
    onError: (err) => {
      logError(err, 'CREATE_CONVERSATION', 'useMenuActions');
      toast.error(`${t('errors.server.500')}: ${t('dancerPage.dancer_conversation_create_failed')}`);
    },
  });

  const handleDelete = useCallback(async () => {
    if (!window.confirm(t('dancerPage.dancer_delete_confirm'))) {
      return;
    }
    await deleteDancer();
  }, [deleteDancer]);

  const handleBlock = useCallback(async () => {
    if (!window.confirm(t('dancerPage.dancer_block_confirm'))) {
      return;
    }
    await blockDancer();
  }, [blockDancer]);

  const handleActionEntertainer = useCallback(async (value) => {
    await handleSelect(value);
  }, []);

  return {
    default: { label: t('dancerPage.menu_actions.available_actions'), onSelect: null },
    message: { label: t('dancerPage.menu_actions.message'), onSelect: createConvo },
    delete: { label: t('dancerPage.menu_actions.delete'), onSelect: handleDelete },
    block: { label: t('dancerPage.menu_actions.block'), onSelect: handleBlock },
    unblock: { label: t('dancerPage.menu_actions.unblock'), onSelect: unblockDancer },
    unsuspend: { label: t('dancerPage.menu_actions.unsuspend'), onSelect: unSuspendDancer },
    pastBookings: {
      label: t('dancerPage.menu_actions.past_bookings'),
      onSelect: () => initModal(UI_MODALS.PREVIOUS_BOOKINGS, { dancerId }),
    },
    viewProfile: {
      label: t('dancerPage.menu_actions.view_profile'),
      onSelect: () => initModal(UI_MODALS.DANCER, { dancerId, appId, removeDancer }),
    },
    suspend: {
      label: t('dancerPage.menu_actions.suspend'),
      onSelect: () => initModal(UI_MODALS.SUSPEND_DANCER, { dancerId, removeDancer, blockedByBusinessId }),
    },
    manageGroups: {
      label: t('dancerPage.menu_actions.manage_groups'),
      onSelect: () => initModal(UI_MODALS.MANAGE_DANCER_GROUPS, { dancerId, businessId }),
    },
    invite: {
      label: t('dancerPage.menu_actions.invite'),
      onSelect: () => initModal(UI_MODALS.INVITE_DANCER, { dancerId }),
    },
    acceptEntertainer: {
      label: t('dancerPage.menu_actions.acceptEntertainer'),
      onSelect: () => handleActionEntertainer('ACCEPT'),
    },
    rejectEntertainer: {
      label: t('dancerPage.menu_actions.rejectEntertainer'),
      onSelect: () => handleActionEntertainer('REJECT'),
    },
    unVerifyEntertainer: {
      label: t('dancerPage.menu_actions.unverifyEntertainer'),
      onSelect: () => handleActionEntertainer('UNVERIFY'),
    },
  };
};

export const getDancerMenuList = (
  path,
  isDropDown,
  links,
  businessId,
  blocked_by,
  suspended,
  admin,
  permissions,
  verificationStatus
) => {
  const ADMIN_CONVO = path === RouteConstants.adminConversations;
  const IS_CHAT_PAGE = [RouteConstants.conversations, RouteConstants.adminConversations].includes(path);
  const RESULTS = [];

  const CAN_BLOCK = admin ? true : permissions.includes(PERMISSIONS.BLOCK_DANCERS);
  const CAN_TALK = admin ? true : permissions.includes(PERMISSIONS.MANAGE_CONVERSATIONS);
  const CAN_GROUP = admin ? true : permissions.includes(PERMISSIONS.MANAGE_GROUPS);
  const CAN_INVITE = admin ? true : permissions.includes(PERMISSIONS.SEND_INVITES);

  RESULTS.push(isDropDown ? links.default : links.viewProfile);
  RESULTS.push(links.pastBookings);
  if (isDropDown && verificationStatus === ACTION_TYPES.PENDING) {
    RESULTS.push(links.acceptEntertainer);
    RESULTS.push(links.rejectEntertainer);
  }
  if (isDropDown && verificationStatus === 'ACCEPTED') {
    RESULTS.push(links.unVerifyEntertainer);
  }
  if (!IS_CHAT_PAGE && CAN_TALK) {
    RESULTS.push(links.message);
  }

  if (businessId && !suspended) {
    if (CAN_GROUP) {
      RESULTS.push(links.manageGroups);
    }
    if (!ADMIN_CONVO && CAN_INVITE) {
      RESULTS.push(links.invite);
    }
    if (!ADMIN_CONVO && CAN_BLOCK) {
      RESULTS.push(links[blocked_by ? 'unblock' : 'block']);
    }
  }

  if (admin) {
    RESULTS.push(links[suspended ? 'unsuspend' : 'suspend'], links.delete);
  }

  return RESULTS;
};
