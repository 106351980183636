import React, { useRef, useCallback, useMemo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { logError } from 'helpers/errors/bug-report';
import { usePageChatCtx, setChatFields } from 'components/pages-login/chat/chat-ctx';
import { useMutation } from '@apollo/react-hooks';
import { useBusiness, useUser } from 'graphql/graph-hooks';
import { MESSAGE_MARK_AS_READ } from 'graphql/mutations/message-mark-as-read';
import * as CachedConversations from 'graphql/cache/conversations';
import * as CachedMessages from 'graphql/cache/messages';
import S from './chat-msg-body.module.scss';
import { MsgItem } from './msg-item';
import classNames from 'classnames';

type ChatMsgProps = {
  isLoading: boolean;
  isFetchMoreLoading: boolean;
  messages: MsgItemType[];
  targetId: string;
  conversationId: string;
  onScrollTop: any;
  error: string | undefined;
};

const ChatMsgBody: React.FC<ChatMsgProps> = ({
  isLoading,
  messages,
  targetId,
  conversationId,
  onScrollTop,
  isFetchMoreLoading,
  error,
}): React.ReactElement => {
  const MSG_CONTAINER = useRef<HTMLElement>();
  const SET_REF = useCallback((el) => (MSG_CONTAINER.current = el), [MSG_CONTAINER]);
  const {
    dispatch,
    state: { firstUnreadId, lastMsgId, chatBizId: businessId, scrollToBottom },
  } = usePageChatCtx();
  const { id: userId, admin: isAdmin } = useUser();
  const { id: blockecByBusinessId } = useBusiness();
  const [lastScrollHeight, setLastScrollHeight] = useState(0);
  const { t } = useTranslation();

  const [markAsRead] = useMutation(MESSAGE_MARK_AS_READ, {
    variables: { messageId: lastMsgId },
    onError: (err) => logError(err, 'MESSAGE_MARK_AS_READ', ChatMsgBody.displayName),
    update: (
      cache,
      {
        data: {
          message_mark_as_read: { message },
        },
      }
    ) => {
      const conversations = CachedConversations.getCachedConversations(
        cache,
        businessId,
        userId,
        isAdmin,
        blockecByBusinessId
      );
      const messages = CachedMessages.getCachedMessages(cache, conversationId).map((msg) => ({
        ...msg,
        read: msg.read || msg.sender?.id === targetId,
      }));

      CachedMessages.setCachedMessages(cache, conversationId, messages);

      //Uses writeQuery instead of writeData so userList updates
      //userList Array won't update if conversations aren't changed
      CachedConversations.setCachedConversations(
        cache,
        businessId,
        userId,
        isAdmin,
        blockecByBusinessId,
        conversations.map((convoObj) =>
          convoObj.id === conversationId
            ? {
                ...convoObj,
                updated_at: message.updated_at,
                business_unread_messages_count: 0,
              }
            : convoObj
        )
      );

      dispatch(setChatFields({ lastMsgId: null }));
    },
  });

  useEffect(() => {
    if (lastMsgId) {
      markAsRead({ variables: { messageId: lastMsgId } });
    }
  }, [lastMsgId, markAsRead]);

  useEffect(() => {
    setLastScrollHeight(0);
  }, [conversationId]);

  useEffect(() => {
    if (MSG_CONTAINER.current) {
      const top = scrollToBottom
        ? MSG_CONTAINER.current.scrollHeight
        : MSG_CONTAINER.current.scrollHeight - lastScrollHeight;
      MSG_CONTAINER.current.scrollTo({ top, behavior: scrollToBottom ? 'smooth' : 'auto' });
      if (messages.length) {
        setLastScrollHeight(MSG_CONTAINER.current.scrollHeight);
      }
    }
  }, [MSG_CONTAINER.current, messages, scrollToBottom]);

  const MESSAGES = useMemo(
    () =>
      messages.map((msg, i) => {
        const NEXT = messages[i + 1];
        const LAST = messages[i - 1];
        const MSG_SENDER = msg?.sender?.id;
        const LAST_SENDER = LAST?.sender?.id;
        const NEXT_SENDER = NEXT?.sender?.id;

        return {
          ...msg,
          isFirstUnRead: firstUnreadId === msg.id,
          // horrible, horrible fix to avoid the insane merging of consecutive messages
          firstMsg: true,
          showTime: true,
          // firstMsg: !LAST || LAST_SENDER !== MSG_SENDER || LAST_SENDER !== MSG_SENDER,
          // showTime: !NEXT || NEXT_SENDER !== MSG_SENDER || NEXT.id === firstUnreadId,
        };
      }),
    [messages, firstUnreadId]
  );

  if (!error && !isLoading && MESSAGES.length === 0) {
    return <h3 className={S.warnMsg}>{t('chatPage.no_messages')}</h3>;
  }

  const handleOnScroll = (e: any) => {
    if (!isLoading && (e.target as HTMLElement).scrollTop === 0) {
      onScrollTop();
    }
  };

  return (
    <div className={S.messageList} onScroll={handleOnScroll} ref={SET_REF}>
      {
        <h3
          className={classNames(S.warnMsg, {
            [S.hidden]: !error && !isLoading && !isFetchMoreLoading,
            [S.errorMsg]: error,
          })}
        >
          {error || t('common.loading')}
        </h3>
      }
      {!isLoading &&
        MESSAGES.map((message) => {
          return (
            <MsgItem
              key={`MESSAGE_${message.id}`}
              {...message}
              targetId={targetId}
              showNewMsgIndicator={!!firstUnreadId}
            />
          );
        })}
      <div className={S.clearFix} />
    </div>
  );
};

ChatMsgBody.displayName = 'ChatMsgBody';

ChatMsgBody.defaultProps = {
  messages: [],
};

export { ChatMsgBody };
