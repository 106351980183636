import { useTranslation } from 'react-i18next';

export const LanguageSelect = () => {
  const { i18n, t } = useTranslation();

  return (
    <div className='absolute top-2 right-10'>
      <select
        name='locale'
        value={i18n.language}
        onChange={(e) => {
          window.localStorage.setItem('locale', e.target.value);
          i18n.changeLanguage(e.target.value);
        }}
        className='mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 bg-slate-800 text-gray-400 ring-1 ring-inset ring-slate-700 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6'
      >
        <option value='en'>{t('common.english')}</option>
        <option value='es'>{t('common.spanish')}</option>
      </select>
    </div>
  );
};
