import * as React from 'react';
import * as PropTypes from 'prop-types';
import * as classNames from 'classnames';

import S from './booking-checkbox.module.scss';
import { FormCheckBox } from '../../../../../common/checkboxes/form-checkbox';

const BookingCheckBox = ({ name, label, gridArea, isChecked, isDisabled, handleChange, children }) => {
  return (
    <div
      style={{ gridArea: gridArea || null }}
      className={classNames(S.bookingFormCheckboxWrapper, {
        [S.disabled]: isDisabled,
      })}
    >
      <FormCheckBox
        id={`booking_checkbox_${name}`}
        label={label}
        name={name}
        isDisabled={isDisabled}
        isChecked={isChecked}
        handleChange={handleChange}
      />
      {children}
    </div>
  );
};

BookingCheckBox.displayName = 'BookingCheckBox';
BookingCheckBox.propTypes = {
  children: PropTypes.object,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  gridArea: PropTypes.string.isRequired,
  isChecked: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
};

BookingCheckBox.defaultProps = {
  name: null,
  children: null,
  error: '',
};

export { BookingCheckBox };
