import React, { useCallback } from 'react';
import * as PropTypes from 'prop-types';
import { FaPlus } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

import { useModal } from '../../../../../global-hooks';
import { UI_MODALS } from '../../../../../../helpers/enums';
import S from '../../../../../../sass/buttons/modern-btn.module.scss';

const BtnPreApproveGroup = ({ groupId }) => {
  const { t } = useTranslation();
  const { initModal } = useModal();
  const handlePreApprove = useCallback(
    () => initModal(UI_MODALS.PRE_APPROVED, { groupId: groupId }),
    [initModal, groupId]
  );

  return (
    <button style={{ width: '70px', marginBottom: '40px' }} className={S.modernBtn} onClick={handlePreApprove}>
      <FaPlus /> {t('groupsPage.actions.add')}
    </button>
  );
};

BtnPreApproveGroup.displayName = 'BtnPreApproveGroup';

BtnPreApproveGroup.propTypes = {
  groupId: PropTypes.string.isRequired,
};

export { BtnPreApproveGroup };
