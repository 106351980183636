import * as classNames from 'classnames';
import * as React from 'react';
import * as PropTypes from 'prop-types';

import S from './booking-groups-select.module.scss';
import { CheckBoxDropDown } from '../../../../../common/form/form-parts/checkbox-dropdown';

const BookingGroupsSelect = ({ label, gridArea, value, name, collection, handleChange, isDisabled }) => {
  return (
    <div
      style={{ gridArea }}
      className={classNames(S.bookingGroupSelectField, {
        [S.disabled]: isDisabled,
      })}
    >
      <label
        className={classNames(S.bookingFormLabel, {
          [S.disabled]: isDisabled,
        })}
      >
        {label}
      </label>
      <CheckBoxDropDown
        name={name}
        list={collection}
        selected={value}
        disabled={isDisabled}
        handleChange={(value) => handleChange({ name, value })}
      />
    </div>
  );
};

BookingGroupsSelect.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  gridArea: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  collection: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
};

export { BookingGroupsSelect };
