import Axios from 'axios';
import { buildForm } from './helpers/form-data-builder';

export const CallUpdateUserPhoto = (userId, img) => {
  let form = new FormData();
  form.append('user[image_attachment]', img);

  return Axios.put(
    `/v1/users/${userId}/photo`,
    form,
    { headers: { Authorization: `Token: ${localStorage.getItem('token')}` } },
  );
};

export const CallUpdateEventImg = (bookingId, img, booking = {}) => {
  let form = buildForm(booking, 'shift');
  form.append('shift[image_attachment]', img);
  return Axios.put(
    `/v1/shifts/${bookingId}`,
    form,
    { headers: { Authorization: `Token: ${localStorage.getItem('token')}` } },
  );
};

export const CallPostPasswordRecover = ({ email = '' }) =>
  Axios.post(
    '/v1/password_resets',
    { email },
  );

export const CallPutPasswordReset = ({ nonce = '', password = '', password_confirmation = '' }) =>
  Axios.put(
    '/v1/password_resets',
    {
      password_reset: {
        nonce,
        password,
        password_confirmation,
      },
    },
  );

export const CallPostDancerEmails = (list, businessId) =>
  Axios.post(
    '/v1/dancers/email',
    {
      businessId,
      emails: list
        .map(({ email }) => email)
        .filter(email => email).join(','),
    },
    { headers: { Authorization: `Token: ${localStorage.getItem('token')}` } },
  );
