import * as PropTypes from 'prop-types';
import React, { useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { StagedCheckbox } from './checkboxes/staged-checkbox';
import { TextSelectedPresenter } from './text-count-selected';

import S from './drop-down-with-selected.module.scss';

function getCheckboxStage(total, selected) {
  if (selected > 0 && selected >= total) {
    return 'checked';
  } else if (selected === 0) {
    return 'unchecked';
  } else {
    return 'some';
  }
}

const DropDownWithSelected = ({ totalCount, selectedCount, onDeselect, onSelectAll }) => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);
  const stage = useMemo(() => getCheckboxStage(totalCount, selectedCount), [totalCount, selectedCount]);
  const useDeselectAll = useCallback(() => {
    onDeselect();
    setIsOpen(false);
  }, [onDeselect]);

  const useSelectAll = useCallback(() => {
    onSelectAll();
    setIsOpen(false);
  }, [onSelectAll]);

  return (
    <div className={S.dropDownSelected} onMouseEnter={() => setIsOpen(true)} onMouseLeave={() => setIsOpen(false)}>
      <div className={S.dropBox}>
        <StagedCheckbox stage={stage} readOnly />
        <span className={S.triangle} />
        {isOpen && (
          <ul>
            {onSelectAll && <li onClick={useSelectAll}>{t('common.select_all')}</li>}
            {onDeselect && <li onClick={useDeselectAll}>{t('common.deselect_all')}</li>}
          </ul>
        )}
      </div>

      <TextSelectedPresenter subCount={selectedCount} totalCount={totalCount} />
    </div>
  );
};

DropDownWithSelected.displayName = 'CheckboxDropdownPresenter';

DropDownWithSelected.propTypes = {
  totalCount: PropTypes.number.isRequired,
  selectedCount: PropTypes.number.isRequired,
  onDeselect: PropTypes.func,
  onSelectAll: PropTypes.func,
};

DropDownWithSelected.defaultProps = {
  onDeselect: null,
  onSelectAll: null,
};

export { DropDownWithSelected };
