import { gql } from 'apollo-boost';

export const CREATE_SHIFT_GQL = gql`    
    mutation ShiftCreate1(
        $recurring: Boolean
        $notificationGroupIds: [ID!]
        $shifts: [ShiftCreateObject!]!
    ){
        shift_create(input:{
            recurring: $recurring
            notificationGroupIds: $notificationGroupIds
            shiftParams:$shifts
        }){ clientMutationId }
    }
`;
