import * as React from 'react';
import * as PropTypes from 'prop-types';

import S from './error-page.module.scss';

const ErrorPage = ({ msg }) => {
  return (
    <div className={S.errorPageWrapper}>
      <h1>{msg}</h1>
    </div>
  );
};

ErrorPage.displayName = 'ErrorPage';

ErrorPage.propTypes = {
  msg: PropTypes.string.isRequired,
};

export { ErrorPage };
