import React, { useCallback } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import S from './group-header.module.scss';
import { GroupName } from './group-name';
import { GroupPageSubHeader } from './group-sub-header';
import { BtnGroupDelete } from '../group-actions/button-group-delete';
import { BtnGroupBlast } from '../group-actions/button-group-blast';
import { BtnGroupInvite } from '../group-actions/button-group-invite';
import { setGroupField, useGroupCtx } from '../../group-details-context';
import { SearchInput } from '../../../../../common/search-input';
import { useHasPermission } from 'graphql/graph-hooks';
import { PERMISSIONS } from 'helpers/enums';

const GroupHeader = ({ name, groupId, dancers, isDefaultGroup }) => {
  const CAN_SEND_INVITES = useHasPermission(PERMISSIONS.SEND_INVITES);
  const { t } = useTranslation();
  const {
    dispatch,
    state: { filter },
  } = useGroupCtx();

  const onFilterChange = useCallback((e) => dispatch(setGroupField('filter', e.target.value)), [dispatch]);

  return (
    <header className={S.groupDetailHeader}>
      <GroupName groupId={groupId} name={name} />

      <div className={S.groupDetailActionRow}>
        <BtnGroupInvite disabled={!CAN_SEND_INVITES} />
        <BtnGroupBlast groupId={groupId} />
        <BtnGroupDelete groupId={groupId} disabled={isDefaultGroup} />
      </div>

      <GroupPageSubHeader groupId={groupId} dancers={dancers} />
      <SearchInput gridArea='filter' placeholder={t('common.search_dancers')} onChange={onFilterChange} />
    </header>
  );
};

GroupHeader.displayName = 'GroupHeader';

GroupHeader.propTypes = {
  groupId: PropTypes.string.isRequired,
  dancers: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  isDefaultGroup: PropTypes.bool,
};

export { GroupHeader };
