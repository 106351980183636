import * as React from 'react';
import * as PropTypes from 'prop-types';
import classNames from 'classnames';
import { FaSearch } from 'react-icons/fa';

import S from './search-input.module.scss';

const SearchInput = ({ placeholder, onChange, gridArea, isDisabled, className }) => {
  return (
    <div className={classNames(S.searchInput, { [className]: className })} style={{ gridArea }}>
      <input type='search' onChange={onChange} placeholder={placeholder} disabled={isDisabled} />
      <FaSearch />
    </div>
  );
};

SearchInput.displayName = 'SearchInput';

SearchInput.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  margin: PropTypes.string,
  gridArea: PropTypes.string,
  isDisabled: PropTypes.bool,
};

SearchInput.defaultProps = {
  placeholder: 'Filter',
  className: null,
  value: '',
  isDisabled: false,
};

export { SearchInput };
