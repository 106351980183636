import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import S from './mini-cal-legend.module.scss';

const MiniCalLegend = memo(() => {
  const { t } = useTranslation();
  return (
    <div className={S.miniCalLegend}>
      <ul>
        <li>
          <div className={S.boxDashed} />
          <span>{t('common.today')}</span>
        </li>
        <li>
          <div className={S.boxSolid} />
          <span>{t('common.selected')}</span>
        </li>
      </ul>

      <ul>
        <li>
          <div className={S.colorRed} />
          <span>{t('bookingCalendar.unfilled')}</span>
        </li>
        <li>
          <div className={S.colorBlue} />
          <span>{t('bookingCalendar.unfilled_booking')}</span>
        </li>
        <li>
          <div className={S.colorGreen} />
          <span>{t('bookingCalendar.filled')}</span>
        </li>
      </ul>
    </div>
  );
});

MiniCalLegend.displayName = 'MiniCalLegend';

export { MiniCalLegend };
