import React, { useRef } from 'react';
import * as PropTypes from 'prop-types';

import S from './modal-group-add-dancers.module.scss';
import { ModalGroupAddDancersForm } from './modal-group-add-dancers-form';
import { CloseButton } from '../../common/buttons/close-button';
import { useOverlayClick } from '../../../helpers/hooks/use-overlay-click';

const ModalGroupAddDancers = ({ onClose, dancerIds }) => {
  const MODAL_REF = useRef();
  useOverlayClick(MODAL_REF, onClose);

  return (
    <div ref={MODAL_REF} className={S.addToGroupModal}>
      <CloseButton onClick={onClose} />
      <ModalGroupAddDancersForm dancerIds={dancerIds} onClose={onClose} />
    </div>
  );
};

ModalGroupAddDancers.displayName = 'ModalGroupAddDancers';

ModalGroupAddDancers.propTypes = {
  onClose: PropTypes.func.isRequired,
  dancerIds: PropTypes.array.isRequired,
};

export { ModalGroupAddDancers };
