import { SHIFT_APPLICATION_FRAGMENT } from '../fragments/shift-application-fragment';
import { gql } from 'apollo-boost';

export const BOOKING_CHECK_OUT_GQL = gql`
    ${SHIFT_APPLICATION_FRAGMENT}

    mutation shift_application_check_out($idArray: [ID!]!){
        shift_application_check_out(input:{ ids: $idArray }){
            failures { id message }
            successes {
                message
                shift_application{
                    ...shiftApplicationFragment
                }
            }
        }
    }
`;
