import * as React from 'react';
import * as PropTypes from 'prop-types';
import { useQuery } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';

import S from './employee-invites-list.module.scss';
import { InviteListItem } from './invite-list-item/invite-list-item';
import { LoadingDiscoWithContainer } from '../../../common/loading/loading-disco';
import { useBusiness, useUser } from '../../../../graphql/graph-hooks';
import { GET_INVITE_CODES } from '../../../../graphql/queries/invite-list';
import { logError } from '../../../../helpers/errors/bug-report';

const InviteList = ({ type, hasPermission, canEdit, search, active }) => {
  let variables;
  const { id: userId } = useUser();
  const { id: businessId } = useBusiness();
  const { t } = useTranslation();

  if (type === 'ADMIN') {
    variables = { admin: true, hasBusiness: false };
  } else if (type === 'CLUB') {
    variables = { businessId };
  } else if (type === 'EMPLOYEE') {
    variables = { businessId, userId };
  }

  const { loading, error, data } = useQuery(GET_INVITE_CODES, {
    fetchPolicy: 'network-only',
    variables: {
      ...variables,
      isClubAdmin: hasPermission,
      search: search,
      active: active,
    },
    onError: (err) => logError(err, 'GET_INVITE_CODES', InviteList.displayName),
  });

  if (loading) {
    return <LoadingDiscoWithContainer />;
  }

  if (error) {
    return <h1 className={S.errorPage}>{t('employeePage.error_loading_invite_codes')}</h1>;
  }

  if (data.registration_codes.length === 0) {
    return <h1 className={S.emptyList}>{t('employeePage.no_invite_codes_available')}</h1>;
  }

  return (
    <div className={S.inviteList}>
      {data.registration_codes.map((invite) => (
        <InviteListItem
          key={`invite_${invite.id}`}
          type={type}
          hasPermission={hasPermission}
          {...invite}
          canEdit={canEdit}
          filter={{ active, search }}
        />
      ))}
    </div>
  );
};

InviteList.displayName = 'InviteList';

InviteList.propTypes = {
  type: PropTypes.oneOf(['CLUB', 'ADMIN', 'EMPLOYEE']).isRequired,
  hasPermission: PropTypes.bool.isRequired,
  canEdit: PropTypes.bool.isRequired,
  search: PropTypes.string,
  active: PropTypes.bool,
};

export { InviteList };
