import { useMutation, useQuery } from '@apollo/react-hooks';
import * as classNames from 'classnames';
import * as PropTypes from 'prop-types';
import React, { useCallback, useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import S from './modal-manage-dancer-groups-form.module.scss';
import { GroupGrid } from '../../common/group-grid';
import { SearchInput } from '../../common/search-input';
import { LoadingDiscoWithContainer } from '../../common/loading/loading-disco';
import { ALL_GROUPS_GQL } from '../../../graphql/queries/group-list';
import { GROUP_UPDATE_GQL } from '../../../graphql/mutations/dancer-add-to-group';
import { logError } from '../../../helpers/errors/bug-report';
import { useModal } from 'components/global-hooks';
import { UI_MODALS } from 'helpers/enums';
import { useApolloClient } from '@apollo/react-hooks';
import updateFragment from 'graphql/cache/updateFragment';
import { DANCER_GRID_FRAGMENT } from 'graphql/fragments/dancer-grid-fragment';
import { useUser } from 'graphql/graph-hooks';

const AddToGroupsForm = ({ onClose, dancerId, businessId }) => {
  const { t } = useTranslation();
  const [filter, setFilter] = useState('');
  const [selected, setSelected] = useState([]);
  const { initModal } = useModal();
  const queryClient = useApolloClient();
  const { admin: isAdmin } = useUser();

  const [updateGroup, { loading }] = useMutation(GROUP_UPDATE_GQL, {
    variables: {
      isAdmin: false,
      hasLocation: false,
      inGroupBusinessId: businessId,
    },
    onError: (err) => {
      logError(err, GROUP_UPDATE_GQL, AddToGroupsForm.displayName);
      toast.error(`${t('errors.server.500')}: ${t('dancerPage.dancer_group_add_failed')}`);
    },
  });

  const {
    loading: initLoad,
    error,
    data,
  } = useQuery(ALL_GROUPS_GQL, {
    fetchPolicy: 'network-only',
    variables: { businessId },
    onError: (err) => {
      logError(err, 'ALL_GROUPS_GQL', AddToGroupsForm.displayName);
      toast.error(`${t('errors.server.500')}: ${t('dancerPage.dancer_groups_get_failed')}`);
    },
  });

  const handleSelection = useCallback(
    (id) => setSelected(selected.includes(id) ? selected.filter((ID) => ID !== id) : [...selected, id]),
    [selected]
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    const diff = getUserGroups().filter((x) => !selected.includes(x));
    const merged = selected.concat(diff);
    Promise.all(
      merged.map((groupId) => {
        const variables = { groupId };
        if (selected.find((gid) => gid === groupId)) {
          variables.toAdd = [dancerId];
        } else {
          variables.toRemove = [dancerId];
        }
        return updateGroup({
          variables,
          update: (
            cache,
            {
              data: {
                group_update: { group },
              },
            }
          ) => {
            cache.writeData({ id: `Group:${groupId}`, data: { group } });
          },
        });
      })
    ).then(() => {
      const variables = { isAdmin, hasLocation: false, blockedByBusinessId: businessId, inGroupBusinessId: businessId };
      updateFragment(
        queryClient,
        { in_group: selected.length },
        {
          variables,
          fragment: DANCER_GRID_FRAGMENT,
          id: `Dancer:${dancerId}`,
          variables,
          fragmentName: 'DancerGridItem',
        }
      );
      toast.success(t('common.save_success'));
      onClose();
    });
  };

  const handleCreateGroupBtnClick = () => initModal(UI_MODALS.CREATE_GROUP);

  const getUserGroups = () =>
    data.groups.filter(({ dancers }) => dancers.find(({ id }) => id === dancerId)).map(({ id }) => id);

  useEffect(() => {
    if (!data?.groups) {
      return;
    }
    setSelected(getUserGroups());
  }, [data?.groups]);

  if (initLoad) {
    return <LoadingDiscoWithContainer />;
  }
  if (error) {
    return <h3 className={S.errorMessage}>{t('dancerPage.error_loading_groups')}</h3>;
  }

  const GROUPS = data.groups.filter(({ name }) => name.toUpperCase().includes(filter.toUpperCase()));

  return (
    <>
      <div className={S.groupSettings}>
        <button className={S.invertedBtnGreen} onClick={handleCreateGroupBtnClick}>
          {t('dancerPage.group_create')}
        </button>
        <SearchInput
          placeholder={t('dancerPage.search_groups')}
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
        />
      </div>
      <form className={S.addToGroupsForm} onSubmit={handleSubmit}>
        <div className={classNames(S.groupArea, { [S.isGrid]: true })}>
          {GROUPS.map(({ id, number_of_dancers, dancers, name }) => (
            <div
              key={id}
              onClick={() => handleSelection(id)}
              className={classNames(S.group, {
                [S.selected]: selected.includes(id),
              })}
            >
              <GroupGrid count={number_of_dancers} dancers={dancers} groupId={id} />
              <span className={S.groupName}>{name}</span>
            </div>
          ))}
        </div>

        <div className={S.actionArea}>
          <button className={S.invertedBtnRed} type='button' disabled={loading} onClick={onClose}>
            {t('common.cancel')}
          </button>
          <button className={S.invertedBtnGreen} type='submit' disabled={loading}>
            {t('common.save')}
          </button>
        </div>
      </form>
    </>
  );
};

AddToGroupsForm.displayName = 'AddToGroupsForm';

AddToGroupsForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  dancerId: PropTypes.string.isRequired,
  businessId: PropTypes.string.isRequired,
};

export { AddToGroupsForm };
