import { gql } from 'apollo-boost';

export const UPDATE_EMPLOYEE_GQL = gql`
    mutation employment_create(
        $userId: ID!
        $businessId: ID!
        $role: String!
        $name: String!
        $email: String!
        $permissions: [String!]
        $notes: String
        $phoneNumber: String
    ) {
        employment_create(
            input: {
                userId: $userId
                businessId: $businessId
                role: $role
                name: $name
                email: $email
                permissions: $permissions
                notes: $notes
                phoneNumber: $phoneNumber
            }
        ) { employee { id } }
    }
`;
