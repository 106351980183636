import React, { useRef } from 'react';
import * as PropTypes from 'prop-types';

import S from './email-modal.module.scss';
import { EmailForm } from './email-form';
import { CloseButton } from '../../common/buttons/close-button';
import { useOverlayClick } from '../../../helpers/hooks/use-overlay-click';

const EmailModal = ({ onClose }) => {
  const MODAL_REF = useRef(null);
  useOverlayClick(MODAL_REF, onClose);

  return (
    <div ref={MODAL_REF} className={S.emailModal}>
      <CloseButton onClick={onClose} />
      <h3 className={S.modalHeading}>Dancer Emails</h3>
      <EmailForm onClose={onClose} />
    </div>
  );
};

EmailModal.displayName = 'EmailModal';
EmailModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export { EmailModal };
