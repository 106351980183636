import React from 'react';
import { useQuery } from '@apollo/react-hooks';

import { LogoutLayout } from '../pages-logout/logout-layout';
import { GlobalProvider } from '../global-context';
import { APOLLO_IS_LOGGED_IN, USER_CLUB_ID } from '../../graphql/local-state';

//CHANGES TO PROPS IN THIS COMPONENT WILL CAUSE PAGES TO REDRAW
const RouterSwitch = () => {
  const {
    data: { isLoggedIn, locale },
  } = useQuery(APOLLO_IS_LOGGED_IN);
  const {
    data: { userClubId },
  } = useQuery(USER_CLUB_ID);

  return isLoggedIn ? <GlobalProvider businessId={userClubId} locale={locale} /> : <LogoutLayout />;
};

RouterSwitch.displayName = 'RouterSwitch';

export { RouterSwitch };
