import * as PropTypes from 'prop-types';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { useModal } from '../../../global-hooks';
import { useTranslation } from 'react-i18next';

import S from '../../../pages-login/dancers/dancer-details/dancer-detail-styles.module.scss';
import { BOOKING_STATUS } from '../../../../helpers/enums';
import { createMoment, TimePatterns } from '../../../../helpers/times';
import { RouteCreator } from '../../../routes/route-constants';

const TIME_FORMAT = TimePatterns.timeStampShort;

const DancerAppItem = ({ timezone, status, shift, rating_sub_one, rating_sub_two, rating_sub_three, showRatings }) => {
  const { t } = useTranslation();
  const { closeModal } = useModal();
  return (
    <Link
      to={RouteCreator.applicationRequests(shift.id, shift.is_audition)}
      onClick={() => closeModal()}
      className={S.bookingApplicationItem}
    >
      <div className='uppercase'>{t(`common.${status.toLowerCase()}`)}</div>
      <div>{createMoment(shift.start_time, timezone).format(TIME_FORMAT)}</div>
      <div>{createMoment(shift.end_time, timezone).format(TIME_FORMAT)}</div>
      <div className='uppercase'>{t(`bookingCalendar.${shift.shift_type}`)}</div>
      {showRatings && status === BOOKING_STATUS.ACCEPTED && (
        <>
          <div>{rating_sub_one || 0}</div>
          <div>{rating_sub_two || 0}</div>
          <div>{rating_sub_three || 0}</div>
        </>
      )}
    </Link>
  );
};

DancerAppItem.displayName = 'DancerAppItem';
DancerAppItem.propTypes = {
  id: PropTypes.string.isRequired,
  showRatings: PropTypes.bool.isRequired,
  timezone: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  rating_sub_one: PropTypes.number,
  rating_sub_two: PropTypes.number,
  rating_sub_three: PropTypes.number,
  shift: PropTypes.shape({
    id: PropTypes.string.isRequired,
    start_time: PropTypes.string.isRequired,
    end_time: PropTypes.string.isRequired,
    shift_type: PropTypes.string.isRequired,
  }).isRequired,
};

DancerAppItem.defaultProps = {
  rating_sub_one: 0,
  rating_sub_two: 0,
  rating_sub_three: 0,
};

export { DancerAppItem };
