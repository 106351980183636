import React from 'react';
import PropTypes from 'prop-types';

import { FormLabel } from './form-parts/form-label';
import { FormError } from './form-parts/form-error';
import { InputFormNumber } from './form-parts/input-form-number';

const FormNumberField = ({
  id,
  label,
  name,
  value,
  error,
  step,
  min,
  max,
  classname,
  isDisabled,
  handleChange,
  gridArea,
  handleBlur,
}) => {
  return (
    <div className={classname} style={{ gridArea }}>
      <FormLabel id={id} label={label} isDisabled={isDisabled} hasError={!!error} />
      <InputFormNumber
        id={id}
        name={name}
        value={value}
        step={step}
        min={min}
        max={max}
        isDisabled={isDisabled}
        hasError={!!error}
        handleChange={handleChange}
        handleBlur={handleBlur}
      />
      <FormError error={error} />
    </div>
  );
};

FormNumberField.displayName = 'FormNumberField';

FormNumberField.propTypes = {
  id: PropTypes.string.isRequired,
  error: PropTypes.string,
  gridArea: PropTypes.string,
  step: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  classname: PropTypes.string,
  isDisabled: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func,
  integerOnly: PropTypes.bool,
};

FormNumberField.defaultProps = {
  gridArea: null,
  error: '',
  handleBlur: null,
  step: 1,
  min: null,
  max: null,
  classname: null,
};

export { FormNumberField };
