import ImageKit from 'imagekit-javascript';
import { UploadResponse } from 'imagekit-javascript/dist/src/interfaces';

import { APIConstants } from 'helpers/constants';

interface ImageKitUploadResponse extends UploadResponse {
  audioCodec?: string;
}

const useImageKit = () => {
  const {
    base_api_url,
    imagekit: { urlEndpoint, publicKey },
  } = APIConstants;

  const imagekit = new ImageKit({
    urlEndpoint,
    publicKey,
    authenticationEndpoint: `${base_api_url}/v1/imagekit/auth_data`,
  });

  const upload = async (base64: string, fileName: string, folder: string, tags: string[]) => {
    const payload = {
      file: base64,
      fileName,
      folder,
      tags,
      extensions: [
        {
          name: 'aws-auto-tagging',
          minConfidence: 80,
          maxTags: 10,
        },
      ],
    };

    const { url, audioCodec, fileType, width, height, fileId } = (await imagekit.upload(
      payload
    )) as ImageKitUploadResponse;
    return {
      url,
      hasSound: !!audioCodec,
      fileType,
      width,
      height,
      fileId,
    };
  };

  return {
    upload,
  };
};

export default useImageKit;
