import * as PropTypes from 'prop-types';
import * as React from 'react';

import { FormLabel } from './form-parts/form-label';
import { FormError } from './form-parts/form-error';
import { InputFormText } from './form-parts/input-form-text';

const FormTextField = ({
  id,
  label,
  name,
  type,
  value,
  error,
  placeholder,
  gridArea,
  classname,
  isDisabled,
  handleChange,
  handleBlur,
  handleFocus,
}) => {
  return (
    <div className={classname} style={{ gridArea }}>
      <FormLabel id={id} label={label} hasError={!!error} isDisabled={isDisabled} />
      <InputFormText
        id={id}
        name={name}
        type={type}
        value={value}
        placeholder={placeholder}
        hasError={!!error}
        isDisabled={isDisabled}
        handleBlur={handleBlur}
        handleChange={handleChange}
        handleFocus={handleFocus}
      />
      <FormError error={error} />
    </div>
  );
};

FormTextField.displayName = 'FormTextField';

FormTextField.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  error: PropTypes.string,
  type: PropTypes.string,
  classname: PropTypes.string,
  gridArea: PropTypes.string,
  isDisabled: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func,
  handleFocus: PropTypes.func,
};

FormTextField.defaultProps = {
  handleBlur: null,
  handleFocus: null,
  classname: null,
  gridArea: null,
  type: 'text',
  error: '',
};

export { FormTextField };
