import * as React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { LoginForm } from 'components/pages-logout/login/login-form';

import S from '../pre-login-parts.module.scss';

const LoginPage: React.FC = (): React.ReactElement => {
  const { t } = useTranslation();

  return (
    <>
      <LoginForm />
      <Link to={'/recovery'} className={S.activeLink}>
        {t('loginForm.forgot_password')}
      </Link>
      <p className={S.terms}>
        <Trans i18nKey='loginForm.terms'>
          As a user, I will not disclose certain proprietary and confidential information (“Confidential Information”).
          “Confidential Information” shall include all information or material that I have received from Indy Work
          Corporation that has or could have commercial value or other utility in business, specifically the scheduling
          features of the app. I agree that I shall hold and maintain the Confidential Information in strictest
          confidence. I shall carefully restrict access of the Confidential Information to third parties. I shall not,
          without prior written approval of Indy Work Corporation, use for my own benefit, publish, copy, or otherwise
          disclose to others, or permit the use by others for their benefit or to the detriment of Indy Work
          Corporation, any Confidential Information. I shall return to Indy Work or delete upon request whether written
          or oral, any and all records, notes, and other written, printed, or tangible materials, including the app, in
          my possession pertaining to Confidential Information.
        </Trans>
      </p>
    </>
  );
};

LoginPage.displayName = 'LoginPage';

export { LoginPage };
