import React from 'react';
import classNames from 'classnames';

const btnBlueFilled =
  'px-4 py-2 text-xs rounded-sm border no-underline inline-block border-blue-500 bg-blue-500 text-white hover:no-underline hover:border-blue-600 hover:bg-blue-600 hover:text-white disabled:text-gray-400 disabled:border-blue-700 disabled:bg-blue-700 disabled:hover:text-gray-400 disabled:hover:border-blue-700 disabled:hover:bg-blue-700 transition-colors duration-300';

const btnBlueInverted =
  'px-4 py-2 text-xs rounded-sm border no-underline inline-block border-blue-400 text-blue-400 hover:border-blue-500 hover:bg-blue-500 hover:text-white hover:no-underline disabled:text-blue-700 disabled:border-blue-700 disabled:hover:bg-transparent disabled:hover:text-blue-700 disabled:hover:border-blue-700 transition-colors duration-300';

const btnGreenFilled =
  'px-4 py-2 text-xs rounded-sm border no-underline inline-block border-green-600 bg-green-600 text-white hover:no-underline hover:border-green-500 hover:bg-green-500 hover:text-white disabled:text-gray-400 disabled:border-green-700 disabled:bg-green-700 disabled:hover:text-gray-400 disabled:hover:border-green-700 disabled:hover:bg-green-700 transition-colors duration-300';

const btnGreenInverted =
  'px-4 py-2 text-xs rounded-sm border no-underline inline-block border-green-600 text-green-600 hover:border-green-500 hover:bg-green-500 hover:text-white hover:no-underline disabled:text-green-700 disabled:border-green-700 disabled:hover:bg-transparent disabled:hover:text-green-700 disabled:hover:border-green-700 transition-colors duration-300';

const btnRedFilled =
  'px-4 py-2 text-xs rounded-sm border no-underline inline-block border-red-700 bg-red-700 text-white hover:no-underline hover:border-red-600 hover:bg-red-600 hover:text-white disabled:text-gray-400 disabled:border-pink-700 disabled:bg-pink-700 disabled:hover:text-gray-400 disabled:hover:border-pink-700 disabled:hover:bg-pink-700 transition-colors duration-300';

const btnRedInverted =
  'px-4 py-2 text-xs rounded-sm border no-underline inline-block border-red-500 text-red-500 hover:border-red-600 hover:bg-red-600 hover:text-white hover:no-underline disabled:text-pink-700 disabled:border-pink-700 disabled:hover:bg-transparent disabled:hover:text-pink-700 disabled:hover:border-pink-700 transition-colors duration-300';

const btnYellowFilled =
  'px-4 py-2 text-xs rounded-sm border no-underline inline-block border-yellow-700 bg-yellow-700 text-white hover:no-underline hover:border-yellow-600 hover:bg-yellow-600 hover:text-white disabled:text-gray-400 disabled:border-pink-700 disabled:bg-pink-700 disabled:hover:text-gray-400 disabled:hover:border-pink-700 disabled:hover:bg-pink-700 transition-colors duration-300';

const btnYellowInverted =
  'px-4 py-2 text-xs rounded-sm border no-underline inline-block border-yellow-500 text-yellow-500 hover:border-yellow-600 hover:bg-yellow-600 hover:text-white hover:no-underline disabled:text-gray-700 disabled:border-gray-700 disabled:hover:bg-transparent disabled:hover:text-gray-700 disabled:hover:border-gray-700 transition-colors duration-300';

export type ColorButtonColors = 'blue' | 'green' | 'red' | 'yellow';
export type ColorButtonTypes = 'filled' | 'inverted';
interface ColorButtonProps {
  color: ColorButtonColors;
  type: ColorButtonTypes;
  children: React.ReactNode;
  className?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  [x: string]: any;
}
const ColorButton: React.FC<ColorButtonProps> = ({ color, type, className, children, ...props }) => {
  let btnClass = '';
  switch (color) {
    case 'blue':
      btnClass = type === 'filled' ? btnBlueFilled : btnBlueInverted;
      break;
    case 'green':
      btnClass = type === 'filled' ? btnGreenFilled : btnGreenInverted;
      break;
    case 'red':
      btnClass = type === 'filled' ? btnRedFilled : btnRedInverted;
      break;
    case 'yellow':
      btnClass = type === 'filled' ? btnYellowFilled : btnYellowInverted;
      break;
    default:
      btnClass = type === 'filled' ? btnBlueFilled : btnBlueInverted;
  }

  return (
    <button className={classNames(btnClass, className)} {...props}>
      {children}
    </button>
  );
};

export default ColorButton;
