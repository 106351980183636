import { gql } from 'apollo-boost';

import { GET_USER_FRAGMENT } from '../fragments/user-fragment';

export const USER_PROFILE_CHANGE = gql`
  ${GET_USER_FRAGMENT}
  mutation userProfileUpdate($userId: ID!, $email: String!, $name: String!, $password: String, $locale: String) {
    user_profile_update(
      input: { updateParams: { id: $userId, email: $email, name: $name, password: $password, locale: $locale } }
    ) {
      user {
        ...userFragment
      }
    }
  }
`;
