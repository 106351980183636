import { useMutation, useQuery } from '@apollo/react-hooks';
import * as PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { toast } from 'react-toastify';

import S from './modal-pre-approval-form.module.scss';
import { PreApproveBookingList } from './booking-list/pre-approve-booking-list';
import { mergeMiniCalData } from '../../common/mini-calendar/mini-calendar-services';
import { MiniCalendar } from '../../common/mini-calendar/mini-calendar/mini-calendar';
import { useBusiness } from '../../../graphql/graph-hooks';
import { UPDATE_PRE_APPROVED_BOOKING } from '../../../graphql/mutations/shift-pre-approved-group-update';
import { GET_PRE_APPROVED_BOOKINGS } from '../../../graphql/queries/pre-approved-shift-list';
import { BOOKING_LIST_GQL } from '../../../graphql/queries/shift-for-invites';
import { logError } from '../../../helpers/errors/bug-report';
import * as TimeHelpers from '../../../helpers/times';
import { useTranslation } from 'react-i18next';

const ModalPreApproveForm = ({ groupId, onClose }) => {
  const { timezone, id } = useBusiness();
  const { t } = useTranslation();
  const [isInit, setIsInit] = useState(true);
  const [dataObj, setDataObj] = useState({});
  const [list, setList] = useState([]);
  const [bookingId, setBookingId] = useState(undefined);
  const [jsDate, setDate] = useState(TimeHelpers.getCurrentTimeAtTimezone(timezone).toDate());

  const { fetchMore } = useQuery(BOOKING_LIST_GQL, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: {
      businessID: id,
      endsAfter: TimeHelpers.startOfMonth(TimeHelpers.getCurrentTimeAtTimezone(timezone)),
      startsBefore: TimeHelpers.endOfMonth(TimeHelpers.getCurrentTimeAtTimezone(timezone)),
    },
    onError: (err) => logError(err, 'BOOKING_LIST_GQL', ModalPreApproveForm.displayName),
    onCompleted: (data) => {
      if (isInit) {
        setIsInit(false);
        const DATA = mergeMiniCalData(data.shifts.nodes, {}, timezone);
        setList(DATA?.[TimeHelpers.JSDateHelpers.date(jsDate)] || []);
        setDataObj(DATA);
      }
    },
  });

  const handleDateChange = useCallback(
    (jsDate) => {
      setDate(jsDate);
      setList(dataObj[TimeHelpers.JSDateHelpers.date(jsDate)] || []);
      setBookingId(null);
    },
    [dataObj]
  );

  const changeMonth = useCallback(
    async ({ activeStartDate }) =>
      await fetchMore({
        variables: {
          endsAfter: TimeHelpers.startOfMonth(activeStartDate),
          startsBefore: TimeHelpers.endOfMonth(activeStartDate),
        },
        updateQuery: (_, { fetchMoreResult }) => {
          setDataObj(mergeMiniCalData(fetchMoreResult.shifts.nodes, {}, timezone));
          return null;
        },
      }),
    [fetchMore]
  );

  const [addGroup, { loading }] = useMutation(UPDATE_PRE_APPROVED_BOOKING, {
    variables: { groupId },
    onError: (err) => {
      logError(err, 'UPDATE_PRE_APPROVED_BOOKING', ModalPreApproveForm.displayName);
      toast.error('Server Error: Unable to add group');
      toast.error(`${t('errors.server.500')}. ${t('modals.group_pre_approve.add_group.failure')}`);
    },
    update: (cache) => {
      const { id, start_time, end_time, shift_type, recurring, recurring_shift, __typename } = list.find(
        ({ id }) => id === bookingId
      );
      const FIELD = recurring ? 'recurring_shifts' : 'non_recurring_shifts';
      const ID = recurring ? recurring_shift?.id : id;
      const { preapproved_shifts } = cache.readQuery({
        query: GET_PRE_APPROVED_BOOKINGS,
        variables: { groupId },
      });

      cache.writeQuery({
        query: GET_PRE_APPROVED_BOOKINGS,
        variables: { groupId },
        data: {
          preapproved_shifts: {
            ...preapproved_shifts,
            [FIELD]: [
              ...preapproved_shifts[FIELD],
              {
                id: ID,
                start_time,
                end_time,
                shift_type,
                __typename,
              },
            ],
          },
        },
      });
      onClose();
    },
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { recurring_shift } = list.find(({ id }) => id === bookingId);
    const FIELD = recurring_shift ? 'recurringToAdd' : 'nonRecurringToAdd';
    await addGroup({ variables: { [FIELD]: [recurring_shift ? recurring_shift.id : bookingId] } });
  };

  return (
    <form className={S.preApprovalForm} onSubmit={handleSubmit}>
      <div className={S.calendarArea}>
        <div>
          <MiniCalendar
            data={dataObj}
            jsDate={jsDate}
            isLoading={loading}
            timezone={timezone}
            dateChange={handleDateChange}
            monthChange={changeMonth}
          />
        </div>

        <PreApproveBookingList
          list={list}
          groupId={groupId}
          timezone={timezone}
          selectedId={bookingId}
          selectedDate={jsDate}
          setBookingId={setBookingId}
        />
      </div>

      <div className={S.actionArea}>
        <button className={S.invertedBtnRed} disabled={loading} onClick={onClose}>
          {t('common.cancel')}
        </button>
        <button className={S.invertedBtnGreen} disabled={loading}>
          {t('modals.group_pre_approve.pre_approve')}
        </button>
      </div>
    </form>
  );
};

ModalPreApproveForm.displayName = 'ModalPreApproveForm';
ModalPreApproveForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
};

export { ModalPreApproveForm };
