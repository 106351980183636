import React, { useState } from 'react';
import { useBusiness } from '../../../../../graphql/graph-hooks';
import { subMonths } from 'date-fns';
import { useQuery } from 'react-query';
import { SerieData } from '../../../../../types/charts';
import { APIConstants } from '../../../../../helpers/constants';
import Graph from './graph';
import Summary from './summary';
import { LoadingDisco } from '../../../../common/loading/loading-disco';
import { Period } from './types';
import DateRangePicker, { DateRange } from 'rsuite/DateRangePicker';
import { useTranslation } from 'react-i18next';
import { InputPicker } from 'rsuite';

const aggregateColors: string[] = [
  'hsl(0, 0% ,100%)',
  'hsl(0, 0%, 0%)',
  'hsl(0, 100% ,50%)',
  'hsl(120, 100% ,50%)',
  'hsl(310, 100% ,50%)',
  'hsl(240, 100% ,50%)',
];

const Analytics = () => {
  const { t } = useTranslation();
  const { id: businessId } = useBusiness();

  const now = new Date();
  const lastMonth = subMonths(new Date(), 1);

  const [period, setPeriod] = useState<Period>(Period.DAY);
  const [dateRange, setDateRange] = useState<DateRange | null>([lastMonth, now]);

  const token = localStorage.getItem('token');

  const params = new URLSearchParams({ club_id: businessId.toString(), period: period.toString() });
  if (dateRange) {
    params.set('date_start', dateRange[0].toISOString().slice(0, 10));
    params.set('date_end', dateRange[1].toISOString().slice(0, 10));
  }

  const { data, isLoading, error } = useQuery<SerieData[]>(
    ['cumulativeBusinessData', businessId, period, dateRange],
    () =>
      fetch(`${APIConstants.BI_API_URL}/clubs/stats?${params}`, {
        headers: [['Authorization', `Bearer ${token}`]],
      }).then((res) => res.json())
  );

  const filtered = data ? data.filter((datum) => !['total_shifts', 'total_slots'].includes(datum.label)) : [];

  return (
    <>
      <div className='mt-6 flex items-center gap-4'>
        <div className='flex items-center gap-2'>
          <h5>{t('clubPage.analytics.picker.stat_type')}</h5>
          <InputPicker
            data={[
              { label: t('clubPage.analytics.picker.daily'), value: Period.DAY },
              { label: t('clubPage.analytics.picker.monthly'), value: Period.MONTH },
            ]}
            defaultValue={period}
            onChange={setPeriod}
            cleanable={false}
            disabled={isLoading}
          />
        </div>

        <div className='flex items-center gap-2'>
          <h5>{t('clubPage.analytics.picker.stat_range')}</h5>
          <DateRangePicker value={dateRange} onChange={setDateRange} disabled={isLoading} />
        </div>
      </div>
      <h2 className='text-xl font-bold mt-6'>{'Cumulative'}</h2>
      {isLoading ? (
        <LoadingDisco />
      ) : error ? (
        <div className='mt-6'>{t('errors.generic')}</div>
      ) : (
        <>
          <Graph data={filtered} colorArray={aggregateColors} chartType={'bar'} stacked={false} />
          <Summary data={data} />
        </>
      )}
    </>
  );
};

Analytics.displayName = 'Analytics';

export { Analytics };
