import React from 'react';
import { InputFormSelect } from '../../common/form/form-parts/input-form-select';

import S from './sort-select.module.scss';
import { useTranslation } from 'react-i18next';

interface SortSelectProps {
  value: string;
  label: string;
  onChange: (sortOrder: string) => void;
}

const SortSelect: React.FC<SortSelectProps> = ({ value = 'DESC', label, onChange }) => {
  const { t } = useTranslation();
  return (
    <div className={S.sortSelect}>
      <label htmlFor='sortField'>{label}</label>
      <InputFormSelect
        id='sortField'
        name='sort'
        value={value}
        onChange={onChange}
        options={ [{label: t('common.newest_first'), value: 'DESC'}, {label: t('common.oldest_first'), value: 'ASC'}] }
      />
    </div>
  );
};

export default SortSelect;
