import * as React from 'react';
import * as Icons from 'react-icons/all';
import { useTranslation } from 'react-i18next';

import S from './nav-club-area.module.scss';
import { IconNavLink } from './icon-nav-link';
import { SubLinks } from './sub-links';
import { NavBadges } from '../nav-badges';
import { EntertainerIcon } from '../../common/icons/entertainer-icon';
import { ManageRequestsIcon } from '../../common/icons/manage-requests';
import { useGlobalCtx } from '../../global-context';
import * as Routes from '../../routes/route-constants';
import { useBusiness, useUser } from '../../../graphql/graph-hooks';
import { PERMISSIONS } from '../../../helpers/enums';
import { useHasPermission } from '../../../graphql/graph-hooks';

const NavClubArea = () => {
  const { t } = useTranslation();

  const {
    state: { app },
  } = useGlobalCtx();
  const { id: businessId } = useBusiness();
  const { id: userId, admin, roles } = useUser();
  const HAS_CONVO = useHasPermission(PERMISSIONS.MANAGE_CONVERSATIONS);

  const _getEntertainerLinks = () => {
    const permissions = roles?.[businessId]?.permissions ?? [];
    let links = [];
    if (admin || permissions.includes(PERMISSIONS.SEARCH_DANCERS)) {
      links.push({
        path: Routes.RouteConstants.dancersSearch,
        label: t('club_nav.search_dancers'),
      });
    }
    if (admin || permissions.includes(PERMISSIONS.MANAGE_GROUPS)) {
      links.push({
        path: Routes.RouteConstants.groupSearch,
        label: t('club_nav.manage_groups'),
      });
    }

    return links;
  };

  const _getClubLinks = () => {
    const permissions = roles?.[businessId]?.permissions ?? [];
    let links = [
      {
        path: Routes.RouteConstants.clubSettings,
        label: t('club_nav.club_settings'),
      },
    ];

    if (admin || permissions.includes(PERMISSIONS.MANAGE_EMPLOYEES)) {
      links.push({
        path: Routes.RouteConstants.employeeSearch,
        label: t('club_nav.employees'),
      });
    }
    if (admin || permissions.includes(PERMISSIONS.MANAGE_REG_CODES)) {
      links.push({
        path: Routes.RouteConstants.clubInvites,
        label: t('club_nav.club_invites'),
      });
    }

    links.push({
      path: Routes.RouteCreator.employeeInvites(userId),
      label: t('club_nav.my_invites'),
    });

    return links;
  };

  const DANCER_LINKS = _getEntertainerLinks();
  const CLUB_LINKS = _getClubLinks();

  return (
    <ul className={S.navClubList}>
      <IconNavLink
        text={t('club_nav.booking_calendar')}
        icon={<Icons.FaCalendarAlt size='20' />}
        path={Routes.RouteConstants.calendar}
      />

      <SubLinks
        color='purple'
        label={t('club_nav.manage_requests')}
        Icon={
          <div className={S.alertWrapper}>
            <ManageRequestsIcon height={20} width={20} color='#6488DA' />
            {app.appCount + app.auditionCount > 0 && <span className={S.circle} />}
          </div>
        }
        links={[
          {
            path: Routes.RouteCreator.applicationRequests(null, false),
            label: t('club_nav.bookings'),
            notification: {
              count: app.appCount,
              color: 'red',
            },
          },
          {
            path: Routes.RouteCreator.applicationRequests(null, true),
            label: t('club_nav.auditions'),
            notification: {
              count: app.auditionCount,
              color: 'red',
            },
          },
        ]}
      />

      {HAS_CONVO && (
        <IconNavLink
          text={t('club_nav.conversations')}
          icon={<Icons.FaComments size='20' />}
          navExtra={<NavBadges count={app.unreadBusiness} color='purple' />}
          path={Routes.RouteConstants.conversations}
        />
      )}

      {DANCER_LINKS.length > 0 && (
        <SubLinks
          label={t('club_nav.entertainers')}
          Icon={<EntertainerIcon height={20} width={20} />}
          links={DANCER_LINKS}
        />
      )}

      <SubLinks label={t('club_nav.my_club')} Icon={<Icons.FaCog size='20' />} links={CLUB_LINKS} />
    </ul>
  );
};

NavClubArea.displayName = 'NavClubArea';

export { NavClubArea };
