import React from 'react';
import PropTypes from 'prop-types';

const EntertainerIcon = ({height, width}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    height={height}
    width={width}
    viewBox="0 0 30 30"
    preserveAspectRatio="xMidYMid meet"
  >
    <g fill="none" fillRule="evenodd">
      <g transform="translate(0 3.453)">
        <mask fill="currentColor">
          <path d="M0 .019h21.846V27.3H0z"/>
        </mask>
        <path
          fill="currentColor"
          d="M21.736 1.497a.772.772 0 00-.892-.32c-1.175.365-3.763 1.179-5.918 1.904a11.483 11.483 0 01-8.601-.516L1.137.097a.795.795 0 00-.863 1.318C1.644 2.603 6.96 6.665 7.65 8.2c.42.934 1.51 3.488 2.183 5.044-2.192 1.15-4.11 2.75-5.935 4.442-.733.678-.713 1.947 0 2.629.886.85 3.68 2.02 5.09 2.537a1.048 1.048 0 001.157-1.666c-.64-.749-1.719-1.984-2.09-2.245 1.173-1.008 2.422-1.945 3.807-2.6-.25 2.65 1.289 7.796 2.039 10.104A1.235 1.235 0 0016.3 25.9c-.3-2.253-.893-8.176-.493-11.572a7.489 7.489 0 00-.412-3.493c-.557-1.496-1.26-3.367-1.438-3.764 1.997-.762 6.099-3.547 7.565-4.563a.754.754 0 00.214-1.012"
        />
      </g>
      <path
        fill="currentColor"
        d="M10.413 6.003a3.002 3.002 0 000-6.003 3.002 3.002 0 000 6.003"
      />
    </g>
  </svg>
);

EntertainerIcon.displayName = 'EntertainerIcon';

EntertainerIcon.propTypes = {
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired
};

export { EntertainerIcon };
