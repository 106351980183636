import { gql } from 'apollo-boost';

export const DELETE_DANCER_IMAGES = gql`
    mutation dancer_delete_images($idArray: [ID!]! $dancerId: ID!){
        dancer_delete_images(input:{
            dancerId: $dancerId
            imageIds: $idArray
        }){
            dancer{
                first_image { id, thumb, medium }
                images { id thumb large }
            }
        }
    }
`;
