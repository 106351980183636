import React from 'react';
import * as PropTypes from 'prop-types';
import { FaImage } from 'react-icons/fa';
import classNames from 'classnames';
import { useTranslation, Trans } from 'react-i18next';

import S from './img-placeholder.module.scss';

const ImgPlaceholder = ({ alt, isRound, hasIcon, handleBtnClick, size }) => {
  const { t } = useTranslation();
  return (
    <div className={classNames(S.placeholder, { [S.isRound]: isRound })} style={{ height: size, width: size }}>
      {hasIcon && <FaImage />}

      <p className={S.p1}>{t('common.drag_file_here', { image: alt.toLowerCase() })}</p>
      <p className={S.p2}>
        {t('common.or')}
        <button type='button' onClick={handleBtnClick}>
          {t('common.browse')}
        </button>
      </p>
      <p className={S.p3}>{`JPG ${t('common.or')} PNG`}</p>
    </div>
  );
};

ImgPlaceholder.displayName = 'ImgPlaceholder';
ImgPlaceholder.propTypes = {
  hasIcon: PropTypes.bool,
  isRound: PropTypes.bool,
  size: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  handleBtnClick: PropTypes.func.isRequired,
};

ImgPlaceholder.defaultProps = {
  hasIcon: false,
  isRound: false,
};

export { ImgPlaceholder };
