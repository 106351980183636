import React, { useMemo } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import S from './courses-list.module.scss';
import { Image } from '../../common/images/img-file';
import { useModal } from '../../global-hooks';
import { useBusiness } from '../../../graphql/graph-hooks';
import { UI_MODALS } from '../../../helpers/enums';
import * as TimeHelpers from '../../../helpers/times';

const FORMAT = TimeHelpers.TimePatterns.modern;

const CoursesList = ({ isAsc, filter, courses, sortField, handleSelection, selectedCategories }) => {
  const { t } = useTranslation();
  const { timezone } = useBusiness();
  const { initModal } = useModal();
  const COURSE_LIST = useMemo(
    () =>
      courses
        .filter(({ title, course_categories }) => {
          if (!title.toUpperCase().includes(filter.toUpperCase())) return false;
          if (selectedCategories.length === 0) return true;

          return course_categories.reduce((acm, { id }) => (acm ? acm : selectedCategories.includes(id)), false);
        })
        .sort((a, b) => {
          const NAME_A = a[sortField].toUpperCase();
          const NAME_B = b[sortField].toUpperCase();
          let comparison = 0;

          if (isAsc) {
            if (NAME_A > NAME_B) comparison = 1;
            else if (NAME_A < NAME_B) comparison = -1;
          } else {
            if (NAME_A < NAME_B) comparison = 1;
            else if (NAME_A > NAME_B) comparison = -1;
          }
          return comparison;
        }),
    [filter, courses, selectedCategories, isAsc, sortField]
  );

  if (!COURSE_LIST.length) {
    return <div className='mt-4 text-center'>{t('coursesPage.no_courses_found')}</div>;
  }

  return (
    <>
      <div className={S.coursesTableHeader}>
        <div>{t('common.details')}</div>
        <div>{t('common.description')}</div>
        <div>{t('common.actions')}</div>
      </div>
      <ul className={S.coursesList}>
        {COURSE_LIST.map(({ id, title, description, image_url, course_categories, created_at, updated_at }) => (
          <li key={`course_${id}`} className={S.courseItem} onClick={() => handleSelection(id)}>
            <div className={S.courseImgArea}>
              <Image
                src={image_url}
                alt={title}
                fallback='VIDEO'
                onClick={() => initModal(UI_MODALS.VIDEO_PLAYER, { courseId: id })}
              />
            </div>

            <div className={S.courseDetails}>
              <h4>{title}</h4>
              <div>
                {t('common.created_at')}: <span>{TimeHelpers.getFormattedTimeZone(created_at, timezone, FORMAT)}</span>
              </div>
              <div>
                {t('common.updated_at')}: <span>{TimeHelpers.getFormattedTimeZone(updated_at, timezone, FORMAT)}</span>
              </div>
              <h5>{t('coursesPage.categories')}</h5>
              <p>{course_categories.map(({ name }) => name).join(' | ')}</p>
            </div>
            <p className={S.courseDescription}>{description}</p>

            <div className={S.courseActionArea}>
              <button
                type='button'
                className={S.invertedBtnBlue}
                onClick={() => initModal(UI_MODALS.COURSE_FORM, { courseId: id })}
              >
                {t('common.edit')}
              </button>
              <button
                type='button'
                className={S.invertedBtnBlue}
                onClick={() => initModal(UI_MODALS.VIDEO_PLAYER, { courseId: id })}
              >
                {t('common.view')}
              </button>
            </div>
          </li>
        ))}
      </ul>
    </>
  );
};

CoursesList.displayName = 'CoursesList';

CoursesList.propTypes = {
  filter: PropTypes.string,
  selectedId: PropTypes.string,
  sortField: PropTypes.string.isRequired,
  selectedCategories: PropTypes.array.isRequired,
  handleSelection: PropTypes.func.isRequired,
  isAsc: PropTypes.bool.isRequired,
  courses: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      youtube_url: PropTypes.string.isRequired,
      description: PropTypes.string,
      image_url: PropTypes.string,
      course_categories: PropTypes.array,
    })
  ).isRequired,
};

CoursesList.defaultProps = { selectedId: null };

export { CoursesList };
