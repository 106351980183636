import React, { useCallback, useMemo } from 'react';
import * as PropTypes from 'prop-types';
import classNames from 'classnames';
import S from './pre-approve-booking-list-item.module.scss';
import { RadioSpan } from '../../../common/radio-buttons/radio-span';
import { BookingTimeArea } from '../../../common/booking-list/booking-list-time';
import * as TimeHelpers from '../../../../helpers/times';
import { useTranslation } from 'react-i18next';

const PreApproveBookingListItem = ({
  id,
  slots,
  groupId,
  timezone,
  isSelected,
  setSelected,
  start_time,
  end_time,
  recurring,
  preapproved_group_ids,
  shift_applications,
  accepted_shift_applications_count,
  checked_in_shift_applications_count,
  checked_out_shift_applications_count,
}) => {
  const { t } = useTranslation();
  const IS_PAST = useMemo(() => TimeHelpers.getIsNowAfterTime(end_time), [end_time]);
  const IS_APPROVED = preapproved_group_ids.includes(Number(groupId));
  const IS_DISABLED = useMemo(() => IS_PAST || IS_APPROVED, [IS_PAST, IS_APPROVED]);
  const handleSelect = useCallback(
    (e) => {
      e.preventDefault();
      if (!IS_PAST) setSelected(id);
    },
    [setSelected, IS_PAST, id]
  );
  const ACCEPTED_COUNT = useMemo(
    () =>
      accepted_shift_applications_count + checked_in_shift_applications_count + checked_out_shift_applications_count,
    [accepted_shift_applications_count, checked_in_shift_applications_count, checked_out_shift_applications_count]
  );

  const SLOTS_LEFT = slots - ACCEPTED_COUNT;

  const StatusIndicator = () => {
    let color = SLOTS_LEFT === 0 ? 'green' : shift_applications.length ? 'blue' : 'red';
    return <div className={`rounded-3xl bg-${color}-500 w-2 h-2`} />;
  };

  return (
    <div className='flex items-center'>
      <StatusIndicator />
      <button className={classNames(S.preApprovedBookingItemBtn, { [S.disabled]: IS_DISABLED })} onClick={handleSelect}>
        <div className={classNames(S.topArea, { [S.disabled]: IS_DISABLED })}>
          <BookingTimeArea timezone={timezone} start={start_time} end={end_time} />

          {!IS_DISABLED && <RadioSpan checked={isSelected} />}
        </div>
        {recurring && <div className={S.extra}>{t('modals.group_pre_approve.repeats_weekly')}</div>}
        {IS_APPROVED && <div className={S.extra}>{t('modals.group_pre_approve.already_pre_approved')}</div>}
      </button>
    </div>
  );
};

PreApproveBookingListItem.displayName = 'BookingListItemPresenter';

PreApproveBookingListItem.propTypes = {
  isSelected: PropTypes.bool.isRequired,
  recurring: PropTypes.bool.isRequired,
  setSelected: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  slots: PropTypes.number.isRequired,
  groupId: PropTypes.string.isRequired,
  start_time: PropTypes.string.isRequired,
  end_time: PropTypes.string.isRequired,
  timezone: PropTypes.string.isRequired,
  preapproved_group_ids: PropTypes.array.isRequired,
  shift_applications: PropTypes.array.isRequired,
  accepted_shift_applications_count: PropTypes.number.isRequired,
  checked_in_shift_applications_count: PropTypes.number.isRequired,
  checked_out_shift_applications_count: PropTypes.number.isRequired,
};

export { PreApproveBookingListItem };
