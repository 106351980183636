import * as React from 'react';
import * as PropTypes from 'prop-types';
import { useQuery } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';

import S from './notification-list.module.scss';
import { AdminNotificationItem } from './admin-notification-item';
import { LoadingDiscoWithContainer } from '../../../common/loading/loading-disco';
import { logError } from '../../../../helpers/errors/bug-report';
import { GET_BLAST_GQL } from '../../../../graphql/queries/blast-get-query';

const TYPES = {
  ALL: 'all_users',
  DANCERS: 'all_dancers',
  WORKERS: 'all_workers',
  EMPLOYEES: 'all_employees_by_club',
};

const getNotificationList = (list, type) => {
  return Object.values(list)
    .filter(({ blast_type }) => (type === TYPES.ALL ? true : blast_type === type))
    .sort((a, b) => {
      if (a.created_at < b.created_at) return 1;
      else if (a.created_at > b.created_at) return -1;
      else return 0;
    });
};

const NotificationList = ({ type }) => {
  const { t } = useTranslation();
  const { loading, error, data } = useQuery(GET_BLAST_GQL, {
    onError: (err) => logError(err, 'GET_BLAST_GQL', NotificationList.displayName),
  });

  if (loading) return <LoadingDiscoWithContainer />;
  if (error) return <h1 className={S.errorPage}>{t('error.server.generic')}</h1>;

  return (
    <>
      <div className={S.notificationTableHeader}>
        <div>{t('common.details')}</div>
        <div>{t('notificationsPage.blast_message')}</div>
      </div>
      <div className={S.scrollBar}>
        {getNotificationList(data.blast_announcements, type).map(({ id, ...ancmnt }) => (
          <AdminNotificationItem key={`notification_${id}`} {...ancmnt} />
        ))}
      </div>
    </>
  );
};

NotificationList.displayName = 'NotificationList';

NotificationList.propTypes = {
  type: PropTypes.string.isRequired,
};

export { NotificationList };
