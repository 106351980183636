import React, { useCallback, useState } from 'react';
import * as PropTypes from 'prop-types';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

import S from './aside-tab-wrapper.module.scss';

const AsideTabWrapper = ({ label, children, defaultOpen }) => {
  const [isOpen, setIsOpen] = useState(defaultOpen || false);
  const handleClick = useCallback(() => setIsOpen(!isOpen), [isOpen]);

  return (
    <div className={S.asideTabWrapper}>
      <div className={S.tab} onClick={handleClick}>
        <h2>{label}</h2>
        {isOpen ? <FaChevronDown /> : <FaChevronUp />}
      </div>
      {isOpen && children}
    </div>
  );
};

AsideTabWrapper.displayName = 'AsideTabWrapper';

AsideTabWrapper.propTypes = {
  defaultOpen: PropTypes.bool,
  label: PropTypes.string.isRequired,
  children: PropTypes.object.isRequired,
};

AsideTabWrapper.defaultProps = {
  defaultOpen: false,
};

export { AsideTabWrapper };
