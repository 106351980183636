import React from 'react';
import { BarChart } from '../../../../common/chart_bar';
import { SerieData } from '../../../../../types/charts';
import { Panel } from 'rsuite';
import { useTranslation } from 'react-i18next';

const LegendItem = ({ color, text }: { color: string; text: string }) => {
  return (
    <>
      <span
        style={{
          display: 'inline-block',
          width: '0.7rem',
          height: '0.7rem',
          backgroundColor: color,
          border: '1px solid #000',
        }}
      ></span>
      <span> {text} </span>
    </>
  );
};

type Formatter = (value: number) => string;

interface Formatters {
  scale?: Formatter;
  tooltip?: Formatter;
  cursor?: Formatter;
}

interface ChartProps {
  data: SerieData[];
  colorArray: string[];
  stacked: boolean;
  chartType?: 'area' | 'bar' | 'line' | 'bubble' | undefined;
  formatters?: Formatters;
}

const Chart: React.FC<ChartProps> = ({data, colorArray, chartType, stacked, formatters}) => {
  const { t } = useTranslation();
  if (!data) {
    return null;
  }

  if (data.length === 0) {
    return <> {t('clubPage.analytics.no_data')} </>;
  }

  return (
    <BarChart
      data={data}
      chartType={chartType}
      colorArray={colorArray}
      stacked={stacked}
      formatters={formatters}
    />
  );
};

interface LegendProps {
  labels: string[];
  colorArray: string[];
}

const Legend: React.FC<LegendProps> = ({labels, colorArray}) => {
  const { t } = useTranslation();
  return (
    <>
      {labels.map((label: string, idx: number) => (
        <div className='flex-1 text-center' key={`legend-${label}`}>
          <LegendItem text={label} color={colorArray[idx]} />
        </div>
      ))}
    </>
  );
};

interface GraphProps {
  data: SerieData[] | undefined;
  colorArray: string[];
  stacked: boolean;
  chartType?: 'area' | 'bar' | 'line' | 'bubble' | undefined;
  formatters?: Formatters;
}
const Graph: React.FC<GraphProps> = ({ data, colorArray, chartType, stacked , formatters, }) => {
  const { t } = useTranslation();
  if (!data) {
    return null;
  }

  const labels: string[] = data.map((datum: SerieData) => datum.label);

  return (
    <Panel className='my-6' bordered header={t('clubPage.analytics.statistics')}>
      <div className='h-96'>
        <Chart data={data} colorArray={colorArray} chartType={chartType} stacked={stacked} formatters={formatters} />
      </div>

      <div className='flex'>
        <Legend labels={labels} colorArray={colorArray} />
      </div>
    </Panel>
  );
};

export default Graph;
