import { GET_CHAT_LIST } from 'graphql/queries/conversations-list-query';
import { logError } from 'helpers/errors/bug-report';

export const getCachedConversations = (
  cache: any,
  businessId: string,
  userId: string,
  isAdmin: boolean,
  blockedByBusinessId: string,
): ConversationTypes[] => {
  try {
    const data = cache.readQuery({
      query: GET_CHAT_LIST,
      variables: {businessId, userId, isAdmin, blockedByBusinessId: blockedByBusinessId}
    });

    return data.conversations || [];
  } catch (err) {
    logError(err, GET_CHAT_LIST, 'getCachedConversations');
    return [];
  }
};

export const setCachedConversations = (
  cache: any,
  businessId: string,
  userId: string,
  isAdmin: boolean,
  blockedByBusinessId: string,
  conversations: ConversationTypes[]
): void => {
  try {
    cache.writeQuery({
      query: GET_CHAT_LIST,
      variables: {businessId, userId, isAdmin, blockedByBusinessId: blockedByBusinessId},
      data: {conversations}
    });
  } catch (err) {
    logError(err, GET_CHAT_LIST, 'setCachedConversations');
  }
};
