import PropTypes from 'prop-types';
import React, { useCallback, useRef, useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import S from './modal-bug.module.scss';
import { FormTextareaField } from '../../common/form/form-textarea-field';
import { useGlobalCtx } from '../../global-context';
import { CREATE_BUG } from '../../../graphql/mutations/but-create';
import { logError } from '../../../helpers/errors/bug-report';
import { useOverlayClick } from '../../../helpers/hooks/use-overlay-click';

function validateReport(report) {
  if (report.length === 0) {
    return 'common.description_is_required';
  } else if (report.length < 10) {
    return 'common.description_is_too_short';
  } else {
    return '';
  }
}

const ModalBug = ({ onClose }) => {
  const { t } = useTranslation();
  const MODAL_REF = useRef();
  useOverlayClick(MODAL_REF, onClose);
  const [description, setDescription] = useState('');
  const [error, setError] = useState('');
  const {
    state: {
      app: { version },
    },
  } = useGlobalCtx();
  const [bugReportMutation, { loading }] = useMutation(CREATE_BUG, {
    fetchPolicy: 'no-cache',
    variables: { version },
    onError: (err) => logError(err, 'CREATE_BUG', ModalBug.displayName),
    onCompleted: () => {
      toast.success(t('bug_submit_success'));
      onClose();
    },
  });

  const handleChange = useCallback((e) => setDescription(e.target.value), []);
  const handleBlur = useCallback((e) => {
    const VALUE = e.target.value.trim();
    setDescription(VALUE);
    setError(t(validateReport(VALUE)));
  }, []);

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      const ERROR = t(validateReport(description));
      if (ERROR) {
        return setError(ERROR);
      }

      await bugReportMutation({
        variables: { description, version },
      });
    },
    [bugReportMutation, description, version]
  );

  return (
    <form ref={MODAL_REF} className={S.bugModalWrapper} onSubmit={handleSubmit}>
      <h1 className={S.modalHeading}>{t('bug_report')}</h1>
      <FormTextareaField
        id='bug_report_field'
        rows={5}
        label={t('common.description')}
        name='description'
        placeholder={t('bug_report_description')}
        value={description}
        error={error}
        isDisabled={loading}
        handleBlur={handleBlur}
        handleChange={handleChange}
      />

      <div className={S.reportActionRow}>
        <button type='RESET' className={S.invertedBtnRed} onClick={onClose}>
          {t('common.cancel')}
        </button>
        <button type='SUBMIT' className={S.invertedBtnGreen}>
          {t('common.submit')}
        </button>
      </div>
    </form>
  );
};

ModalBug.displayName = 'ModalBug';
ModalBug.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export { ModalBug };
