import * as React from 'react';
import * as PropTypes from 'prop-types';
import { Image } from '../../../../common/images/img-file';
import classNames from 'classnames';
import S from './group-notification-item.module.scss';
import { AnnouncementSender } from './group-notification-sender';
import { MessageArea } from '../../message-area';
import { CheckboxSpan } from '../../../../common/checkboxes/checkbox-span';
import { useTranslation } from 'react-i18next';

const GroupAnnouncementItem = ({ announcement, isSelected, onSelect, onDelete }) => {
  const { t } = useTranslation();
  const { id, created_at, message, employee, group, title } = announcement;

  return (
    <div className={classNames(S.groupNotification, { [S.selected]: isSelected })}>
      <button className={S.selectionBtn} onClick={() => onSelect(id)}>
        <CheckboxSpan isChecked={isSelected} />
        <Image alt='user profile' fallback='CLUB' src={group.business?.first_image?.thumb} />
      </button>
      <div className={S.notificationData}>
        <AnnouncementSender
          clubName={group.business.name}
          created={created_at}
          authorName={employee.name}
          groupName={group.name}
        />

        <MessageArea title={title} message={message} />
      </div>
      <button className={S.deleteBtn} onClick={() => onDelete(id)}>
        {t('common.delete')}
      </button>
    </div>
  );
};

const MsgShape = PropTypes.shape({
  en: PropTypes.string.isRequired,
  es: PropTypes.string,
});

GroupAnnouncementItem.displayName = 'GroupAnnouncementItem';
GroupAnnouncementItem.propTypes = {
  isSelected: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  announcement: PropTypes.shape({
    id: PropTypes.string.isRequired,
    message: MsgShape.isRequired,
    title: MsgShape.isRequired,
    created_at: PropTypes.string.isRequired,
    employee: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
    group: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      business: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        first_image: PropTypes.shape({
          id: PropTypes.string.isRequired,
          thumb: PropTypes.string.isRequired,
        }),
      }),
    }),
  }),
};

export { GroupAnnouncementItem };
