import * as React from 'react';
import moment from 'moment';
import * as PropTypes from 'prop-types';

import S from '../../../../sass/libs/shift-calendar.scss';
import * as TimeHelpers from '../../../../helpers/times';

const JS_DATE_HELP = TimeHelpers.JSDateHelpers;

const MiniCalendarDateTile = ({ date, data, today }) => {
  const DATE = JS_DATE_HELP.date(date);
  const DATA = data[DATE] || [];
  const IS_PAST = moment(DATE).isBefore(moment(today));
  let color;

  const SHIFTS = DATA.reduce(
    (acm, cur) => {
      const ACCEPTED =
        cur.accepted_shift_applications_count +
        cur.checked_in_shift_applications_count +
        cur.checked_out_shift_applications_count;

      return {
        filled: acm.filled + ACCEPTED,
        pending: acm.pending + cur.pending_shift_applications_count,
        unfilled: acm.unfilled + (cur.slots - ACCEPTED),
      };
    },
    { filled: 0, unfilled: 0, pending: 0 }
  );

  if (!IS_PAST && DATA.length > 0) {
    if (SHIFTS.unfilled === 0) color = 'green';
    else color = SHIFTS.pending > 0 ? 'blue' : 'red';
  } else if (SHIFTS.unfilled > 0 && SHIFTS.pending > 0) color = 'blue';

  return <div className={S.DotContainer}>{color && <div className={`S.dot-${color}`} />}</div>;
};

MiniCalendarDateTile.displayName = 'MiniCalendarDateTile';
MiniCalendarDateTile.propTypes = {
  today: PropTypes.object.isRequired,
  date: PropTypes.instanceOf(Date).isRequired,
  data: PropTypes.object.isRequired,
};

export { MiniCalendarDateTile };
