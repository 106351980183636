import React, { useRef } from 'react';
import * as PropTypes from 'prop-types';

import S from './modal-dancer.module.scss';
import { ModalDancerForm } from './modal-dancer-form';
import { CloseButton } from '../../common/buttons/close-button';
import { useOverlayClick } from '../../../helpers/hooks/use-overlay-click';

const ModalDancer = (props) => {
  const MODAL_REF = useRef();
  useOverlayClick(MODAL_REF, props.onClose);

  return (
    <div ref={MODAL_REF} className={S.dancerModalWrapper}>
      <CloseButton onClick={props.onClose} />
      <ModalDancerForm {...props} />
    </div>
  );
};

ModalDancer.displayName = 'DancerModal';
ModalDancer.propTypes = {
  dancerId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  appId: PropTypes.string,
  callback: PropTypes.func,
};

ModalDancer.defaultProps = {
  appId: null,
  callback: null,
};

export { ModalDancer };
