import { FC, useRef, useState, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useOverlayClick } from 'helpers/hooks/use-overlay-click';
import { useTranslation } from 'react-i18next';

import { GetstreamGSComment, GetstreamPost } from 'types/getstream';
import { CloseButton } from '../../common/buttons/close-button';
import { getFormattedJsTime } from 'helpers/times';
import { parseText } from 'helpers/feed';

import S from './modal-social-post.module.scss';
import { logError } from 'helpers/errors/bug-report';
import { APIConstants } from 'helpers/constants';
import { useUser } from 'graphql/graph-hooks';
import LoadMoreButton from 'components/common/buttons/load-more-btn/load-more-btn';
import MultimediaGallery from 'components/common/multimedia-gallery';
import { GET_GETSTREAM_COMMENT_REPORT, GET_GETSTREAM_POST } from 'graphql/queries/getstream';
import { LoadingDisco } from 'components/common/loading/loading-disco';
import CommentList from './comment-list';

interface Props {
  onClose: () => void;
  id: string;
  postData: GetstreamPost;
}

export const ModalSocialPost: FC<Props> = ({ onClose, id, postData }) => {
  const { t } = useTranslation();
  const { getstream_user } = useUser();
  const MODAL_REF = useRef(null);
  const [comments, setComments] = useState<GetstreamGSComment[]>([]);
  const [likes, setLikes] = useState([]);
  const [nextPage, setNextPage] = useState('');
  const [isCommentsLoading, setIsCommentsLoading] = useState(false);
  useOverlayClick(MODAL_REF, onClose);

  const { loading, error, data } = useQuery(GET_GETSTREAM_POST, {
    variables: { id, commentReportsStatus: 'REJECTED' },
    skip: !!postData,
  });

  const reports = useQuery(GET_GETSTREAM_COMMENT_REPORT, {
    fetchPolicy: 'network-only',
    variables: { getstreamPostId: id, status: 'REJECTED', first: 1000 },
    skip: !!postData,
  });

  const rejectedComments =
    (postData?.getstream_comment_reports || reports?.data?.getstream_comment_reports?.nodes)?.map(
      ({ cache }) => cache
    ) || [];

  const post = postData || data?.getstream_post;

  const makeGSRequest = async (url: string) => {
    try {
      return await fetch(url, {
        method: 'GET',
        cache: 'no-store',
        headers: {
          'Content-Type': 'application/json',
          'Stream-Auth-Type': 'jwt',
          Authorization: getstream_user.auth_token,
        },
      });
    } catch (error) {
      throw error;
    }
  };

  const fetchGetstreamComments = async () => {
    setIsCommentsLoading(true);
    try {
      const url = nextPage
        ? APIConstants.getstream.api.baseUrl + nextPage
        : `${APIConstants.getstream.api.fullUrl}/reaction/activity_id/${post.getstream_id}/comment/?api_key=${APIConstants.getstream.api.key}&limit=20`;
      const response = await makeGSRequest(url);
      const { results, next } = await response.json();
      setNextPage(next);
      setComments([...comments, ...results]);
    } catch (error) {
      logError(error, 'fetchGetstreamComments', 'ModalSocialPost');
    }
    setIsCommentsLoading(false);
  };

  const fetchGetstreamLikes = async () => {
    try {
      const url = `${APIConstants.getstream.api.fullUrl}/reaction/activity_id/${post.getstream_id}/like/?api_key=${APIConstants.getstream.api.key}`;
      const response = await makeGSRequest(url);
      const { results } = await response.json();
      setLikes(results);
    } catch (error) {
      logError(error, 'fetchGetstreamLikes', 'ModalSocialPost');
    }
  };

  useEffect(() => {
    if (!post?.getstream_id) return;
    if (post.status === 'APPROVED') {
      fetchGetstreamLikes();
    }

    fetchGetstreamComments();
  }, [post?.getstream_id]);

  if (error || loading) {
    return (
      <div className={S.modal} ref={MODAL_REF}>
        <div className='mb-2'>
          <CloseButton onClick={onClose} />
          <div>
            {loading ? (
              <LoadingDisco />
            ) : (
              <h1 className='text-center text-red-500'>{t('modals.social_post.error_fetching_post')}</h1>
            )}
          </div>
        </div>
      </div>
    );
  }

  const media = post.media
    .map((item) => {
      const transformations = ['f-mp4', 'w-960', 'h=540'];
      const [mediaUrl, queryStr] = item.url.split('?tr=');
      const trArr: string[] = queryStr ? queryStr.split(',') : [];
      transformations.push(...trArr);
      return { ...item, url: `${mediaUrl}?tr=${transformations.join(',')}` };
    })
    .sort((a, b) => a.order - b.order);

  return (
    <div className={S.modal} ref={MODAL_REF}>
      <div className='mb-2'>
        <CloseButton onClick={onClose} />
        <div>
          <div className='font-bold'>
            {post.user.name}
            <span className='ml-2 text-gray-500'> @{post.user.getstream_user.getstream_id} </span>
          </div>
        </div>
      </div>

      {media.length ? (
        <div className={'my-4'}>
          <MultimediaGallery
            media={media}
            showFullscreenButton={false}
            showPlayButton={false}
            showThumbnails={media.length > 1}
          />
        </div>
      ) : null}

      {post.title && <h3 className={'mb-4 text-lg font-bold'}>{post.title}</h3>}
      <p className={'mb-4'}>{parseText(post.text, null, { link: 'text-blue-500' }).text}</p>
      {post.link && (
        <p className={'mb-4'}>
          <a href={post.link.url} className='text-blue-400' target='_blank' rel='noreferrer'>
            {post.link.title}
          </a>
        </p>
      )}

      <div className='text-xs border-t-[1px] py-1 border-gray-500'>
        <div className='text-gray-300 flex justify-between'>
          <div>
            {t('modals.social_post.posted')}: <span className='font-bold'>{getFormattedJsTime(post.time)}</span>
          </div>
          <div>❤️({likes?.length || 0})</div>
        </div>
        {post.moderated_by && (
          <div className='text-gray-300'>
            <div className='font-bold'>
              {t(`common.${post.status.toLowerCase()}`)} {t('common.by')} {post.moderated_by.name} (
              {post.moderated_by.email}) @ <span className='font-bold'>{getFormattedJsTime(post.moderated_at)}</span>
            </div>
          </div>
        )}
      </div>

      {comments.length > 0 && <CommentList comments={comments} rejected={rejectedComments} />}

      {nextPage && (
        <div className='flex justify-center mt-4'>
          <LoadMoreButton isLoading={isCommentsLoading} onClick={fetchGetstreamComments} />
        </div>
      )}
    </div>
  );
};
