import { logError } from 'helpers/errors/bug-report';
import { GET_MESSAGES } from '../queries/messages-query';

export const getCachedMessages = (cache: any, chatId:string): MsgItemType[] => {
  try {
    const data = cache.readQuery({
      query: GET_MESSAGES,
      variables: { chatId }
    });

    return data.messages || [];
  } catch (err) {
    logError(err, GET_MESSAGES, 'getCachedMessages');
    return [];
  }
};

export const setCachedMessages = (cache: any, chatId:string | null, messages: MsgItemType[]): void => {
  try {
    cache.writeQuery({
      query: GET_MESSAGES,
      variables: {chatId },
      data: { messages }
    });
  } catch (err) {
    logError(err, GET_MESSAGES, 'setCachedMessages');
  }
};
