import _debounce from 'lodash/debounce';
import { useState, useEffect } from 'react';

import { setDancerSearchPagination, useDancerSearchCtx } from './dancer-search-context';

export const useDancerSearchScroll = (fetchMore, FIELD) => {
  const {
    dispatch,
    state: { pagination },
  } = useDancerSearchCtx();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', loadMore, {
      passive: true,
    });

    return () => {
      window.removeEventListener('scroll', loadMore);
    };
  }, []);

  const loadMore = _debounce(async () => {
    if (!isLoading && pagination.hasNextPage) {
      const bottom = Math.ceil(window.innerHeight + window.scrollY + 10) >= document.documentElement.scrollHeight;

      console.info('bottom', bottom, pagination);
      if (bottom) {
        setIsLoading(true);
        await fetchMore({
          variables: { after: pagination.endCursor },
          updateQuery: ({ [FIELD]: { nodes } }, { fetchMoreResult }) => {
            dispatch(setDancerSearchPagination(fetchMoreResult[FIELD].pageInfo));

            return {
              ...fetchMoreResult[FIELD],
              [FIELD]: {
                ...fetchMoreResult[FIELD],
                nodes: [...nodes, ...fetchMoreResult[FIELD].nodes],
              },
            };
          },
        });

        setIsLoading(false);
      }
    }
  }, 1000);

  return { isLoading, loadMore };
};
