import * as React from 'react';
import * as PropTypes from 'prop-types';

import { AvailableShiftItem }            from './invites-shift-item';
import { PreApprovedBookingListWrapper } from '../../../common/booking-list/pre-approved-booking-list-wrapper';
import { NoItems }                       from '../../../common/no-items';


const InviteBookingList = ({
  list,
  dancerIds,
  timezone,
  selectedId,
  setBookingId,
  selectedDate
}) => {
  return (
    <PreApprovedBookingListWrapper date={selectedDate}>
      {list.length === 0
        ? <NoItems />
        : list.map(booking => (
          <AvailableShiftItem
            key={booking.id}
            dancerIds={dancerIds}
            timezone={timezone}
            isSelected={booking.id === selectedId}
            setSelected={setBookingId}
            {...booking}
          />
        ))
      }
    </PreApprovedBookingListWrapper>
  );
};

InviteBookingList.displayName = 'InviteBookingList';
InviteBookingList.propTypes = {
  list:         PropTypes.array.isRequired,
  dancerIds:    PropTypes.array.isRequired,
  timezone:     PropTypes.string.isRequired,
  selectedId:   PropTypes.string,
  selectedDate: PropTypes.object.isRequired,
  setBookingId: PropTypes.func.isRequired,
};

InviteBookingList.defaultProps = {
  bookingId: null,
};

export { InviteBookingList };
