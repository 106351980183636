import * as React from 'react';
import * as PropTypes from 'prop-types';

import S from './radio-input.module.scss';

const RadioInput = ({ id, label, value, name, isDisabled, handleChange, isChecked }) => {
  return (
    <div className={S.radioInput}>
      <input
        id={id}
        type='radio'
        name={name}
        disabled={isDisabled}
        onChange={handleChange}
        checked={isChecked}
        value={value.toString()}
      />
      <label htmlFor={id}>{label}</label>
    </div>
  );
};

RadioInput.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]),
  isDisabled: PropTypes.bool,
  isChecked: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
};

RadioInput.defaultProps = {
  isDisabled: false,
};

export { RadioInput };
