import React, { FC, ReactNode, useState, useRef, ChangeEvent, DragEvent, useEffect } from 'react';
import { Formik, Form, Field, FormikProps } from 'formik';
import classNames from 'classnames';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { toast } from 'react-toastify';
import isEmpty from 'lodash/isEmpty';
import { GridContextProvider, GridDropZone, GridItem, swap, move } from 'react-grid-dnd';
import { useTranslation } from 'react-i18next';

import { useModal } from 'components/global-hooks';
import useImageKit from 'helpers/hooks/use-imagekit';

import { FormTextField } from 'components/common/form/form-text-field';
import { FormTextareaField } from 'components/common/form/form-textarea-field';
import Button from 'components/common/buttons';
import FileInput from './FileInput/';
import * as Icons from 'react-icons/all';

import validationSchema from './validation';
import { FormProps, Props } from '../types';
import { Base64Media, GetstreamMedia, MediaType } from 'types/getstream';
import { UI_MODALS } from 'helpers/enums';

import 'react-datepicker/dist/react-datepicker.css';
import { CheckboxSpan } from 'components/common/checkboxes/checkbox-span';

const Row: FC<{ className?: string; children: ReactNode }> = ({ className, children }) => {
  return <div className={classNames('flex flex-row mb-6', className)}>{children}</div>;
};

const CalendarContainer: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <div style={{ position: 'absolute', left: 0, top: 75, backgroundColor: 'white', color: 'black' }}>{children}</div>
  );
};

const getDateTimeBondaries = (date: Date) => {
  const now = moment();
  const isToday = now.isSame(date, 'd');
  const min = isToday ? now.startOf('hour').add(1, 'hour').toDate() : moment().startOf('day').toDate();
  const max = moment(date).endOf('day').toDate();
  return { min, max };
};

const MediaPreview: FC<{ media: Base64Media | GetstreamMedia; onClick: () => void }> = ({ media, onClick }) => {
  const { base64 } = media as Base64Media;
  const { url, media_type } = media as GetstreamMedia;
  const onDragStart = (e: DragEvent) => e.preventDefault();
  return ['PHOTO', 'image'].includes(media_type) ? (
    <img
      src={url || base64}
      alt='photo'
      className='object-contain max-w-full max-h-full'
      onClick={onClick}
      onDragStart={onDragStart}
    />
  ) : (
    <video className='object-contain max-w-full max-h-full' controls={true}>
      <source src={url || base64} type={'video/mp4'} />
    </video>
  );
};

const ScheduledPostForm: FC<Props> = ({ initialValues, onSubmit, isLoading }) => {
  const { t } = useTranslation();
  const [showCalendar, setShowCalendar] = useState(false);
  const [publishNow, setPublishNow] = useState(false);
  const [dateTimeBounds, setDateTimeBounds] = useState({
    min: moment().startOf('day').toDate(),
    max: moment().endOf('day').toDate(),
  });
  const { initModal, closeModal } = useModal();
  const formikRef = useRef<FormikProps<FormProps>>(null);
  const imagekit = useImageKit();
  const [isBusy, setIsBusy] = useState(false);

  const [mediaItems, setMediaItems] = React.useState<GetstreamMedia[]>([]);

  const handleOnMediaDropped = (ev: DragEvent) => {
    const files: File[] = [];
    if (!ev.dataTransfer) return;

    if (ev.dataTransfer.items) {
      [...ev.dataTransfer.items].forEach((item, i) => {
        // If dropped items aren't files, reject them
        if (item.kind === 'file') {
          files.push(item.getAsFile() as File);
        }
      });
    } else {
      [...ev.dataTransfer.files].forEach((file, i) => {
        files.push(file);
      });
    }

    if (files) {
      handleOnImageSelected(files[0]);
    }
  };

  const handleOnMediaInputChange = (ev: ChangeEvent<HTMLInputElement>) => {
    const files = (ev.target as HTMLInputElement).files;
    if (files) {
      handleOnImageSelected(files[0]);
    }
  };

  const handleRemoveMedia = (idx: number) => {
    const media = [...mediaItems];
    media.splice(idx, 1);
    setMediaItems(media);
    updateFieldValue('media', media);
  };

  const handleOnImageSelected = (file: File) => {
    if (file.size >= 10000000) {
      formikRef.current?.setErrors({
        ...formikRef.current.errors,
        media: t('social.scheduler.error.max_filesize'),
      });
      return;
    }
    const fileNameParts = file.name.split('.');
    const fileExt = fileNameParts[fileNameParts.length - 1];
    const mediaType = file.type ? file.type.split('/')[1] : fileExt;
    if (
      !['BMP', 'JPEG', 'JPG', 'PNG', 'GIF', 'MP4', 'MOV', 'HEIC', 'HEIF', 'QUICKTIME'].includes(mediaType.toUpperCase())
    ) {
      toast.error(t('social.scheduler.error.file_format'));
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = async () => {
      if (['heif', 'heic'].includes(fileExt)) {
        handleHeifImage(reader.result as string);
        return;
      }

      if (isMediaCroppable(mediaType.toUpperCase())) {
        handleImageCrop(reader.result);
      } else handleOnImageReady(reader.result as string);
    };
  };

  const handleHeifImage = async (base64: string) => {
    setIsBusy(true);
    try {
      // Upload to a temp directory
      const { fileType, url } = await imagekit.upload(base64, 'pp_post', '/feed/poleposition/temp', [
        'feed',
        'post',
        'poleposition',
      ]);
      const nextMedia = [...mediaItems, { url, base64, media_type: 'PHOTO' }].map((item, idx) => ({
        ...item,
        order: idx + 1,
      }));
      setMediaItems(nextMedia as GetstreamMedia[]);
      updateFieldValue('media', nextMedia);
    } catch (error) {
      toast.error(t('social.scheduler.error.image_preview'));
    }
    setIsBusy(false);
  };

  const handleImageCrop = (imageSrc: string | ArrayBuffer | undefined | null) => {
    initModal(UI_MODALS.IMAGE_CROP, {
      imageSrc,
      onCropped: handleOnImageReady,
      aspectRatio: '4:3',
    });
  };

  const handleOnImageReady = (base64: string) => {
    const mediaType = base64.split(';')[0].split(':')[1];
    const [media_type] = mediaType.split('/') as [MediaType];
    const nextMedia = [...mediaItems, { url: '', base64, media_type }].map((item, idx) => ({
      ...item,
      order: idx + 1,
    }));
    setMediaItems(nextMedia);
    updateFieldValue('media', nextMedia);
  };

  const handleOnPublishAtChange = (date: Date) => {
    const newDate = moment(date).isSameOrBefore(moment()) ? moment().startOf('hour').add(1, 'hour').toDate() : date;
    updateFieldValue('publishAt', newDate);
    setShowCalendar(false);
    if (newDate) {
      setDateTimeBounds(getDateTimeBondaries(newDate));
    }
  };

  const updateFieldValue = (field: keyof FormProps, value: any) => {
    if (formikRef.current) {
      formikRef.current.setFieldValue(field, value);
    }
  };

  const handleSubmit = (values: FormProps) => {
    values.media = [...mediaItems];
    if (moment(values.publishAt).isSameOrBefore(moment())) {
      initModal(UI_MODALS.CONFIRMATION, {
        title: t('common.warning'),
        message: t('social.scheduler.warning.auto_adjust_publish_time'),
        onConfirmation: () => {
          values.publishAt = moment(values.publishAt).startOf('hour').add(1, 'hour').toDate();
          closeModal();
          onSubmit(values);
        },
      });
      return;
    } else {
      onSubmit(values);
    }
  };

  const handleTogglePublishNow = () => {
    const nextPublishAt = publishNow ? initialValues.publishAt : null;
    updateFieldValue('publishAt', nextPublishAt);
    setPublishNow(!publishNow);
    if (showCalendar) {
      setShowCalendar(false);
    }
  };

  const isMediaCroppable = (type: string) => {
    return ['JPEG', 'JPG', 'PNG', 'BMP'].includes(type);
  };

  const onMediaOrderChanged = (sourceId: string, sourceIndex: number, targetIndex: number, targetId?: string) => {
    const nextState = swap(mediaItems, sourceIndex, targetIndex);
    setMediaItems(nextState);
  };

  useEffect(() => {
    setDateTimeBounds(getDateTimeBondaries(initialValues.publishAt));
  }, []);

  useEffect(() => {
    if (!mediaItems.length) {
      setMediaItems(initialValues.media);
    }
  }, [initialValues.media]);

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ values, errors, touched, handleChange, handleSubmit, handleBlur, isValid }) => {
        return (
          <Form>
            <GridContextProvider onChange={onMediaOrderChanged}>
              <GridDropZone id='items' boxesPerRow={5} rowHeight={205} className='h-72 relative'>
                {mediaItems.map((item, idx) => (
                  <GridItem key={item.order}>
                    <div className='h-48 w-48 mr-2 border-dashed border-2 border-gray-500 flex items-center justify-center'>
                      <div
                        className='absolute top-2 left-2 z-10 cursor-pointer hover:opacity-50'
                        onClick={handleRemoveMedia.bind(this, idx)}
                      >
                        <Icons.FaTimes size='20' color='red' />
                      </div>

                      <div className='absolute top-2 right-5 z-10 cursor-pointer hover:opacity-50 z-10 cursor-grab'>
                        <Icons.FaGripVertical size='20' color='white' />
                      </div>

                      <MediaPreview
                        media={item}
                        onClick={isMediaCroppable(item.media_type) ? handleImageCrop.bind(this, item.url) : () => null}
                      />
                    </div>
                  </GridItem>
                ))}
              </GridDropZone>
            </GridContextProvider>

            {mediaItems.length < 5 ? (
              <div className='absolute' style={{ left: mediaItems.length * 205, top: 0, transition: 'left 0.45s' }}>
                <FileInput
                  onChange={handleOnMediaInputChange}
                  onDropFile={handleOnMediaDropped}
                  isLoading={isBusy}
                  error={errors.media as string}
                />
              </div>
            ) : null}

            <Row>
              <Field>
                {() => (
                  <FormTextField
                    id='title'
                    name='title'
                    label={t('common.title')}
                    value={values.title}
                    error={touched.title ? errors.title : null}
                    isDisabled={false}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    classname={'flex-1 mr-5'}
                  />
                )}
              </Field>

              <div style={{ position: 'relative' }}>
                <FormTextField
                  id='publishAt'
                  name='publishAt'
                  label={t('social.scheduler.publish_at')}
                  value={publishNow ? '' : moment(values.publishAt).format('YYYY-MM-DD HH:mm')}
                  error={touched.publishAt ? (errors.publishAt as string) : null}
                  isDisabled={publishNow}
                  handleChange={() => null}
                  handleBlur={handleBlur}
                  handleFocus={() => setShowCalendar(true)}
                  classname={'flex-1 w-96'}
                />

                {showCalendar && (
                  <DatePicker
                    selected={values.publishAt}
                    onChange={handleOnPublishAtChange}
                    showTimeSelect
                    minDate={dateTimeBounds.min}
                    minTime={dateTimeBounds.min}
                    maxTime={dateTimeBounds.max}
                    timeFormat='HH:mm'
                    timeIntervals={60}
                    timeCaption='time'
                    inline
                    dateFormat='yyyy-MM-dd hh:mm'
                    calendarContainer={CalendarContainer}
                  />
                )}
              </div>

              <div className='flex ml-4 mt-5 items-center' onClick={handleTogglePublishNow}>
                <CheckboxSpan isForm isChecked={publishNow} value={publishNow} />
                <div className='ml-2'>{t('social.scheduler.publish_now')}</div>
              </div>
            </Row>

            <Row>
              <Field>
                {() => (
                  <FormTextareaField
                    id='title'
                    name='text'
                    label={t('social.scheduler.text')}
                    value={values.text}
                    error={touched.text ? errors.text : null}
                    isDisabled={false}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    classname={'flex-1'}
                  />
                )}
              </Field>
            </Row>

            <Row>
              <Field>
                {() => (
                  <FormTextField
                    id='link_title'
                    name='link_title'
                    label={t('social.scheduler.link.title')}
                    value={values.link_title}
                    error={touched.link_title ? errors.link_title : null}
                    isDisabled={false}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    classname={'flex-1 w-64 mr-5'}
                  />
                )}
              </Field>

              <Field>
                {() => (
                  <FormTextField
                    id='link_url'
                    name='link_url'
                    label={t('social.scheduler.link.url')}
                    value={values.link_url.replace(' ', '')}
                    error={touched.link_url ? errors.link_url : null}
                    isDisabled={false}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    classname={'flex-1 w-64'}
                  />
                )}
              </Field>
            </Row>

            <div>
              <Button
                title={t('common.submit')}
                color='green'
                onClick={handleSubmit}
                isLoading={isLoading}
                disabled={isBusy || (!isValid && !isEmpty(errors))}
              />
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ScheduledPostForm;
