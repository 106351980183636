import React, { useCallback, useMemo } from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import S from './group-list.module.scss';
import { removeDancerFromGroup } from '../page-group-services';
import { setGroupField, useGroupCtx } from '../group-details-context';
import { AlphabetizeBtn } from '../../../../common/buttons/alphabetize-btn';
import { DancerGridItem } from '../../../../common/dancer-grid-item/dancer-grid-item';
import { filterViaText } from '../../../../../helpers/filter';
import { sortByCollectionName } from '../../../../../helpers/sorts';
import { useBusiness } from '../../../../../graphql/graph-hooks';

const GroupDancerList = ({ dancerList, groupId, isAdmin }) => {
  const { t } = useTranslation();
  const {
    dispatch,
    state: { filter, isAsc, selected },
  } = useGroupCtx();
  const CLIENT = useApolloClient();
  const { id: businessId } = useBusiness();

  const DANCERS = useMemo(
    () => sortByCollectionName(isAsc, filterViaText(dancerList, 'name', filter)),
    [dancerList, filter, isAsc]
  );

  const removeDancer = (dancerId) => {
    dispatch(
      setGroupField(
        'selected',
        selected.filter((dancer) => dancer.id !== dancerId)
      )
    );
  };

  const handleToggleDancer = useCallback(
    (dancer) =>
      dispatch(
        setGroupField(
          'selected',
          !!selected.find((d) => d.id === dancer.id)
            ? selected.filter((d) => d.id !== dancer.id)
            : [...selected, dancer]
        )
      ),
    [dispatch, selected]
  );

  return (
    <div className={S.groupListWrapper}>
      <AlphabetizeBtn handleClick={() => dispatch(setGroupField('isAsc', !isAsc))} isAsc={isAsc} />

      {!DANCERS.length && <div className='text-center mt-6'>{t('groupsPage.no_dancers')}</div>}

      <div className={S.groupDancerList}>
        {DANCERS.map((dancer) => (
          <DancerGridItem
            isScalable
            hasOptions
            key={`GROUP_DANCER_${dancer.id}`}
            dancerId={dancer.id}
            isSelected={!!selected.find((d) => d.id === dancer.id)}
            selectDancer={() => handleToggleDancer(dancer)}
            removeDancer={() => {
              removeDancer(dancer.id);
              removeDancerFromGroup(CLIENT, dancer.id, { groupId, isAdmin, businessId });
            }}
          />
        ))}
      </div>
    </div>
  );
};

GroupDancerList.displayName = 'GroupDancerList';

GroupDancerList.propTypes = {
  dancerList: PropTypes.arrayOf(PropTypes.object),
  groupId: PropTypes.string.isRequired,
  isAdmin: PropTypes.bool.isRequired,
};

export { GroupDancerList };
