import { useMutation, useQuery } from '@apollo/react-hooks';
import * as PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import S from './group-sub-header.module.scss';
import { setGroupField, useGroupCtx } from '../../group-details-context';
import { DropDownWithSelected } from '../../../../../common/drop-down-with-selected';
import { useModal } from '../../../../../global-hooks';
import { useBusiness, useUser } from '../../../../../../graphql/graph-hooks';
import { GROUP_UPDATE_GQL } from '../../../../../../graphql/mutations/dancer-add-to-group';
import { GET_GROUP_GQL } from '../../../../../../graphql/queries/group-info';
import { UI_MODALS } from '../../../../../../helpers/enums';
import { logError } from '../../../../../../helpers/errors/bug-report';

const GroupPageSubHeader = ({ groupId, dancers }) => {
  const { t } = useTranslation();
  const {
    dispatch,
    state: { selected },
  } = useGroupCtx();
  const { admin: isAdmin } = useUser();
  const { id: businessId } = useBusiness();
  const { initModal } = useModal();
  const dancerIds = selected.map(({ id }) => id);
  const handleAddGroup = useCallback(() => initModal(UI_MODALS.ADD_TO_GROUP, { dancerIds }), [initModal, selected]);

  const handleDeselectAll = useCallback(() => dispatch(setGroupField('selected', [])), [dispatch]);

  const handleSelectAll = useCallback(() => dispatch(setGroupField('selected', dancers)), [dispatch, dancers]);

  const [removeDancers] = useMutation(GROUP_UPDATE_GQL, {
    variables: {
      groupId,
      isAdmin,
      toRemove: dancerIds,
      hasLocation: false,
      inGroupBusinessId: businessId,
    },
    update: (
      cache,
      {
        data: {
          group_update: { group },
        },
      }
    ) => {
      dispatch(setGroupField('selected', []));
      cache.writeQuery({
        query: GET_GROUP_GQL,
        variables: { groupId, isAdmin, hasLocation: false },
        data: { group },
      });
    },
    onCompleted: () => {
      toast.success(t('groupsPage.actions.remove_from_group.success'));
    },
    onError: (err) => {
      logError(err, 'remove GROUP_UPDATE_GQL', GroupPageSubHeader.displayName);
      toast.error(t('groupsPage.actions.remove_from_group.failure'));
    },
  });

  return (
    <div className={S.groupSubHeaderWrapper}>
      <DropDownWithSelected
        selectedCount={selected.length}
        totalCount={dancers.length}
        onDeselect={handleDeselectAll}
        onSelectAll={handleSelectAll}
      />

      <div className={S.groupSubHeaderActionArea}>
        {selected.length > 0 && (
          <>
            <button className={S.invertedBtnGreen} type='button' onClick={handleAddGroup}>
              {t('groupsPage.actions.add')}
            </button>
            <button className={S.invertedBtnRed} type='button' onClick={removeDancers}>
              {t('groupsPage.actions.remove')}
            </button>
          </>
        )}
      </div>
    </div>
  );
};

GroupPageSubHeader.displayName = 'GroupPageSubHeader';

GroupPageSubHeader.propTypes = {
  groupId: PropTypes.string.isRequired,
  dancers: PropTypes.array.isRequired,
};

export { GroupPageSubHeader };
