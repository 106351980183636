import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { GetstreamGSComment } from 'types/getstream';
import { getFormattedJsTime } from 'helpers/times';
import { parseText } from 'helpers/feed';

interface Props {
  comment: GetstreamGSComment;
  likes: number;
}

const truncate = (str: string, n: number) => {
  return str.length > n ? str.slice(0, n - 1) + '...' : str;
};

const CommentItem: FC<Props> = ({ comment: { user_id, created_at, data, user }, likes }) => {
  const { t } = useTranslation();
  const moderationStatus = data.moderation_status ? t(`common.${data.moderation_status}`).toUpperCase() : '';
  return (
    <div className={'my-2 rounded border-[1px] p-2 border-gray-500'}>
      <div className='flex'>
        <div>
          <img src={user?.data.profile_image} className='w-10 h-10 rounded-3xl mr-2' />
        </div>
        <div className='w-full'>
          <div className='text-xs text-gray-500 flex justify-between'>
            <div>
              {user?.data.display_name} <span className='font-bold'> @{truncate(user_id, 25)}</span>{' '}
            </div>
            <div className='flex'>
              {t('modals.social_post.likes') && <div className='mr-6'>❤️({likes})</div>}
              <div>{getFormattedJsTime(created_at)}</div>
            </div>
          </div>
          <div className='mt-1'>{parseText(data.text, null, { link: 'text-blue-500' }).text}</div>
          {data.moderation_status && (
            <div
              className={`flex justify-end text-xs ${
                { pending: 'text-yellow-300', rejected: 'text-red-500', approved: 'text-green-300' }[
                  data.moderation_status
                ]
              }`}
            >
              {`${moderationStatus} ${
                data.flagged_by.length
                  ? ` | ${t('modals.social_post.flagged_by')}: ` + data.flagged_by.map((name) => truncate(name, 25))
                  : ''
              }`}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CommentItem;
