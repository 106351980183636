import runtimeEnv from '@mars/heroku-js-runtime-env';

const MAPS_ID = 'googleMaps';

export const loadGoogleMaps = (callback) => {
  const existingScript = document.getElementById(MAPS_ID);

  if (!existingScript) {
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${runtimeEnv().REACT_APP_GOOGLE_PLACES_API_KEY}&libraries=places`;
    script.id = MAPS_ID;
    document.body.appendChild(script);

    script.onload = () => {
      if (callback) callback();
    };
  }

  if (existingScript && callback) callback();
};
