import { GET_DANCER_SEARCH_SQL, GET_DANCER_ADMIN_SEARCH_SQL } from '../../../../../graphql/queries/dancer-search-query';

export const removeDancerFromList = (isAdminSearch, cache, dancerId, params) => {
  const QUERY = {
    query: isAdminSearch ? GET_DANCER_ADMIN_SEARCH_SQL :  GET_DANCER_SEARCH_SQL,
    variables: params
  };
  const field = isAdminSearch ? 'admin_dancers' : 'dancers';

  const cacheData = cache.readQuery(QUERY);

  cache.writeQuery({
    ...QUERY,
    data: {
      [field]: {
        ...cacheData[field],
        nodes: cacheData[field].nodes.filter(({id}) => id !== dancerId),
        totalCount: params.isSuspended || params.isBlocked
          ? 0
          : cacheData[field].totalCount - 1,
      }
    }
  });
};
