import { SHIFT_APPLICATION_FRAGMENT } from '../fragments/shift-application-fragment';
import { gql } from 'apollo-boost';

export const SHIFT_APPLICATION_INVITE_CANCEL_GQL = gql`
  ${SHIFT_APPLICATION_FRAGMENT}

  mutation shift_application_invite_cancel($idArray: [ID!]!) {
    shift_application_invite_cancel(input: { ids: $idArray }) {
      failures {
        id
        message
      }
      successes {
        message
        shift_application {
          ...shiftApplicationFragment
        }
      }
    }
  }
`;
