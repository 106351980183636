import React from 'react';
import ColorButton from '../../common/buttons/color-button';
import { useTranslation } from 'react-i18next';

interface FilterProps {
  value: string;
  onChange: (status: string) => void;
  options?: string[];
}

const Filter: React.FC<FilterProps> = ({ value, onChange, options = ['APPROVED', 'PENDING', 'REJECTED'] }) => {
  const { t } = useTranslation();
  return (
    <div className='flex flex-row justify-between space-x-2'>
      {options.map((opt) => (
        <ColorButton
          key={`filter-${opt}`}
          color='blue'
          type={value === opt ? 'filled' : 'inverted'}
          className='uppercase w-28'
          onClick={onChange.bind(this, opt)}
        >
          {t(`common.${opt}`.toLowerCase())}
        </ColorButton>
      ))}
    </div>
  );
};

export default Filter;
