import { useApolloClient } from '@apollo/react-hooks';
import React, { useRef } from 'react';
import * as PropTypes from 'prop-types';
import { Player } from 'video-react';

import S from './modal-video.module.scss';
import { CloseButton } from '../../common/buttons/close-button';
import { COURSE_FRAGMENT } from '../../../graphql/fragments/course-fragment';
import { useOverlayClick } from '../../../helpers/hooks/use-overlay-click';

const ModalVideo = ({ onClose, courseId }) => {
  const CLIENT = useApolloClient();
  const MODAL_REF = useRef();
  const VIDEO_REF = useRef();

  useOverlayClick(MODAL_REF, onClose);

  const { title, image_url, youtube_url } = CLIENT.readFragment({
    fragment: COURSE_FRAGMENT,
    id: `Course:${courseId}`,
  });

  return (
    <div ref={MODAL_REF} className={S.modalVideoPlayer}>
      <CloseButton onClick={onClose} />
      <h3 className={S.modalHeading}>{title}</h3>
      <Player autoPlay ref={VIDEO_REF} poster={image_url} src={youtube_url} />
    </div>
  );
};

ModalVideo.displayName = 'ModalVideo';
ModalVideo.propTypes = {
  courseId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export { ModalVideo };
