import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import S from './hours-column.module.scss';
import * as ClubFormCtx from '../../club-form-context';
import { InputFormSelect } from '../../../../../common/form/form-parts/input-form-select';
import { getDayFromIndex } from '../../../../../../helpers/times';
import { OPERATION_TIMES } from '../../../../../../helpers/constants';

const HoursColumn = ({ isDisabled, index }) => {
  const { t, i18n } = useTranslation();
  const {
    dispatch,
    state: { form },
  } = ClubFormCtx.useClubFormCtx();

  let dayIndex = index;
  if (i18n.language === 'es') {
    switch (index) {
      case 0:
        dayIndex = 1;
        break;
      case 6:
        dayIndex = 0;
        break;
      default:
        dayIndex = index + 1;
        break;
    }
  }

  const { close, open } = form.schedule[dayIndex];

  const handleChange = useCallback(
    (val) => {
      dispatch(ClubFormCtx.setClubFormHour(dayIndex, { ...val }));
    },
    [dispatch, dayIndex]
  );
  return (
    <div className={S.hourColumn} style={{ gridArea: `col${dayIndex}` }}>
      <label>{getDayFromIndex(index, 'ddd', i18n.language)}</label>
      <InputFormSelect
        id={`hours_${dayIndex}_open`}
        name={`hours_${dayIndex}_open`}
        value={open}
        hasError={false}
        isDisabled={isDisabled}
        onChange={(e) => handleChange({ open: e.target.value, close })}
        options={[
          { value: 'closed', label: t('common.closed') },
          { value: 'allDay', label: t('common.24_hrs') },
          ...OPERATION_TIMES,
        ]}
      />

      {!['closed', 'allDay'].includes(open) && (
        <InputFormSelect
          id={`hours_${dayIndex}_closed`}
          name={`hours_${dayIndex}_closed`}
          value={close}
          hasError={false}
          isDisabled={isDisabled}
          onChange={(e) => handleChange({ close: e.target.value, open })}
          options={OPERATION_TIMES}
        />
      )}
    </div>
  );
};

HoursColumn.displayName = 'HoursColumn';

HoursColumn.propTypes = {
  isDisabled: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
};

export { HoursColumn };
