import * as React from 'react';
import { toast } from 'react-toastify';
import { useQuery } from '@apollo/react-hooks';
import * as PropTypes from 'prop-types';

import { LoginRoutes } from './login-routes';
import { setAppData, useGlobalCtx } from '../global-context';
import config from '../../configs/app-config';
import { useUser } from '../../graphql/graph-hooks';
import { BUSINESS_APP_STATE_GQL } from '../../graphql/queries/app-state-query';
import { logError } from '../../helpers/errors/bug-report';

//CHANGES TO PROPS IN THIS COMPONENT WILL CAUSE PAGES TO REDRAW
const LoginLayout = ({ businessId }) => {
  const { admin, employments } = useUser();
  const {
    dispatch,
    state: { app },
  } = useGlobalCtx();

  useQuery(BUSINESS_APP_STATE_GQL, {
    fetchPolicy: 'network-only',
    skip: !businessId,
    notifyOnNetworkStatusChange: true,
    pollInterval: config.appStateInterval,
    variables: { isAdmin: admin, businessId, client: config.client },
    onError: (err) => logError(err, 'BUSINESS_APP_STATE_GQL', LoginLayout.displayName),
    onCompleted: (data) => {
      const VERSION = data?.client_version?.client_version;
      if (
        [
          app.unreadBusiness !== data?.business_app_state?.unread_message_count,
          app.unreadAdmin !== data?.business_app_state?.admin_unread_message_count,
          app.appCount !== data?.business_app_state?.pending_application_count,
          app.verificationCount !== data?.business_app_state?.pending_verification_count,
          app.photoVerificationCount !== data?.business_app_state?.pending_photo_verification_count,
          app.inviteCodeRequestsCount !== data?.business_app_state?.pending_invite_requests_count,
          app.dobChangeRequestsCount !== data?.business_app_state?.pending_dob_change_request_count,
          app.auditionCount !== data?.business_app_state?.pending_audition_application_count,
        ].some((bool) => bool)
      ) {
        dispatch(
          setAppData({
            auditionCount: data?.business_app_state?.pending_audition_application_count,
            appCount: data?.business_app_state?.pending_application_count,
            unreadAdmin: data?.business_app_state?.admin_unread_message_count,
            unreadBusiness: data?.business_app_state?.unread_message_count,
            verificationCount: data?.business_app_state?.pending_verification_count,
            photoVerificationCount: data?.business_app_state?.pending_photo_verification_count,
            inviteCodeRequestsCount: data?.business_app_state?.pending_invite_requests_count,
            dobChangeRequestsCount: data?.business_app_state?.pending_dob_change_request_count,
            version: app.version ? app.version : VERSION,
          })
        );
      }

      if (app.version && app.version !== VERSION) {
        toast('We have an update! The page will refresh shortly.', {
          autoClose: 5000,
          position: toast.POSITION.BOTTOM_LEFT,
          className: 'mobile-toast',
          bodyClassName: 'mobile-toast-body',
          progressClassName: 'mobile-toast-progress-bar',
          toastId: 'OUT_OF_DATE',
          onClose: () => (window.location = window.location.href),
        });
      }
    },
  });

  return <LoginRoutes chatBusinessId={employments[0].employable_id} businessId={businessId} />;
};

LoginLayout.displayName = 'LoginLayout';
LoginLayout.propTypes = { businessId: PropTypes.string };
LoginLayout.defaultProps = { businessId: null };

export { LoginLayout };
