import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Form, Formik, Field, ErrorMessage } from 'formik';
import classNames from 'classnames';
import { TranslateButton } from 'components/common/translate_button';
import { logError } from 'helpers/errors/bug-report';
import { toast } from 'react-toastify';
import { useMutation } from '@apollo/react-hooks';
import { ADD_LINK, UPDATE_LINK } from 'graphql/mutations/vip';
import { camelizeKeys } from 'humps';
import { GET_VIP_PROMOS } from 'graphql/queries/vip-promos';
import * as Yup from 'yup';
import { ButtonSpinner } from 'components/common/button_spinner';
import { VIP_OFFER_LINK_TYPES } from 'helpers/constants';
import { useTranslation } from 'react-i18next';

interface Props {
  isOpen: boolean;
  closeModal: () => void;
  initialValues: VipOfferLinkCreate | VipOfferLinkUpdate;
  title: string;
}

const ModalLink: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const { isOpen, closeModal, initialValues, title } = props;

  const [addLink, { loading: isAddSubmitting }] = useMutation(ADD_LINK, {
    onCompleted: () => {
      toast.success(t('vip.offer.link.add.success'));
      closeModal();
    },
    refetchQueries: [{ query: GET_VIP_PROMOS }],
    onError: (err: Error) => {
      logError(err, 'ADD_LINK', ModalLink.displayName);
      toast.error(t('vip.offer.link.add.failure'));
    },
  });

  const [updateLink, { loading: isUpdateSubmitting }] = useMutation(UPDATE_LINK, {
    onCompleted: () => {
      toast.success(t('vip.offer.link.edit.success'));
      closeModal();
    },
    refetchQueries: [{ query: GET_VIP_PROMOS }],
    onError: (err: Error) => {
      logError(err, 'UPDATE_LINK', ModalLink.displayName);
      toast.error(t('vip.offer.link.edit.failure'));
    },
  });

  const isSubmitting = isAddSubmitting || isUpdateSubmitting;

  const linkValidationSchema = Yup.object().shape({
    name: Yup.object().shape({
      en: Yup.string().required(t('errors.validation.required', { field_name: t('common.name') })),
      es: Yup.string().required(t('errors.validation.required', { field_name: t('common.name') })),
    }),
    link_url: Yup.string()
      .url(t('errors.validation.invalid_url'))
      .required(t('errors.validation.required', { field_name: 'URL' })),
    link_type: Yup.string()
      .oneOf(Object.keys(VIP_OFFER_LINK_TYPES))
      .required(t('errors.validation.required', { field_name: t('vip.offer.link.type') })),
    sort_order: Yup.number()
      .integer(t('errors.validation.not_integer'))
      .min(0, t('errors.validation.min', { number: 0 }))
      .max(9999, t('errors.validation.max', { number: 9999 }))
      .required(t('errors.validation.required', { field_name: t('common.sort_order') })),
  });

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as='div' className='relative z-10' onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
        </Transition.Child>

        <div className='fixed z-10 inset-0 overflow-y-auto'>
          <div className='flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <Dialog.Panel className='relative bg-gray-900 rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-3xl sm sm:w-full sm:p-6'>
                <div>
                  <div className='mt-3 sm:mt-5'>
                    <Dialog.Title as='h3' className='text-lg leading-6 text-center font-medium text-gray-300'>
                      {title}
                    </Dialog.Title>
                    <div className='mt-2'>
                      <div className='mt-5 sm:mt-6'>
                        <Formik
                          validationSchema={linkValidationSchema}
                          initialValues={initialValues}
                          validateOnBlur={false}
                          onSubmit={(values) => {
                            const camelizedValues = camelizeKeys(values);
                            if (values.hasOwnProperty('id')) {
                              updateLink({ variables: { input: camelizedValues } });
                            } else {
                              addLink({ variables: { input: camelizedValues } });
                            }
                          }}
                        >
                          {({ values, touched, errors, isValid, setFieldValue }) => (
                            <Form>
                              <div className='bg-gray-800 shadow px-4 py-5 sm:rounded-lg sm:p-6 sm:col-span-3 mb-2'>
                                <div className='md:grid md:grid-cols-3 md:gap-6'>
                                  <div className='sm:col-span-3'>
                                    <h3 className='text-lg font-medium leading-6 text-gray-500'>
                                      {t('vip.offer.link.name')}
                                    </h3>
                                  </div>
                                  <div className='sm:col-span-3'>
                                    <label htmlFor='name.en' className='block text-sm font-medium text-gray-500'>
                                      {t('common.english')}
                                    </label>
                                    <div className='mt-1 flex rounded-md shadow-sm'>
                                      <Field
                                        type='text'
                                        name='name.en'
                                        id='name.en'
                                        className={classNames(
                                          'h-9 px-4 py-2 flex-1 text-gray-800 focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300',
                                          { ['border-2  border-red-500']: touched.name?.en && errors.name?.en }
                                        )}
                                      />
                                    </div>
                                    <ErrorMessage name='name.en' className='text-red-500 text-sm' component='div' />
                                  </div>
                                  <div className='sm:col-span-3'>
                                    <label htmlFor='name.es' className='block text-sm font-medium text-gray-500'>
                                      {t('common.spanish')}
                                    </label>
                                    <div className='mt-1 flex rounded-md shadow-sm'>
                                      <div className='relative flex items-stretch flex-grow focus-within:z-10'>
                                        <Field
                                          type='text'
                                          name='name.es'
                                          id='name.es'
                                          className={classNames(
                                            'h-9 px-4 py-2 text-gray-800 focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-l-md sm:text-sm border-gray-300',
                                            {
                                              ['border-2  border-red-500']: touched.name?.es && errors.name?.es,
                                            }
                                          )}
                                        />
                                      </div>
                                      <TranslateButton
                                        stringOriginal={values.name.en}
                                        langFrom='en'
                                        langTo='es'
                                        flatLeft
                                        onTranslate={(translatedString) => setFieldValue('name.es', translatedString)}
                                      />
                                    </div>
                                    <ErrorMessage name='name.es' className='text-red-500 text-sm' component='div' />
                                  </div>
                                </div>
                              </div>

                              <div className='bg-gray-800 shadow px-4 py-5 sm:rounded-lg sm:p-6 sm:col-span-3 mb-2'>
                                <div className='md:grid md:grid-cols-3 md:gap-6'>
                                  <div className='sm:col-span-3 mb-2'>
                                    <label htmlFor='link_url' className='block text-sm font-medium text-gray-500'>
                                      {t('vip.offer.link.url')}
                                    </label>
                                    <div className='mt-1 flex rounded-md shadow-sm'>
                                      <Field
                                        type='text'
                                        name='link_url'
                                        id='link_url'
                                        className={classNames(
                                          'h-9 px-4 mt-1 block w-full pl-3 pr-10 py-2 text-base text-gray-800 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md',
                                          { ['border-2  border-red-500']: touched.link_url && errors.link_url }
                                        )}
                                      />
                                    </div>
                                    <ErrorMessage name='link_url' className='text-red-500 text-sm' component='div' />
                                  </div>
                                </div>
                              </div>

                              <div className='bg-gray-800 shadow px-4 py-5 sm:rounded-lg sm:p-6 sm:col-span-3 mb-2'>
                                <div className='md:grid md:grid-cols-3 md:gap-6'>
                                  <div className='sm:col-span-3 mb-2'>
                                    <label htmlFor='link_type' className='block text-sm font-medium text-gray-500'>
                                      {t('vip.offer.link.type')}
                                    </label>
                                    <div className='mt-1 flex rounded-md shadow-sm'>
                                      <Field
                                        as='select'
                                        name='link_type'
                                        id='link_type'
                                        className={classNames(
                                          'flex-1 py-2 px-2 text-gray-800 focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300',
                                          { ['border-2  border-red-500']: touched.link_type && errors.link_type }
                                        )}
                                      >
                                        {Object.keys(VIP_OFFER_LINK_TYPES).map((key) => (
                                          <option key={key} value={key}>
                                            {t(VIP_OFFER_LINK_TYPES[key as keyof typeof VIP_OFFER_LINK_TYPES])}
                                          </option>
                                        ))}
                                      </Field>
                                    </div>
                                    <ErrorMessage name='link_type' className='text-red-500 text-sm' component='div' />
                                  </div>
                                </div>
                              </div>

                              <div className='bg-gray-800 shadow px-4 py-5 sm:rounded-lg sm:p-6 sm:col-span-3 mb-2'>
                                <div className='md:grid md:grid-cols-3 md:gap-6'>
                                  <div className='sm:col-span-3 mb-2'>
                                    <label htmlFor='sort_order' className='block text-sm font-medium text-gray-500'>
                                      {t('common.sort_order')}
                                    </label>
                                    <div className='mt-1 flex rounded-md shadow-sm'>
                                      <Field
                                        type='number'
                                        name='sort_order'
                                        id='sort_order'
                                        className={classNames(
                                          'px-4 py-2 text-gray-800 focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300',
                                          { ['border-2  border-red-500']: touched.sort_order && errors.sort_order }
                                        )}
                                      />
                                    </div>
                                    <ErrorMessage name='sort_order' className='text-red-500 text-sm' component='div' />
                                  </div>
                                </div>
                              </div>

                              <div className='flex justify-between mt-4'>
                                <button
                                  type='button'
                                  className='w-40 rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:text-sm'
                                  onClick={() => {
                                    closeModal();
                                  }}
                                >
                                  {t('common.cancel')}
                                </button>
                                <button
                                  type='submit'
                                  disabled={!isValid || isSubmitting}
                                  className={classNames([
                                    isValid ? 'bg-green-600 hover:bg-green-700' : 'bg-gray-600',
                                    'w-40 ml-3 inline-flex justify-center py-2 px-4 rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:text-sm',
                                  ])}
                                >
                                  {isSubmitting && <ButtonSpinner />} {t('common.submit')}
                                </button>
                              </div>
                            </Form>
                          )}
                        </Formik>
                      </div>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

ModalLink.displayName = 'ModalLink';

ModalLink.propTypes = {};

export { ModalLink };
