import * as PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { FaCheck, FaTimes } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';

import S from './group-name-form.module.scss';
import { FormError } from '../../../../../common/form/form-parts/form-error';
import { FormLabel } from '../../../../../common/form/form-parts/form-label';
import { InputFormText } from '../../../../../common/form/form-parts/input-form-text';
import { useUser } from '../../../../../../graphql/graph-hooks';
import { USER_CLUB_ID } from '../../../../../../graphql/local-state';
import { GROUP_UPDATE_GQL } from '../../../../../../graphql/mutations/dancer-add-to-group';
import { GET_GROUP_NAME_LIST } from '../../../../../../graphql/queries/group-name-list';
import { logError } from '../../../../../../helpers/errors/bug-report';
import { validateGroupName } from '../../../../../../helpers/validations/group-validations';

const GroupNameForm = ({ name = '', groupId, onClose }) => {
  const { t } = useTranslation();
  const [nameList, setNameList] = useState([]);
  const [error, setError] = useState('');
  const [newName, setNewName] = useState(name);
  const {
    data: { userClubId },
  } = useQuery(USER_CLUB_ID);
  const { admin } = useUser();

  useQuery(GET_GROUP_NAME_LIST, {
    variables: { businessId: userClubId },
    onCompleted: ({ groups }) => setNameList(groups.map(({ name }) => name)),
    onError: (err) => logError(err, 'GET_GROUP_NAME_LIST', GroupNameForm.displayName),
  });

  const [updateName, { loading: processing }] = useMutation(GROUP_UPDATE_GQL, {
    variables: {
      groupId,
      isAdmin: admin,
      hasLocation: false,
    },
    update: (
      cache,
      {
        data: {
          group_update: { group },
        },
      }
    ) => {
      setNameList([...nameList.filter((n) => n !== name), group.name]);
      cache.writeData({
        id: `Group:${group.id}`,
        data: { name: group.name },
      });
    },
    onCompleted: () => {
      toast.success(t('groupsPage.name_update.success'));
      onClose();
    },
    onError: (err) => {
      logError(err, 'GROUP_UPDATE_GQL', GroupNameForm.displayName);
      toast.error(t('groupsPage.name_update.failure'));
    },
  });

  const handleSave = useCallback(
    async (e) => {
      e.preventDefault();
      const NAME = newName.trim();
      const ERROR_MSG = validateGroupName(NAME, nameList);

      if (ERROR_MSG === '') await updateName({ variables: { name: NAME } });
      else {
        setError(ERROR_MSG);
        setNewName(NAME);
      }
    },
    [newName, updateName, nameList]
  );

  const handleChange = useCallback(
    (e) => {
      setError(validateGroupName(e.target.value, nameList));
      setNewName(e.target.value);
    },
    [nameList]
  );

  return (
    <form className={S.groupNameForm} onSubmit={handleSave}>
      <FormLabel id='modal_group_name' label={t('groupsPage.group_name')} hasError={!!error} isDisabled={processing} />
      <div>
        <InputFormText
          id='modal_group_name'
          name={name}
          value={newName}
          hasError={!!error}
          placeholder={t('groupsPage.group_name')}
          isDisabled={processing}
          handleChange={handleChange}
        />
        <FormError error={error} />
      </div>

      <button type='submit'>
        <FaCheck color='#27AE60' />
      </button>
      <button type='button' onClick={onClose}>
        <FaTimes color='#EB5757' />
      </button>
    </form>
  );
};

GroupNameForm.displayName = 'GroupNameForm';

GroupNameForm.propTypes = {
  name: PropTypes.string.isRequired,
  groupId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export { GroupNameForm };
