import { gql } from 'apollo-boost';

export const GET_CODE_FRAGMENT = gql`
  fragment my_registration_code on RegistrationCode {
    id
    code
    active
    description
    location {
      id
      city
      state
      country
      google_places_id
    }
  }
`;
