import React from 'react';
import { FaSortAlphaDown, FaSortAlphaUp } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

import BS from 'sass/buttons/button.module.scss';
import S from './alphabtize-btn.module.scss';

type ButtonProps = {
  handleClick: () => void;
  isAsc?: boolean;
};

const AlphabetizeBtn: React.FC<ButtonProps> = ({ handleClick, isAsc = false }): React.ReactElement => {
  const { t } = useTranslation();

  return (
    <button onClick={handleClick} className={`${BS.invertedBtnBlue} ${S.button}`}>
      {isAsc ? <FaSortAlphaDown /> : <FaSortAlphaUp />} {t('common.sort_alpha')}
    </button>
  );
};

export { AlphabetizeBtn };
