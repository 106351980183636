import { FC, FormEvent } from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';

import { useDebounce } from 'helpers/hooks/use-debounce';

import { SearchInput } from 'components/common/search-input';
import { StagedCheckbox } from 'components/common/checkboxes/staged-checkbox';
import { AlphabetizeBtn } from 'components/common/buttons/alphabetize-btn';
import Actions from './Actions';
import AcceptedFilter from './AcceptedFilter';

import { RequestParams, ToolbarProps } from './types';

const Toolbar: FC<ToolbarProps> = ({
  showSort,
  sort,
  onSortChange,
  search,
  onSearchChange,
  showFilter,
  filter,
  onFilterChange,
  toggleSelectAllIds,
  selected,
  totalNum,
  onActionSuccess,
}) => {
  const { t } = useTranslation();
  const { bookingId } = useParams<RequestParams>();

  const selectedStage = !selected.length ? 'UNCHECKED' : selected.length === totalNum ? 'CHECKED' : 'SOME';

  const { onChange: debouncedOnChange } = useDebounce(search, onSearchChange);

  const handleOnSearchChange = (e: FormEvent<HTMLInputElement>) => {
    debouncedOnChange(e);
  };

  const handleOnSortChange = () => {
    onSortChange(sort === 'asc' ? 'desc' : 'asc');
  };

  return (
    <div className='flex justify-between mb-8 px-0 py-5 border-b border-b-gray-600'>
      {
        <div className='flex items-center gap-8'>
          <div
            className={`${!totalNum && 'opacity-50'}`}
            title={selectedStage === 'CHECKED' ? t('common.deselect_all') : t('common.select_all')}
          >
            <StagedCheckbox disabled={!totalNum} stage={selectedStage} handleChange={toggleSelectAllIds} />
          </div>

          <div className='flex gap-1 mt-1 w-36 items-end'>
            <div className='text-business-purple'>{`${selected.length}`}</div>
            <div>{`${t('common.of')}`}</div>
            <div className='text-business-purple'>{`${totalNum} `}</div>
            <div>{`${t('common.selected').toLowerCase()}`}</div>
          </div>

          {showSort && <AlphabetizeBtn isAsc={sort === 'asc'} handleClick={handleOnSortChange} />}

          {showFilter && !selected.length && <AcceptedFilter selected={filter} onChange={onFilterChange} />}

          <div className='flex gap-2'>
            <Actions bookingId={bookingId} applications={selected} onActionSuccess={onActionSuccess} />
          </div>
        </div>
      }

      <div className='w-64'>
        <SearchInput placeholder={t('common.search_dancers')} onChange={handleOnSearchChange} />
      </div>
    </div>
  );
};

export default Toolbar;
