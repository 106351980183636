import { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { RouteConstants } from 'components/routes/route-constants';
import { ADD_OFFER } from 'graphql/mutations/vip';
import { logError } from 'helpers/errors/bug-report';
import { camelizeKeys } from 'humps';
import { useHistory, useParams, useLocation } from 'react-router';
import { toast } from 'react-toastify';
import { OfferForm } from './offer_form';
import { useTranslation } from 'react-i18next';

const VipOfferAdd: React.FC = () => {
  const { t } = useTranslation();
  const { sectionId } = useParams<{ sectionId: string }>();
  const location = useLocation<{ sortOrder: number }>();
  const history = useHistory();

  const initialValues: VipOfferCreate = {
    name: {
      en: undefined,
      es: undefined,
    },
    section_id: parseInt(sectionId),
    description: {
      en: undefined,
      es: undefined,
    },
    image_input: undefined,
    is_active: true,
    sort_order: location.state.sortOrder,
  };

  const [addOffer, { loading: isSubmitting }] = useMutation<VipOfferCreate>(ADD_OFFER, {
    onCompleted: () => {
      history.push(RouteConstants.vipPromos);
      toast.success(t('vip.offer.create.success'));
    },
    onError: (err) => {
      logError(err, 'ADD_OFFER', VipOfferAdd.displayName);
      toast.error(t('vip.offer.create.failure'));
    },
  });

  return (
    <OfferForm
      pageTitle={t('vip.offer.create.title')}
      initialValues={initialValues}
      isSubmitting={isSubmitting}
      submitHandler={(values) => addOffer({ variables: { input: camelizeKeys(values) } })}
    />
  );
};

VipOfferAdd.displayName = 'VipOfferAdd';

export { VipOfferAdd };
