import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import React, { useCallback, useMemo } from 'react';
import { useApolloClient, useMutation } from '@apollo/react-hooks';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { AdditionalInfoForm } from './additional-form/additional-form';
import { BasicInfoForm } from './basic-form/basic-info';
import S from './club-form.module.scss';
import * as ClubFormCtx from './club-form-context';
import { setClubFromCache } from './club-services';
import { useClubForm } from './use-club-form';
import { RouteConstants } from '../../../routes/route-constants';
import { Tabs } from '../../../common/tabs';
import { LoadingDiscoWithContainer } from '../../../common/loading/loading-disco';
import { useBusiness, useHasPermission, useUser } from '../../../../graphql/graph-hooks';
import { BUSINESS_DETAIL_GQL } from '../../../../graphql/queries/business-page-query';
import { DELETE_BUSINESS } from 'graphql/mutations/business-delete';
import { PERMISSIONS } from '../../../../helpers/enums';
import posthog from 'posthog-js';
import { Analytics } from './analytics/analytics';
import { useModal } from 'components/global-hooks';
import { UI_MODALS } from 'helpers/enums';
import { ClosedLabel } from '../closed-label/closed-label';
import ClubScore from './analytics/ClubScore';

const ClubForm = ({ isNewForm }) => {
  const { t } = useTranslation();
  const HISTORY = useHistory();
  const { id, name, closed } = useBusiness();
  const { admin } = useUser();
  const { initModal, closeModal } = useModal();
  const CLIENT = useApolloClient();

  const {
    dispatch,
    state: { isDisabled, view },
  } = ClubFormCtx.useClubFormCtx();
  const { useGetBusiness, useSubmitForm, isProcessing } = useClubForm(isNewForm);
  const CAN_EDIT = useHasPermission(PERMISSIONS.EDIT_CLUB);
  const CAN_DELETE = useHasPermission(PERMISSIONS.DELETE_BUSINESS);
  const { loading, error, client } = useGetBusiness();

  const handleEditClick = useCallback(() => dispatch(ClubFormCtx.setClubField('isDisabled', false)), [dispatch]);

  const SHOW_EDIT_BTN = useMemo(
    () => (admin || !closed) && !isNewForm && isDisabled && CAN_EDIT && !['analytics', 'score'].includes(view),
    [isNewForm, isDisabled, CAN_EDIT, view]
  );
  const SHOW_DELETE_BTN = CAN_DELETE && !isNewForm;

  const onCancel = useCallback(() => {
    if (isNewForm) {
      HISTORY.push(RouteConstants.clubs);
    } else {
      const { business } = client.readQuery({
        query: BUSINESS_DETAIL_GQL,
        variables: { businessId: id },
      });

      setClubFromCache(dispatch, business);
      dispatch(ClubFormCtx.setClubField('isDisabled', true));
    }
  }, [HISTORY, isNewForm, dispatch, client, id]);

  const clubScoreEnabled = posthog.isFeatureEnabled('club-score');

  const tabs = useMemo(() => {
    const ta = [
      { key: 'basic', label: t('clubPage.basic_info_tab') },
      { key: 'additional', label: t('clubPage.additional_info_tab') },
    ];

    const canSeeAnalytics = isDisabled && (admin || CAN_EDIT);
    if (canSeeAnalytics) {
      ta.push({ key: 'analytics', label: t('clubPage.analytics_tab') });
    }

    const canSeeClubScore = clubScoreEnabled && isDisabled && (admin || CAN_EDIT);

    if (canSeeClubScore) {
      ta.push({ key: 'score', label: 'Club Score' });
    }

    return ta;
  }, [isDisabled, clubScoreEnabled]);

  const HEADING = useMemo(() => {
    return isNewForm
      ? t('clubPage.create_new_club')
      : isDisabled
      ? t('clubPage.club_settings')
      : t('clubPage.edit_club');
  }, [isNewForm, isDisabled]);

  const [deleteClub] = useMutation(DELETE_BUSINESS, {
    onError: (err) => logError(err, 'DELETE_BUSINESS', ClubForm.displayName),
    onCompleted: () => {
      toast.success(t('clubPage.delete_success'));
      CLIENT.writeData({ data: { userClubId: null } });
      HISTORY.push(RouteConstants.clubs);
    },
  });

  const handleDelete = () => {
    initModal(UI_MODALS.CONFIRMATION, {
      title: t('common.action_cannot_be_undone'),
      message: `${t('clubPage.delete_club')} ${name}.`,
      onConfirmation: () => {
        closeModal();
        deleteClub({ variables: { id } });
      },
    });
  };

  if (loading) {
    return <LoadingDiscoWithContainer />;
  }
  if (error) {
    return (
      <h1 className={S.errorPage}>
        {t('errors.server.500')}: {t('clubPage.club_data_error')}
      </h1>
    );
  }

  posthog.capture('ClubFormPage', { businessId: id });

  return (
    <>
      {closed && <ClosedLabel />}
      <form className={S.clubForm} onSubmit={useSubmitForm} noValidate>
        <header>
          <h1 className={S.h1Heading}>{HEADING}</h1>
          <div className={S.actionRow}>
            {SHOW_EDIT_BTN && (
              <button type='button' onClick={handleEditClick} className={S.invertedBtnGreen}>
                {t('common.edit')}
              </button>
            )}
            {SHOW_DELETE_BTN && (
              <button type='button' className={S.invertedBtnRed} onClick={handleDelete}>
                {t('common.delete')}
              </button>
            )}
          </div>
        </header>

        {!isNewForm && (
          <Tabs
            activeTab={view}
            tabs={tabs}
            handleTabClick={(key) => dispatch(ClubFormCtx.setClubField('view', key))}
          />
        )}

        {(isNewForm || view === 'basic') && (
          <BasicInfoForm isDisabled={isDisabled || isProcessing} isNewForm={isNewForm} />
        )}
        {(isNewForm || view === 'additional') && <AdditionalInfoForm isDisabled={isDisabled || isProcessing} />}

        {isDisabled && view === 'analytics' && <Analytics />}

        {isDisabled && view === 'score' && <ClubScore />}

        {(isNewForm || (!isNewForm && !isDisabled)) && (
          <div className={S.clubActionRow}>
            <button type='button' disabled={isProcessing || isDisabled} onClick={onCancel} className={S.invertedBtnRed}>
              {t('common.cancel')}
            </button>
            <button type='submit' disabled={isProcessing || isDisabled} className={S.invertedBtnGreen}>
              {t('common.submit')}
            </button>
          </div>
        )}
      </form>
    </>
  );
};

ClubForm.displayName = 'ClubForm';

ClubForm.propTypes = {
  isNewForm: PropTypes.bool.isRequired,
};

export { ClubForm };
