import React, { memo } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { getFormattedJsTime } from '../../../../../helpers/times';
import S from './group-notification-sender.module.scss';

const AnnouncementSender = memo(({ clubName, authorName, groupName, created }) => {
  const { t } = useTranslation();
  return (
    <div className={S.senderInfo}>
      <div>{clubName}</div>
      <p>
        {t('notificationsPage.sent_by')} <span>{authorName}</span>
      </p>
      <p>{groupName}</p>
      <p>{getFormattedJsTime(created, 'h:mma | M.D.YY')}</p>
    </div>
  );
});

AnnouncementSender.displayName = 'AnnouncementSender';
AnnouncementSender.propTypes = {
  clubName: PropTypes.string.isRequired,
  authorName: PropTypes.string.isRequired,
  groupName: PropTypes.string.isRequired,
  created: PropTypes.string.isRequired,
};

export { AnnouncementSender };
