import { useMutation } from '@apollo/react-hooks';
import * as PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { FaTimes } from 'react-icons/fa';
import { toast } from 'react-toastify';

import S from './pre-approved-booking.module.scss';
import { SpinnerIcon } from '../../../../../../common/icons/spinner-icon';
import { UPDATE_PRE_APPROVED_BOOKING } from '../../../../../../../graphql/mutations/shift-pre-approved-group-update';
import { GET_PRE_APPROVED_BOOKINGS } from '../../../../../../../graphql/queries/pre-approved-shift-list';
import { logError } from '../../../../../../../helpers/errors/bug-report';

const PreApprovedBooking = ({ id, time, canUpdate, groupId, isRecurring, inProgress }) => {
  const [removePreApproval, { loading }] = useMutation(UPDATE_PRE_APPROVED_BOOKING, {
    variables: { groupId },
    onError: (err) => {
      logError(err, 'UPDATE_PRE_APPROVED_BOOKING', PreApprovedBooking.displayName);
      toast.error('Server error: Could not remove pre approval');
    },
    update: (cache) => {
      const FIELD = isRecurring ? 'recurring_shifts' : 'non_recurring_shifts';
      const { preapproved_shifts } = cache.readQuery({
        query: GET_PRE_APPROVED_BOOKINGS,
        variables: { groupId },
      });

      cache.writeQuery({
        query: GET_PRE_APPROVED_BOOKINGS,
        variables: { groupId },
        data: {
          preapproved_shifts: {
            ...preapproved_shifts,
            [FIELD]: preapproved_shifts[FIELD].filter((shift) => shift.id !== id),
          },
        },
      });
    },
  });

  const handleDelete = useCallback(async () => {
    const FIELD = isRecurring ? 'recurringToRemove' : 'nonRecurringToRemove';
    await removePreApproval({ variables: { [FIELD]: [id] } });
  }, [id, isRecurring, removePreApproval]);

  return (
    <div className={S.preApprovedRow}>
      <div>{time}</div>
      {!loading && !inProgress && canUpdate && (
        <button className={S.deleteBtn} onClick={handleDelete}>
          <FaTimes />
        </button>
      )}
      {inProgress && <p>In Progress</p>}
      {loading && <SpinnerIcon />}
    </div>
  );
};

PreApprovedBooking.displayName = 'PreApprovedBooking';

PreApprovedBooking.propTypes = {
  id: PropTypes.string.isRequired,
  groupId: PropTypes.string.isRequired,
  time: PropTypes.string.isRequired,
  canUpdate: PropTypes.bool.isRequired,
  isRecurring: PropTypes.bool.isRequired,
  inProgress: PropTypes.bool.isRequired,
};

PreApprovedBooking.defaultProps = {
  isRecurring: false,
};

export { PreApprovedBooking };
