import * as PropTypes from 'prop-types';
import {
  FaComments,
  FaPlayCircle,
  FaCheckCircle,
  FaBan,
  FaChartBar,
  FaShoppingBag,
  FaBolt,
  FaBug,
  FaStamp,
} from 'react-icons/fa';

import { IconNavLink } from './icon-nav-link';
import { NavBadges } from '../nav-badges';
import { RouteConstants } from '../../routes/route-constants';
import { GroupNotificationIcon } from '../../common/icons/group-notifications';
import { BlastNotificationIcon } from '../../common/icons/blast-notifications';
import { InviteIcon } from '../../common/icons/invite-icon';
import { EntertainerIcon } from '../../common/icons/entertainer-icon';
import posthog from 'posthog-js';
import { SubLinks } from './sub-links';
import { useTranslation } from 'react-i18next';

const colorByCount = (count) => {
  if (count < 10) {
    return 'green';
  } else if (count < 25) {
    return 'orange';
  } else {
    return 'red';
  }
};

const AdminLinkArea = ({
  verificationCount,
  unreadAdmin,
  photoVerificationCount,
  inviteCodeRequestsCount,
  dobChangeRequestsCount,
}) => {
  const { t } = useTranslation();
  return (
    <>
      {posthog.isFeatureEnabled('invites-menu-item') && (
        <IconNavLink
          text={t('admin_nav.invite_requests')}
          icon={<FaStamp size={20} />}
          path={RouteConstants.inviteRequests}
          navExtra={<NavBadges count={inviteCodeRequestsCount} color={colorByCount(inviteCodeRequestsCount)} />}
        />
      )}
      <IconNavLink
        text={t('admin_nav.verifications')}
        icon={<FaCheckCircle size='20' />}
        path={RouteConstants.verifications}
        navExtra={<NavBadges count={verificationCount} color={colorByCount(verificationCount)} />}
      />
      <IconNavLink
        text={t('admin_nav.photo_verifications')}
        icon={<FaCheckCircle size='20' />}
        path={RouteConstants.photoVerifications}
        navExtra={<NavBadges count={photoVerificationCount} color={colorByCount(photoVerificationCount)} />}
      />
      {posthog.isFeatureEnabled('dob-change-menu-item') && (
        <IconNavLink
          text={t('admin_nav.dob_change')}
          icon={<FaCheckCircle size='20' />}
          path={RouteConstants.dobChangeRequest}
          navExtra={<NavBadges count={dobChangeRequestsCount} color={colorByCount(dobChangeRequestsCount)} />}
        />
      )}
      <IconNavLink
        text={t('admin_nav.conversations')}
        icon={<FaComments size='20' />}
        path={RouteConstants.adminConversations}
        navExtra={<NavBadges count={unreadAdmin} color={colorByCount(unreadAdmin)} />}
      />
      <IconNavLink text={t('admin_nav.courses')} icon={<FaPlayCircle size='20' />} path={RouteConstants.courses} />
      <IconNavLink
        text={t('admin_nav.paused_dancers')}
        icon={<FaBan size='20' />}
        path={RouteConstants.dancersSearchSuspended}
      />

      <IconNavLink
        text={t('admin_nav.dancers')}
        icon={<EntertainerIcon height={20} width={20} />}
        path={RouteConstants.adminDancersSearch}
      />

      <IconNavLink
        text={t('admin_nav.invite_codes')}
        icon={<InviteIcon height={20} width={20} />}
        path={RouteConstants.adminCodes}
      />
      <IconNavLink
        text={t('admin_nav.blast_notification')}
        icon={<BlastNotificationIcon height={20} width={20} />}
        path={RouteConstants.blastNotifications}
      />
      <IconNavLink
        text={t('admin_nav.group_notifications')}
        icon={<GroupNotificationIcon height={20} width={20} />}
        path={RouteConstants.groupNotifications}
      />
      <IconNavLink
        text={t('admin_nav.vip_shopping')}
        icon={<FaShoppingBag size={20} />}
        path={RouteConstants.vipPromos}
      />

      <SubLinks
        label={t('admin_nav.lightning_feed')}
        Icon={<FaBolt size={20} />}
        links={[
          { path: RouteConstants.socialScheduledPosts, label: t('admin_nav.admin_posts') },
          { path: RouteConstants.socialPosts, label: t('admin_nav.posts') },
          { path: RouteConstants.socialComments, label: t('admin_nav.comments') },
        ]}
      />

      {posthog.isFeatureEnabled('statistics-menu-item') && (
        <IconNavLink
          text={t('admin_nav.statistics')}
          icon={<FaChartBar size={20} />}
          path={RouteConstants.statistics}
        />
      )}
      {posthog.isFeatureEnabled('bugs-menu-item') && (
        <IconNavLink text={t('admin_nav.bugs')} icon={<FaBug size={20} />} path={RouteConstants.bugs} />
      )}
    </>
  );
};

AdminLinkArea.displayName = 'AdminLinkArea';
AdminLinkArea.propTypes = {
  verificationCount: PropTypes.number.isRequired,
  unreadAdmin: PropTypes.number.isRequired,
  verificationCount: PropTypes.number,
  photoVerificationCount: PropTypes.number,
  inviteCodeRequestsCount: PropTypes.number,
  dobChangeRequestsCount: PropTypes.number,
};

AdminLinkArea.defaultProps = {
  verificationCount: 0,
  unreadAdmin: 0,
  photoVerificationCount: 0,
  inviteCodeRequestsCount: 0,
};

export { AdminLinkArea };
