import * as PropTypes from 'prop-types';
import classNames from 'classnames';

import S from './nav-badges.module.scss';

const NavBadges = ({ count, color }) => {
  if (count === 0) return null;
  count = count < 100 ? count : '!';

  return (
    <div
      className={classNames({
        [S.badgeCirclePurple]: color === 'purple',
        [S.badgeCircleGreen]: color === 'green',
        [S.badgeCircleRed]: color === 'red',
        [S.badgeCirclePink]: color === 'pink',
        [S.badgeCircleOrange]: color === 'orange',
        [S.badgeCircleBlue]: color === 'blue',
      })}
    >
      {count}
    </div>
  );
};

NavBadges.displayName = 'Badge';
NavBadges.propTypes = {
  count: PropTypes.number,
  color: PropTypes.string.isRequired,
};

NavBadges.defaultProps = {
  count: 0,
};

export { NavBadges };
