import * as React from 'react';

const SET_GROUP_NOTE_FIELD = 'SET_GROUP_NOTE_FIELD';
const SET_GROUP_NOTE_FIELDS = 'SET_GROUP_NOTE_FIELDS';
const PAGE_GROUP_NOTE_INIT_STATE = {
  pagination: {},
  selected: [],
  totalCount: 0,
  search: '',
};

export const setGroupNoteField = (field, payload) => ({
  type: SET_GROUP_NOTE_FIELD, field, payload,
});

export const setGroupNoteFields = (payload) => ({
  type: SET_GROUP_NOTE_FIELDS, payload,
});


function pageGroupNotificationsReducer(state, { type, field, payload }) {
  switch (type) {
    case SET_GROUP_NOTE_FIELD:
      return {...state, [field]: payload};

    case SET_GROUP_NOTE_FIELDS:
      return {...state, ...payload};

    default:
      throw new Error(`Unsupported action type: ${type}`);
  }
}

const PAGE_CTX = React.createContext();


export function PageGroupNotificationsProvider(props) {
  const [state, dispatch] = React.useReducer(pageGroupNotificationsReducer, PAGE_GROUP_NOTE_INIT_STATE);
  const value = React.useMemo(() => [state, dispatch], [state]);

  return <PAGE_CTX.Provider value={value} {...props} />;
}


export function useGroupNoteCtx() {
  const context = React.useContext(PAGE_CTX);

  if (!context) {
    throw new Error(`${useGroupNoteCtx.name} must be used within a ${PageGroupNotificationsProvider.name}`);
  }

  const [state, dispatch] = context;
  return { state, dispatch };
}

