import { useApolloClient } from '@apollo/react-hooks';
import React, { useMemo } from 'react';
import * as PropTypes from 'prop-types';
import { COURSES_CATEGORY_GQL } from '../../../graphql/queries/course-category-query';
import { useTranslation } from 'react-i18next';

import S from './courses-header.module.scss';
import { CheckBoxDropDown } from '../../common/form/form-parts/checkbox-dropdown';
import { SearchInput } from '../../common/search-input';
import { useModal } from '../../global-hooks';
import { UI_MODALS } from '../../../helpers/enums';

const CoursesHeader = ({ filter, setFilter, selectedCategories, setSelectedCategories }) => {
  const { t } = useTranslation();
  const { initModal } = useModal();
  const CLIENT = useApolloClient();
  const { course_categories } = CLIENT.readQuery({ query: COURSES_CATEGORY_GQL });

  const CATEGORY_LIST = useMemo(
    () => course_categories.map(({ id, name }) => ({ value: id, label: name })),
    [course_categories]
  );
  return (
    <header className={S.coursesHeader}>
      <div className={S.coursesHeaderLeft}>
        <h1 className={S.h1Heading}>{t('coursesPage.courses')}</h1>
        <button type='button' onClick={() => initModal(UI_MODALS.COURSE_FORM)} className={S.invertedBtnBlue}>
          {t('coursesPage.new_course')}
        </button>
        <button type='button' onClick={() => initModal(UI_MODALS.COURSE_CATEGORY)} className={S.invertedBtnBlue}>
          {t('coursesPage.edit_category')}
        </button>
      </div>

      <div className={S.coursesHeaderRight}>
        <label className={S.sideLabel}>{t('common.filter')}</label>
        <CheckBoxDropDown
          placeholder={t('coursesPage.categories')}
          name='courses_category_filter'
          selected={selectedCategories}
          list={CATEGORY_LIST}
          handleChange={(val) => setSelectedCategories(val)}
        />
        <SearchInput placeholder={t('common.title')} onChange={(e) => setFilter(e.target.value)} />
      </div>
    </header>
  );
};

CoursesHeader.displayName = 'CoursesHeader';
CoursesHeader.propTypes = {
  selectedId: PropTypes.string,
  filter: PropTypes.string.isRequired,
  setFilter: PropTypes.func.isRequired,
  selectedCategories: PropTypes.arrayOf(PropTypes.string).isRequired,
  setSelectedCategories: PropTypes.func.isRequired,
};
CoursesHeader.defaultProps = { selectedId: null };

export { CoursesHeader };
