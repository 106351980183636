import * as Yup from 'yup';
import i18next from 'i18next';

const { t } = i18next;

const validationSchema = Yup.object().shape({
  title: Yup.string()
    .min(3, () => t('errors.validation.min_length', { field_name: t('common.title'), min_length: 3 }))
    .max(90, () => t('errors.validation.max_length', { field_name: t('common.title'), max_length: 90 })),
  text: Yup.string()
    .required(() => t('errors.validation.required', { field_name: t('social.scheduler.text') }))
    .min(3, () => t('errors.validation.min_length', { field_name: t('social.scheduler.text'), min_length: 3 }))
    .max(1000, () => t('errors.validation.max_length', { field_name: t('social.scheduler.text'), max_length: 1000 })),
  link_title: Yup.string()
    .min(3, () => t('errors.validation.min_length', { field_name: t('social.scheduler.link.title'), min_length: 3 }))
    .max(90, () => t('errors.validation.max_length', { field_name: t('social.scheduler.link.title'), max_length: 90 }))
    .test(
      'invalid-title',
      () => t('errors.validation.required', { field_name: t('social.scheduler.link.title') }),
      function (link_title) {
        const { link_url }: { link_url: string } = this.parent;
        return link_url ? !!link_title : true;
      }
    ),
  link_url: Yup.string()
    .matches(/^(https?:\/\/[a-zA-Z0-9_+%-]+(.[a-zA-Z0-9+\_%-]+)*(:[0-9]{1,5})?(\/[a-zA-Z0-9+()?#~=&\._%-]*)*)?$/, () =>
      t('errors.validation.invalid_url')
    )
    .test(
      'required-url',
      () => t('errors.validation.required', { field_name: t('social.scheduler.link.url') }),
      function (link_url) {
        const { link_title }: { link_title: string } = this.parent;
        return link_title ? !!link_url : true;
      }
    ),
  publishAt: Yup.date().nullable(),
});

export default validationSchema;
