import React from 'react';
import PropTypes from 'prop-types';

import { InputFormTextarea } from './form-parts/input-form-textarea';
import { FormLabel } from './form-parts/form-label';
import { FormError } from './form-parts/form-error';

const FormTextareaField = ({
  id,
  classname,
  label,
  name,
  value,
  error,
  rows,
  placeholder,
  isDisabled,
  handleBlur,
  handleChange,
  gridArea,
}) => {
  return (
    <div className={classname} style={{ gridArea }}>
      <FormLabel id={id} label={label} isDisabled={isDisabled} hasError={!!error} />
      <InputFormTextarea
        id={id}
        rows={rows}
        name={name}
        value={value}
        placeholder={placeholder}
        isDisabled={isDisabled}
        hasError={!!error}
        handleBlur={handleBlur}
        handleChange={handleChange}
      />
      <FormError error={error} />
    </div>
  );
};

FormTextareaField.displayName = 'FormTextareaField';

FormTextareaField.propTypes = {
  id: PropTypes.string.isRequired,
  rows: PropTypes.number,
  gridArea: PropTypes.string,
  classname: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  error: PropTypes.string,
  isDisabled: PropTypes.bool.isRequired,
  handleBlur: PropTypes.func,
  handleChange: PropTypes.func.isRequired,
};

FormTextareaField.defaultProps = {
  handleBlur: null,
  classname: null,
  gridArea: null,
  error: '',
  placeholder: '',
  rows: 5,
};

export { FormTextareaField };
