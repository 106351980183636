import * as React from 'react';
import * as PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';

import S from './icon-nav-link.module.scss';

const IconNavLink = ({ path, icon, text, navExtra, color }) => (
  <NavLink to={path} exact className={classNames(S.navLink, S[color])} activeClassName={S.active}>
    {icon}
    {text}
    {navExtra || null}
  </NavLink>
);

IconNavLink.displayName = 'IconNavLink';

IconNavLink.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.object.isRequired,
  text: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  navExtra: PropTypes.object,
};

IconNavLink.defaultProps = {
  navExtra: null,
  color: null,
};

export { IconNavLink };
