import { useApolloClient, useMutation } from '@apollo/react-hooks';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import * as PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import S from './modal-group-create.module.scss';
import { FormTextField } from '../../common/form/form-text-field';
import { CloseButton } from '../../common/buttons/close-button';
import { useBusiness } from '../../../graphql/graph-hooks';
import { useOverlayClick } from '../../../helpers/hooks/use-overlay-click';
import { CREATE_GROUP_GQL } from '../../../graphql/mutations/group-create';
import { ALL_GROUPS_GQL } from '../../../graphql/queries/group-list';
import { validateGroupName } from '../../../helpers/validations/group-validations';
import useSuggestions, { SuggestionListType } from 'helpers/hooks/useSuggestions';
import RichToast from 'components/common/rich-toast';
import { checkForFilterError } from 'helpers/filtered-words';

const ModalGroupCreate = ({ onClose, addGroup }) => {
  const { t } = useTranslation();
  const MODAL_REF = useRef();
  const CLIENT = useApolloClient();
  const [name, setName] = useState('');
  const [error, setError] = useState('');
  const { id: businessId } = useBusiness();
  const variables = useMemo(() => ({ businessId }), [businessId]);
  const { checkText } = useSuggestions(SuggestionListType.MANAGER);

  const { groups } = CLIENT.readQuery({ query: ALL_GROUPS_GQL, variables });

  const GROUP_NAMES = groups.map(({ name }) => name);

  const [submitGroup, { loading }] = useMutation(CREATE_GROUP_GQL, {
    variables,
    onError: (error) => {
      const message = t('modals.group_create.failure');
      const filterError = checkForFilterError(error, message);
      toast.error(<RichToast title={filterError.title} message={filterError.message} />);
    },
    update: (
      cache,
      {
        data: {
          group_create: { group },
        },
      }
    ) => {
      cache.writeQuery({
        query: ALL_GROUPS_GQL,
        variables,
        data: { groups: [...groups, group] },
      });

      addGroup(group);
    },
    onCompleted: () => {
      toast.success(t('modals.group_create.success'));
      onClose();
    },
  });

  const handleNameChange = useCallback(
    (e) => {
      const NAME = e.target.value;
      setName(NAME);
      setError(validateGroupName(NAME, GROUP_NAMES));
    },
    [GROUP_NAMES]
  );

  const handleNameBlur = useCallback(
    (e) => {
      const NAME = e.target.value.trim();
      setName(NAME);
      setError(validateGroupName(NAME, GROUP_NAMES));
    },
    [GROUP_NAMES]
  );

  const handleSubmit = useCallback(
    async (e) => {
      try {
        e.preventDefault();
        await checkText(name);
        const ERROR_MSG = validateGroupName(name.trim(), GROUP_NAMES);
        if (ERROR_MSG === '') await submitGroup({ variables: { name: name.trim() } });
        else setError(ERROR_MSG);
      } catch (error) {
        const filterError = checkForFilterError(error, t('errors.generic'));
        toast.error(<RichToast title={filterError.title} message={filterError.message} />);
      }
    },
    [GROUP_NAMES, name, submitGroup]
  );

  useOverlayClick(MODAL_REF, onClose);

  return (
    <form className={S.createGroupModal} onSubmit={handleSubmit} ref={MODAL_REF}>
      <CloseButton onClick={onClose} />
      <h3 className={S.modalHeading}>{t('dancerPage.group_create')}</h3>

      <FormTextField
        id='modal_create_group_name'
        label={t('modals.group_create.name')}
        name='name'
        placeholder={t('modals.group_create.name')}
        error={error}
        value={name}
        isDisabled={loading}
        handleChange={handleNameChange}
        handleBlur={handleNameBlur}
      />

      <div className={S.buttonRow}>
        <button type='submit' disabled={loading} className={S.invertedBtnGreen}>
          {t('common.submit')}
        </button>
        <button type='button' onClick={onClose} disabled={loading} className={S.invertedBtnRed}>
          {t('common.cancel')}
        </button>
      </div>
    </form>
  );
};

ModalGroupCreate.displayName = 'ModalGroupCreate';
ModalGroupCreate.propTypes = {
  onClose: PropTypes.func.isRequired,
  addGroup: PropTypes.func.isRequired,
};

export { ModalGroupCreate };
