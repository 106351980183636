import { useApolloClient, useQuery } from '@apollo/react-hooks';

import { USER_CLUB_ID, APOLLO_USER_ID } from './local-state';
import { BUSINESS_FRAGMENT } from './fragments/business-fragment';
import { GET_USER_FRAGMENT } from './fragments/user-fragment';

export const useBusiness = () => {
  const CLIENT = useApolloClient();
  const {
    data: { userClubId },
  } = useQuery(USER_CLUB_ID);

  const BUSINESS = CLIENT.readFragment({
    id: `Business:${userClubId}`,
    fragment: BUSINESS_FRAGMENT,
  });

  if (BUSINESS && !BUSINESS.timezone) {
    BUSINESS.timezone = 'UTC';
  }

  return BUSINESS || {};
};

export const useUser = () => {
  const CLIENT = useApolloClient();
  const {
    data: { userId },
  } = useQuery(APOLLO_USER_ID);

  const USER = CLIENT.readFragment({
    id: `User:${userId}`,
    fragment: GET_USER_FRAGMENT,
  });

  return USER || {};
};

export const useHasPermission = (permission) => {
  const {
    data: { userClubId },
  } = useQuery(USER_CLUB_ID);
  const { admin, roles } = useUser();

  if (admin) return true;

  const PERMISSIONS = roles?.[userClubId]?.permissions ?? [];

  return PERMISSIONS.includes(permission);
};
