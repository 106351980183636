import * as React from 'react';

const SET_PROFILE_FIELD = 'SET_PROFILE_FIELD';
const SET_PROFILE_FIELDS = 'SET_PROFILE_FIELDS';
const SET_PROFILE_FORM_FIELDS = 'SET_PROFILE_FORM_FIELDS';
const SET_PROFILE_ERROR_FIELDS = 'SET_PROFILE_ERROR_FIELDS';

const GLOBAL_INIT_STATE = {
  errors: {},
  form: {
    id: null,
    name: '',
    email: '',
    locale: '',
    password: '',
    confirm: '',
  },
};

export const setProfileField = (field = '', payload) => ({ type: SET_PROFILE_FIELD, field, payload });
export const setProfileFormField = (field = '', payload) => ({ type: SET_PROFILE_FORM_FIELDS, field, payload });
export const setProfileErrorField = (field = '', payload) => ({ type: SET_PROFILE_ERROR_FIELDS, field, payload });
export const setProfileFields = (payload) => ({ type: SET_PROFILE_FIELDS, payload });

function pageProfileReducer(state, { type, field, payload }) {
  switch (type) {
    case SET_PROFILE_FIELDS:
      return { ...state, ...payload };
    case SET_PROFILE_FIELD:
      return { ...state, [field]: payload };
    case SET_PROFILE_FORM_FIELDS:
      return { ...state, form: { ...state.form, [field]: payload } };
    case SET_PROFILE_ERROR_FIELDS:
      return { ...state, errors: { ...state.errors, [field]: payload } };
    default:
      throw new Error(`Unsupported action type: ${type}`);
  }
}

const PAGE_CTX = React.createContext();

export function ProfilePageProvider(props) {
  const [state, dispatch] = React.useReducer(pageProfileReducer, GLOBAL_INIT_STATE);
  const value = React.useMemo(() => [state, dispatch], [state]);
  return <PAGE_CTX.Provider value={value} {...props} />;
}

export function useProfilePageCtx() {
  const context = React.useContext(PAGE_CTX);
  if (!context) {
    throw new Error(`${useProfilePageCtx.name} must be used within a ${pageProfileReducer.name}`);
  }
  const [state, dispatch] = context;
  return { state, dispatch };
}
