import React from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { InviteForm } from './invite-form';
import S from './modal-invite-code.module.scss';
import { CloseButton } from '../../common/buttons/close-button';

const ModalInviteCode = (props) => {
  const { t } = useTranslation();
  return (
    <div className={S.modalInvite}>
      <CloseButton onClick={props.onClose} />
      <h3 className={S.modalHeading}>{t('employeePage.invite_code')}</h3>
      <InviteForm {...props} />
    </div>
  );
};

ModalInviteCode.displayName = 'ModalInviteCode';
ModalInviteCode.propTypes = {
  onClose: PropTypes.func.isRequired,
  codeId: PropTypes.string,
  type: PropTypes.oneOf(['CLUB', 'ADMIN', 'EMPLOYEE']).isRequired,
};

ModalInviteCode.defaultProps = { codeId: null };

export { ModalInviteCode };
