import React, { useEffect, useRef, useState } from 'react';
import * as PropTypes from 'prop-types';
import { useMutation, useQuery } from '@apollo/react-hooks';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import ModalDancerCarousel from './modal-dancer-carousel';
import { LoadingDiscoWithContainer } from 'components/common/loading/loading-disco';
import { DancerMenu } from 'components/common/dancer-action-menu/dancer-action-menu';
import { GET_DANCER_GQL, GET_APPLICATION_GQL } from 'graphql/queries/dancer-modal';
import { useBusiness, useHasPermission } from '../../../graphql/graph-hooks';
import { useUser } from 'graphql/graph-hooks';

import { FaEllipsisH, FaTrashAlt, FaCheck, FaTimes, FaClock } from 'react-icons/fa';
import DropDownButton from 'components/common/dropdown-button';
import { FormSelectField } from 'components/common/form/form-select-field';
import ApplicationActions from './ApplicationActions';

import {
  ACCEPT_REJECT_ACTIONS,
  BOOKING_STATUS,
  PERMISSIONS,
  PHOTO_VERIFICATION_REJECTION_REASONS,
} from 'helpers/enums';
import { closestLocation } from 'helpers/distance';
import { logError } from 'helpers/errors/bug-report';
import { TimePatterns } from 'helpers/times';
import {
  PHOTO_VERIFICATION_ACTIONS_TRANSLATION,
  PHOTO_VERIFICATION_REJECTION_REASON_TRANSLATION,
} from 'helpers/constants';
import { PROFILE_PICTURE_APPROVE, PROFILE_PICTURE_REJECT } from 'graphql/mutations/profile-picture';
import { DELETE_DANCER_IMAGES } from 'graphql/mutations/dancer-img-delete';
import updateFragment from 'graphql/cache/updateFragment';
import { UI_MODALS } from 'helpers/enums';
import { useModal } from 'components/global-hooks';

import S from './modal-dancer-form.module.scss';
import { DANCER_CAROUSEL_GQL } from 'graphql/fragments/dancer-imgs-fragment';
import { APPROVE_DANCER } from 'graphql/mutations/dancer-approve';
import { REJECT_DANCER } from 'graphql/mutations/dancer-reject';

const dateFormat = (time, tz) => moment.tz(time, tz).format(TimePatterns.dateSlashed);

const ModalDancerForm = ({ dancerId, appId, removeDancer, onActionSuccess }) => {
  const { t, i18n } = useTranslation();
  const { admin } = useUser();
  const carouselRef = useRef();
  const [targetImage, setTargetImage] = useState(null);
  const [rejectionReason, setRejectionReason] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const { timezone, longitude, latitude, id: businessId } = useBusiness();
  const CAN_MANAGE_REQUESTS = useHasPermission(PERMISSIONS.MANAGE_REQUESTS);

  const { initModal, closeModal } = useModal();

  const options = [
    { value: '', label: t('common.select_option') },
    {
      value: PHOTO_VERIFICATION_REJECTION_REASONS.LOW_QUALITY,
      label: PHOTO_VERIFICATION_REJECTION_REASON_TRANSLATION.LOW_QUALITY[i18n.language],
    },
    {
      value: PHOTO_VERIFICATION_REJECTION_REASONS.OFFENSIVE,
      label: PHOTO_VERIFICATION_REJECTION_REASON_TRANSLATION.OFFENSIVE[i18n.language],
    },
    {
      value: PHOTO_VERIFICATION_REJECTION_REASONS.MULTIPLE_PEOPLE,
      label: PHOTO_VERIFICATION_REJECTION_REASON_TRANSLATION.MULTIPLE_PEOPLE[i18n.language],
    },
    {
      value: PHOTO_VERIFICATION_REJECTION_REASONS.PROFESSIONAL_GUIDELINES,
      label: PHOTO_VERIFICATION_REJECTION_REASON_TRANSLATION.PROFESSIONAL_GUIDELINES[i18n.language],
    },
  ];

  const rejectOptions = [
    { value: '', label: t('common.select_option') },
    {
      value: 'INVALID_VERIFICATION',
      label: t('modals.verification.rejection_reason.verification_picture'),
    },
    {
      value: 'INVALID_PROFILE_PICTURE',
      label: t('modals.verification.rejection_reason.profile_picture'),
    },
    {
      value: 'UNABLE_TO_VERIFY',
      label: t('modals.verification.rejection_reason.unable_to_verify'),
    },
    {
      value: 'EXPIRED',
      label: t('modals.verification.rejection_reason.time_expired'),
    },
  ];

  const queryVars = {
    dancerId: dancerId,
    isAdmin: admin,
    hasLocation: true,
    blockedByBusinessId: businessId,
    inGroupBusinessId: businessId,
  };

  const { loading, error, data } = useQuery(GET_DANCER_GQL, {
    fetchPolicy: 'cache-and-network',
    variables: queryVars,
    onError: (err) => logError(err, 'GET_DANCER_GQL', ModalDancerForm.displayName),
  });
  const [dancerApprove] = useMutation(APPROVE_DANCER);
  const [dancerReject] = useMutation(REJECT_DANCER);

  const handleApproveReject = async (isApproved) => {
    if (isLoading) return;

    try {
      if (isApproved) {
        await dancerApprove({
          variables: { dancerId },
          update: (cache) => {
            // Update cache to reflect approval
            const data = cache.readQuery({
              query: GET_DANCER_GQL,
              variables: { dancerId },
            });
            cache.writeQuery({
              query: GET_DANCER_GQL,
              data: {
                ...data,
                dancer: { ...data.dancer, verification_status: 'ACCEPTED' },
              },
            });
          },
        });
        toast.success(t('photoVerificationPage.dancerApprove.success'));
      } else {
        await dancerReject({
          variables: {
            dancerId,
            rejectionReason,
            rejectionText: rejectOptions[rejectionReason],
            images: rejectionReason === 'INVALID_PROFILE_PICTURE' ? [targetImage] : [],
          },
          update: (cache) => {
            // Update cache to reflect rejection
            const data = cache.readQuery({
              query: GET_DANCER_GQL,
              variables: { dancerId },
            });
            cache.writeQuery({
              query: GET_DANCER_GQL,
              data: {
                ...data,
                dancer: { ...data.dancer, verification_status: 'REJECTED' },
              },
            });
          },
        });
        toast.success(t('photoVerificationPage.dancerReject.success'));
      }

      closeModal();
    } catch (error) {
      toast.error(t('photoVerificationPage.action.failure'));
      closeModal();
    }
  };

  const {
    data: application,
    error: appError,
    refetch: appRefetch,
  } = useQuery(GET_APPLICATION_GQL, {
    fetchPolicy: 'network-only',
    variables: { id: appId },
    skip: !(CAN_MANAGE_REQUESTS && appId),
    onError: (error) => console.log('Get application error', error),
  });

  const [deleteImages, { loading: deleting }] = useMutation(DELETE_DANCER_IMAGES, {
    update: (
      cache,
      {
        data: {
          dancer_delete_images: {
            dancer: { first_image, images },
          },
        },
      }
    ) => {
      cache.writeData({
        id: `Dancer:${dancerId}`,
        data: { first_image, images },
      });
    },
    onCompleted: () => toast.success(t('modals.dancer.image.delete.success')),
    onError: () => handleError(t('modals.dancer.image.delete.failure')),
  });

  const handleError = (message) => toast.error(`${t('errors.server.500')} ${message}}`);

  const handleDeleteImg = async (index) => {
    if (window.confirm(t('modals.dancer.image.delete.confirm'))) {
      const idArray = [data.dancer.images[index].id];
      try {
        await deleteImages({
          variables: {
            idArray,
            dancerId,
          },
        });
      } catch (error) {
        handleError(t('modals.dancer.image.delete.failure'));
      }
    }
  };

  const updateCache = (cache, data) => {
    const options = {
      fragment: DANCER_CAROUSEL_GQL,
      fragmentName: 'DancerCarousel',
      id: `Dancer:${dancerId}`,
    };
    const cachedData = cache.readFragment(options);
    const targetImage = cachedData.images.find(({ id }) => id === data.id);
    targetImage.is_verified = data.is_verified;
    if (data.rejection_text) {
      targetImage.rejection_text = data.rejection_text;
    }
    updateFragment(cache, cachedData, options);
  };

  const [rejectVerification, { loading: rejecting }] = useMutation(PROFILE_PICTURE_REJECT, {
    update: (cache, { data }) => {
      updateCache(cache, data.profile_picture_reject.image);
    },
    onCompleted: () => {
      handleImageVerification(null);
      toast.success(t('photoVerificationPage.reject.success'));
    },
  });

  const [approveVerification, { loading: approving }] = useMutation(PROFILE_PICTURE_APPROVE, {
    update: (cache, { data }) => {
      updateCache(cache, data.profile_picture_approve.image);
    },
    onCompleted: () => {
      handleImageVerification(null);
      toast.success(t('photoVerificationPage.approve.success'));
    },
  });

  const handleDancerMenuSelect = (e) => {
    setSelectedOption(e);
    if (e === ACCEPT_REJECT_ACTIONS.UNVERIFY) {
      handleDancerUnverify();
    }
  };

  const handleImageVerification = async (index) => {
    setSelectedOption(null);
    setTargetImage(index !== null ? data.dancer.images[index] : null);

    setRejectionReason(
      index !== null &&
        !data.dancer.images[index].is_verified &&
        !!options.find((item) => item.value === data.dancer.images[index].rejection_text)
        ? data.dancer.images[index].rejection_text
        : null
    );
  };

  const handleSubmitVerification = (isVerified) => {
    const id = targetImage.id;
    if (!isVerified) {
      rejectVerification({
        variables: {
          id,
          rejectionReason,
          rejectionText: PHOTO_VERIFICATION_REJECTION_REASON_TRANSLATION[rejectionReason],
        },
      });
    } else {
      approveVerification({ variables: { id, rejection_text: '' } });
    }
  };

  const handleDancerUnverify = () => {
    initModal(UI_MODALS.UNVERIFY, { dancer: data.dancer });
  };

  const handleOnOptionSelect = (value) => {
    setSelectedOption(null);
    const idx = carouselRef.current?.getCurrentIndex();
    switch (value) {
      case 'delete':
        handleDeleteImg(idx);
        break;
      case 'reject':
        handleImageVerification(idx);
        break;
      case 'unverify':
        handleDancerUnverify();
        break;
    }
  };

  const carouselCustomControls = () => {
    return (
      <DropDownButton
        button={<FaEllipsisH />}
        containerClassName={S.dropdownMenu}
        buttonClassName={S.dropdownMenuButton}
        options={[
          {
            text: t('common.profilePhoto'),
            disabled: true,
          },
          {
            value: 'delete',
            text: t('common.delete'),
            icon: <FaTrashAlt />,
            disabled: data.dancer.images.length < 2,
            title: data.dancer.images.length < 2 ? t('modals.dancer.image.delete.last') : '',
          },
          {
            value: 'reject',
            text: `${t('common.approve')} / ${t('common.reject')}`,
            icon: <FaCheck />,
            title: t('photoVerificationPage.title'),
          },
        ]}
        onSelect={handleOnOptionSelect}
      />
    );
  };

  const verificationIcon =
    data?.dancer?.verification_status === 'ACCEPTED' ? (
      <>
        <FaCheck color='green' />
      </>
    ) : data?.dancer?.verification_status === 'PENDING' ? (
      <>
        <FaClock color='grey' />
      </>
    ) : (
      <>
        <FaTimes color='red' />
      </>
    );

  const handleRejectionReasonChange = (e) => setRejectionReason(e.target.value);

  const handleOnActionSuccess = (ids, action) => {
    if (action === 'inviteCancel') {
      appRefetch();
    }
    onActionSuccess(ids, action);
  };

  const isLoading = loading || approving || rejecting || deleting;

  if (loading) {
    return <LoadingDiscoWithContainer />;
  }
  if (error) {
    return <h3 className={S.listError}>{t('errors.unable_to_load_dancer')}</h3>;
  }
  const {
    name,
    email,
    locations,
    registration_code: { code },
    profile_options,
    licenses,
    blocked_by,
    in_group: inGroup,
    verification_status,
    deleted,
  } = data?.dancer;

  return (
    <div className={S.dancerModalGrid}>
      {data.dancer.images.length ? (
        <ModalDancerCarousel
          dancerId={dancerId}
          blocked={blocked_by !== null}
          disabled={!!targetImage}
          inGroup={inGroup}
          customControls={carouselCustomControls}
          ref={carouselRef}
        />
      ) : (
        <div className='flex items-center justify-center w-1/2 bg-black bg-opacity-25'>{name} </div>
      )}
      <div className={S.infoArea}>
        <div className={S.nameArea}>
          <h3 className={S.rowArea}>
            <span>{name}</span>
            <span className='ml-2'>{verificationIcon}</span>
          </h3>

          {admin && email && <h4>{email}</h4>}
          {blocked_by && (
            <h2>
              {t('dancerPage.blocked_by')}: {blocked_by.name}
            </h2>
          )}
          <h5>
            {t('common.location')}
            {': '}
            <strong> {closestLocation({ lon: longitude, lat: latitude }, locations).location} </strong>
          </h5>
          {admin && (
            <h5>
              {t('common.invite_code')}
              {': '}
              <strong> {code} </strong>
            </h5>
          )}
          <h5>
            {t('modals.dancer.verificationStatus')}
            {': '}
            <strong>{t(`common.${verification_status.toLowerCase()}`)}</strong>
          </h5>
        </div>

        {
          /* default actions */
          !targetImage ? (
            <div>
              {!deleted && selectedOption === null && (
                <DancerMenu
                  handleSelect={handleDancerMenuSelect}
                  dancerId={dancerId}
                  removeDancer={removeDancer}
                  isDropDown
                  businessId={businessId}
                  verificationStatus={data?.dancer?.verification_status}
                />
              )}
              <div>
                {selectedOption === ACCEPT_REJECT_ACTIONS.ACCEPT && (
                  <label>
                    {t('common.approve')} {name}{' '}
                  </label>
                )}

                {selectedOption === ACCEPT_REJECT_ACTIONS.REJECT && (
                  <FormSelectField
                    label={t('photoVerificationPage.rejection_text')}
                    id='photo_verification-rejection_text'
                    name='rejectionReason'
                    handleChange={handleRejectionReasonChange}
                    value={rejectionReason}
                    isDisabled={isLoading}
                    options={rejectOptions}
                  />
                )}

                <div className='flex'>
                  {selectedOption !== null && (
                    <button
                      className='rounded border border-red-400 px-2.5 py-1.5 mr-4 font-medium shadow-sm hover:border-red-800 hover:text-gray-400'
                      onClick={handleImageVerification.bind(this, null)}
                    >
                      {t('common.cancel')}
                    </button>
                  )}

                  {selectedOption === ACCEPT_REJECT_ACTIONS.REJECT && (
                    <button
                      disabled={isLoading || !rejectionReason}
                      onClick={() => handleApproveReject(false)}
                      className={`rounded border border-transparent bg-red-600 px-2.5 py-1.5 mr-4 text-xs font-medium shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 ${
                        (!rejectionReason || isLoading) && 'disabled:opacity-50'
                      }`}
                    >
                      {t('common.reject')}
                    </button>
                  )}

                  {selectedOption === ACCEPT_REJECT_ACTIONS.ACCEPT && (
                    <button
                      disabled={isLoading || rejectionReason}
                      onClick={() => handleApproveReject(true)}
                      className={`inline-flex items-center rounded border border-transparent bg-green-600 px-2.5 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 ${
                        (isLoading || rejectionReason) && 'disabled:opacity-50'
                      }`}
                    >
                      {t('common.approve')}
                    </button>
                  )}
                </div>
              </div>

              {application?.shift_application && !appError && (
                <ApplicationActions
                  application={application.shift_application}
                  onActionSuccess={handleOnActionSuccess}
                />
              )}
              <div className='mt-4'>
                <label>
                  {t('common.about')} {name}{' '}
                </label>
                {data?.dancer?.bio && <p>{data.dancer.bio}</p>}
              </div>
              {profile_options.length > 0 && (
                <article>
                  <label>{t('common.style')}</label>
                  <div className={S.styleList}>
                    {profile_options.map(({ trans_key }) => t(`profileOptions.${trans_key}`)).join(' ')}
                  </div>
                </article>
              )}
              {licenses.length > 0 && (
                <article>
                  <label>{t('dancerPage.licenses')}</label>
                  <div className={S.licenseArea}>
                    {licenses.map((lis) => {
                      return (
                        <div key={lis.id} className={S.license}>
                          <div className={S.bold}>{lis.name}</div>
                          <div>{lis.license_number}</div>
                          <div>
                            {lis.city}, {lis.state}
                          </div>
                          <div>{lis.county}</div>
                          <div>
                            {dateFormat(lis.issue_date, timezone)} - {dateFormat(lis.expiration_date, timezone)}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </article>
              )}
            </div>
          ) : (
            <div>
              <FormSelectField
                label={t('photoVerificationPage.rejection_text')}
                id='photo_verification-rejection_text'
                name='rejectionReason'
                handleChange={handleRejectionReasonChange}
                value={rejectionReason}
                isDisabled={isLoading}
                options={options}
              />

              <div className='flex'>
                <button
                  className='rounded border border-red-400 px-2.5 py-1.5 mr-4 font-medium shadow-sm hover:border-red-800 hover:text-gray-400'
                  onClick={handleImageVerification.bind(this, null)}
                >
                  {t('common.cancel')}
                </button>

                <button
                  disabled={isLoading || !rejectionReason}
                  onClick={() => handleSubmitVerification(false)}
                  className={`rounded border border-transparent bg-red-600 px-2.5 py-1.5 mr-4 text-xs font-medium shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 ${
                    (!rejectionReason || isLoading) && 'disabled:opacity-50'
                  }`}
                >
                  {t('common.reject')}
                </button>

                <button
                  disabled={isLoading || rejectionReason}
                  onClick={() => handleSubmitVerification(true)}
                  className={`inline-flex items-center rounded border border-transparent bg-green-600 px-2.5 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 ${
                    (isLoading || rejectionReason) && 'disabled:opacity-50'
                  }`}
                >
                  {t('common.approve')}
                </button>
              </div>
            </div>
          )
        }
      </div>
    </div>
  );
};

ModalDancerForm.displayName = 'ModalDancerForm';

ModalDancerForm.propTypes = {
  dancerId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  appId: PropTypes.string,
  callback: PropTypes.func,
  removeDancer: PropTypes.func,
};

ModalDancerForm.defaultProps = {
  appId: null,
  callback: null,
  removeDancer: null,
};

export { ModalDancerForm };
