import { useMutation, useQuery } from '@apollo/react-hooks';
import React, { useCallback, useState } from 'react';
import * as PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import S from './modal-invites-booking-form.module.scss';
import { InviteBookingList } from './invite-calendar/invite-booking-list';
import { mergeMiniCalData } from '../../common/mini-calendar/mini-calendar-services';
import { MiniCalendar } from '../../common/mini-calendar/mini-calendar/mini-calendar';
import { useBusiness } from '../../../graphql/graph-hooks';
import { INVITE_TO_BOOKING } from '../../../graphql/mutations/shift-invite';
import { BOOKING_LIST_GQL } from '../../../graphql/queries/shift-for-invites';
import { logError } from '../../../helpers/errors/bug-report';
import * as TimeHelpers from '../../../helpers/times';

const ModalInviteBookingsForm = ({ onClose, dancerIds }) => {
  const { t } = useTranslation();
  const { timezone, id } = useBusiness();
  const [isInit, setIsInit] = useState(true);
  const [list, setList] = useState([]);
  const [dataObj, setDataObj] = useState({});
  const [bookingId, setBookingId] = useState(undefined);
  const [jsDate, setDate] = useState(TimeHelpers.getCurrentTimeAtTimezone(timezone).toDate());

  const [submitInvite, { loading: isInviting, client }] = useMutation(INVITE_TO_BOOKING, {
    onError: (err) => {
      logError(err, 'INVITE_TO_BOOKING', ModalInviteBookingsForm.displayName);
      let toastMessage = 'modals.invite.sent.failure';
      const errMsgParts = err.toString().split('GraphQL error: ');
      const errorMessage = errMsgParts[errMsgParts.length - 1];

      switch (errorMessage) {
        case 'DANCER_HAS_CONFLICTING_SHIFTS':
          toastMessage = 'modals.invite.sent.DANCER_HAS_CONFLICTING_SHIFTS';
          break;
        case 'UNDER_21_RESTRICTED':
          toastMessage = 'modals.invite.sent.UNDER_21_RESTRICTED';
          break;
      }
      toast.error(t(toastMessage));
    },
    update: (
      _,
      {
        data: {
          shift_invite: { notifications },
        },
      }
    ) => {
      // TODO: notifications[0].shift throws error - cannot read properties of undefined (reading 'shift')
      client.writeData({
        id: notifications[0].shift.id,
        data: {
          shift_applications: notifications[0].shift.shift_applications,
          invited_shift_applications_count: notifications[0].shift.invited_shift_applications_count,
        },
      });
    },
    onCompleted: () => {
      onClose();
      toast.success(t('modals.invite.sent.success'));
    },
  });

  const { loading, fetchMore } = useQuery(BOOKING_LIST_GQL, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: {
      businessID: id,
      endsAfter: TimeHelpers.startOfMonth(TimeHelpers.getCurrentTimeAtTimezone(timezone)),
      startsBefore: TimeHelpers.endOfMonth(TimeHelpers.getCurrentTimeAtTimezone(timezone)),
    },
    onError: (err) => logError(err, 'BOOKING_LIST_GQL', ModalInviteBookingsForm.displayName),
    onCompleted: (data) => {
      if (isInit) {
        const DATA = mergeMiniCalData(data.shifts.nodes, dataObj, timezone);
        setList(DATA?.[TimeHelpers.JSDateHelpers.date(jsDate)] || []);
        setIsInit(false);
        setDataObj(DATA);
      }
    },
  });

  const changeMonth = useCallback(
    async ({ activeStartDate }) =>
      await fetchMore({
        variables: {
          endsAfter: TimeHelpers.startOfMonth(activeStartDate),
          startsBefore: TimeHelpers.endOfMonth(activeStartDate),
        },
        updateQuery: (_, { fetchMoreResult }) => {
          setDataObj(mergeMiniCalData(fetchMoreResult.shifts.nodes, {}, timezone));
          return fetchMoreResult;
        },
      }),
    [fetchMore]
  );

  const handleDateChange = useCallback(
    (jsDate) => {
      setDate(jsDate);
      setList(dataObj[TimeHelpers.JSDateHelpers.date(jsDate)] || []);
      setBookingId(null);
    },
    [dataObj]
  );

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      if (!bookingId) return toast.info(t('modals.invite.select_booking'));
      const booking = list.find(({ id }) => id === bookingId);
      const alreadyInvitedIds = booking.shift_applications
        .filter(({ dancer: { id } }) => dancerIds.includes(id))
        .map(({ dancer: { id } }) => id);
      const notYetInvitedIds = dancerIds.filter((id) => !alreadyInvitedIds.includes(id));
      await submitInvite({ variables: { bookingId, dancerIds: notYetInvitedIds } });
    },
    [submitInvite, bookingId]
  );

  return (
    <form onSubmit={handleSubmit}>
      <div className={S.top}>
        <div>
          <MiniCalendar
            timezone={timezone}
            data={dataObj}
            jsDate={jsDate}
            isLoading={loading}
            dateChange={handleDateChange}
            monthChange={changeMonth}
          />
        </div>
        <InviteBookingList
          list={list}
          dancerIds={dancerIds}
          timezone={timezone}
          selectedDate={jsDate}
          selectedId={bookingId}
          setBookingId={setBookingId}
        />
      </div>

      <div className={S.actionArea}>
        <button className={S.invertedBtnRed} type='button' onClick={onClose} disabled={isInviting}>
          {t('common.cancel')}
        </button>
        <button className={S.invertedBtnGreen} type='submit' disabled={isInviting || !bookingId}>
          {t('modals.invite.invite')}
        </button>
      </div>
    </form>
  );
};

ModalInviteBookingsForm.displayName = 'ModalInviteBookingsForm';
ModalInviteBookingsForm.propTypes = {
  dancerIds: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
};

export { ModalInviteBookingsForm };
