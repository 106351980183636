import React, { useState, useMemo } from 'react';
import * as PropTypes from 'prop-types';

import defaultDancerPhoto from '../../../assets/user-placeholder-mask.png';
import defaultClubPhoto   from '../../../assets/club-placeholder.jpg';
import videoPlaceholder   from '../../../assets/video-placeholder.png';

const Image = ({
  alt,
  src,
  styles,
  title,
  fallback,
  classname,
  onClick
}) => {
  const [hasError, setHasError] = useState(false);
  const DEFAULT = useMemo(() => {
    if      (fallback === 'USER')  return defaultDancerPhoto;
    else if (fallback === 'CLUB')  return defaultClubPhoto;
    else if (fallback === 'VIDEO') return videoPlaceholder;
    else return null;
  }, [fallback]);

  if (hasError) {
    return (
      <img
        alt={alt}
        title={title}
        className={classname}
        style={styles}
        src={DEFAULT}
        onClick={onClick}
      />
    );
  } else {
    return (
      <img
        alt={alt}
        title={title}
        className={classname}
        style={styles}
        src={src || DEFAULT}
        onClick={onClick}
        onError={() => setHasError(true)}
      />
    );
  }
};

Image.displayName = 'Image';
Image.propTypes = {
  alt: PropTypes.string.isRequired,
  src: PropTypes.string,
  title: PropTypes.string,
  styles: PropTypes.object,
  fallback: PropTypes.oneOf(['USER', 'CLUB', 'VIDEO']),
  classname: PropTypes.string,
  onClick: PropTypes.func
};

Image.defaultProps = {
  src:       null,
  classname: null,
  onClick:   null,
  styles:    null,
  fallback:  null,
  title:     null,
};

export { Image };
